import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

const initialState: SessionState = {
  authUser: JSON.parse(localStorage.getItem("authUser") || "{}"),
};

export const sessionReducer = createSlice({
  name: "setAuthUser",
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<authUserInfo>) => {
      state.authUser = action.payload;
    },
  },
});

export const selectAuthUser = (state: RootState) => state.sessionState.authUser;
export const { setAuthUser } = sessionReducer.actions;
export default sessionReducer.reducer;
