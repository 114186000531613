import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentCrewEntryDescent from "./PopupContentCrewEntryDescent";

interface IProps {
  isOpen: boolean;
  closePopup: () => void;
  rowEditId: undefined | number;
  isOpenEditPopup: boolean;
  closeEditPopup: () => void;
  successCallback: () => void;
  eventType: CrewEntryDescentType;
}

const PopupCrewEntryDescentAdd: React.FC<IProps> = ({
  isOpen,
  closePopup,
  rowEditId,
  isOpenEditPopup,
  closeEditPopup,
  successCallback,
  eventType,
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
    closeEditPopup();
  }, [successCallback, closePopup, closeEditPopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: IAddCrewEntryDescent) => {
    setErrorMessage("");
    setBodyRequest(JSON.stringify(values));
  };

  const [bodyRequest, setBodyRequest] = useState<false | string>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `agent/crew-entries-descents${
      rowEditId !== undefined ? `/${rowEditId}` : ""
    }`,
    method: rowEditId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpenEditPopup === false || isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpenEditPopup, isOpen]);

  return (
    <DialogStyled
      open={isOpen || isOpenEditPopup}
      onClose={() => {
        closePopup();
        closeEditPopup();
      }}
    >
      <DialogTitleStyled
        title={
          eventType === CrewEntryDescentType.ENTRY
            ? t("add_crew_entry")
            : t("add_crew_descent")
        }
      />
      <PopupContentCrewEntryDescent
        rowId={rowEditId}
        onSubmit={onSubmit}
        eventType={eventType}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={() => {
          closePopup();
          closeEditPopup();
        }}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupCrewEntryDescentAdd;
