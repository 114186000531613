import {
  faBuilding,
  faInfo,
  faPeopleArrows,
  faUser,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import CompaniesInPortsOperationalSupport from "../../OperationalSupport/CompaniesInPorts";
import GuestsInPortsOperationalSupport from "../../OperationalSupport/GuestsInPorts";
import NotificationInPortsOperationalSupport from "../../OperationalSupport/NotificationsInPorts";
import SupportOperationalSupport from "../../OperationalSupport/Support";
import SupportReportOperationalSupport from "../../OperationalSupport/Support/SupportReport";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { selectOperationalSupportNotificationMenuItemName } from "../../../reducers/notificationsSummary";
import NotificationInPort from "../../OperationalSupport/NotificationInPort";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const notificationsMenuItemName = useAppSelector(
    selectOperationalSupportNotificationMenuItemName
  );

  const navLinksArrayOperationalSupport: navLinksArrayTimeWorkerInterface = {
    profileId: 13,
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/operational-support/notifications/:id",
        component: (props) => <NotificationInPort {...props} />,
      },
    ],

    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/operational-support/profile",
        component: () => <Profile />,
      },
      {
        icon: faPeopleArrows,
        name: t("support"),
        ifHidden: false,
        singleLink: false,
        path: [
          {
            name: t("support"),
            path: "/operational-support/home",
            ifHidden: false,
            component: () => <SupportOperationalSupport />,
          },
          {
            name: t("support_report"),
            path: "/operational-support/support-report",
            ifHidden: false,
            component: () => <SupportReportOperationalSupport />,
          },
        ],
      },
      {
        icon: faUserFriends,
        name: notificationsMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/operational-support/notifications",
        component: () => <NotificationInPortsOperationalSupport />,
      },
      {
        icon: faUser,
        name: t("persons"),
        ifHidden: false,
        singleLink: true,
        path: "/operational-support/persons",
        component: () => <GuestsInPortsOperationalSupport />,
      },
      {
        icon: faBuilding,
        name: t("companies"),
        ifHidden: false,
        singleLink: true,
        path: "/operational-support/companies",
        component: () => <CompaniesInPortsOperationalSupport />,
      },
    ],
  };

  return navLinksArrayOperationalSupport;
};
export default useNavLinksArray;
