import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";

const useColumns = (profileType: ProfileType) => {
  const { t } = useTranslation();

  return profileType === ProfileType.OPERATIONAL_SUPPORT
    ? [
        { name: "id", title: t("number") },
        { name: "date", title: t("date"), type: "date" },
        { name: "timeFrom", title: t("time_from") },
        { name: "timeTo", title: t("time_to") },
        { name: "numberOfEmployees", title: t("number_of_people") },
        { name: "companies", title: t("companies") },
        { name: "createdAt", title: t("data_created"), type: "date" },
        { name: "employeesFilter", title: "employeesFilter", hide: true },
      ]
    : [
        { name: "id", title: t("number") },
        { name: "date", title: t("date"), type: "date" },
        { name: "timeFrom", title: t("time_from") },
        { name: "timeTo", title: t("time_to") },
        { name: "numberOfEmployees", title: t("number_of_people") },
        { name: "companies", title: t("companies") },
        { name: "createdAt", title: t("data_created"), type: "date" },
        { name: "employeesFilter", title: "employeesFilter", hide: true },
      ];
};

export default useColumns;
