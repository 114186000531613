import React, { useState, useEffect, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import "./hoursRequest.scss";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import TimeWithDescription from "../../NumberWithDescription/numberWithDescription";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import { ProfileType } from "../../../../enums/profileType";

interface TopRowsInterface {
  id: number;
  selectedDate: Date;
  open: boolean;
  isLoadingTopRows: boolean;
  setfromHour: Dispatch<string>;
  setTillHour: Dispatch<string>;
  setIsLoadingTopRows: Dispatch<boolean>;
  timeWorkerId?: number;
}

const TopRows = ({
  id,
  selectedDate,
  setfromHour,
  setTillHour,
  isLoadingTopRows,
  setIsLoadingTopRows,
  timeWorkerId,
}: TopRowsInterface) => {
  const { t } = useTranslation();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  const [topRowsData, setTopRowsData] = useState<any>({});
  const [isErrorTopRows, setIsErrorTopRows] = useState<boolean>(false);
  const [ifFetchTopRows, setIfFetchTopRows] = useState(true);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const hoursRequestsTopRowsResponse = useFetchGet(
    profileType === ProfileType.SUPERIOR_TIME
      ? `superior-time/hour-request-information?TimeWorkerId=${timeWorkerId}&day=${computeDate(
          selectedDate,
          "T00:00:00"
        )}`
      : `worker-time/${authUserId}/hour-request-information?TimeWorkerId=${
          authUser.currentProfile.subjectId
        }&day=${computeDate(selectedDate, "T00:00:00")}`,
    ifFetchTopRows
  );

  useEffect(() => {
    if (hoursRequestsTopRowsResponse.error !== null) {
      setIsErrorTopRows(true);
      setIfFetchTopRows(false);
      return;
    }
    if (hoursRequestsTopRowsResponse.data === null) return;

    if (!id || (id && id <= 0)) {
      setfromHour(
        convertMinsToHrsMins(
          hoursRequestsTopRowsResponse.data.offeredTimeFromInMin
        )
      );
      setTillHour(
        convertMinsToHrsMins(
          hoursRequestsTopRowsResponse.data.offeredTimeToInMin
        )
      );
    }

    setIfFetchTopRows(false);
    setIsLoadingTopRows(false);
    setTopRowsData(hoursRequestsTopRowsResponse.data);
  }, [
    hoursRequestsTopRowsResponse,
    setfromHour,
    setTillHour,
    setIsLoadingTopRows,
    id,
  ]);

  useEffect(() => {
    setIsErrorTopRows(false);
    setIfFetchTopRows(true);
    setIsLoadingTopRows(true);
  }, [selectedDate, setIsLoadingTopRows, timeWorkerId]);

  return (
    <LoadingWrapper
      isLodadingProgress={isLoadingTopRows}
      isError={isErrorTopRows}
      setIfFetchAgain={setIfFetchTopRows}
      setIsError={setIsErrorTopRows}
    >
      {!isLoadingTopRows && !isErrorTopRows && (
        <div>
          <TimeWithDescription
            size="small"
            parametrs={[
              { name: t("day_schema") },
              {
                time:
                  new Date(topRowsData.daySchemaFrom).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  ) +
                  " - " +
                  new Date(topRowsData.daySchemaTo).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  ),
                description: t("plan"),
              },
              {
                time:
                  new Date(topRowsData.entry).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  ) +
                  " - " +
                  new Date(topRowsData.exit).toLocaleString(
                    t("scheduler_language"),
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  ),
                description: t("execution"),
              },
            ]}
          />

          <TimeWithDescription
            size="small"
            parametrs={[
              { name: t("overtime_to_take") },
              {
                time: convertMinsToHrsMins(topRowsData.overtimeToTakeToday),
                description: t("today"),
              },
              {
                time: convertMinsToHrsMins(
                  topRowsData.overtimeToTakeRequestDay
                ),
                description: t("request_day"),
              },
              {
                time: convertMinsToHrsMins(
                  topRowsData.overtimeToTakeAllRequests
                ),
                description: t("all_requests"),
              },
            ]}
          />

          <TimeWithDescription
            size="small"
            parametrs={[
              { name: t("days_to_take") },
              {
                time: topRowsData.daysToTakeToday,
                description: t("today"),
              },
              {
                time: topRowsData.daysToTakeRequestDay,
                description: t("request_day"),
              },
              {
                time: topRowsData.daysToTakeAllRequests,
                description: t("all_requests"),
              },
            ]}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};

export default TopRows;
