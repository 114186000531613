import React from "react";
import "./numberWithDescription.scss";
import ValueUnitDescription from "./valueUnitDescription";

interface ObjectInterface {
  name?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: string;
  time?: string | number;
  unit?: string | number;
}

interface Props {
  parametrs: Array<ObjectInterface>;
  size?: "small" | "big";
  titlePadding?: string;
}

const TimeWithDescription: React.FC<Props> = (props) => {
  if (props.parametrs.length === 1 || props.parametrs.length === 0) return null;
  if (props.size === "small") {
    return (
      <div className="numberWithDescriptionSmall">
        <div>
          <div className="titleSmall">{props.parametrs[0].name}</div>
          <div className="elementsSmall">
            {props.parametrs.slice(1).map((value, inex) => {
              return (
                <div key={inex} className="containerSmall">
                  <div className="vauleSmall">
                    {value.time} {value.unit}
                  </div>
                  <div className="descriptionSmall">{value.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="numberWithDescription">
      <div
        className="title"
        style={
          props?.titlePadding ? { padding: props.titlePadding } : undefined
        }
      >
        {props.parametrs[0].name}
      </div>
      <div className="elements">
        {props.parametrs.slice(1).map((value, inex) => {
          return (
            <ValueUnitDescription
              key={inex}
              value={value.time}
              unit={value.unit}
              description={value.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TimeWithDescription;
