import { useTranslation } from "react-i18next";
import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import { ProfileType } from "../../../enums/profileType";
import CrewEntriesDescentsContent from "../../helpersComponents/CrewEntriesDescents/CrewEntriesDescentsContent";

function CrewDescentsShiftDirector() {
  let { t } = useTranslation();
  return (
    <div>
      <CrewEntriesDescentsContent
        eventType={CrewEntryDescentType.DESCENT}
        topBannerTitle={t("crew_descent")}
        pathChangeStatus={"shift-director/crew-entry-descent-change-status"}
        newStatusIdConfirm={4}
        profileType={ProfileType.SHIFT_DIRECTOR}
        layoutSettingName="layoutShiftDirectorCrewDescents"
        refreshingButton={true}
      />
    </div>
  );
}

export default CrewDescentsShiftDirector;
