import React, { useEffect } from "react";
import "./scheduler.scss";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  MonthView,
  Toolbar,
  SchedulerProps,
  DateNavigator,
  WeekView,
  ViewSwitcher,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Menu, MenuItem, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import classNames from "clsx";
import { useTranslation } from "react-i18next";
import Button from "../Button/button";
import TableCell from "@material-ui/core/TableCell";
import { useState } from "react";
import mouseState from "../../../HelpersFunctions/mouseDownUpState";
import compareDatesIgnoringTime from "../../../HelpersFunctions/dateAndTime/compareDatesIgnoringTime";

const initialState = {
  mouseX: null,
  mouseY: null,
};

///////////// some style /////////

const styles: any = (theme) => ({
  cell: {
    position: "relative",
    userSelect: "none",
    verticalAlign: "top",
    padding: 0,
    height: 100,
    border: "1px solid #e7eaec",
    "&:first-child": {
      borderLeft: "none",
    },
    "&:last-child": {
      paddingRight: 0,
    },
    "tr:last-child &": {
      borderBottom: "none",
    },
  },
  text: {
    padding: "0.5em",
    textAlign: "center",
  },
  appointment: {
    borderRadius: "10px",
  },
});

/////////// get date range of current view //////////
const getRange = (date, currentViewName) => {
  if (currentViewName === "Month") {
    return {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59),
    };
  }
  if (currentViewName === "CurrentWeek") {
    let firstDay = date.getDate() - date.getDay();
    let lastDay = firstDay + 6;
    return {
      startDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        firstDay,
        0,
        0,
        0
      ),
      endDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        lastDay,
        23,
        59,
        59
      ),
    };
  }
  if (currentViewName === "Day") {
    return {
      startDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0
      ),
      endDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
      ),
    };
  }
};

////////// view switch component ///////////
const SwitcherComponent =
  (t, onChange: (viewName: "Month" | "CurrentWeek" | "Day") => void) => () => {
    return (
      <div className="changeView">
        <Button
          onClick={() => {
            onChange("Month");
          }}
        >
          {t("month")}
        </Button>
        <Button
          onClick={() => {
            onChange("CurrentWeek");
          }}
        >
          {t("week")}
        </Button>
        <Button
          onClick={() => {
            onChange("Day");
          }}
        >
          {t("day")}
        </Button>
      </div>
    );
  };

interface ShedulerInterface extends SchedulerProps {
  currentViewName: "Month" | "CurrentWeek" | "Day";
  currentDate: Date;
  // components: JSX.Element[]
  components: any;
  setCurrentDate: React.Dispatch<Date>;
  // isDayOffCheck?: boolean;
  setCurrentViewName: (data: "Month" | "CurrentWeek" | "Day") => void;
  contextMenuOptions?: {
    optionName: React.ReactNode;
    onClick: (selectedRange: SchedulerContextMenuCallbackData) => void;
  }[];
}

function Sheduler({
  currentViewName,
  currentDate,
  data,
  components,
  setCurrentDate,
  // isDayOffCheck,
  setCurrentViewName,
  contextMenuOptions,
}: ShedulerInterface) {
  const { t } = useTranslation();

  //////////// background color appointment ////////////
  let AppointmentsAppointment: any = Appointments.Appointment;
  const Appointment: any = ({ children, style, ...restProps }) => {
    let backgroundColor = "#1ab394";
    // if (restProps.data.backgroundColor === "white") return <Appointments.Appointment
    //   {...restProps}
    //   style={{
    //     ...style,
    //     minHeight: '20px',
    //     minWidth: '20px',
    //     display: "none",
    //   }}
    //   className="customAppointment"
    // >
    //   <></>
    // </Appointments.Appointment>
    if (restProps.data.backgroundColor)
      backgroundColor = restProps.data.backgroundColor;
    return (
      <AppointmentsAppointment
        {...restProps}
        style={{
          ...style,
          minHeight: "20px",
          minWidth: "20px",
          background: backgroundColor,
        }}
        className="customAppointment"
      >
        {components[restProps.data.id]}
      </AppointmentsAppointment>
    );
  };

  ////////////////// today button ///////////////
  const Today: any = ({ children, style, ...restProps }) => {
    return (
      <Button
        onClick={() => {
          if (
            currentDate.getFullYear() === new Date().getFullYear() &&
            currentDate.getDate() === new Date().getDate() &&
            currentDate.getMonth() === new Date().getMonth()
          )
            return;
          setCurrentDate(new Date());
        }}
      >
        {t("today")}
      </Button>
    );
  };

  //////////////// context menu functions ///////////

  const [state, setState] = useState<any>(initialState);

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState(initialState);
  };

  const getSelectedStartEndDate = (): SchedulerContextMenuCallbackData => {
    let dateArrayRangeCopy = [...dateArrayRange];
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    dateArrayRangeCopy.forEach((isSelected, index) => {
      if (startDate !== null) return;
      if (isSelected === true) {
        startDate = new Date(new Date(currentDate).setDate(index));
        startDate.setHours(0, 0, 0);
      }
    });
    dateArrayRangeCopy.reverse();
    dateArrayRangeCopy.forEach((isSelected, index) => {
      if (endDate !== null) return;
      if (isSelected === true) {
        endDate = new Date(
          new Date(currentDate).setDate(dateArrayRangeCopy.length - index - 1)
        );
        endDate.setHours(23, 59, 59);
      }
    });
    let selectedPoints: { [key in any]: any }[] = [];

    if (startDate !== null && endDate !== null) {
      data?.forEach((element) => {
        if (startDate === null) return;
        if (endDate === null) return;
        let elementDate = new Date(element.startDate);
        if (
          elementDate.getFullYear() >= startDate.getFullYear() &&
          elementDate.getMonth() >= startDate.getMonth() &&
          elementDate.getDate() >= startDate.getDate() &&
          elementDate.getFullYear() <= endDate.getFullYear() &&
          elementDate.getMonth() <= endDate.getMonth() &&
          elementDate.getDate() <= endDate.getDate()
        ) {
          selectedPoints.push(element);
        }
      });
    }
    return { startDate, endDate, selectedPoints };
  };

  /////////////////////// selecting cells ////////////////////////

  const [dateArrayRange, setDateArrayRange] = useState<any>([]);

  useEffect(() => {
    let range: any = getRange(currentDate, "Month");
    let dateArray: any = [0];
    let dt = new Date(range.startDate);
    while (dt <= range.endDate) {
      // dateArray.push([dt.getFullYear(), dt.getMonth(), dt.getDate()]);
      dateArray.push(false);
      dt.setDate(dt.getDate() + 1);
    }
    setDateArrayRange(dateArray);
  }, [currentDate]);

  const setSelectedRange = (event, dayIndex, resetState = false) => {
    if (event.buttons === 4 || event.buttons === 2) return;

    const dateArrayRangeCopy = [...dateArrayRange];
    if (resetState) {
      dateArrayRangeCopy.forEach((value, index) => {
        dateArrayRangeCopy[index] = false;
      });
      dateArrayRangeCopy[0] = dayIndex;
    }

    if (dayIndex >= dateArrayRangeCopy[0]) {
      dateArrayRangeCopy.forEach((value, index) => {
        if (index === 0) return;
        if (index >= dateArrayRangeCopy[0] && index <= dayIndex)
          dateArrayRangeCopy[index] = true;
        else dateArrayRangeCopy[index] = false;
      });
    } else {
      dateArrayRangeCopy.forEach((value, index) => {
        if (index === 0) return;
        if (index <= dateArrayRangeCopy[0] && index >= dayIndex)
          dateArrayRangeCopy[index] = true;
        else dateArrayRangeCopy[index] = false;
      });
    }
    setDateArrayRange(dateArrayRangeCopy);
  };

  ////////////// month view - cell /////////////

  const CellBase = ({ classes, startDate, formatDate, otherMonth }) => {
    const isFirstMonthDay = startDate.getDate() === 1;
    const formatOptions = isFirstMonthDay
      ? { day: "numeric", month: "long" }
      : { day: "numeric" };

    const dayNumber = startDate.getDate();

    // if day is not current month, display empty gray cell
    if (otherMonth)
      return (
        <TableCell tabIndex={0} className={classNames(classes.cell)}>
          <div className="backgronudDisabled">
            <div></div>
          </div>
        </TableCell>
      );

    return (
      <TableCell
        tabIndex={0}
        className={
          classNames(classes.cell) +
          (dateArrayRange[dayNumber] ? " backgronudSelected" : "") +
          " cursorPointer"
        }
        onMouseDown={(e) => {
          setSelectedRange(e, dayNumber, true);
        }}
        onMouseEnter={(e) => {
          if (mouseState() === "up") return;
          setSelectedRange(e, dayNumber);
        }}
      >
        <div className={classes.text}>
          {formatDate(startDate, formatOptions)}
        </div>
        <div
          className={"cellMonth"}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {data?.map((value: any, i) => {
            if (compareDatesIgnoringTime(startDate, value.startDate)) {
              return (
                <span key={i} className="cursorPointer">
                  {components[value.id]}
                </span>
              );
            }
            return null;
          })}
        </div>
      </TableCell>
    );
  };

  const TimeTableCell: any = withStyles(styles, { name: "Cell" })(CellBase);

  //////////////// hide empty rows from other months ///////////
  const RowBase = ({ children }) => {
    let otherMonthLenght = 0;
    children.forEach((value) => {
      if (value.props.otherMonth) otherMonthLenght += 1;
    });
    if (otherMonthLenght === 7) {
      return <></>;
    }

    return <MonthView.TimeTableRow>{children}</MonthView.TimeTableRow>;
  };
  const TimeTableRow: any = withStyles(styles, { name: "Cell" })(RowBase);

  return (
    <Paper onContextMenu={handleClick}>
      <Scheduler
        data={data}
        locale={t("scheduler_language")}
        firstDayOfWeek={1}
      >
        <ViewState
          currentViewName={
            currentViewName === "CurrentWeek" ? "Week" : currentViewName
          }
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
        />
        <MonthView
          timeTableCellComponent={TimeTableCell}
          timeTableRowComponent={TimeTableRow}
        />

        <WeekView
          startDayHour={0}
          endDayHour={24}
          intervalCount={1}
          cellDuration={60}
        />
        <DayView
          name="Day"
          startDayHour={0}
          endDayHour={24}
          intervalCount={1}
          cellDuration={60}
        />
        <Toolbar />
        <ViewSwitcher
          switcherComponent={SwitcherComponent(t, setCurrentViewName)}
        />

        <DateNavigator />
        <TodayButton buttonComponent={Today} />
        {currentViewName !== "Month" ? (
          <Appointments appointmentComponent={Appointment} />
        ) : null}
      </Scheduler>
      {contextMenuOptions && contextMenuOptions?.length > 0 && (
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={() => {
            handleClose();
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          {contextMenuOptions?.map((singleMenu, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                  singleMenu.onClick(getSelectedStartEndDate());
                }}
              >
                {singleMenu.optionName}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Paper>
  );
}

export default Sheduler;
