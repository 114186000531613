import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import ButtonStyled from "../../../helpersComponents/Button/button";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { TimeUnit } from "../../../../Constants/timeUnit";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";

export default function HolidaysValidationContent() {
  const { t } = useTranslation();
  const columns = [
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "organizationalUnit", title: t("organizational_units") },
    { name: "remainedOverdueLeave", title: t("overdue_vacation") },
    {
      name: "fourteenConsecutiveDaysOff",
      title: t("14_days_off_in_a_row"),
    },
  ];

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>(() => {
    let selected: number[] = [];
    if (process.env.NODE_ENV === "development") {
      selected = [2, 4, 6, 7, 10];
    }
    return selected;
  });
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);
  const [allWorkers, setAllWorkers] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Year")
  );

  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Year"));
  const [selectedWorkersString, setSelectedWorkersString] = useState<
    string | undefined
  >();

  const modifyResponseCallback = useCallback(
    (responseData) => {
      if (responseData && responseData.resJson) {
        const modified = responseData.resJson.map((row) => {
          return {
            ...row,
            fourteenConsecutiveDaysOff: row.fourteenConsecutiveDaysOff ? (
              <FontAwesomeIcon
                className={styles.faCheckCircle}
                icon={faCheckCircle}
              />
            ) : (
              <FontAwesomeIcon
                className={styles.faTimesCircle}
                icon={faTimesCircle}
              />
            ),
            remainedOverdueLeave:
              row.remainedOverdueLeaveUnit === TimeUnit.MINUTES
                ? `${convertMinsToHrsMins(row.remainedOverdueLeave)} ${t(
                    "hours_2"
                  )}`
                : `${row.remainedOverdueLeave} ${t("days")}`,
            id: row.timeWorkerId,
          };
        });

        return modified;
      }

      return [];
    },
    [t]
  );

  const [holidaysValidationBody, setHolidaysValidationBody] = useState<
    false | string
  >(false);

  const [
    fetchingStateholidaysValidationRows,
    fetchAgainholidaysValidationRows,
  ] = useFetchOtherThanGET({
    path: "superior-time/holidays-validation",
    method: "POST",
    contentType: "application/json",
    body: holidaysValidationBody,
    setBody: setHolidaysValidationBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: modifyResponseCallback,
  });

  useEffect(() => {
    let requestBodyObj = {
      dateFrom: startDate,
      dateTo: endDate,
      timeWorkersIds: allWorkersSelected
        ? []
        : selectedWorkersString
        ? selectedWorkersString.split(",").map((e) => parseInt(e))
        : [],
    };

    setHolidaysValidationBody(JSON.stringify(requestBodyObj));
  }, [startDate, endDate, allWorkersSelected, selectedWorkersString]);

  useEffect(() => {
    if (startDate && endDate && selectedWorkersString) {
      fetchAgainholidaysValidationRows();
    }
  }, [
    startDate,
    endDate,
    selectedWorkersString,
    fetchAgainholidaysValidationRows,
  ]);

  const confirmFilters = () => {
    if (allWorkers === undefined) return;
    let selectedWorkersLocal = [...selectedWorkers];
    if (selectedWorkers.length === 0) {
      allWorkers.forEach((worker) => {
        selectedWorkersLocal.push(worker.id);
      });
    }
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
  };

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateholidaysValidationRows.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <ButtonStyled
            onClick={() => {
              confirmFilters();
            }}
          >
            {t("get_employees")}
          </ButtonStyled>
        )}
      </div>
      <span className="errorMessaggeSummarySuperior">
        {fetchingStateholidaysValidationRows.isError &&
          t("something_went_wrong")}
      </span>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("holidays_validation")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName="layoutSuperiorTimeSummarySummariesOfManyEmployeesEmployees"
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          />
        </div>

        <div>
          {fetchingStateholidaysValidationRows.response?.resJson && (
            <TableComponent
              columns={columns}
              rows={fetchingStateholidaysValidationRows.response?.resJson ?? []}
              layoutSettingName="layoutSuperiorTimeAnalysisHolidaysValidation"
              hideActionsAndVisibilityColumns={true}
              tableEditColumnWidth={230}
            />
          )}
        </div>
      </div>
    </>
  );
}
