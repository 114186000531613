import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import GuestsList from "../../helpersComponents/GuestsList";
import AccordionStyled from "../../helpersComponents/MaterialUi/Accordion/AccordionStyled";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../helpersComponents/RowDetailsHelpers";

const RowDetailCrewEntryDescent = ({
  row,
}: {
  row: ICrewEntryDescentGuest;
}) => {
  const { t } = useTranslation();
  const guestsInfo = row?.guests.map((item) => ({
    notificationGuestId: item.crewEntryDescentGuestId,
    guest: item.guest,
    letIn: item.isLetIn,
    securityFullName: item.securityFullName,
    securityDateConfirmation: item.securityDateConfirmation,
    securityRejectionReason: `${item.securityRejectionReason} ${
      item.securityRejectionDescription
        ? ` - ${item.securityRejectionDescription}`
        : ""
    }`,
  }));

  return (
    <>
      <RowDetailsContainer>
        <AccordionStyled title={t("information")} defaultExpanded={true}>
          <>
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("date_of_arrival_ship")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.arrivalOfShipDate
                    ? toLocaleStringCustom({
                        date: row.crewEntryDescent.arrivalOfShipDate,
                        t,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("ship")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.shipName
                    ? row.crewEntryDescent.shipName
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("confirmation_pfso")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.pfsoFullName
                    ? row.crewEntryDescent.pfsoFullName + ", "
                    : ""}
                  {row?.crewEntryDescent.pfsoDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.crewEntryDescent.pfsoDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>
                  {t("confirmation_shift_director")}:
                </RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.shiftDirectorFullName
                    ? row.crewEntryDescent.shiftDirectorFullName + ", "
                    : ""}
                  {row?.crewEntryDescent.shiftDirectorDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.crewEntryDescent
                          .shiftDirectorDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("created")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.addingPersonFullName
                    ? row?.crewEntryDescent.addingPersonFullName + ", "
                    : ""}
                  {row?.crewEntryDescent.createdAt
                    ? toLocaleStringCustom({
                        date: row.crewEntryDescent.createdAt,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("rejection_reason")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.rejectionReason
                    ? row.crewEntryDescent.rejectionReason
                    : "-"}
                  {row?.crewEntryDescent.rejectionDescription &&
                    ` (${row.crewEntryDescent.rejectionDescription})`}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.crewEntryDescent.comments
                    ? row.crewEntryDescent.comments
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>
          </>
        </AccordionStyled>

        {/*  */}
        <GuestsList
          notificationId={row?.crewEntryDescent.id}
          guestsInfo={guestsInfo}
          defaultExpanded={true}
          onlyNames={true}
        />
      </RowDetailsContainer>
    </>
  );
};

export default RowDetailCrewEntryDescent;
