import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export default function useWorkerTimeConxtexMenuSchedule() {
  const { t } = useTranslation();
  const history = useHistory();

  return [
    {
      optionName: t("add_business_trip"),
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/delegations-list",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("submit_hours_application"),
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/hours-request",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("submit_a_holiday_application"),
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/holiday",
          state: selectedDateRange,
        });
      },
    },
    {
      optionName: t("plan_holidays"),
      onClick: (selectedDateRange: SchedulerContextMenuCallbackData) => {
        history.push({
          pathname: "/worker-time/holidays-plan",
          state: selectedDateRange,
        });
      },
    },
  ];
}
