import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TableAndActions from "./tableAndActions";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { CircularProgress } from "@material-ui/core";
import Button from "../../../helpersComponents/Button/button";
import { INotification } from "./type";
import useRequestStatusName from "../../../../hooks/useRequestStatusName/useRequestStatusName";

function HolidaysRequestsContent() {
  const date = new Date();
  const { t } = useTranslation();
  const translateRequestStatusName = useRequestStatusName();

  const [notificationsParams, setNotificationsParams] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59),
    showOnlyIntroduced: false,
  });

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const modifyResponseCallback = useCallback(
    (responseData: INotification[]) => {
      const modified = responseData?.map((row, index) => {
        return {
          ...row,
          guest: `${row.guestLastName} ${row.guestFirstName}`,
          requestStatus: translateRequestStatusName(row.requestStatus),
        };
      });
      return modified;
    },
    [translateRequestStatusName]
  );

  const [notifications, fetchingStateNotifications, fetchAgainNotifications] =
    useFetchAndSetGET<INotification[]>({
      path:
        "superior-time/notifications?dateFrom=" +
        computeDateRangeFetch(notificationsParams.startDate, "T00:00:00") +
        "&dateTo=" +
        computeDateRangeFetch(notificationsParams.endDate, "T23:59:59") +
        `&showOnlyIntroduced=${
          notificationsParams.showOnlyIntroduced ? "true" : "false"
        }`,
      modifyResponseCallback: modifyResponseCallback,
    });

  const columns = [
    { name: "arrivalDate", title: t("date_of_arrival"), type: "date_hours" },
    { name: "guest", title: t("guest") },
    { name: "announces", title: t("notifying_employee") },
    { name: "conferenceRoomName", title: t("conference_room") },
    { name: "parkingName", title: t("parking_lot") },
    { name: "requestStatus", title: t("status") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "approverName", title: t("approved_by") },
    { name: "description", title: t("description") },
  ];

  const confirmFilters = (showOnlyIntroduced) => {
    setNotificationsParams((prev) => {
      return {
        ...prev,
        showOnlyIntroduced,
      };
    });
  };

  useEffect(() => {
    fetchAgainNotifications();
  }, [notificationsParams, fetchAgainNotifications]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateNotifications.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <div>
            {" "}
            <Button
              onClick={() => {
                confirmFilters(false);
              }}
            >
              {t("refresh")}
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => {
                confirmFilters(true);
              }}
            >
              {t("show_introduced")}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      <TopBanner pathName={t("list_of_notifications")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={notificationsParams.startDate}
            date2={notificationsParams.endDate}
            setDate={(value) => {
              setNotificationsParams((prev) => {
                return { ...prev, startDate: new Date(value) };
              });
            }}
            setDate2={(value) => {
              setNotificationsParams((prev) => {
                return { ...prev, endDate: new Date(value) };
              });
            }}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>

        {!fetchingStateNotifications.isFetching &&
          !fetchingStateNotifications.isError && (
            <TableAndActions
              rows={notifications}
              columns={columns}
              parallerPatchPath={"superior-time/notifications"}
              settingsLayoutName={"layoutSuperiorTimeNotificationsList"}
              fetchObject={{
                fetchAgain: fetchAgainNotifications,
                isError: fetchingStateNotifications.isError,
                isFetching: fetchingStateNotifications.isFetching,
                response: fetchingStateNotifications.response,
                setIsFetching: () => {},
              }}
            />
          )}
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default HolidaysRequestsContent;
