const doFetch = async (
  requestType: "PUT" | "POST" | "PATCH" | "DELETE",
  path: string,
  arrayId: number[],
  body: any
) => {
  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  let language = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  let requests = arrayId.map((id) => {
    // if (i === 1) id = "/ghjsdsd"
    // id = "sdfsd"
    return fetch(window.globalConfig.API_URL + "/api/" + path + "/" + id, {
      method: requestType,
      credentials: window.globalConfig?.LOG_BY_WINDOWS_DOMAIN
        ? "include"
        : "omit",
      headers: {
        Authorization: "bearer " + authUser.token,
        "Accept-Language": language ? language[0] : "pl",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  });

  const finalResponse = await Promise.allSettled(requests)
    .then((responses: any) =>
      Promise.allSettled(
        responses.map((r) => {
          if (r.value.ok) return "ok";
          else if (r.value.status === 400) return r.value.json();
          else return r.value.status;
        })
      )
    )
    .then((finalResponses: any) => {
      let returningFinalResponse: any = {
        isAllGood: true,
        isAllFail: true,
        ifLogOut: false,
        receivedIdsArray: arrayId,
        results: {},
      };
      finalResponses.forEach((response, index) => {
        if (response.value !== "ok") {
          if (response.value === 401) returningFinalResponse.ifLogOut = 401;
          returningFinalResponse.isAllGood = false;

          if (response.value?.status === 400)
            return (returningFinalResponse.results[arrayId[index]] =
              response.value);
          returningFinalResponse.results[arrayId[index]] = {
            status: response.value,
          };
        } else {
          returningFinalResponse.isAllFail = false;
          returningFinalResponse.results[arrayId[index]] = "ok";
        }
      });
      return returningFinalResponse;
    });
  return finalResponse;
};

export default doFetch;
