import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import "./notificationList.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  TextFieldStyled,
  CheckboxStyled,
} from "../../../helpersComponents/MaterialUi/index";
import Button from "../../../helpersComponents/Button/button";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";

const AddGuestForm = (props) => {
  const { t } = useTranslation();
  const [firstNameGuest, setFirstNameGuest] = useState<any>("");
  const [lastNameGuest, setLastNameGuest] = useState<any>("");
  const [companyGuest, setCompanyGuest] = useState<any>("");
  const [descriptionGuest, setDescriptionGuest] = useState<any>("");
  const [orderGuest, setOrderGuest] = useState<number>(1000);
  const [firstNameErrorGuest, setFirstNameErrorGuest] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [lastNameErrorGuest, setLastNameErrorGuest] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [postRequestGuestBody, setPostRequestGuestBody] = useState<any>(false);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let postRequestGuest = useFetchPost(
    `worker-time/${authUserId}/guest-notifications`,
    postRequestGuestBody
  );

  const {
    setCheckbox,
    setPutingPostingCircularProgressGuest,
    setIsLoading,
    setResponseErrorMessageGuest,
    setIfIgnoreTime,
    setAddedIdGuest,
    setIfCheckForUpdateCache,
    setShowAddGuestForm,
  } = props;

  useEffect(() => {
    if (postRequestGuest.data?.status === 201) {
      let id = postRequestGuest.data.location.substring(
        postRequestGuest.data.location.lastIndexOf("/") + 1
      );
      setAddedIdGuest(parseInt(id));
      setDescriptionGuest("");
      setIsLoading(true);
      setFirstNameGuest("");
      setLastNameGuest("");
      setCompanyGuest("");
      setOrderGuest(1000);
      setIfCheckForUpdateCache(false);
      setCheckbox((checkbox) => ({ ...checkbox, activeGuest: true }));
      setPutingPostingCircularProgressGuest(false);
      setResponseErrorMessageGuest("");
      setIfIgnoreTime(true);
      setShowAddGuestForm(false);
    } else if (postRequestGuest.data?.status === 400) {
      let error = "";
      Object.keys(postRequestGuest.data.errors).forEach((errorObject) => {
        postRequestGuest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgressGuest(false);
      setResponseErrorMessageGuest(error);
    } else if (postRequestGuest.error !== null) {
      setPutingPostingCircularProgressGuest(false);
      setResponseErrorMessageGuest(t("something_went_wrong"));
    }
  }, [
    postRequestGuest,
    setCheckbox,
    setPutingPostingCircularProgressGuest,
    setIfCheckForUpdateCache,
    setResponseErrorMessageGuest,
    setIfIgnoreTime,
    setAddedIdGuest,
    setIsLoading,
    setShowAddGuestForm,
    t,
  ]);

  const addGuest = () => {
    let ifFetch = true;

    if (firstNameGuest === "") {
      setFirstNameErrorGuest({
        isError: true,
        errorMessage: "required",
      });
      ifFetch = false;
    }
    if (lastNameGuest === "") {
      setLastNameErrorGuest({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }

    if (!ifFetch) return;
    props.setPutingPostingCircularProgressGuest(true);
    setPostRequestGuestBody({
      firstName: firstNameGuest,
      lastName: lastNameGuest,
      company: companyGuest,
      description: descriptionGuest,
      isActive: props.checkbox.activeGuest,
      order: orderGuest,
    });
  };

  return (
    <>
      <div className="addGuestContainer">
        <div className="selectFormNotfifiLists">
          <div className="titleWidthNotifLists">{t("first_name")}:</div>
          <div className="inputWidthNotifLists">
            <TextFieldStyled
              label={t("first_name")}
              defaultValue={firstNameGuest}
              required={true}
              isError={firstNameErrorGuest.isError}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setFirstNameErrorGuest({
                    isError: true,
                    errorMessage: "required",
                  });
                } else {
                  setFirstNameErrorGuest({
                    isError: false,
                    errorMessage: "",
                  });
                }
                setFirstNameGuest(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="selectFormNotfifiLists">
          <div className="titleWidthNotifLists">{t("last_name")}:</div>
          <div className="inputWidthNotifLists">
            <TextFieldStyled
              label={t("last_name")}
              defaultValue={lastNameGuest}
              required={true}
              isError={lastNameErrorGuest.isError}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setLastNameErrorGuest({
                    isError: true,
                    errorMessage: "required",
                  });
                } else {
                  setLastNameErrorGuest({
                    isError: false,
                    errorMessage: "",
                  });
                }
                setLastNameGuest(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="selectFormNotfifiLists">
          <div className="titleWidthNotifLists">{t("company")}:</div>
          <div className="inputWidthNotifLists">
            <TextFieldStyled
              label={t("company")}
              defaultValue={companyGuest}
              onBlur={(e) => {
                setCompanyGuest(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="selectFormNotfifiLists">
          <div className="titleWidthNotifLists">{t("description")}:</div>
          <div className="inputWidthNotifLists">
            <TextFieldStyled
              label={t("description")}
              defaultValue={descriptionGuest}
              onBlur={(e) => {
                setDescriptionGuest(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="checkboxFormGuests">
          <div className="titleWidthNotifLists">{t("active")}</div>
          <div>
            <FormControlLabel
              control={
                <CheckboxStyled
                  checked={props.checkbox.activeGuest}
                  name="activeGuest"
                  onChange={props.handleChangeCheckbox}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={<span className="FormControlLabelStyle"></span>}
            />
          </div>
        </div>

        <div className="selectFormNotfifiLists">
          <div className="titleWidthNotifLists">{t("order")}:</div>
          <div className="inputWidthNotifLists">
            <TextFieldStyled
              label={t("order")}
              defaultValue={orderGuest}
              type="number"
              onBlur={(e) => {
                setOrderGuest(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="addGuestConfirmContainer">
          {!props.putingPostingCircularProgressGuest ? (
            <>
              <div className="responseErrorMessage">
                {props.responseErrorMessageGuest}
              </div>
              <div className="addGuest">
                <Button onClick={() => addGuest()}>{t("add_guest")}</Button>
              </div>
            </>
          ) : (
            <div className="confirmWaiting">
              <CircularProgress size={25} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddGuestForm;
