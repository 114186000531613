import { useState, useEffect, useCallback } from "react";
import "./schedule.module.scss";
import { useTranslation } from "react-i18next";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import SelectingTable from "./selectingTable";
import Button from "../../../helpersComponents/Button/button";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import SelectDateStyledContainer from "../../../helpersComponents/SelectDateStyledContainer/selectDateStyledContainer";
import {
  AutocompleteStyled,
  DatePickerStyled,
} from "../../../helpersComponents/MaterialUi";
import styles from "./schedule.module.scss";
import compareDatesIgnoringTime from "../../../../HelpersFunctions/dateAndTime/compareDatesIgnoringTime";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import useFetchingSchemes from "./useFetchingSchemas";
import useFetchingSchedules from "./useFetchingSchedules";
import useCreateGrid from "./useCreateGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { selectAdvancedOptions } from "../../../../reducers/advancedOptions";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import PopupSetTime from "./Dialogs/SetTime/PopupSetTime";
import {
  DaysSchemasValidationErrorModel,
  SchedulesValidator,
} from "./schedulesValidator";
import { timeStringToMinutes } from "../../../../HelpersFunctions/dateAndTime/timeStrings";
import { addDays, addMinutes } from "date-fns/esm";
import {} from "./../../../../extensions/arrayExtensions";
import { WorkShiftRequireWork } from "../../../../enums/workShiftRequireWork";
import { WorkShiftDayType } from "../../../../enums/workShiftDayType";
import { GlobalCalendarDayType } from "../../../../enums/globalCalendarDayType";
import setCurrentCellError from "./setCurrentCellError";
import { DaysSchemasValidationError } from "../../../../enums/daysSchemasValidationError";
import useFetchingGlobalCalendar from "./useFetchingGlobalCalendar";
import useFetchingBillingsPeriods from "./useFetchingBillingsPeriods";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import PopupScheduleVersions from "./Dialogs/ScheduleVersions/PopupScheduleVersions";
import { useSnackbar } from "notistack";
import { DayOfWeek } from "../../../../enums/dayOfWeek";
import { getMinutesFromDatesHoursAndMinutes } from "../../../../HelpersFunctions/dateAndTime/getMinutesFromDatesHoursAndMinutes";
import { setTimeToDate } from "../../../../HelpersFunctions/dateAndTime/setTimeToDate";
import addDaysToDate from "../../../../HelpersFunctions/dateAndTime/addDaysToDate";
import addMinutesToDate from "../../../../HelpersFunctions/dateAndTime/addMinutesToDate";
import PopupSetDayFunction from "./Dialogs/SetDayFunction/PopupSetDayFunction";
import { ProfileType } from "../../../../enums/profileType";

const ScheduleContent = ({ ...props }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  let isChangeScheduleAllowed = false;

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  let allowedPermissions = [1, 2, 3, 4];
  let foundProfile = authUser.tokenDecoded.profiles.find(
    (el) =>
      el.type === ProfileType.SUPERIOR_TIME &&
      el.permissionId &&
      allowedPermissions.includes(el.permissionId)
  );

  if (foundProfile) {
    isChangeScheduleAllowed = true;
  }

  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );

  const advancedOptions = useAppSelector(selectAdvancedOptions);

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);

  const [
    selectedWorkersHelperCreatingGrid,
    setSelectedWorkersHelperCreatingGrid,
  ] = useState<number[]>([]);
  const [allWorkers, setAllWorkers] = useState<
    { [key in string]: any }[] | undefined
  >();

  const [cellDescription, setCellDescription] = useState<string>("");

  const [grid, setGrid] = useState<GridTemplate[][] | null>(null);
  const [gridInitialTemplate, setGridInitialTemplate] = useState<
    GridTemplate[][]
  >([]);
  const [changesInGrid, setChangesInGrid] = useState(false);

  //////////////////////////////////////////// fetching schedules state
  const [selectedWorkersString, setSelectedWorkersString] = useState<string>();

  const [fetchedSchedules, setFetchedSchedules] = useState<
    FetchedWorkersSchedules[] | null
  >(null);

  //////////////////////////////////////////// fetching schemes and selecting schemes states
  const [selectedScheme, setSelectedScheme] = useState<
    { [key in string]: any } | null
  >(null);

  const { schemas, fetchingStateSchemas, fetchAgainSchemas } =
    useFetchingSchemes(setSelectedScheme);

  const [selectedSchemeError, setSelectedSchemeError] = useState<{
    isError: boolean;
    errorMessage: string;
  }>({
    isError: false,
    errorMessage: "",
  });

  ////////////////////////////////////////////////// fetching schedules
  const [selectedDate, setSelectedDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );

  const [isGridCreated, setIsGridCreated] = useState<boolean>(false);

  const [fetchingSchedulesState, fetchSchedulesAgain] = useFetchingSchedules({
    selectedDate,
    selectedWorkersString,
    setFetchedSchedules,
    setIsGridCreated,
  });

  const [scheduleDataLoaded, setScheduleDataLoaded] = useState<boolean>(false);

  ////////////////////////////////////////////////// fetching global callendar
  const [fetchedGlobalCalendarData, setFetchedGlobalCalendarData] = useState<
    GlobalCalendar[]
  >([]);

  const [, fetchGlobalCalendarAgain] = useFetchingGlobalCalendar({
    selectedDate,
    setFetchedGlobalCalendarData,
  });

  //////////////////////////////////////////// fetching billings periods
  const [fetchedBillingsPeriodsData, setFetchedBillingsPeriodsData] = useState<
    BillingPeriod[]
  >([]);

  const [, fetchBillingsPeriodsAgain] = useFetchingBillingsPeriods({
    selectedDate,
    selectedWorkersString,
    setFetchedBillingsPeriodsData,
  });

  const [numberOfErrors, setNumberOfErrors] = useState<number>(0);

  const [popupSetTimeOpen, setPopupSetTimeOpen] = useState(false);
  const popupSetTimeClose = () => {
    setPopupSetTimeOpen(false);
  };

  const [popupSetDayFunctionOpen, setPopupSetDayFunctionOpen] = useState(false);

  const popupAssignDayFunctionClose = () => {
    setPopupSetDayFunctionOpen(false);
  };

  const [popupScheduleVersionsOpen, setPopupScheduleVersionsOpen] =
    useState(false);
  const popupScheduleVersionsClose = () => {
    setPopupScheduleVersionsOpen(false);
  };

  const [selectedTimeWorkerId, setSelectedTimeWorkerId] = useState<number>(-1);

  ///////////////// creating grid template
  useCreateGrid({
    isGridCreated,
    fetchedSchedules,
    allWorkers,
    selectedDate,
    selectedWorkersHelperCreatingGrid,
    setGrid,
    setIsGridCreated: setIsGridCreated,
    setGridInitialTemplate,
    setScheduleDataLoaded,
  });

  const confirmFilters = () => {
    if (allWorkers === undefined) return;
    let selectedWorkersLocal = [...selectedWorkers];
    if (selectedWorkers.length === 0) {
      allWorkers.forEach((worker) => {
        selectedWorkersLocal.push(worker.id);
      });
    }
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );

    setSelectedWorkersHelperCreatingGrid(selectedWorkersLocal);
    setSelectedWorkersString(selectedWorkersStringLocal);
  };

  const refreshGridData = useCallback(() => {
    setNumberOfErrors(0);
    setAssignSchemesErrorsList([]);
    fetchGlobalCalendarAgain();
    fetchBillingsPeriodsAgain();
    fetchSchedulesAgain();
    setCellDescription("");
  }, [
    setNumberOfErrors,
    fetchGlobalCalendarAgain,
    fetchBillingsPeriodsAgain,
    fetchSchedulesAgain,
  ]);

  const ConfirmFilters = (
    <div>
      <Button
        disabled={changesInGrid}
        onClick={() => {
          confirmFilters();
        }}
      >
        {t("get_employees")}
      </Button>
    </div>
  );

  //////////////////////// start to fetch schedules after creating workers ids string
  useEffect(() => {
    if (selectedWorkersString && selectedDate) {
      refreshGridData();
    }
  }, [
    selectedDate,
    selectedWorkersString,
    refreshGridData,
    fetchGlobalCalendarAgain,
    fetchBillingsPeriodsAgain,
    fetchSchedulesAgain,
  ]);

  const [assignSchemesErrorsList, setAssignSchemesErrorsList] =
    useState<ErrorsLists>([]);

  const [sendSchedulesBodyRequest, setSendSchedulesBodyRequest] = useState<
    false | any
  >(false);

  const errorCallback = useCallback(
    (obj) => {
      enqueueSnackbar(t("schedule_validation_error"), {
        variant: "error",
      });
    },
    [t, enqueueSnackbar]
  );

  const [sendSchedulesDataState, sendSchedulesDataAgain] = useFetchOtherThanGET(
    {
      path: "superior-time/schedules",
      method: "PUT",
      body: sendSchedulesBodyRequest,
      contentType: "application/json",
      setBody: setSendSchedulesBodyRequest,
      forceSnackbar: true,
      disableErrorSnackbar: true,
      disableSuccessSnackbar: false,
      errorCallback: errorCallback,
    }
  );

  useEffect(() => {
    if (
      sendSchedulesDataState &&
      sendSchedulesDataState.isFetching &&
      !sendSchedulesDataState.isError &&
      sendSchedulesDataState.response &&
      sendSchedulesDataState.response.status === 200
    )
      setChangesInGrid(false);
  }, [sendSchedulesDataState]);

  useEffect(() => {
    if (sendSchedulesBodyRequest) {
      sendSchedulesDataAgain();
    }
  }, [sendSchedulesBodyRequest, sendSchedulesDataAgain]);

  const getErrorMessage = useCallback(
    (error: DaysSchemasValidationError) => {
      let errorMessage: string = "";

      switch (error) {
        case DaysSchemasValidationError.NO_ELEVEN_HOURS_OF_BREAK:
          errorMessage = t("no_11_hours_of_break");
          break;

        case DaysSchemasValidationError.NO_THIRTY_FIFT_HOURS_BREAK_IN_WEEK:
          errorMessage = t("no_thirty_fift_hours_of_break_in_week");
          break;

        case DaysSchemasValidationError.MAX_SIX_DAYS_IN_ROW_EXCEEDED:
          errorMessage = t("max_six_days_in_row_exceeded");
          break;

        case DaysSchemasValidationError.WORKING_DAY_LIMIT_EXCEEDED:
          errorMessage = t("working_day_limit_exceeded");
          break;

        case DaysSchemasValidationError.NO_EVERY_FOURTH_SUNDAY_FREE:
          errorMessage = t("no_every_fourth_sunday_free");
          break;
      }

      return errorMessage;
    },
    [t]
  );

  const formatDateToLocale = useCallback(
    (date: Date) => {
      return date.toLocaleString(t("scheduler_language"), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    [t]
  );

  const drawValidationErrorsInGrid = useCallback(
    (gridRow: GridTemplate[], errors: DaysSchemasValidationErrorModel[]) => {
      let cumulatedValidationErrors: { [key: number]: string } = {};

      if (errors != null) {
        errors.forEach((e) => {
          let errorMessage: string = getErrorMessage(e.error);

          e.ranges.forEach((r) => {
            let rangeDateFrom = new Date(r.dateFrom);
            let rangeDateTo = new Date(r.dateTo);

            let startIndex = getCellPosition(gridRow, rangeDateFrom);
            let endIndex = getCellPosition(gridRow, rangeDateTo);

            if (startIndex >= 0 && endIndex <= gridRow.length - 1) {
              for (let i = startIndex; i <= endIndex; i++) {
                if (cumulatedValidationErrors[i]) {
                  if (!cumulatedValidationErrors[i].includes(errorMessage)) {
                    cumulatedValidationErrors[i] += "; " + errorMessage;
                  }
                } else {
                  cumulatedValidationErrors[i] = errorMessage;
                }
              }
            }
          });
        });
      }

      for (const key in cumulatedValidationErrors) {
        const cellIndex: number = parseInt(key);
        setCurrentCellError({
          cellInRow: gridRow[cellIndex],
          cellNumber: cellIndex,
          errorName: cumulatedValidationErrors[cellIndex],
          t,
        });
      }
    },
    [t, getErrorMessage]
  );

  const validateGrid = useCallback(
    (grd: GridTemplate[][] | null = null) => {
      setNumberOfErrors(0);
      setAssignSchemesErrorsList([]);

      let validationErrorsLists: ErrorsLists = {};
      let numberOfErrors: number = 0;

      let beginOfMonth = new Date(getBeginningOfDate(selectedDate, "Month"));
      let endOfMonth = new Date(getEndOfDate(selectedDate, "Month"));

      const dontDisplayNoThirtyFiftHoursBreakInWeek =
        advancedOptions.NiePokazuj35GodzinOdpoczynku?.value ?? "";
      const dontDisplayNoElevenHoursOfBreak =
        advancedOptions.NiePokazuj11GodzinOdpoczynku?.value ?? "";
      const dontDisplayMaxSixDaysInRowExceeded =
        advancedOptions.BlokujPrzekroczeniaMaxNormy?.value ?? "";
      const dontDisplayWorkingDayLimitExceeded =
        advancedOptions.NiePokazujDobyPracowniczej?.value ?? "";
      const dontDisplayEveryFourthSundayFree =
        advancedOptions.NiePokazujCo4NiedzieliWolnej?.value ?? "";

      let schedulesValidator: SchedulesValidator = new SchedulesValidator(
        dontDisplayNoThirtyFiftHoursBreakInWeek,
        dontDisplayNoElevenHoursOfBreak,
        dontDisplayMaxSixDaysInRowExceeded,
        dontDisplayWorkingDayLimitExceeded,
        dontDisplayEveryFourthSundayFree
      );

      let clonedGrid: GridTemplate[][] | null = null;

      if (grd) {
        clonedGrid = grd;
      } else {
        clonedGrid = _.cloneDeep(grid);
      }
      if (clonedGrid === null) return;

      assingnDaySchemasWhenNotDefined(clonedGrid);

      if (fetchedSchedules === null) return;
      let currentSchedules: Array<FetchedWorkersSchedules> = [
        ...fetchedSchedules,
      ];

      clearGridCellsFromValidationErrors(clonedGrid);

      clonedGrid.forEach((row, iRow) => {
        if (iRow === 0) return;

        let timeWorkerDaySchemas: Array<DaySchema> = [];

        row.forEach((cellInRow, iCell) => {
          cellInRow.isSelected = false;

          if (cellInRow.day) {
            if (cellInRow.schemaInfo) {
              let daySchema: DaySchema = {
                day: cellInRow.schemaInfo.day,
                begin: cellInRow.schemaInfo.dateFrom,
                end: cellInRow.schemaInfo.dateTo,
                norm: cellInRow.schemaInfo.norm,
                dayType: cellInRow.schemaInfo.dayType,
                workRequired: cellInRow.schemaInfo.workRequired,
                dayFunction: cellInRow.schemaInfo.dayFunction,
              };

              timeWorkerDaySchemas.push(daySchema);
            }
          }
        });

        if (row[0].workerId) {
          let timeWorkerId = row[0].workerId;

          let timeWorkerSchedules: Array<FetchedWorkersSchedules> =
            currentSchedules.filter((s) => s.workerId === timeWorkerId);

          timeWorkerSchedules.sort(
            (obj1: FetchedWorkersSchedules, obj2: FetchedWorkersSchedules) => {
              let date1 = new Date(obj1.day);
              let date2 = new Date(obj2.day);

              if (date1.getTime() > date2.getTime()) {
                return 1;
              } else if (date1.getTime() < date2.getTime()) {
                return -1;
              }

              return 0;
            }
          );

          let firstDayOfMonthIndex: number = timeWorkerSchedules.findIndex(
            (scheme) => {
              return (
                scheme.workerId === row[0].workerId &&
                compareDatesIgnoringTime(new Date(scheme.day), beginOfMonth)
              );
            }
          );

          let lastDayOfMonthIndex: number = timeWorkerSchedules.findIndex(
            (scheme) => {
              return (
                scheme.workerId === row[0].workerId &&
                compareDatesIgnoringTime(new Date(scheme.day), endOfMonth)
              );
            }
          );

          let daySchemasBefore: Array<DaySchema> = [];
          if (firstDayOfMonthIndex > 0) {
            daySchemasBefore = timeWorkerSchedules
              .take(firstDayOfMonthIndex)
              .map<DaySchema>((obj: FetchedWorkersSchedules) => {
                return {
                  day: obj.day,
                  begin: obj.dateFrom,
                  end: obj.dateTo,
                  norm: obj.norm,
                  dayType: obj.dayType,
                  workRequired: obj.workRequired,
                  dayFunction: obj.dayFunction,
                };
              });
          }

          let daysSchemasAfter: Array<DaySchema> = [];
          if (lastDayOfMonthIndex < currentSchedules.length - 1) {
            daysSchemasAfter = timeWorkerSchedules
              .skip(lastDayOfMonthIndex + 1)
              .take(7)
              .map<DaySchema>((obj: FetchedWorkersSchedules) => {
                return {
                  day: obj.day,
                  begin: obj.dateFrom,
                  end: obj.dateTo,
                  norm: obj.norm,
                  dayType: obj.dayType,
                  workRequired: obj.workRequired,
                  dayFunction: obj.dayFunction,
                };
              });
          }

          let allDaysSchemas: DaySchema[] = [
            ...daySchemasBefore,
            ...timeWorkerDaySchemas,
            ...daysSchemasAfter,
          ];

          let billingPeriodFrom = beginOfMonth;
          let billingPeriodTo = endOfMonth;

          let foundBillingPeriod = fetchedBillingsPeriodsData.find(
            (e) => e.timeWorkerId === timeWorkerId
          );

          if (
            foundBillingPeriod &&
            foundBillingPeriod.dateRanges &&
            foundBillingPeriod.dateRanges.length >= 2
          ) {
            billingPeriodFrom = foundBillingPeriod.dateRanges[0].dateFrom;
            billingPeriodTo = foundBillingPeriod.dateRanges[1].dateTo;
          }

          let slGlobalCalendarDictionary: {
            [key: number]: GlobalCalendarDayType;
          } = {};

          if (fetchedGlobalCalendarData) {
            fetchedGlobalCalendarData.forEach((c) => {
              if (!slGlobalCalendarDictionary[endOfMonth.getTime()]) {
                slGlobalCalendarDictionary[endOfMonth.getTime()] = c.dayType;
              }
            });
          }

          let validationErrors = schedulesValidator.validate(
            billingPeriodFrom,
            billingPeriodTo,
            timeWorkerId,
            selectedDate,
            allDaysSchemas,
            slGlobalCalendarDictionary
          );

          if (validationErrors && validationErrors.length > 0) {
            validationErrors.forEach((e) => {
              validationErrorsLists[timeWorkerId] = {
                ...validationErrorsLists[timeWorkerId],
                [e.error]: {
                  errorName: getErrorMessage(e.error),
                  date: e.ranges
                    .map((r, index) => {
                      numberOfErrors++;
                      return `${formatDateToLocale(
                        r.dateFrom
                      )}-${formatDateToLocale(r.dateTo)}`;
                    })
                    .join(";"),
                },
              };
            });
          }

          drawValidationErrorsInGrid(row, validationErrors);
        }
      });

      setNumberOfErrors(numberOfErrors);
      setAssignSchemesErrorsList(validationErrorsLists);
      setFetchedSchedules(currentSchedules);
      setGrid(clonedGrid);
      setGridInitialTemplate(clonedGrid);
    },
    [
      advancedOptions.BlokujPrzekroczeniaMaxNormy?.value,
      advancedOptions.NiePokazuj11GodzinOdpoczynku?.value,
      advancedOptions.NiePokazuj35GodzinOdpoczynku?.value,
      advancedOptions.NiePokazujCo4NiedzieliWolnej?.value,
      advancedOptions.NiePokazujDobyPracowniczej?.value,
      drawValidationErrorsInGrid,
      fetchedBillingsPeriodsData,
      fetchedGlobalCalendarData,
      fetchedSchedules,
      formatDateToLocale,
      getErrorMessage,
      grid,
      selectedDate,
    ]
  );

  useEffect(() => {
    if (scheduleDataLoaded) {
      validateGrid();
      setScheduleDataLoaded(false);
    }
  }, [scheduleDataLoaded, validateGrid]);

  const saveChanges = () => {
    if (
      numberOfErrors > 0 &&
      advancedOptions.BlokujRegulyKodeksuPracy.value !== "1"
    ) {
      enqueueSnackbar(t("cant_save_schedules_with_validation_errors"), {
        variant: "warning",
      });
      return;
    }

    let schedulesToSend: ITimeWorkerSchedules = {
      selectedMonth: getBeginningOfDate(selectedDate, "Month"),
      schedules: [],
    };

    grid?.forEach((row, iRow) => {
      if (iRow > 0) {
        let tempOj: ITimeWorkerSchedule = {
          timeWorkerId: row[0].workerId || -1,
          schemas: [],
        };

        row.forEach((cell, iCell) => {
          if (iCell > 0) {
            tempOj.schemas.push({
              day: convertDateFetch(new Date(cell.day!), "T00:00:00"),
              schemaId: cell.schemaInfo?.schemaId ?? 0,
              schemaName: cell.schemaInfo?.schemaName,
              dayFunction: cell.schemaInfo?.dayFunction,
            });
          }
        });

        schedulesToSend.schedules.push(tempOj);
      }
    });

    setSendSchedulesBodyRequest(JSON.stringify(schedulesToSend));
  };

  const cancelSaveChanges = () => {
    setChangesInGrid(false);
    refreshGridData();
  };

  const assignSchemes = () => {
    if (selectedScheme === null) {
      setSelectedSchemeError({
        isError: true,
        errorMessage: "",
      });
      return;
    } else {
      setSelectedSchemeError({
        isError: false,
        errorMessage: "",
      });
    }

    let clonedGrid: GridTemplate[][] | null = _.cloneDeep(grid);
    if (clonedGrid === null) return;

    if (fetchedSchedules === null) return;
    let currentSchedules: FetchedWorkersSchedules[] = [...fetchedSchedules];

    clonedGrid.forEach((row, iRow) => {
      if (iRow === 0) return;

      //////// clear red borders and errors
      row.forEach((cellRowClearErrors) => {
        cellRowClearErrors.border = "none";
        if (cellRowClearErrors?.cellValueString) {
          cellRowClearErrors.cellValue = cellRowClearErrors.cellValueString;
        }
      });

      row.forEach((cellInRow, iCell) => {
        if (!cellInRow?.isSelected) return;

        if (cellInRow.cellBackground !== undefined) {
          return;
        }

        if (!row[0].workerId || !cellInRow.day) {
          return;
        }

        let currentCellDate = new Date(cellInRow.day);
        let workerIdCurrentIteration = row[0].workerId;
        let tempSchemaDateFrom = getBeginningOfDate(
          new Date(currentCellDate),
          "Day"
        );
        let tempSchemaDateTo = getBeginningOfDate(
          new Date(currentCellDate),
          "Day"
        );

        const tempTimeFromInMinutes = getMinutesFromDatesHoursAndMinutes(
          new Date(selectedScheme.shifs[0].begin)
        );

        if (selectedScheme.options?.includes("poprzedniDzien=1")) {
          tempSchemaDateFrom = addDaysToDate(tempSchemaDateFrom, -1);
        }

        tempSchemaDateFrom = setTimeToDate(
          tempSchemaDateFrom,
          tempTimeFromInMinutes
        );

        tempSchemaDateTo = new Date(tempSchemaDateFrom);
        tempSchemaDateTo = addMinutesToDate(
          tempSchemaDateTo,
          selectedScheme.shifs[0].norm
        );

        /*if (cellInRow.schemaInfo) {
          cellInRow.schemaInfo.dateFrom = tempSchemaDateFrom;
          cellInRow.schemaInfo.dateTo = tempSchemaDateTo;
          cellInRow.schemaInfo.day = currentCellDate;
          cellInRow.schemaInfo.norm = selectedScheme.shifs[0].norm;
          cellInRow.schemaInfo.schemaId = selectedScheme.id;
          cellInRow.schemaInfo.schemaName = selectedScheme.scheduleName;
          cellInRow.schemaInfo.workerId = workerIdCurrentIteration;
          cellInRow.schemaInfo.options = selectedScheme.options;
          cellInRow.schemaInfo.workRequired =
            WorkShiftRequireWork.IN_WORKING_DAYS;
          cellInRow.schemaInfo.dayType = WorkShiftDayType.FROM_GLOBAL_CALENDAR;
        }*/

        let schemaInfo: CellSchema = {
          dateFrom: tempSchemaDateFrom,
          dateTo: tempSchemaDateTo,
          day: currentCellDate,
          norm: selectedScheme.shifs[0].norm,
          schemaId: selectedScheme.id,
          schemaName: selectedScheme.scheduleName,
          workerId: workerIdCurrentIteration,
          options: selectedScheme.options,
          workRequired: WorkShiftRequireWork.IN_WORKING_DAYS,
          dayType: WorkShiftDayType.FROM_GLOBAL_CALENDAR,
          dayFunction: cellInRow.dayFunction,
        };

        ////////// update currentSchedules for next iterations
        let currentDaySchemeIndex: number;
        currentDaySchemeIndex = currentSchedules.findIndex((schemas) => {
          return (
            schemas.workerId === workerIdCurrentIteration &&
            compareDatesIgnoringTime(new Date(schemas.day), currentCellDate)
          );
        });

        if (currentDaySchemeIndex === -1) {
          currentSchedules.push(schemaInfo);
        } else {
          currentSchedules[currentDaySchemeIndex] = schemaInfo;
        }

        ///////// update cell by selected scheme
        cellInRow.cellValue = selectedScheme.scheduleName;
        cellInRow.cellValueString = selectedScheme.scheduleName;
        cellInRow.schemaInfo = schemaInfo;
        setChangesInGrid(true);
      });

      row.forEach((cellInRow, iCell) => {
        cellInRow.isSelected = false;
      });
    });

    validateGrid(clonedGrid);
    setFetchedSchedules(currentSchedules);
    setGrid(clonedGrid);
    setGridInitialTemplate(clonedGrid);
  };

  const setTime = () => {
    setPopupSetTimeOpen(true);
  };

  const setDayFunction = () => {
    setPopupSetDayFunctionOpen(true);
  };

  const deleteDayFunction = () => {
    let clonedGrid: GridTemplate[][] | null = _.cloneDeep(grid);
    if (clonedGrid === null) return;

    if (fetchedSchedules === null) return;
    let currentSchedules: FetchedWorkersSchedules[] = [...fetchedSchedules];

    clonedGrid.forEach((row, iRow) => {
      if (iRow === 0) return;

      row.forEach((cellInRow, iCell) => {
        if (!cellInRow?.isSelected) return;

        if (cellInRow.cellBackground !== undefined) {
          return;
        }

        if (!row[0].workerId || !cellInRow.day) {
          return;
        }

        if (cellInRow.schemaInfo) {
          cellInRow.schemaInfo.dayFunction = null;
          setChangesInGrid(true);
        }
      });

      row.forEach((cellInRow, iCell) => {
        cellInRow.isSelected = false;
      });
    });

    validateGrid(clonedGrid);
    setFetchedSchedules(currentSchedules);
    setGrid(clonedGrid);
    setGridInitialTemplate(clonedGrid);
  };

  const popupSetTimeConfirm = (time) => {
    const newSchemaName = time;

    if (selectedScheme === null) {
      setSelectedSchemeError({
        isError: true,
        errorMessage: "",
      });
      return;
    } else {
      setSelectedSchemeError({
        isError: false,
        errorMessage: "",
      });
    }

    let clonedGrid: GridTemplate[][] | null = _.cloneDeep(grid);
    if (clonedGrid === null) return;

    if (fetchedSchedules === null) return;
    let currentSchedules: FetchedWorkersSchedules[] = [...fetchedSchedules];

    clonedGrid.forEach((row, iRow) => {
      if (iRow === 0) return;

      row.forEach((cellInRow, iCell) => {
        if (!cellInRow?.isSelected) return;

        if (cellInRow.cellBackground !== undefined) {
          return;
        }

        if (!row[0].workerId || !cellInRow.day) {
          return;
        }

        let currentCellDate = new Date(cellInRow.day);
        let workerIdCurrentIteration = row[0].workerId;
        let schemaTimeInfo = getSchemaTimeInfo(newSchemaName, currentCellDate);

        let schemaInfo: CellSchema = {
          dateFrom: new Date(schemaTimeInfo.dayFrom),
          dateTo: new Date(schemaTimeInfo.dayTo),
          day: currentCellDate,
          norm: schemaTimeInfo.norm,
          schemaId: 0,
          schemaName: newSchemaName,
          workerId: workerIdCurrentIteration,
          options: schemaTimeInfo.previousDay ? "poprzedniDzien=1" : "",
          workRequired: WorkShiftRequireWork.IN_WORKING_DAYS,
          dayType: WorkShiftDayType.FROM_GLOBAL_CALENDAR,
          dayFunction: cellInRow.dayFunction,
        };

        ////////// update currentSchedules for next iterations
        let currentDaySchemeIndex: number;
        currentDaySchemeIndex = currentSchedules.findIndex((scheme) => {
          return (
            scheme.workerId === workerIdCurrentIteration &&
            compareDatesIgnoringTime(new Date(scheme.day), currentCellDate)
          );
        });

        if (currentDaySchemeIndex === -1) {
          currentSchedules.push(schemaInfo);
        } else {
          currentSchedules[currentDaySchemeIndex] = schemaInfo;
        }

        ///////// update cell by set time
        cellInRow.cellValue = time;
        cellInRow.cellValueString = time;
        cellInRow.schemaInfo = schemaInfo;
        setChangesInGrid(true);
      });

      row.forEach((cellInRow, iCell) => {
        cellInRow.isSelected = false;
      });
    });

    validateGrid(clonedGrid);
    setFetchedSchedules(currentSchedules);
    setGrid(clonedGrid);
    setGridInitialTemplate(clonedGrid);
  };

  const popupAssignDayFunctionConfirm = (dayFunction: DayFunction) => {
    let clonedGrid: GridTemplate[][] | null = _.cloneDeep(grid);
    if (clonedGrid === null) return;

    if (fetchedSchedules === null) return;
    let currentSchedules: FetchedWorkersSchedules[] = [...fetchedSchedules];

    clonedGrid.forEach((row, iRow) => {
      if (iRow === 0) return;

      row.forEach((cellInRow, iCell) => {
        if (!cellInRow?.isSelected) return;

        if (cellInRow.cellBackground !== undefined) {
          return;
        }

        if (!row[0].workerId || !cellInRow.day) {
          return;
        }

        if (cellInRow.schemaInfo) {
          cellInRow.schemaInfo.dayFunction = dayFunction;
          cellInRow.schemaInfo.dayFunction.day = new Date(cellInRow.day);
          setChangesInGrid(true);
        }
      });

      row.forEach((cellInRow, iCell) => {
        cellInRow.isSelected = false;
      });
    });

    validateGrid(clonedGrid);
    setFetchedSchedules(currentSchedules);
    setGrid(clonedGrid);
    setGridInitialTemplate(clonedGrid);
  };

  const clearGridCellsFromValidationErrors = (gridData: GridTemplate[][]) => {
    gridData.forEach((row, rowIndex) => {
      if (rowIndex === 0) return;

      row.forEach((cell) => {
        cell.border = "none";
        if (cell?.cellValueString) {
          cell.cellValue = cell.cellValueString;
        }
      });
    });
  };

  const getSchemaTimeInfo = (
    schemaName: string,
    day: Date
  ): { dayFrom: Date; dayTo: Date; norm: number; previousDay: boolean } => {
    const schemaParts = schemaName.split("-");

    let minutesFrom = timeStringToMinutes(schemaParts[0]);
    let minutesTo = timeStringToMinutes(schemaParts[1]);

    let dayFrom = getBeginningOfDate(day, "Day");
    let dayTo = getBeginningOfDate(day, "Day");
    let norm = minutesTo - minutesFrom;
    let previousDay = false;

    if (minutesFrom > minutesTo) {
      dayFrom = addDays(dayFrom, -1);
      norm = 24 * 60 + minutesTo - minutesFrom;
      previousDay = true;
    }

    dayFrom = addMinutes(dayFrom, minutesFrom);
    dayTo = addMinutes(dayTo, minutesTo);

    return {
      dayFrom: dayFrom,
      dayTo: dayTo,
      norm: norm,
      previousDay: previousDay,
    };
  };

  const getCellPosition = (gridRow: GridTemplate[], date: Date) => {
    if (gridRow) {
      let searchDate = new Date(getBeginningOfDate(new Date(date), "Day"));

      for (let i = 0; i < gridRow.length; i++) {
        if (gridRow[i].day !== undefined) {
          if (compareDatesIgnoringTime(gridRow[i].day!, searchDate)) {
            return i;
          }
        }
      }
    }

    return -1;
  };

  const getSelectedTimeWorkerId = () => {
    let timeWorkerId: number | undefined = -1;

    if (grid && grid.length > 0) {
      for (let row = 0; row < grid.length; row++) {
        for (let col = 0; col < grid[row].length; col++) {
          if (grid[row][col].isSelected) {
            timeWorkerId = grid[row][col].schemaInfo?.workerId;
            break;
          }
        }

        if (timeWorkerId && timeWorkerId > 0) {
          break;
        }
      }
    }

    return timeWorkerId;
  };

  const checkScheduleVersionsHistory = () => {
    let timeWorkerId = getSelectedTimeWorkerId();
    if (timeWorkerId && timeWorkerId > 0) {
      setSelectedTimeWorkerId(timeWorkerId);
      setPopupScheduleVersionsOpen(true);
    }
  };

  const assingnDaySchemasWhenNotDefined = (grid: GridTemplate[][]) => {
    grid.forEach((row, rowIndex) => {
      if (rowIndex === 0) return;

      row.forEach((cell) => {
        if (!cell.schemaInfo) {
          let schemaName = "DW";

          if (cell.day?.getDay() === DayOfWeek.SATURDAY) {
            schemaName = "DWS";
          } else if (cell.day?.getDay() === DayOfWeek.SUNDAY) {
            schemaName = "DWN";
          }

          if (cell.day && row[0].workerId) {
            cell.schemaInfo = {
              dateFrom: getBeginningOfDate(cell.day, "Day"),
              dateTo: getBeginningOfDate(cell.day, "Day"),
              day: cell.day,
              norm: 0,
              schemaId: 0,
              schemaName: schemaName,
              workerId: row[0].workerId,
              options: "",
              workRequired: WorkShiftRequireWork.IN_WORKING_DAYS,
              dayType: WorkShiftDayType.FROM_GLOBAL_CALENDAR,
              dayFunction: undefined,
            };
            cell.cellValueString = schemaName;
            cell.cellValue = schemaName;
          }

          row.forEach((cellInRow, iCell) => {
            cellInRow.isSelected = false;
          });
        }
      });
    });
  };

  return (
    <>
      <TopBanner pathName={t("schedules")} />
      <PopupSetTime
        isOpen={popupSetTimeOpen}
        closePopup={popupSetTimeClose}
        confirm={popupSetTimeConfirm}
      />

      <PopupSetDayFunction
        isOpen={popupSetDayFunctionOpen}
        closePopup={popupAssignDayFunctionClose}
        confirm={popupAssignDayFunctionConfirm}
      />

      <PopupScheduleVersions
        isOpen={popupScheduleVersionsOpen}
        closePopup={popupScheduleVersionsClose}
        timeWorkerId={selectedTimeWorkerId}
        selectedMonth={selectedDate}
      />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName="layoutSuperiorTimeAdministrationScheduleSelectWorkers"
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            pageSize={10}
          />
        </div>
        <div>
          <SelectDateStyledContainer
            title={t("select_date")}
            rightActions={ConfirmFilters}
          >
            <DatePickerStyled
              view={["year", "month"]}
              format="yyyy-MM"
              date={selectedDate}
              setDate={setSelectedDate}
              openTo="month"
              darkTheme={true}
              width={"100%"}
              disabled={changesInGrid}
            />
          </SelectDateStyledContainer>
        </div>
        <div>
          <div>
            {grid !== null && (
              <>
                <div className={styles.toolbarContainer}>
                  <div className={styles.selectScheme}>
                    <LoadingWrapper
                      isLodadingProgress={fetchingStateSchemas.isFetching}
                      isError={fetchingStateSchemas.isError}
                      setIfFetchAgain={fetchAgainSchemas}
                    >
                      {!fetchingStateSchemas.isFetching &&
                        !fetchingStateSchemas.isError &&
                        schemas !== null && (
                          <AutocompleteStyled
                            disabled={!isChangeScheduleAllowed}
                            required={true}
                            options={schemas}
                            getOptionLabel={(option: any) => {
                              return option.scheduleName;
                            }}
                            width="100%"
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            value={selectedScheme ? selectedScheme : null}
                            isError={selectedSchemeError.isError}
                            onChange={(_, newValue) => {
                              setSelectedScheme(newValue);
                            }}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                setSelectedSchemeError({
                                  isError: true,
                                  errorMessage: "",
                                });
                              } else {
                                setSelectedSchemeError({
                                  isError: false,
                                  errorMessage: "",
                                });
                              }
                            }}
                            label={t("day_schema")}
                          />
                        )}
                    </LoadingWrapper>
                  </div>
                  <div>
                    <Button
                      disabled={!isChangeScheduleAllowed}
                      onClick={() => {
                        assignSchemes();
                      }}
                    >
                      {t("assign")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={!isChangeScheduleAllowed}
                      onClick={() => {
                        setTime();
                      }}
                    >
                      {t("set_time")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={!isChangeScheduleAllowed}
                      onClick={() => {
                        setDayFunction();
                      }}
                    >
                      {t("assign_function")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={!isChangeScheduleAllowed}
                      onClick={() => {
                        deleteDayFunction();
                      }}
                    >
                      {t("delete_function")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        checkScheduleVersionsHistory();
                      }}
                    >
                      {t("check_schedule_versions_history")}
                    </Button>
                  </div>
                  {changesInGrid && (
                    <div>
                      <Button
                        className={styles.btnSaveChanges}
                        onClick={() => {
                          saveChanges();
                        }}
                      >
                        {t("save")}
                      </Button>

                      <Button
                        className={styles.btnCancelChanges}
                        onClick={() => {
                          cancelSaveChanges();
                        }}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  )}
                </div>
                {numberOfErrors > 0 && (
                  <div className={styles.errorsContainer}>
                    <div className={styles.errorsHeader}>
                      {`${t("number_of_errors")}: ${numberOfErrors}`}
                      <FontAwesomeIcon
                        className={styles.faExclamationTriangle}
                        icon={faExclamationTriangle}
                      />
                    </div>

                    <ul className={styles.ulErrors}>
                      {Object.keys(assignSchemesErrorsList).map(
                        (singleRowErrors) => {
                          return Object.keys(
                            assignSchemesErrorsList[singleRowErrors]
                          ).map((singleErrorCellKey) => {
                            let errorData =
                              assignSchemesErrorsList[singleRowErrors][
                                singleErrorCellKey
                              ];
                            return (
                              <li key={singleErrorCellKey}>
                                <div className={styles.errorRow}>
                                  <div>
                                    {`${timeWorkersSuperiorObject[singleRowErrors].firstName} ${timeWorkersSuperiorObject[singleRowErrors].lastName}[${timeWorkersSuperiorObject[singleRowErrors].evidenceNumber}]`}
                                  </div>
                                  <div>
                                    <b>{errorData.date}</b>
                                  </div>
                                  <div>{errorData.errorName} </div>
                                </div>
                              </li>
                            );
                          });
                        }
                      )}
                    </ul>
                  </div>
                )}
              </>
            )}
            <div>
              <LoadingWrapper
                isLodadingProgress={fetchingSchedulesState.isFetching}
                isError={fetchingSchedulesState.isError}
                setIfFetchAgain={fetchSchedulesAgain}
              >
                {!fetchingSchedulesState.isFetching &&
                  !fetchingSchedulesState.isError &&
                  grid !== null && (
                    <div>
                      <div>
                        {cellDescription && (
                          <p>
                            {t("cell_description")}: {cellDescription}
                          </p>
                        )}
                      </div>
                      <SelectingTable
                        grid={grid}
                        setGrid={setGrid}
                        setCellDescription={setCellDescription}
                        gridInitialTemplate={gridInitialTemplate}
                      />
                    </div>
                  )}
              </LoadingWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleContent;
