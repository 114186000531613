import { DialogContent } from "@material-ui/core";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { toDataURL } from "../../../../HelpersFunctions/toDataURL";
import {
  selectNewAreaPoints,
  setToggleIsCreatingAreaMode,
} from "../../../../reducers/visualization/creatingNewArea";
import {
  selectAllMaps,
  setAddNewMapInsideMap,
  setAddUpdateMapToAllMaps,
} from "../../../../reducers/visualization/visualization";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import DisplaySingleFileWithDeleteButton from "../../../helpersComponents/Attachments/displaySingleFileWithDeleteButton";
import SelectFileInput from "../../../helpersComponents/Attachments/selectFileInput";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";
import styles from "./newMap.module.scss";

interface Props {
  close: () => void;
}
interface RefInterface {
  confirm: () => void;
}

const NewMapPopupContent = forwardRef<RefInterface, Props>(({ close }, ref) => {
  const dispatch = useAppDispatch();
  const allMaps = useAppSelector(selectAllMaps);
  const newAreaPoints = useAppSelector(selectNewAreaPoints);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [selectedMapImage, setSelectedMapImage] = useState<File[]>([]);
  const [selectedMapImageError, setSelectedMapImageError] =
    useState<boolean>(false);
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    async confirm() {
      if (!allMaps[1]) {
        alert("Brak mapy głównej");
        return;
      }
      let ifFetch = true;
      if (name === "") {
        ifFetch = false;
        setNameError({
          isError: true,
          errorMessage: "",
        });
      } else {
        setNameError({
          isError: false,
          errorMessage: "",
        });
      }
      if (selectedMapImage.length === 0) {
        ifFetch = false;
        setSelectedMapImageError(true);
      } else {
        setSelectedMapImageError(false);
      }

      if (!ifFetch) return;
      let selectedFileBase64: any = await toDataURL(selectedMapImage[0]);

      let id = 2;
      let ifNewId = false;
      let allIds = Object.keys(allMaps).map((id) => parseInt(id));
      while (!ifNewId) {
        if (allIds.includes(id)) {
          id++;
        } else {
          ifNewId = true;
        }
      }
      dispatch(setAddNewMapInsideMap({ mapId: id, mapPoints: newAreaPoints }));
      dispatch(
        setAddUpdateMapToAllMaps({
          id: id,
          isMainMap: false,
          mapsInside: [],
          name: name,
          rooms: [],
          base64: selectedFileBase64,
        })
      );
      dispatch(setToggleIsCreatingAreaMode(false));
      close();
    },
  }));

  return (
    <>
      <DialogContent>
        <Row>
          <RowTitle>{t("name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("name")}
              defaultValue={name}
              required={true}
              isError={nameError.isError}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setNameError({
                    isError: true,
                    errorMessage: "",
                  });
                } else {
                  setNameError({
                    isError: false,
                    errorMessage: "",
                  });
                }
                setName(e.target.value);
              }}
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>
            <SelectFileInput
              setSelectedFiles={setSelectedMapImage}
              title={t("select_map")}
              accept="image/png, image/gif, image/jpeg"
            />
          </RowTitle>
          <RowInput>
            <div className={styles.selectImageError}>
              {selectedMapImageError && selectedMapImage.length === 0 && (
                <>Wybierz obraz mapy</>
              )}
            </div>
            <div>
              {selectedMapImage.length !== 0 && (
                <DisplaySingleFileWithDeleteButton
                  file={selectedMapImage[0]}
                  fileIndex={0}
                  allFiles={selectedMapImage}
                  setUpadteAllFilesAfterDelete={setSelectedMapImage}
                />
              )}
            </div>
          </RowInput>
        </Row>
      </DialogContent>
    </>
  );
});
export default NewMapPopupContent;
