import {
  faAnchor,
  faFire,
  faInfo,
  faPeopleArrows,
  faTruck,
  faUserFriends,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ArrivalsOfShipsShiftDirector from "../../shiftDirector/ArrivalOfShips";
import CrewDescentsShiftDirector from "../../shiftDirector/CrewDescents";
import CrewEntriesShiftDirector from "../../shiftDirector/CrewEntries";
import FireReportShiftDirector from "../../shiftDirector/FireReport";
import NotificationInPortsShiftDirector from "../../shiftDirector/NotificationsInPorts";
import ServicesAndDeliverieShiftDirector from "../../shiftDirector/ServicesAndDeliveries";
import SupportShiftDirector from "../../shiftDirector/Support";
import SupportReportShiftDirector from "../../shiftDirector/Support/SupportReport";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import {
  selectShiftDirectorCrewDescentsMenuItemName,
  selectShiftDirectorCrewEntriesMenuItemName,
  selectShiftDirectorNotificationMenuItemName,
  selectShiftDirectorServicesAndDeliveriesMenuItemName,
} from "../../../reducers/notificationsSummary";
import NotificationInPort from "../../shiftDirector/NotificationInPort";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const notificationsMenuItemName = useAppSelector(
    selectShiftDirectorNotificationMenuItemName
  );

  const servicesAndDeliveriesMenuItemName = useAppSelector(
    selectShiftDirectorServicesAndDeliveriesMenuItemName
  );

  const crewEntriesMenuItemName = useAppSelector(
    selectShiftDirectorCrewEntriesMenuItemName
  );

  const crewDescentsMenuItemName = useAppSelector(
    selectShiftDirectorCrewDescentsMenuItemName
  );

  const navLinksArrayShiftDirector: navLinksArrayTimeWorkerInterface = {
    profileId: 9,
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/shift-director/notifications/:id",
        component: (props) => <NotificationInPort {...props} />,
      },
    ],

    ifIgonreSettingsPreferences: false,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/shift-director/profile",
        component: () => <Profile />,
      },
      {
        icon: faTruck,
        name: servicesAndDeliveriesMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/shift-director/home",
        component: () => <ServicesAndDeliverieShiftDirector />,
      },
      {
        icon: faUserPlus,
        name: crewEntriesMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/shift-director/crew-entries",
        component: () => <CrewEntriesShiftDirector />,
      },
      {
        icon: faUserMinus,
        name: crewDescentsMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/shift-director/crew-descents",
        component: () => <CrewDescentsShiftDirector />,
      },
      {
        icon: faAnchor,
        name: t("arrival_of_ships"),
        ifHidden: false,
        singleLink: true,
        path: "/shift-director/arrival-of-ships",
        component: () => <ArrivalsOfShipsShiftDirector />,
      },
      {
        icon: faPeopleArrows,
        name: t("support"),
        ifHidden: false,
        singleLink: false,
        path: [
          {
            name: t("support"),
            path: "/shift-director/support",
            ifHidden: false,
            component: () => <SupportShiftDirector />,
          },
          {
            name: t("support_report"),
            path: "/shift-director/support-report",
            ifHidden: false,
            component: () => <SupportReportShiftDirector />,
          },
        ],
      },
      {
        icon: faUserFriends,
        name: notificationsMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/shift-director/notifications",
        component: () => <NotificationInPortsShiftDirector />,
      },
      {
        icon: faFire,
        name: t("fire_report"),
        ifHidden: false,
        singleLink: true,
        path: "/shift-director/fire-report",
        component: () => <FireReportShiftDirector />,
      },
    ],
  };

  return navLinksArrayShiftDirector;
};
export default useNavLinksArray;
