import { CircularProgress, DialogActions } from "@material-ui/core";
import React, { ButtonHTMLAttributes, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button/button";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  onCancel?: () => void;
  onConfirm?: () => void;
  ifHideConfirm?: boolean;
  ifHideCancel?: boolean;
  errorString?: string;
  isLoading?: boolean;
  typConfirm?: "button" | "reset" | "submit";
  formConfirm?: string;
  onConfirmText?: string;
  onConfirmColor?: string;
  onCancelText?: string;
  disabledConfirm?: boolean;
}

export default function DialogActionsStyled({
  onCancel,
  onConfirm,
  errorString,
  isLoading = false,
  typConfirm,
  formConfirm,
  ifHideConfirm,
  ifHideCancel,
  onConfirmText,
  onConfirmColor,
  onCancelText,
  disabledConfirm,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <DialogActions className="dialogActionsGlobal">
      {errorString && <div className="responseErrorMessage">{errorString}</div>}
      {isLoading ? (
        <>
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        </>
      ) : (
        <>
          {!ifHideCancel && (
            <Button
              type="button"
              onClick={() => {
                if (onCancel) onCancel();
              }}
            >
              {onCancelText ? onCancelText : t("cancel")}
            </Button>
          )}
          {!ifHideConfirm && (
            <Button
              color={onConfirmColor}
              type={typConfirm}
              form={formConfirm}
              onClick={() => {
                if (onConfirm) onConfirm();
              }}
              disabled={disabledConfirm}
            >
              {onConfirmText ? onConfirmText : t("confirm")}
            </Button>
          )}
        </>
      )}
    </DialogActions>
  );
}
