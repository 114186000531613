import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchFile from "../../../../hooks/fetchHooks/get/useFetchFile";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";

const RowDetail: React.FC = ({ row }: any) => {
  const { t } = useTranslation();
  // const [ifFetchholidayById, setIfFetchholidayById] = useState<any>(false)
  const [attachmentIdAndFileName, setAttachmentIdAndFileName] = useState<any>([
    false,
    "",
  ]);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const { data } = useFetchFile(
    `worker-time/${authUserId}/holiday-requests/${row.id}/attachments`,
    attachmentIdAndFileName
  );
  useEffect(() => {
    if (data === null) return;
    setAttachmentIdAndFileName([false, ""]);
  }, [data]);

  return (
    <div className="detailsNotificationList">
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("guest_name")}:</b>
          </div>
          <div>{row?.guestFirstName ? row.guestFirstName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("guest_surname")}:</b>
          </div>
          <div>{row?.guestLastName ? row.guestLastName : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("date_of_arrival")}:</b>
          </div>
          <div>{row?.arrivalDateString ? row.arrivalDateString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("for_employee")}:</b>
          </div>
          <div>{row?.announces ? row.announces : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("parking_lot")}:</b>
          </div>
          <div>{row?.parkingName ? row.parkingName : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("prepare_meal")}:</b>
          </div>
          <div>
            {row?.isProvisioningString ? row.isProvisioningString : "—"}
          </div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("arrival")}:</b>
          </div>
          <div>{row?.arrivalString ? row.arrivalString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("comments")}:</b>
          </div>
          <div>{row?.comments ? row.comments : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("description")}:</b>
          </div>
          <div>{row?.description ? row.description : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("comment")}:</b>
          </div>
          <div>{row?.typeDescription ? row.typeDescription : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("was_added")}:</b>
          </div>
          <div>{row?.createdAtString ? row.createdAtString : "—"}</div>
        </div>
        <div>
          <div>
            <b>{t("approved")}:</b>
          </div>
          <div>{row?.approvedAtString ? row.approvedAtString : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("licence_place")}:</b>
          </div>
          <div>
            {row?.carRegistrationNumber ? row.carRegistrationNumber : "—"}
          </div>
        </div>
        <div>
          <div>
            <b>{t("approved_by")}:</b>
          </div>
          <div>{row?.approverName ? row.approverName : "—"}</div>
        </div>
      </div>
      <div className="detailsNotificationListRow">
        <div>
          <div>
            <b>{t("confirm_contact")}:</b>
          </div>
          <div>
            {row?.meetingApprovalString ? row.meetingApprovalString : "—"}
          </div>
        </div>
        <div>
          <div>
            <b>{t("type_of_visit")}:</b>
          </div>
          <div>{row?.typeOfVisit ? row.typeOfVisit : "—"}</div>
        </div>
      </div>
    </div>
  );
};

export default RowDetail;
