import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useCofirm from "../../../../hooks/useConfirm/useConfirm";
import {
  selectNewAreaPoints,
  selectNewAreaPointsHistory,
  selectCurrentHistoryIndex,
  selectIsCreatingAreaMode,
  setNewAreaPoints,
  setDecrementIndexHistory,
  setIncrementIndexHistory,
  setToggleIsCreatingAreaMode,
  seIfOpenPopupNewArea,
} from "../../../../reducers/visualization/creatingNewArea";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Button from "../../../helpersComponents/Button/button";
import SelectMenu from "../../../helpersComponents/SelectMenu/selectMenu";
import styles from "./selectArea.module.scss";

export default function CreateNewAreaActions(): ReactElement {
  const dispatch = useAppDispatch();
  const isCreatingAreaMode = useAppSelector(selectIsCreatingAreaMode);
  const newAreaPoints = useAppSelector(selectNewAreaPoints);
  const currentHistoryIndex = useAppSelector(selectCurrentHistoryIndex);
  const newAreaPointsHistory = useAppSelector(selectNewAreaPointsHistory);
  const { confirm } = useCofirm();
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.actionButtons}>
        <div className={styles.creatingNewAreaTitle}>
          <div>Tworzenie nowego obszaru</div>
          <div>
            <Button
              onClick={async () => {
                if (newAreaPoints.length > 0) {
                  let selectedbuttonId = await confirm({
                    text: t(
                      "Czy na pewno chcesz zakończyć tworzenie obszaru? Stracisz cały aktualny postep."
                    ),
                    buttons: [
                      { buttonName: t("zakończ"), buttonId: 0 },
                      { buttonName: t("cancel"), buttonId: 1 },
                    ],
                  });
                  if (selectedbuttonId === 0) {
                    dispatch(setToggleIsCreatingAreaMode(!isCreatingAreaMode));
                  }
                } else {
                  dispatch(setToggleIsCreatingAreaMode(!isCreatingAreaMode));
                }
              }}
            >
              {isCreatingAreaMode ? (
                <span>Zakończ tworzenie obszaru</span>
              ) : (
                <span>Rozpocznij tworzenie obszaru</span>
              )}
            </Button>
          </div>
          <div>
            {isCreatingAreaMode && (
              <SelectMenu
                name={"Dodaj obszar"}
                disabled={newAreaPoints.length < 3}
                items={[
                  {
                    optionName: "mapa",
                    onClick: () => dispatch(seIfOpenPopupNewArea("map")),
                  },
                  {
                    optionName: "room",
                    onClick: () => dispatch(seIfOpenPopupNewArea("room")),
                  },
                ]}
              />
            )}
          </div>
        </div>
        {isCreatingAreaMode && (
          <div className={styles.historyButtons}>
            <div>
              <Button
                disabled={currentHistoryIndex === -1}
                onClick={() => {
                  if (currentHistoryIndex === 0) {
                    dispatch(setNewAreaPoints([]));
                    dispatch(setDecrementIndexHistory());
                    return;
                  }
                  dispatch(
                    setNewAreaPoints(
                      newAreaPointsHistory[currentHistoryIndex - 1]
                    )
                  );
                  dispatch(setDecrementIndexHistory());
                }}
              >
                History back
              </Button>
            </div>
            <div>
              <Button
                disabled={
                  currentHistoryIndex === newAreaPointsHistory.length - 1 ||
                  newAreaPointsHistory.length === 0
                }
                onClick={() => {
                  dispatch(
                    setNewAreaPoints(
                      newAreaPointsHistory[currentHistoryIndex + 1]
                    )
                  );
                  dispatch(setIncrementIndexHistory());
                }}
              >
                History next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
