import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import * as mrz from "mrz";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useIdentifyCard from "../../../hooks/useIdentityCard/useIdentifyCard";
import ButtonStyled from "../Button/button";
import { TextFieldFormik } from "../FormikInputs";
import { Row, RowInput, RowTitle } from "../PopupHelpers";
import styles from "./styles.module.scss";

export default function ReadIdCardNumberFormikPopupRow({
  widthTitle,
  setFieldValue,
  setFieldTouched,
  name = "idNumber",
  successCallback,
  disabled,
  startListeningInit = true,
  onChangeExtra,
  required,
}: ReadIdCardNumberFormikPopupRowInterface): ReactElement {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const [identifyCardData, startListening, isListening] =
    useIdentifyCard(startListeningInit);

  const checkMRZIsValid = useCallback((mrzObj) => {
    let documentNumberValid = false;
    let documentNumberCheckDigitValid = false;
    let firstNameValid = false;
    let lastNameValid = false;

    if (mrzObj && mrzObj.details && mrzObj.details.length > 0) {
      mrzObj.details.forEach((item) => {
        if (item.field === "documentNumber" && item.valid) {
          documentNumberValid = true;
        }

        if (item.field === "documentNumberCheckDigit" && item.valid) {
          documentNumberCheckDigitValid = true;
        }

        if (item.field === "firstName" && item.valid) {
          firstNameValid = true;
        }

        if (item.field === "lastName" && item.valid) {
          lastNameValid = true;
        }
      });
    }

    return (
      documentNumberValid &&
      documentNumberCheckDigitValid &&
      firstNameValid &&
      lastNameValid
    );
  }, []);

  useEffect(() => {
    if (identifyCardData.response) {
      let result;
      try {
        result = mrz.parse(identifyCardData.response);
        setError(false);
      } catch (e) {
        console.log("error", e);
      }

      if (!checkMRZIsValid(result)) {
        setFieldTouched(name, true);
        if (result?.fields && result?.fields?.documentNumber) {
          setFieldValue(name, result.fields?.documentNumber);
        }
        setError(true);

        return;
      }

      if (successCallback) successCallback(result);

      setFieldValue(name, result?.fields?.documentNumber);
      startListening(false);
    }
  }, [
    identifyCardData,
    name,
    successCallback,
    setFieldValue,
    setFieldTouched,
    startListening,
    t,
    checkMRZIsValid,
  ]);

  return (
    <Row>
      <RowTitle width={widthTitle}>
        {isListening ? (
          <div
            className={styles.moveIdentityCard}
            onClick={(e) => {
              if (e.ctrlKey) {
                localStorage.removeItem("useIdentifyCard");
              }
            }}
          >
            <div>
              <ButtonStyled width={150} onClick={() => startListening(false)}>
                {t("bring_id_to_reader")}...{" "}
                <FontAwesomeIcon
                  className={styles.faTimesCircle}
                  icon={faTimesCircle}
                />
              </ButtonStyled>
            </div>
            <div>
              <CircularProgress size={20} />
            </div>{" "}
          </div>
        ) : (
          <ButtonStyled
            width={150}
            onClick={(e) => {
              if (e.ctrlKey) {
                localStorage.removeItem("useIdentifyCard");
              }
              startListening(true);
            }}
          >
            {t("read_again")} <FontAwesomeIcon icon={faSyncAlt} />
          </ButtonStyled>
        )}
      </RowTitle>

      <div className={styles.idNumberTextField}>
        <RowInput>
          <TextFieldFormik
            label={t("id_number")}
            ifToUpperCaseShipsAndPorts={true}
            name={name}
            width="100%"
            type="text"
            disabled={disabled}
            onChangeExtra={onChangeExtra}
            required={required !== undefined ? true : false}
          />
        </RowInput>

        <div className={styles.errorFormat}>
          {error && (
            <p>
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />{" "}
              {t("check_correctness_of_document")}
            </p>
          )}
        </div>
      </div>
    </Row>
  );
}
