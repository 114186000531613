import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface stateInterface {
  newAreaPoints: L.LatLngTuple[];
  newAreaPointsHistory: L.LatLngTuple[][];
  isCreatingAreaMode: boolean;
  currentHistoryIndex: number;
  ifOpenPopupNewArea: false | "map" | "room";
}

const initialState: stateInterface = {
  newAreaPoints: [],
  newAreaPointsHistory: [],
  isCreatingAreaMode: false,
  currentHistoryIndex: -1,
  ifOpenPopupNewArea: false,
};

export const creatingNewAreaReducer = createSlice({
  name: "visualization",
  initialState,
  reducers: {
    setNewAreaPoints: (state, action: PayloadAction<L.LatLngTuple[]>) => {
      state.newAreaPoints = action.payload;
    },
    setPushPointNewAreaPoints: (
      state,
      action: PayloadAction<L.LatLngTuple>
    ) => {
      state.newAreaPointsHistory.splice(state.currentHistoryIndex + 1);
      state.newAreaPoints.push(action.payload);
    },
    setPushPointNewAreaPointsHistory: (
      state,
      action: PayloadAction<L.LatLngTuple[]>
    ) => {
      state.newAreaPointsHistory.push(action.payload);
      state.currentHistoryIndex = state.newAreaPointsHistory.length - 1;
    },
    setToggleIsCreatingAreaMode: (state, action: PayloadAction<boolean>) => {
      if (state.isCreatingAreaMode) Object.assign(state, initialState);
      else state.isCreatingAreaMode = action.payload;
    },
    setNewAreaPointsModifyPointByIndex: (
      state,
      action: PayloadAction<{
        newPoint: L.LatLngTuple;
        newPointIndex: number;
        ifSPiceHistory?: boolean;
      }>
    ) => {
      if (action.payload.ifSPiceHistory === true) {
        state.newAreaPointsHistory.splice(state.currentHistoryIndex + 1);
      }
      state.newAreaPoints[action.payload.newPointIndex] =
        action.payload.newPoint;
    },
    setDecrementIndexHistory: (state) => {
      state.currentHistoryIndex -= 1;
    },
    setIncrementIndexHistory: (state) => {
      state.currentHistoryIndex += 1;
    },
    setClearStateCreatingNewArea: (state) => {
      Object.assign(state, initialState);
    },
    seIfOpenPopupNewArea: (
      state,
      action: PayloadAction<false | "map" | "room">
    ) => {
      state.ifOpenPopupNewArea = action.payload;
    },
  },
});

export const selectNewAreaPoints = (state: RootState) =>
  state.creatingNewArea.newAreaPoints;
export const selectNewAreaPointsHistory = (state: RootState) =>
  state.creatingNewArea.newAreaPointsHistory;
export const selectIsCreatingAreaMode = (state: RootState) =>
  state.creatingNewArea.isCreatingAreaMode;
export const selectCurrentHistoryIndex = (state: RootState) =>
  state.creatingNewArea.currentHistoryIndex;
export const selectIfOpenPopupNewArea = (state: RootState) =>
  state.creatingNewArea.ifOpenPopupNewArea;

export const {
  seIfOpenPopupNewArea,
  setNewAreaPoints,
  setIncrementIndexHistory,
  setDecrementIndexHistory,
  setPushPointNewAreaPoints,
  setToggleIsCreatingAreaMode,
  setPushPointNewAreaPointsHistory,
  setNewAreaPointsModifyPointByIndex,
  setClearStateCreatingNewArea,
} = creatingNewAreaReducer.actions;
export default creatingNewAreaReducer.reducer;
