import { useTranslation } from "react-i18next";
import { RequestStatus } from "../Constants/requestStatus";

const RequestStatusName = (requestStatusId: RequestStatus) => {
  const { t } = useTranslation();

  switch (requestStatusId) {
    case RequestStatus.INTRODUCTED:
      return t("request_status_introduced");
    case RequestStatus.APPROVED:
      return t("request_status_approved");
    case RequestStatus.FINALLY_APPROWED:
      return t("request_status_finally_approved");
    case RequestStatus.TO_CHANGE:
      return t("request_status_to_change");
    case RequestStatus.REJECTED:
      return t("request_status_rejected");
    case RequestStatus.FINALLY_REJECTED:
      return t("request_status_finally_rejected");
    case RequestStatus.CANCELED:
      return t("request_status_canceled");
    default:
      return "";
  }
};

export default RequestStatusName;
