import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  selectIfOpenPopupNewArea,
  seIfOpenPopupNewArea,
} from "../../../../reducers/visualization/creatingNewArea";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import NewRoomContent from "./newRoomContent";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";

export default function NewRoomPopup(): ReactElement {
  const [
    isLoading,
    // setIsLoading
  ] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [, setIfFetch] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const closDialog = () => dispatch(seIfOpenPopupNewArea(false));

  return (
    <div>
      <DialogStyled
        open={useAppSelector(selectIfOpenPopupNewArea) === "room"}
        onClose={closDialog}
      >
        <DialogTitleStyled title={t("adding_task")} />
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetch}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && <NewRoomContent close={closDialog} />}
        </LoadingWrapper>
        <DialogActionsStyled onCancel={closDialog} onConfirm={() => null} />
      </DialogStyled>
    </div>
  );
}
