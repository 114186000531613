import { useAppDispatch } from "../../store/hooks";
import {
  DialogType,
  hideConfirm,
  showConfirm,
} from "../../reducers/useConfirm";
import { useCallback } from "react";

interface confirmInterface {
  text: React.ReactNode;
  buttons: { buttonName: React.ReactNode; buttonId: number }[];
  typeDialog?: DialogType;
  disableBackdropClick?: boolean;
}

let resolveCallback;
export default function useConfirm() {
  const dispatch = useAppDispatch();

  const confirm = useCallback(
    ({ text, buttons, typeDialog, disableBackdropClick }: confirmInterface) => {
      dispatch(
        showConfirm({
          text: text,
          buttons: buttons,
          typeDialog: typeDialog,
          disableBackdropClick: disableBackdropClick,
        })
      );
      return new Promise((res, rej) => {
        resolveCallback = res;
      });
    },
    [dispatch]
  );

  const onConfirm = (selectedOption) => {
    resolveCallback(selectedOption);
    dispatch(hideConfirm());
  };

  const onCancel = () => {
    dispatch(hideConfirm());
    resolveCallback(false);
  };

  return { confirm, onConfirm, onCancel };
}
