import { useEffect, useReducer, useRef } from "react";
import parallelFetchJson from "./parallelFetchJson";
import useLogOut from "../../LogOut/logOut";

const useFetchNoParametrsReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { isFetching: true };
    case "SUCCESS":
      return action.payload;
  }
  return state;
};

const useParallelFetchJson = (
  requestType: "PUT" | "POST" | "PATCH" | `DELETE`,
  path: string,
  body: any,
  arrayId: number[],
  ifResetReturnedValue: boolean = false
) => {
  const [response, dispatch] = useReducer(useFetchNoParametrsReducer, {
    isFetching: true,
  });
  useLogOut(response?.ifLogOut);
  let isMounted = useRef(true);

  useEffect(() => {
    if (ifResetReturnedValue) dispatch({ type: "FETCHING" });
  }, [ifResetReturnedValue]);

  useEffect(() => {
    if (!body) return;

    dispatch({ type: "FETCHING" });
    parallelFetchJson(requestType, path, arrayId, body).then((data) => {
      if (isMounted.current) {
        dispatch({ type: "SUCCESS", payload: data });
      }
    });
  }, [path, body, arrayId, requestType]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return response;
};

export default useParallelFetchJson;
