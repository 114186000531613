import { useTranslation } from "react-i18next";
import styles from "./importFileStyle.module.scss";
import OwnButton from "../../helpersComponents/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import useFetchPostFormData from "../../../hooks/fetchHooks/post/useFetchPostFormData";
import { useSnackbar } from "notistack";

interface FileToImport {
  setIsFetching?: React.Dispatch<React.SetStateAction<any>>;
  setFetchAgain?: React.Dispatch<React.SetStateAction<any>>;
  callback?: () => void;
  exampleFile: any;
  hideExampleFile?: boolean;
  address: string;
}

const ImportFile = (props: FileToImport) => {
  const [selectedFile, setSelectedFile] = useState<any>(false);
  const [postFileBody, setPostFileBody] = useState<boolean>(false);
  const [importFileCircualProgress, setImportFileCircualProgress] =
    useState<boolean>(false);
  const ref: any = useRef();
  const { t } = useTranslation();

  const {
    setIsFetching,
    callback,
    setFetchAgain,
    exampleFile,
    hideExampleFile,
    address,
  } = props;

  let postFileRequest = useFetchPostFormData(address, postFileBody);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (postFileRequest.data?.status === 200) {
      enqueueSnackbar(t("successful_import"), { variant: "success" });
      if (setFetchAgain) setFetchAgain(true);
      if (setIsFetching) setIsFetching(true);
      if (callback) callback();
      setSelectedFile(false);
      setPostFileBody(false);
    } else if (postFileRequest.data?.status === 400) {
      let error = "";
      Object.keys(postFileRequest.data.errors).forEach((errorObject) => {
        postFileRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      if (setIsFetching) setIsFetching(true);
      enqueueSnackbar(error, { variant: "error" });
    } else if (postFileRequest.error !== null) {
      if (setIsFetching) setIsFetching(true);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
    setImportFileCircualProgress(false);
  }, [
    postFileRequest,
    setFetchAgain,
    callback,
    setIsFetching,
    enqueueSnackbar,
    t,
  ]);

  return (
    <div className={styles.containerImportFile}>
      <div className={styles.containerHeader}>
        <div>
          <b>{t("import_from_file")}</b>
        </div>
        {!hideExampleFile && (
          <div className={styles.containerExampleFile}>
            <a
              href={exampleFile.filePath}
              className="importLink"
              download={exampleFile.name}
            >
              <Button onClick={() => {}}>
                <div className="exampleFileNotifications">
                  (<div>{t("example_file_to_import")}</div>
                  <div>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  )
                </div>
              </Button>
            </a>
          </div>
        )}
      </div>
      <div className={styles.containerInput}>
        <div className={styles.selectedFiles}>
          <input
            type="file"
            style={{ display: "none" }}
            accept={".xls, .xlsx"}
            onClick={(e: any) => (e.target.value = null)}
            onChange={(e) => {
              if (!e.target.files) return;
              if (!e.target.files?.[0]) {
                return setSelectedFile(false);
              }
              setSelectedFile(e.target.files?.[0]);
            }}
            ref={ref}
          />
          <OwnButton
            onClick={() => {
              ref.current.click();
            }}
          >
            {t("select_file")}
          </OwnButton>
        </div>
        {selectedFile ? (
          <div className={styles.selectedFile}>
            <div>
              <b>{selectedFile.name}</b>{" "}
              {(selectedFile.size * 0.001).toFixed(2)} kB
            </div>
            <div>
              {!importFileCircualProgress ? (
                <OwnButton
                  onClick={() => {
                    if (!selectedFile) return;
                    let formdata: any = new FormData();
                    formdata.append("", selectedFile);
                    setPostFileBody(formdata);
                    if (setIsFetching) setIsFetching(true);
                    setImportFileCircualProgress(true);
                  }}
                >
                  {t("import")}
                </OwnButton>
              ) : (
                <div className={styles.circularProgressImport}>
                  <CircularProgress size={25} />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ImportFile;
