import { DialogTitle, DialogTitleProps } from "@material-ui/core";
import { ReactElement } from "react";

interface Props extends DialogTitleProps {
  title: string;
  classNameTitle?: string;
}
export default function DialogTitleStyled({
  title,
  classNameTitle,
  ...restPorps
}: Props): ReactElement {
  return (
    <DialogTitle className="dialogTitleGlobal" {...restPorps}>
      <div className={classNameTitle ? classNameTitle : "titlePopup"}>
        {title}
      </div>
    </DialogTitle>
  );
}
