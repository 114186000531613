import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useState, useEffect } from "react";
import { setSettings } from "../../reducers/settings";
import useFetchGet from "../fetchHooks/get/useFetchGet";
import { useTranslation } from "react-i18next";
import { selectAuthUser } from "../../reducers/session";
import { profilesRoutesDictionary } from "../../components/ProfilesRouting/profilesDictionaries";

const useUpdateSettings = (ifUpdateSettings: boolean, ifSetEmpty = false) => {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);
  let url = profilesRoutesDictionary[authUser.currentProfile?.type];
  if (url === "worker-time") {
    url = url + `/${authUser.currentProfile?.subjectId}`;
  }
  const settings = useFetchGet(`${url}/settings`, ifUpdateSettings);

  const preferences = useFetchGet(`${url}/preferences`, ifUpdateSettings);
  const { i18n } = useTranslation();
  const [ifSettingsUpdated, setIfSettingsUpdated] = useState<any>(false);

  useEffect(() => {
    if (ifSetEmpty) {
      dispatch(setSettings({}));
    }
  }, [dispatch, ifSetEmpty]);

  useEffect(() => {
    if (ifUpdateSettings === false && ifSettingsUpdated?.error !== true) {
      setIfSettingsUpdated(false);
    }
  }, [ifUpdateSettings, ifSettingsUpdated]);

  useEffect(() => {
    if (settings.error === 401 || preferences.error === 401) {
      dispatch(setSettings({ ifSettingsFetched: false }));
      return setIfSettingsUpdated({ error: true });
    }
    if (
      settings.data?.status === 400 ||
      settings.error !== null ||
      preferences.data?.status === 400 ||
      preferences.error !== null
    ) {
      dispatch(setSettings({ error: true }));
      return setIfSettingsUpdated({ error: true });
    }
    if (settings.data === null || preferences.data === null) return;

    //////// creating object of settings
    let settingsObject: any = {};
    settings.data.forEach((value) => {
      settingsObject[value.name] = value;
    });

    //////// overwrite setting by preferences
    preferences.data.forEach((value) => {
      settingsObject[value.name] = value;
    });

    /////////////// checking if there is language in settings, if is set it in localstorage
    let language = settingsObject?.["web_jezyk"];

    if (language !== undefined) {
      let languageArray = [language.value, 0];
      i18n.changeLanguage(language.value);
      localStorage.setItem(
        "languageAndOptionId",
        JSON.stringify(languageArray)
      );
    } else if (localStorage.getItem("languageAndOptionId") !== null) {
      let language = JSON.parse(
        localStorage.getItem("languageAndOptionId") || "{}"
      )?.[0];
      i18n.changeLanguage(language);
    }

    /*if (process.env.NODE_ENV === "development") {
      /////////////////////// TEMPORARY SETTINGS FOR TESTING ////////////////////////
      settingsObject["WebWiadomosciCzestotliwoscOdpytywania"] = {
        name: "WebWiadomosciCzestotliwoscOdpytywania",
        value: "300",
      };
      settingsObject["WebMaksIloscZalWnioskowUrlopowych"] = {
        name: "WebMaksIloscZalWnioskowUrlopowych",
        value: "2",
      }; //////// chyba działa
      settingsObject["WebObslugiwaneTypyZalWnioskowUrlopowych"] = {
        name: "WebObslugiwaneTypyZalWnioskowUrlopowych",
        value: ".jpg, .pdf, .png",
      };
      settingsObject["WebWylaczObslugeZalWnioskowUrlopowych"] = {
        name: "WebWylaczObslugeZalWnioskowUrlopowych",
        value: "1",
      };
      settingsObject["WebWniosekUrlopowyWyborGodzinyDlaOpiekiWymagany"] = {
        name: "WebWniosekUrlopowyWyborGodzinyDlaOpiekiWymagany",
        value: "1",
      };
      settingsObject["WebWniosekUrlopowyWymuszanieZastepstwa"] = {
        name: "WebWniosekUrlopowyWymuszanieZastepstwa",
        value: "1",
      };
      settingsObject["WebWniosekUrlopowyLiczbaDniWstecz"] = {
        name: "WebWniosekUrlopowyLiczbaDniWstecz",
        value: "5",
      };
      settingsObject["WebUkryjPracownikAnonse"] = {
        name: "WebUkryjPracownikAnonse",
        value: "0",
      };
      settingsObject["ZablokujAnonse"] = { name: "ZablokujAnonse", value: "0" };
      settingsObject["WebUkryjPracownikDelegacja"] = {
        name: "WebUkryjPracownikDelegacja",
        value: "0",
      };
      settingsObject["WebUkryjPracownikWnioskiPlanUrlopu"] = {
        name: "WebUkryjPracownikWnioskiPlanUrlopu",
        value: "0",
      };
      settingsObject["WebUkryjPracownikWnioskiUrlopowe"] = {
        name: "WebUkryjPracownikWnioskiUrlopowe",
        value: "0",
      };
      settingsObject["WebUkryjPracownikWnioskiGodzin"] = {
        name: "WebUkryjPracownikWnioskiGodzin",
        value: "0",
      };
      settingsObject["WebUkryjPracownikWyjsciaSluzbowe"] = {
        name: "WebUkryjPracownikWyjsciaSluzbowe",
        value: "0",
      };
      settingsObject["WebUkryjPodsumowanieEwidencjaRoczna"] = {
        name: "WebUkryjPodsumowanieEwidencjaRoczna",
        value: "0",
      };
      settingsObject["WebUkryjPodsumowanieCzasPracy"] = {
        name: "WebUkryjPodsumowanieCzasPracy",
        value: "0",
      };
      settingsObject["WebUkryjPodsumowanieZdarzenia"] = {
        name: "WebUkryjPodsumowanieZdarzenia",
        value: "0",
      };
      settingsObject["WebUkryjPodsumowanieHarmonogram"] = {
        name: "WebUkryjPodsumowanieHarmonogram",
        value: "0",
      };
      settingsObject["WebUkryjPodsumowanieNieobecnoscWspolpracownikow"] = {
        name: "WebUkryjPodsumowanieNieobecnoscWspolpracownikow",
        value: "0",
      };
      settingsObject["WebUkryjPodsumowanieUprawnieniaKlucze"] = {
        name: "WebUkryjPodsumowanieUprawnieniaKlucze",
        value: "0",
      };
      settingsObject["WebUkryjPosiadanaOdziezBHPISprzet"] = {
        name: "WebUkryjPosiadanaOdziezBHPISprzet",
        value: "0",
      };
      settingsObject["WebBrakOgraniczenWPlanowaniu"] = {
        name: "WebBrakOgraniczenWPlanowaniu",
        value: "0",
      };
      settingsObject["WebAnonseBezAprowizacji"] = {
        name: "WebAnonseBezAprowizacji",
        value: "0",
      };
      settingsObject["WebAnonseBezOsobyDocelowej"] = {
        name: "WebAnonseBezOsobyDocelowej",
        value: "0",
      };
      settingsObject["WebPracownikBezposrednioPodsumowanie"] = {
        name: "WebPracownikBezposrednioPodsumowanie",
        value: "0",
      };
      settingsObject["WebBrakZmianyHasla"] = {
        name: "WebBrakZmianyHasla",
        value: "0",
      };
      settingsObject["WebNazwaPodsumowanie"] = {
        name: "WebNazwaPodsumowanie",
        value: "0",
      };
      settingsObject["WebWniosekGodzinowyLiczbaDniWstecz"] = {
        name: "WebWniosekGodzinowyLiczbaDniWstecz",
        value: "1",
      };
      settingsObject["WebWidoczneWnioskiGodzinowe"] = {
        name: "WebWidoczneWnioskiGodzinowe",
        value: "0",
      };
      settingsObject["WnioskiOgraniczoneDoDzis"] = {
        name: "WnioskiOgraniczoneDoDzis",
        value: "1",
      };
      settingsObject["WebWyborJezyka"] = { name: "WebWyborJezyka", value: "1" };
      settingsObject["WebWejsciaWyjsciaZdalne"] = {
        name: "WebWejsciaWyjsciaZdalne",
        value: "1",
      };
      settingsObject["HomeOffice"] = { name: "HomeOffice", value: "1" };
      settingsObject["WebWejsciaWyjscia"] = {
        name: "WebWejsciaWyjscia",
        value: "1",
      };
      settingsObject["ZablokujWeWy"] = { name: "ZablokujWeWy", value: "0" };
      settingsObject["WebDodawanieZdarzenPrzezPrzelozonego"] = {
        name: "WebDodawanieZdarzenPrzezPrzelozonego",
        value: "1",
      };
      settingsObject["WebUsuwanieZdarzenPrzezPrzelozonego"] = {
        name: "WebUsuwanieZdarzenPrzezPrzelozonego",
        value: "1",
      };
      settingsObject["WebEdycjaZdarzenPrzezPrzelozonego"] = {
        name: "WebEdycjaZdarzenPrzezPrzelozonego",
        value: "1",
      };
      settingsObject["WebStronaGlownaUkryjMiesiecznyWymiarPracy"] = {
        name: "WebStronaGlownaUkryjMiesiecznyWymiarPracy",
        value: "0",
      };
      settingsObject["WebStronaGlownaUkryjDziennyWymiarPracy"] = {
        name: "WebStronaGlownaUkryjDziennyWymiarPracy",
        value: "0",
      };

      settingsObject["WebStronaGlownaUkryjSzybkieZadania"] = {
        name: "WebStronaGlownaUkryjSzybkieZadania",
        value: "0",
      };

      settingsObject["WebProjektyUkryjStronaGlowna"] = {
        name: "WebProjektyUkryjStronaGlowna",
        value: "0",
      };

      settingsObject["WebProjektyUkryjMojeZadania"] = {
        name: "WebProjektyUkryjMojeZadania",
        value: "0",
      };

      settingsObject["HarmonogramNorma"] = {
        name: "HarmonogramNorma",
        value: "0",
      };

      settingsObject["WebWejscieWyjscieZdalnePrzycisk8Godzin"] = {
        name: "WebWejscieWyjscieZdalnePrzycisk8Godzin",
        value: "1",
      };
    }*/

    settingsObject["ifSettingsFetched"] = true;
    dispatch(setSettings(settingsObject));
    setIfSettingsUpdated(true);
  }, [settings, preferences, i18n, dispatch]);

  return ifSettingsUpdated;
};

export default useUpdateSettings;
