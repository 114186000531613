import React, { ReactElement, useMemo, useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import marker from "../images/marker.png";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectNewAreaPoints,
  setNewAreaPointsModifyPointByIndex,
  setPushPointNewAreaPointsHistory,
} from "../../../../reducers/visualization/creatingNewArea";
import styles from "./selectArea.module.scss";

let greenIcon = L.icon({
  iconUrl: marker,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
});

interface Props {
  position: [number, number];
  pointIntex: number;
}

export default function DraggableMarker({
  position,
  pointIntex,
}: Props): ReactElement {
  const markerRef = useRef(null);
  const dispatch = useAppDispatch();
  const newAreaPoints = useAppSelector(selectNewAreaPoints);

  const eventHandlers = useMemo(
    () => ({
      move() {
        const marker: any = markerRef.current;
        if (marker != null) {
          let latLng = marker.getLatLng();

          let lat = latLng.lat;
          let lng = latLng.lng;
          // if (latLng.lat < 0) {
          //   lat = 0;
          // }
          // if (latLng.lng < 0) {
          //   lng = 0;
          // }
          dispatch(
            setNewAreaPointsModifyPointByIndex({
              newPoint: [
                // parseFloat(latLng.lat.toFixed(2)),
                // parseFloat(latLng.lng.toFixed(2)),
                lat,
                lng,
              ],
              newPointIndex: pointIntex,
            })
          );
        }
      },
      dragend() {
        const marker: any = markerRef.current;
        let latLng = marker.getLatLng();
        let lat = latLng.lat;
        let lng = latLng.lng;
        // if (latLng.lat < 0) {
        //   lat = 0;
        // }
        // if (latLng.lng < 0) {
        //   lng = 0;
        // }
        dispatch(
          setNewAreaPointsModifyPointByIndex({
            newPoint: [
              // parseFloat(latLng.lat.toFixed(2)),
              // parseFloat(latLng.lng.toFixed(2)),
              lat,
              lng,
            ],
            newPointIndex: pointIntex,
            ifSPiceHistory: true,
          })
        );
        dispatch(setPushPointNewAreaPointsHistory(newAreaPoints));
      },
    }),
    [dispatch, pointIntex, newAreaPoints]
  );

  return (
    <>
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        ref={markerRef}
        icon={greenIcon}
        position={position}
      >
        <Popup minWidth={450} keepInView={true}>
          <div className={styles.pointPopupRoot}>
            <div className={styles.pointsPopuplatLng}>
              <div className={styles.pointPopuplatLng}>
                {/* <div>lat:</div> */}
                <div>
                  <TextFieldStyled
                    type="number"
                    label={"lat"}
                    value={position[0]}
                    inputProps={{
                      step: "1",
                    }}
                    onChange={(e) => {
                      dispatch(
                        setNewAreaPointsModifyPointByIndex({
                          newPoint: [
                            // parseFloat(parseFloat(e.target.value).toFixed(2)),
                            e.target.value,
                            position[1],
                          ],
                          newPointIndex: pointIntex,
                          ifSPiceHistory: true,
                        })
                      );
                    }}
                    onBlur={() => {
                      dispatch(setPushPointNewAreaPointsHistory(newAreaPoints));
                    }}
                  />
                </div>
              </div>
              <div className={styles.pointPopuplatLng}>
                {/* <div>lng:</div> */}
                <div>
                  <TextFieldStyled
                    value={position[1]}
                    label={"lng"}
                    type="number"
                    inputProps={{
                      step: "1",
                    }}
                    onChange={(e) => {
                      dispatch(
                        setNewAreaPointsModifyPointByIndex({
                          newPoint: [
                            position[0],
                            // parseFloat(parseFloat(e.target.value).toFixed(2)),
                            e.target.value,
                          ],
                          newPointIndex: pointIntex,
                          ifSPiceHistory: true,
                        })
                      );
                    }}
                    onBlur={() => {
                      dispatch(setPushPointNewAreaPointsHistory(newAreaPoints));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </Marker>
    </>
  );
}
