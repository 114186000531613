import { ProfileType } from "../../../enums/profileType";
import ServicesAndDeliveriesContent from "../../helpersComponents/ServicesAndDeliveries/ServicesAndDeliveriesContent";

const ServicesAndDeliverieShiftDirector = () => {
  return (
    <div>
      <ServicesAndDeliveriesContent
        pathApiChangeConfirmationStatus="shift-director/delivery-service-change-status"
        pathApiServicesAndDeliveries="shift-director/deliveries-services"
        layoutSettingName="layoutShiftDirectorServicesAndDeliverie"
        profileType={ProfileType.SHIFT_DIRECTOR}
      />
    </div>
  );
};

export default ServicesAndDeliverieShiftDirector;
