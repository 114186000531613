import React from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import classnameForNotificationStatus from "../../../HelpersFunctions/classnameForNotificationStatus";

const useColumns = (profileType: ProfileType) => {
  const { t } = useTranslation();
  return profileType === ProfileType.AGENT ||
    profileType === ProfileType.OPERATIONAL_SUPPORT
    ? [
        { name: "action", title: t("action") },
        { name: "notificationNumber", title: t("number") },
        { name: "date", title: t("date"), type: "date" },
        { name: "companyName", title: t("company") },
        { name: "numberOfPeople", title: t("number_of_people") },
        {
          name: "confirmationStatusName",
          title: t("confirmation_status"),
          customFormatter: (row: IDeliveryServiceGuest) => (
            <p
              className={`status ${classnameForNotificationStatus(
                row.deliveryService.confirmationStatus
              )}`}
            >
              {row.deliveryService.confirmationStatusName}
            </p>
          ),
        },
        { name: "typeOfVisitName", title: t("type_of_visit") },
        { name: "guestsFilter", title: "guestsFilter", hide: true },
      ]
    : [
        { name: "notificationNumber", title: t("number") },
        { name: "date", title: t("date"), type: "date" },
        { name: "companyName", title: t("company") },
        { name: "numberOfPeople", title: t("number_of_people") },
        {
          name: "confirmationStatusName",
          title: t("confirmation_status"),
          customFormatter: (row: IDeliveryServiceGuest) => (
            <p
              className={`status ${classnameForNotificationStatus(
                row.deliveryService.confirmationStatus
              )}`}
            >
              {row.deliveryService.confirmationStatusName}
            </p>
          ),
        },
        { name: "typeOfVisitName", title: t("type_of_visit") },
        { name: "guestsFilter", title: "guestsFilter", hide: true },
      ];
};

export default useColumns;
