import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../helpersComponents/PopupHelpers";
import PopupContentServicesAndDeliveries from "./PopupContentServicesAndDeliveries";

interface IProps {
  isOpen: boolean;
  rowId?: number;
  closePopup: () => void;
  successCallback: () => void;
  isOpenRepeatPopup?: boolean;
  closeRepeatPopup?: () => void;
  repeatDeliveryId?: number;
  isOpenEditPopup?: boolean;
  editRowId?: number;
  closeEditPopup?: () => void;
  pathApiServicesAndDeliveries: string;
  pathApiCompaniesInPorts: string;
  pathApiArrivalsOfShips: string;
  pathApiTypesOfVisit: string;
  pathApiGuests: string;
}

const PopupAddServicesAndDeliveries: React.FC<IProps> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
  isOpenRepeatPopup,
  closeRepeatPopup,
  repeatDeliveryId,
  isOpenEditPopup,
  editRowId,
  closeEditPopup,
  pathApiServicesAndDeliveries,
  pathApiCompaniesInPorts,
  pathApiArrivalsOfShips,
  pathApiTypesOfVisit,
  pathApiGuests,
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
    if (closeRepeatPopup) closeRepeatPopup();
    if (closeEditPopup) closeEditPopup();
  }, [successCallback, closePopup, closeRepeatPopup, closeEditPopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: IAddDeliveryService) => {
    setErrorMessage("");
    setBodyRequest(JSON.stringify(values));
  };

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiServicesAndDeliveries}${
      editRowId !== undefined ? `/${editRowId}` : ""
    }`,
    method: editRowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  return (
    <DialogStyled
      open={isOpen || isOpenRepeatPopup || isOpenEditPopup ? true : false}
      onClose={() => {
        closePopup();
        if (closeRepeatPopup) closeRepeatPopup();
        if (closeEditPopup) closeEditPopup();
      }}
    >
      <DialogTitleStyled title={t("add_service_delivery")} />
      <PopupContentServicesAndDeliveries
        rowId={editRowId}
        onSubmit={onSubmit}
        pathApiServicesAndDeliveries={pathApiServicesAndDeliveries}
        pathApiCompaniesInPorts={pathApiCompaniesInPorts}
        pathApiArrivalsOfShips={pathApiArrivalsOfShips}
        pathApiTypesOfVisit={pathApiTypesOfVisit}
        pathApiGuests={pathApiGuests}
        repeatDeliveryId={repeatDeliveryId}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={() => {
          closePopup();
          if (closeRepeatPopup) closeRepeatPopup();
          if (closeEditPopup) closeEditPopup();
        }}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupAddServicesAndDeliveries;
