const filterEvents = (events) => {
  let filteredEvents = events.filter(
    (event) =>
      event.typeId === 1 ||
      event.typeId === 11 ||
      event.typeId === 8 ||
      event.typeId === 18 ||
      event.typeId === 2
  );
  return filteredEvents;
};

const removeBusinessExitEvents = (events) => {
  let filteredEvents = events.filter(
    (event) =>
      event.typeId !== 2 || (event.typeId === 2 && !event.timeExitReader)
  );
  return filteredEvents;
};

const removeElementsUntilFirstEntryEvent = (events) => {
  if (events) {
    // Wyszukujemy pierwsze zdarzenie wejścia
    for (let i = 0; i < events.length; i++) {
      // usuwamy wszystko co było przed tym pierwszym zdarzeniem
      if (
        events[i].typeId === 1 ||
        events[i].typeId === 11 ||
        (events[i].typeId === 2 && !events[i].timeExitReader)
      ) {
        events.splice(0, i);
        break;
      }
    }
  }
};

const removeElementsOfTheSameTypeAfterEachOther = (events) => {
  let result: any[] = [];

  if (events) {
    for (let i = 0; i < events.length; i++) {
      if (result.length === 0) {
        result.push(events[i]);
      } else {
        if (result[result.length - 1].typeId === events[i].typeId) {
          if (
            events[i].typeId === 2 &&
            result[result.length - 1].timeExitReader !==
              events[i].timeExitReader
          ) {
            result.push(events[i]);
          } else {
            result[result.length - 1] = events[i];
          }
        } else {
          result.push(events[i]);
        }
      }
    }
  }

  return result;
};

const computeWorkedTimeToday = (events, date = new Date()) => {
  let workedTimeToday = 0;

  if (events && events.length > 0) {
    let filteredEvents = filterEvents(events);

    // Sortujemy zdarzenia rosnąco
    let filteredSortedEvents = [...filteredEvents].sort((a, b) => {
      return new Date(a.time) > new Date(b.time) ? 1 : -1;
    });

    removeElementsUntilFirstEntryEvent(filteredSortedEvents);
    filteredSortedEvents =
      removeElementsOfTheSameTypeAfterEachOther(filteredSortedEvents);

    if (
      filteredSortedEvents &&
      filteredSortedEvents.length > 0 &&
      filteredSortedEvents[filteredSortedEvents.length - 1].typeId === 2 &&
      filteredSortedEvents[filteredSortedEvents.length - 1].timeExitReader
    )
      return -1;

    filteredSortedEvents = removeBusinessExitEvents(filteredSortedEvents);
    for (let i = 0; i < filteredSortedEvents.length; i = i + 1) {
      if (filteredSortedEvents[i + 1]) {
        workedTimeToday =
          workedTimeToday +
          new Date(filteredSortedEvents[i + 1].time).getTime() -
          new Date(filteredSortedEvents[i].time).getTime();
      } else {
        workedTimeToday =
          workedTimeToday +
          new Date().getTime() -
          new Date(filteredSortedEvents[i].time).getTime();
      }
    }
  }

  return Math.floor(workedTimeToday / 1000);
};

export { computeWorkedTimeToday, filterEvents };
