import { Menu, MenuItem } from "@material-ui/core";
import { LatLngExpression } from "leaflet";
import React, { ReactElement, useMemo, useState } from "react";
import { Polygon } from "react-leaflet";
import { selectIsCreatingAreaMode } from "../../../../reducers/visualization/creatingNewArea";
import {
  selectIsEditMode,
  setCurrentAreaDetailsId,
} from "../../../../reducers/visualization/visualization";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

interface Props {
  positions: LatLngExpression[];
  pathOptions: { color: string };
  areaId: number;
}

export default function SingleAreaPolygon({
  positions,
  pathOptions,
  areaId,
}: Props): ReactElement {
  const isCreatingAreaMode = useAppSelector(selectIsCreatingAreaMode);
  const [contextMenu, setContextMenu] = useState<null | {
    mouseX: number;
    mouseY: number;
  }>(null);
  const isEditMode = useAppSelector(selectIsEditMode);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setContextMenu(null);
  };

  const eventHandlers = useMemo(
    () => ({
      click() {
        if (!isEditMode) return;
        dispatch(setCurrentAreaDetailsId(areaId));
      },
      contextmenu(e) {
        if (isEditMode) return;
        setContextMenu((prev) =>
          prev === null
            ? {
                mouseX: e.originalEvent.clientX - 2,
                mouseY: e.originalEvent.clientY - 4,
              }
            : null
        );
      },
    }),
    [isEditMode, dispatch, areaId]
  );
  return (
    <>
      <Polygon
        eventHandlers={isCreatingAreaMode ? undefined : eventHandlers}
        pathOptions={pathOptions}
        positions={positions}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        onContextMenu={(e) => e.preventDefault()}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose}>Otwóz na stałe</MenuItem>
        <MenuItem onClick={handleClose}>Otwórz jednorazowo</MenuItem>
        <MenuItem onClick={handleClose}>Zamknij na stałe</MenuItem>
        <MenuItem onClick={handleClose}>Zablokuj wejście</MenuItem>
        <MenuItem onClick={handleClose}>Odblokuj wejście</MenuItem>
        <MenuItem onClick={handleClose}>Pokaż obecnych</MenuItem>
      </Menu>
    </>
  );
}
