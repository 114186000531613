import { i18n } from "../../lang/i18n";

export function getLocalizedDaysString(value: number, language: string) {
  let days = i18n.t("day").toLowerCase();

  if (value === 0 || value > 1) {
    days = i18n.t("days").toLowerCase();
  }

  return days;
}
