import { DialogContent } from "@material-ui/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteStyled,
  TextFieldStyled,
} from "../../../helpersComponents/MaterialUi";
import RowInput from "../../../helpersComponents/PopupHelpers/rowInput";
import Row from "../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../helpersComponents/PopupHelpers/rowTitle";

interface Props {
  close;
}

export default function NewRoomPopupContent({ close }: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Row>
        <RowTitle>{t("name")}:</RowTitle>
        <RowInput>
          <TextFieldStyled
            label={t("name")}
            defaultValue={"sdsd"}
            required={true}
          />
        </RowInput>
      </Row>
      <Row>
        <RowTitle>{t("name")}:</RowTitle>
        <RowInput>
          <AutocompleteStyled
            options={null}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            width="100%"
            getOptionSelected={(option, value) => option.id === value.id}
            value={null}
            label={t("select_project")}
          />
        </RowInput>
      </Row>
    </DialogContent>
  );
}
