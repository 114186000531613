import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps } from "formik";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import addDaysToDate from "../../../HelpersFunctions/dateAndTime/addDaysToDate";
import {
  DatePickerFormik,
  TextFieldFormik,
} from "../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import styles from "./performerStyle.module.scss";

interface Props {
  guestIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  handleChange: any;
  setFieldValue: any;
  guestsArrayValues: GuestObject;
  typeOfVisit: TypeOfVisitInterface | null;
}

export default function GenerateGuestsForm({
  guestIndex,
  arrayHelpers,
  handleChange,
  setFieldValue,
  guestsArrayValues,
  typeOfVisit,
}: Props): ReactElement {
  const { t } = useTranslation();
  const yesterday = addDaysToDate(new Date(), -1);

  return (
    <div>
      <div className={styles.guestTitle}>
        <b>
          {t("person")} {guestIndex + 1}
        </b>
        {guestIndex >= 0 ? (
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              arrayHelpers.remove(guestIndex);
            }}
          />
        ) : null}
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.rowLeftRight}>
          <div className={styles.title}>{t("first_name")}:</div>
          <div className={styles.input}>
            <TextFieldFormik
              label={t("first_name")}
              name={`guestsArray.${guestIndex}.firstName`}
              type="text"
              ifToUpperCaseShipsAndPorts={true}
              required={true}
            />
          </div>
        </div>
        <div className={styles.rowLeftRight}>
          <div className={styles.title}>{t("last_name")}:</div>
          <div className={styles.input}>
            <TextFieldFormik
              label={t("last_name")}
              name={`guestsArray.${guestIndex}.lastName`}
              type="text"
              ifToUpperCaseShipsAndPorts={true}
              required={true}
            />
          </div>
        </div>
      </div>

      <div className={styles.guestDataInfoRow}>
        {typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK && (
          <div>{t("notification_guest_performance_of_work")}.</div>
        )}
      </div>

      <div className={styles.rowContainer}>
        {(!typeOfVisit ||
          typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK) && (
          <div className={styles.rowLeftRight}>
            <div className={styles.title}>
              <CheckboxWithLabelFormik
                name={`guestsArray.${guestIndex}.isSelectedDateOfBirth`}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value) {
                    if (typeOfVisit?.id === TypeOfVisit.BUSINESS) return;
                    setFieldValue(
                      `guestsArray.${guestIndex}.isSelectedEmail`,
                      true
                    );
                  }
                }}
                ifCircle={true}
                label={
                  <span className={styles.formControlLabel}>
                    {t("date_of_birth")}
                  </span>
                }
              />
            </div>
            <div className={styles.input}>
              {guestsArrayValues.isSelectedDateOfBirth && (
                <DatePickerFormik
                  label={t("date_of_birth")}
                  name={`guestsArray.${guestIndex}.dateOfBirth`}
                  maxDate={yesterday}
                  view={["year", "month", "date"]}
                  format="dd-MM-yyyy"
                />
              )}
            </div>
          </div>
        )}

        <div className={styles.rowLeftRight}>
          <div className={styles.title}>
            <CheckboxWithLabelFormik
              name={`guestsArray.${guestIndex}.isSelectedEmail`}
              ifCircle={true}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value) {
                  if (typeOfVisit?.id === 1) return;
                  setFieldValue(
                    `guestsArray.${guestIndex}.isSelectedDateOfBirth`,
                    true
                  );
                }
              }}
              label={
                <span className={styles.formControlLabel}>{t("email")}</span>
              }
            />
          </div>
          <div className={styles.input}>
            {guestsArrayValues.isSelectedEmail && (
              <TextFieldFormik
                label={t("email")}
                name={`guestsArray.${guestIndex}.email`}
                type="text"
                ifToUpperCaseShipsAndPorts={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
