import { faHandHolding, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import NavigationPorter from "../../reception/Navigation/navigationReception";
import NavigationSecurity from "../../security/NavigationSecurity/navigationSecurity";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import EndVisitPopup from "./EndVisitPopup";
import RowDetailsUnreturnedCards from "./RowDetailsUnreturnedCards";
import styles from "./unreturnedCardsStyle.module.scss";

interface IProps {
  pathApi: string;
  profileType: ProfileType.SECURITY | ProfileType.RECEPTION;
  layoutSettingName: string;
  pathApiEndVisit?: string;
}

const ContentUnreturnedCards = ({
  pathApi,
  profileType,
  layoutSettingName,
  pathApiEndVisit,
}: IProps) => {
  const { t } = useTranslation();

  const columns = [
    { name: "guestFirstName", title: t("first_name") },
    { name: "guestLastName", title: t("last_name") },
    { name: "guestIDNumber", title: t("id_number") },
    { name: "notificationNumber", title: t("notification_number") },
    { name: "company", title: t("company") },
    { name: "entryDate", title: t("entry_date"), type: "date_hours" },
    { name: "permissionZoneName", title: t("permission_zone") },
    { name: "typeOfEntry", title: t("type_of_entry") },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: IUnreturnedCard[]) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          guest: `${row.guestFirstName} ${row.guestLastName}`,
          guestIDNumber: row?.guestIDNumber !== "" ? row.guestIDNumber : "-",
          company: row?.companyName !== "" ? row.companyName : "-",
          guestDateOfBirth: row?.guestDateOfBirth
            ? toLocaleStringCustom({
                date: row.guestDateOfBirth,
                t,
              })
            : "-",
          permissionZoneName: row?.permissionZoneName
            ? row.permissionZoneName
            : "-",
          typeOfEntry: row?.typeOfEntry ? row.typeOfEntry : "-",
          notificationNumber: row?.notificationNumber
            ? row.notificationNumber
            : "-",
        };
      });
      return modified;
    },
    [t]
  );

  const [
    unreturnedCardsRows,
    fetchingStateUnreturnedCards,
    fetchAgainUnreturnedCards,
  ] = useFetchAndSetGET<IUnreturnedCard[]>({
    path: pathApi,
    modifyResponseCallback: modifyResponseCallback,
  });

  useEffect(() => {
    fetchAgainUnreturnedCards();
  }, [fetchAgainUnreturnedCards]);

  const [cardNumberEndVisit, setCardNumberEndVisit] = useState({
    date: new Date(),
    cardNumber: "",
  });

  const [endVisitByCardNumberFetchingState, endVisitByCardNumberFetchAgain] =
    useFetchOtherThanGET({
      path: `${pathApiEndVisit}?cardNumber=${cardNumberEndVisit.cardNumber}`,
      method: "PATCH",
      body: JSON.stringify([
        {
          path: "/endVisit",
          op: "replace",
          value: true,
        },
      ]),
      contentType: "application/json",
      successCallback: fetchAgainUnreturnedCards,
    });

  useEffect(() => {
    if (cardNumberEndVisit.cardNumber && cardNumberEndVisit.date) {
      endVisitByCardNumberFetchAgain();
    }
  }, [cardNumberEndVisit, endVisitByCardNumberFetchAgain]);

  const [openEndVisitPopup, setOpenEndVisitPopup] = useState(false);
  const { confirm } = useConfirm();

  const [visitIdToEndVisit, setVisitIdToEndVisit] = useState<false | number>(
    false
  );
  const [endVisitByIdFetchingState, endVisitByIdFetchAgain] =
    useFetchOtherThanGET({
      path: `${pathApiEndVisit}/${visitIdToEndVisit}`,
      method: "PATCH",
      body: JSON.stringify([
        {
          path: "/endVisit",
          op: "replace",
          value: true,
        },
      ]),
      contentType: "application/json",
      successCallback: fetchAgainUnreturnedCards,
    });

  useEffect(() => {
    if (visitIdToEndVisit) {
      endVisitByIdFetchAgain();
    }
  }, [visitIdToEndVisit, endVisitByIdFetchAgain]);

  return (
    <>
      <div>
        <TopBanner pathName={t("unreturned_cards")} />
      </div>
      <div>
        {profileType === ProfileType.SECURITY ? (
          <NavigationSecurity />
        ) : (
          <NavigationPorter />
        )}
      </div>
      <div className={styles.summaryContainer}>
        {profileType === ProfileType.RECEPTION && (
          <div className={styles.generalButtonsContainer}>
            <div
              className={styles.cardButton}
              onClick={() => setOpenEndVisitPopup(true)}
            >
              <FontAwesomeIcon icon={faHandHolding} color="white" size="1x" />
              {t("receive_card")}
            </div>

            <div
              className={styles.cardButton}
              onClick={fetchAgainUnreturnedCards}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
              {" " + t("refresh")}
            </div>
          </div>
        )}
      </div>

      <div>
        <TableComponentMemo
          columns={columns}
          rows={unreturnedCardsRows}
          layoutSettingName={layoutSettingName}
          hideActionsAndVisibilityColumns={true}
          rowDetail={RowDetailsUnreturnedCards}
          fetchingState={{
            fetchAgain: fetchAgainUnreturnedCards,
            isError: fetchingStateUnreturnedCards.isError,
            isFetching:
              fetchingStateUnreturnedCards.isFetching ||
              endVisitByCardNumberFetchingState.isFetching ||
              endVisitByIdFetchingState.isFetching,
          }}
          defaultSorting={[{ columnName: "entryDate", direction: "asc" }]}
          actionsColumnUpdated={{
            editButton: {
              name: t("receive_card"),
              ifShow: profileType === ProfileType.RECEPTION,
              onClick: async (row: IUnreturnedCard) => {
                let selectedbuttonId = await confirm({
                  text: t("are_you_want_to_return_card") + "?",
                  buttons: [
                    { buttonName: t("yes"), buttonId: 0 },
                    { buttonName: t("no"), buttonId: 1 },
                  ],
                  disableBackdropClick: true,
                });

                if (selectedbuttonId === 0) {
                  setVisitIdToEndVisit(row.id);
                }
              },
            },
          }}
          tableEditColumnWidth={
            profileType === ProfileType.RECEPTION ? 200 : 50
          }
        />
      </div>

      {openEndVisitPopup && (
        <EndVisitPopup
          isOpen={openEndVisitPopup}
          closePopup={() => {
            setOpenEndVisitPopup(false);
          }}
          setCardNumberEndVisit={setCardNumberEndVisit}
        />
      )}
    </>
  );
};

export default ContentUnreturnedCards;
