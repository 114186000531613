import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { TextFieldFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { DialogContentStyled, Row, RowInput, RowTitle } from "../PopupHelpers";

const PopupContentNewCompany = ({
  rowId,
  onSubmit,
  pathApiCompaniesInPorts,
}: {
  rowId?: number;
  onSubmit: (values: ICompanyInPortsAdd) => void;
  pathApiCompaniesInPorts: string;
}) => {
  const { t } = useTranslation();

  const [companies, fetchingStateCompanies, fetchingAgainCompanies] =
    useFetchAndSetGET<ICompanyInPorts[]>({
      path: pathApiCompaniesInPorts,
    });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<ICompanyInPorts>({
      path: `${pathApiCompaniesInPorts}/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  return (
    <div>
      {" "}
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateCompanies.isFetching ||
          fetchingStateFetchedRow.isFetching
        }
        isError={
          fetchingStateCompanies.isError || fetchingStateFetchedRow.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateCompanies.isError) {
            fetchingAgainCompanies();
          }
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {companies && (fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                name: fetchedRow?.name ? fetchedRow.name : "",
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .nullable()
                  .required(t("field_required"))
                  .test("isNameExists", t("company_name_exists"), (value) => {
                    return value ===
                      companies.find((c) => c.name === value && rowId !== c.id)
                        ?.name
                      ? false
                      : true;
                  }),
              })}
            >
              <Form noValidate id="submitForm">
                <Row>
                  <RowTitle>{t("name")}:</RowTitle>
                  <RowInput>
                    <TextFieldFormik
                      ifToUpperCaseShipsAndPorts={true}
                      label={t("name")}
                      name="name"
                      type="text"
                      required={true}
                    />
                  </RowInput>
                </Row>
              </Form>
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PopupContentNewCompany;
