import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import classnameForNotificationStatus from "../../../HelpersFunctions/classnameForNotificationStatus";
import computeDateRangeFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { Button } from "@mui/material";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import DatePickerRange from "../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import ImportFile from "../ImportFile";
import PopupCrewEntryDescent from "./PopupCrewEntryDescent";
import RowDetailCrewEntryDescent from "./RowDetailCrewEntryDescent";

const ContentCrewEntryDescent = ({
  eventType,
}: {
  eventType: CrewEntryDescentType;
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "CurrentWeek")
  );
  const [endDate, setEndDate] = useState(
    getEndOfDate(new Date(), "CurrentWeek")
  );

  const columns = [
    { name: "notificationNumber", title: t("number") },
    { name: "date", title: t("date"), type: "date" },
    { name: "numberOfPeople", title: t("number_of_people") },
    {
      name: "confirmationStatusName",
      title: t("confirmation_status"),
      customFormatter: (row: ICrewEntryDescentGuest) => (
        <p
          className={`status ${classnameForNotificationStatus(
            row.crewEntryDescent.confirmationStatus
          )}`}
        >
          {row.crewEntryDescent.confirmationStatusName}
        </p>
      ),
    },
    { name: "guestsFilter", title: "guestsFilter", hide: true },
  ];

  const modifyArrivalOfShipsResponseCallback = useCallback(
    (responseData: ICrewEntryDescentGuest[]) => {
      const modified = responseData.map((row, index) => {
        return {
          ...row,
          id: index,
          notificationNumber: row.crewEntryDescent.notificationNumber,
          date: row.crewEntryDescent.date,
          numberOfPeople: row.guests.length,
          confirmationStatusName: row.crewEntryDescent.confirmationStatusName,
          guestsFilter: row.guests
            .map((item) => ({
              firstName: item.guest.firstName,
              lastName: item.guest.lastName,
              toString: function () {
                return this.firstName + " " + this.lastName;
              },
            }))
            .join(";"),
        };
      });
      return modified;
    },
    []
  );

  const [
    crewEntryDescentRows,
    fetchingStateCrewEntryDescent,
    fetchAgainCrewEntryDescent,
  ] = useFetchAndSetGET<ICrewEntryDescentGuest[]>({
    path: `agent/crew-entries-descents?DateFrom=
      ${computeDateRangeFetch(startDate)}&DateTo=${computeDateRangeFetch(
      endDate
    )}&EventType=${eventType}`,
    modifyResponseCallback: modifyArrivalOfShipsResponseCallback,
  });

  const [crewEntryDescentIdToRemove, setCrewEntryDescentIdToRemove] = useState<
    false | number
  >(false);

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `agent/crew-entries-descents/${crewEntryDescentIdToRemove}`,
    method: "DELETE",
    body: crewEntryDescentIdToRemove,
    setBody: setCrewEntryDescentIdToRemove,
    successCallback: fetchAgainCrewEntryDescent,
  });

  useEffect(() => {
    if (crewEntryDescentIdToRemove !== false) {
      fetchAgain();
    }
  }, [crewEntryDescentIdToRemove, fetchAgain]);

  useEffect(() => {
    fetchAgainCrewEntryDescent();
  }, [fetchAgainCrewEntryDescent, startDate, endDate]);

  const exampleFile = {
    name:
      eventType === CrewEntryDescentType.DESCENT
        ? "zejscie_zalogi_example"
        : "wejscie_zalogi_example",
    filePath:
      eventType === CrewEntryDescentType.DESCENT
        ? "/Import_zejscie_zalogi_przyklad.xls"
        : "/Import_wejscie_zalogi_przyklad.xls",
  };

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainCrewEntryDescent
  );

  const [isOpenEditPopup, setIsOpenEditPopup] = useState<{
    isOpen: boolean;
    rowId: number | undefined;
  }>({
    isOpen: false,
    rowId: undefined,
  });

  const closeEditPopup = useCallback(() => {
    setIsOpenEditPopup({
      isOpen: false,
      rowId: undefined,
    });
  }, []);

  return (
    <>
      <div>
        <TopBanner
          pathName={
            eventType === CrewEntryDescentType.ENTRY
              ? t("crew_entry")
              : t("crew_descent")
          }
        />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          >
            <div>
              <ImportFile
                exampleFile={exampleFile}
                setFetchAgain={fetchAgainCrewEntryDescent}
                address={`agent/crew-entries-descents/import?eventType=${
                  eventType === CrewEntryDescentType.ENTRY ? false : true
                }`}
              />
            </div>
          </DatePickerRange>
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={crewEntryDescentRows}
            layoutSettingName={
              eventType === CrewEntryDescentType.ENTRY
                ? "layoutAgentCrewEntryRecords"
                : "layoutAgentCrewDescentRecords"
            }
            rowDetail={RowDetailCrewEntryDescent}
            actionsColumnUpdated={{
              popup: (props) => {
                return (
                  <PopupCrewEntryDescent
                    rowEditId={isOpenEditPopup.rowId}
                    isOpenEditPopup={isOpenEditPopup.isOpen}
                    closeEditPopup={closeEditPopup}
                    eventType={eventType}
                    successCallback={fetchAgainCrewEntryDescent}
                    {...props}
                  />
                );
              },
              extraInfo: { eventType },
              successCallbackPopup: fetchAgainCrewEntryDescent,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
                customComponent(row: ICrewEntryDescentGuest) {
                  return row.crewEntryDescent.confirmationStatus !==
                    StatusInPortsType.APPROVED &&
                    row.crewEntryDescent.confirmationStatus !==
                      StatusInPortsType.REJECTED ? (
                    <Button
                      onClick={() => {
                        setIsOpenEditPopup({
                          isOpen: true,
                          rowId: row.crewEntryDescent.id,
                        });
                      }}
                    >
                      {t("edit")}
                    </Button>
                  ) : (
                    <></>
                  );
                },
              },
              removeButton: {
                ifShow: true,
                customComponent(row: ICrewEntryDescentGuest) {
                  return row.crewEntryDescent.confirmationStatus !==
                    StatusInPortsType.APPROVED &&
                    row.crewEntryDescent.confirmationStatus !==
                      StatusInPortsType.REJECTED ? (
                    <Button
                      onClick={() =>
                        setCrewEntryDescentIdToRemove(row.crewEntryDescent.id)
                      }
                    >
                      {t("remove")}
                    </Button>
                  ) : (
                    <></>
                  );
                },
                onClick: (row: ICrewEntryDescentGuest) => {
                  setCrewEntryDescentIdToRemove(row.crewEntryDescent.id);
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainCrewEntryDescent,
              isError: fetchingStateCrewEntryDescent.isError,
              isFetching:
                fetchingStateCrewEntryDescent.isFetching ||
                fetchingState.isFetching,
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentCrewEntryDescent;
