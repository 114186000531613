import { Palette } from "@devexpress/dx-react-chart";
import {
  Chart,
  Legend,
  PieSeries,
  Title,
} from "@devexpress/dx-react-chart-material-ui";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertMinsToHrsMins from "../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import styles from "./charts.module.scss";

interface Props {
  yesterday: number;
  twoDaysAgo: number;
  titleString: string;
  ifTimeFormat?: boolean;
}

export default function YesterdayTwoDaysAgoChart({
  yesterday,
  twoDaysAgo,
  titleString,
  ifTimeFormat = true,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [chartDataWorkingTime, setchartDataWorkingTime] = useState<any>([]);

  useEffect(() => {
    setchartDataWorkingTime([
      {
        absence: `${t("yesterday")}: ${
          ifTimeFormat ? convertMinsToHrsMins(yesterday) : yesterday
        }`,
        amount: yesterday,
      },
      {
        absence: `${t("day_before_yesterday")}: ${
          ifTimeFormat ? convertMinsToHrsMins(twoDaysAgo) : twoDaysAgo
        }`,
        amount: twoDaysAgo,
      },
    ]);
  }, [ifTimeFormat, twoDaysAgo, yesterday, t]);

  const title: any = (
    <div className={styles.chartLegend}>
      <div>{titleString} </div>
    </div>
  );

  return (
    <div>
      <div>
        <div className={styles.chartCard}>
          <Chart data={chartDataWorkingTime} height={200} width={450}>
            <Palette scheme={["#50D6AD", "#ffcd59"]} />
            <PieSeries
              name="Series name"
              valueField="amount"
              argumentField="absence"
            />
            <Legend />
            <Title text={title} />
          </Chart>
        </div>
      </div>
    </div>
  );
}
