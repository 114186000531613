import { useTranslation } from "react-i18next";
import { RequestStatus } from "../../Constants/requestStatus";
import { useCallback } from "react";

const useRequestStatusName = () => {
  const { t } = useTranslation();
  const func = useCallback(
    (requestStatusId: RequestStatus) => {
      switch (requestStatusId) {
        case RequestStatus.INTRODUCTED:
          return t("request_status_introduced");
        case RequestStatus.APPROVED:
          return t("request_status_approved");
        case RequestStatus.FINALLY_APPROWED:
          return t("request_status_finally_approved");
        case RequestStatus.TO_CHANGE:
          return t("request_status_to_change");
        case RequestStatus.REJECTED:
          return t("request_status_rejected");
        case RequestStatus.FINALLY_REJECTED:
          return t("request_status_finally_rejected");
        case RequestStatus.CANCELED:
          return t("request_status_canceled");
        default:
          return "";
      }
    },
    [t]
  );

  return func;
};

export default useRequestStatusName;
