import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { selectAuthUser } from "../../reducers/session";
import useFetchAndSetGET from "../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { useEffect } from "react";

interface IProps {
  checkPermirssionApiPath: string;
  notificationId: number;
  profileName: string;
}

const useGoToPortNotification = ({
  checkPermirssionApiPath,
  notificationId,
  profileName,
}: IProps) => {
  const history = useHistory();
  const authUser = useAppSelector(selectAuthUser);

  const [, fetchingState, fetchAgain] = useFetchAndSetGET({
    path: checkPermirssionApiPath,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (notificationId > 0) {
      fetchAgain();
    }
  }, [notificationId, fetchAgain]);

  useEffect(() => {
    if (!fetchingState.isFetching && fetchingState.response?.status === 200) {
      history.push({
        pathname: "/performer-add-notification",
        state: {
          successRedirectPath: `/home`,
          notificationId: notificationId,
          profileName: profileName,
          edit: true,
        },
      });
    } else if (fetchingState.isError) {
      history.push({
        pathname: "/home",
      });
    }
  }, [
    authUser.currentProfile.type,
    fetchingState.isFetching,
    fetchingState.isError,
    fetchingState.response?.status,
    history,
    notificationId,
    profileName,
  ]);
};

export default useGoToPortNotification;
