import { useTranslation } from "react-i18next";

const useColumns = (onlySupport: boolean | undefined) => {
  const { t } = useTranslation();
  return onlySupport
    ? [
        { name: "guestFirstName", title: t("first_name") },
        { name: "guestLastName", title: t("last_name") },
        { name: "companyName", title: t("company") },
        {
          name: "listOfEmployeeDateFrom",
          title: t("date_from"),
          type: "date_hours",
        },
        {
          name: "listOfEmployeeDateTo",
          title: t("date_to2"),
          type: "date_hours",
        },
        { name: "isFinished", title: t("is_finished") },
        {
          name: "entryDate",
          title: t("entry_company_area"),
          type: "date_hours",
        },
        { name: "exitDate", title: t("exit_company_area"), type: "date_hours" },
        { name: "cardUniversalNumber", title: t("card_tag") },
      ]
    : [
        { name: "guestFirstName", title: t("first_name") },
        { name: "guestLastName", title: t("last_name") },
        { name: "notificationNumber", title: t("notification_number") },
        { name: "companyName", title: t("company") },
        { name: "isFinished", title: t("is_finished") },
        {
          name: "entryDate",
          title: t("entry_company_area"),
          type: "date_hours",
        },
        { name: "exitDate", title: t("exit_company_area"), type: "date_hours" },
        { name: "typeOfEntry", title: t("type_of_entry") },
        { name: "cardUniversalNumber", title: t("card_tag") },
      ];
};

export default useColumns;
