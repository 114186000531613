import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import addDaysToDate from "../../../HelpersFunctions/dateAndTime/addDaysToDate";
import { DatePickerFormik, TextFieldFormik } from "../FormikInputs";
import CheckboxWithLabelFormik from "../FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import GetCardTagPopupRow from "../GetCardTagPopupRow/getCardTagPopupRow";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";
import styles from "./styles.module.scss";

interface Props extends ReadIdCardNumberFormikPopupRowInterface {
  guestIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  numberOfGuests: number;
  isPopupOpen: boolean;
  guestsArray: GuestsObject[];
}

export default function GuestForm({
  guestIndex,
  arrayHelpers,
  numberOfGuests,
  isPopupOpen,
  guestsArray,
  ...restProps
}: Props): ReactElement {
  const { t } = useTranslation();
  const { setFieldValue } = restProps;

  const successCallbackIdReader = useCallback(
    (readerResponse) => {
      setFieldValue(
        `guestsArray.${guestIndex}.firstName`,
        readerResponse.fields.firstName
      );
      setFieldValue(
        `guestsArray.${guestIndex}.lastName`,
        readerResponse.fields.lastName
      );
    },
    [setFieldValue, guestIndex]
  );

  const yesterday = addDaysToDate(new Date(), -1);

  const { values } = useFormikContext<PopupEntryUnauthorizedFormValues>();

  return (
    <>
      <div className={styles.guestTitle}>
        <b>
          {t("person")} {guestIndex + 1}
        </b>
        {numberOfGuests > 1 ? (
          <FontAwesomeIcon
            className="faTrash"
            icon={faTimesCircle}
            onClick={() => {
              arrayHelpers.remove(guestIndex);
            }}
          />
        ) : null}
      </div>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle>{t("first_name")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("first_name")}
              name={`guestsArray.${guestIndex}.firstName`}
              type="text"
              required={true}
              ifToUpperCaseShipsAndPorts={true}
              multiline
            />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>{t("last_name")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("last_name")}
              name={`guestsArray.${guestIndex}.lastName`}
              type="text"
              required={true}
              ifToUpperCaseShipsAndPorts={true}
              multiline
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>
      <TwoColumnsRowsContainer>
        <GetCardTagPopupRow
          setFieldValue={setFieldValue}
          name={`guestsArray.${guestIndex}.cardNumber`}
          componentId={`entryUnathorized/guestsArray.${guestIndex}.cardNumber`}
          isPopupOpen={isPopupOpen}
          checkForTagsInOtherInputs={{
            thisInputIndex: guestIndex,
            allInputs: guestsArray,
          }}
          required={!guestsArray[guestIndex].bh3}
          mask={"****************"}
        />

        <ReadIdCardNumberFormikPopupRow
          setFieldValue={setFieldValue}
          setFieldError={restProps.setFieldError}
          setFieldTouched={restProps.setFieldTouched}
          successCallback={successCallbackIdReader}
          name={`guestsArray.${guestIndex}.idNumber`}
          required={true}
        />
      </TwoColumnsRowsContainer>
      <TwoColumnsRowsContainer>
        <Row>
          <RowTitle>{t("date_of_birth")}:</RowTitle>
          <RowInput>
            <DatePickerFormik
              label={t("date_of_birth")}
              name={`guestsArray.${guestIndex}.dateOfBirth`}
              maxDate={yesterday}
              view={["year", "month", "date"]}
              format="dd-MM-yyyy"
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle>{t("email")}:</RowTitle>
          <RowInput>
            <TextFieldFormik
              label={t("email")}
              name={`guestsArray.${guestIndex}.email`}
              type="text"
              ifToUpperCaseShipsAndPorts={true}
            />
          </RowInput>
        </Row>
      </TwoColumnsRowsContainer>

      {values.typeOfVisit?.id === TypeOfVisit.COMPLETION_OF_THE_WORK && (
        <TwoColumnsRowsContainer>
          <Row>
            <RowTitle>{t("unnecessary_training")}:</RowTitle>
            <RowInput>
              <CheckboxWithLabelFormik
                name={`guestsArray.${guestIndex}.isTrainingNotRequired`}
                defaultValue={false}
                label={<span className="chekbox">{t("yes")}</span>}
                ifCircle={true}
              />
            </RowInput>
          </Row>

          {!values.guestsArray[`${guestIndex}`].isTrainingNotRequired && (
            <Row>
              <RowTitle>{t("set_guest_training_date_expiry")}:</RowTitle>
              <RowInput>
                <DatePickerFormik
                  label={t("training_expiry_date")}
                  name={`guestsArray.${guestIndex}.trainingExpiryDate`}
                  view={["year", "month", "date"]}
                  minDate={new Date()}
                  format="dd-MM-yyyy"
                  required={false}
                />
              </RowInput>
            </Row>
          )}
        </TwoColumnsRowsContainer>
      )}

      <CheckboxWithLabelFormik
        name={`guestsArray.${guestIndex}.bh3`}
        ifCircle={true}
        label={<span>{"BH3"}</span>}
      />
    </>
  );
}
