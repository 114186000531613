import {
  faDoorOpen,
  faFire,
  faFireExtinguisher,
  faIdCard,
  faIdCardAlt,
  faPeopleArrows,
  faShip,
  faTable,
  faTools,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PopupEntryUnauthorized from "../../helpersComponents/EntryUnauthorized/PopupEntryUnauthorized";
import PopupInspectionServices from "../../helpersComponents/InspectionServices/PopupInspectionServices";
import PopupInspectionCompanies from "../InspectionCompanies/PopupInspectionCompanies";
import NavLinkItem from "./NavLinkItem";
import styles from "./styles.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { selectReceptionNotificationMenuItemName } from "../../../reducers/notificationsSummary";

const NavigationReception = () => {
  const { t } = useTranslation();
  const notificationsMenuItemName = useAppSelector(
    selectReceptionNotificationMenuItemName
  );

  const [openInspectionServices, setOpenInspectionServices] = useState(false);
  const [openEntryUnauthorized, setOpenEntryUnauthorized] = useState(false);
  const [openInspectionCompanies, setOpenInspectionCompanies] = useState(false);

  const successCallbackInspectionServices = useCallback(() => {
    setOpenInspectionServices(false);
  }, []);

  const successCallbackEntryUnauthorized = useCallback(() => {
    setOpenEntryUnauthorized(false);
  }, []);

  const successCallbackInspectionCompanies = useCallback(() => {
    setOpenInspectionCompanies(false);
  }, []);

  return (
    <>
      <div className={styles.navigationContainer}>
        <div className={styles.firstLineContainer}>
          <NavLinkItem
            to="/reception/home"
            title={notificationsMenuItemName}
            icon={faUserFriends}
          />

          <NavLinkItem
            to="/reception/replacement-cards"
            title={t("replacement_cards")}
            icon={faIdCard}
          />

          <NavLinkItem
            to="/reception/arrival-of-ships"
            title={t("arrival_of_ships")}
            icon={faShip}
          />

          <div
            className={styles.navigationElement}
            onClick={() => setOpenInspectionCompanies(true)}
          >
            <p>
              <FontAwesomeIcon icon={faTools} /> {t("inspection_companies")}
            </p>
          </div>

          <div
            className={styles.navigationElement}
            onClick={() => setOpenInspectionServices(true)}
          >
            <p>
              <FontAwesomeIcon icon={faFireExtinguisher} />
              {"  " + t("inspection_services")}
            </p>
          </div>

          <NavLinkItem
            to="/reception/unreturned-cards"
            title={t("unreturned_cards")}
            icon={faIdCardAlt}
          />

          <NavLinkItem
            to="/reception/fire-report"
            title={t("fire_report")}
            icon={faFire}
            color="#ff3232"
          />
        </div>

        <div className={styles.secondLineContainer}>
          <NavLinkItem
            to="/reception/white-list"
            title={t("white_list")}
            icon={faTable}
          />

          <div
            className={styles.navigationElement}
            onClick={() => setOpenEntryUnauthorized(true)}
          >
            <p>
              <FontAwesomeIcon icon={faDoorOpen} /> {t("entry_unauthorized")}
            </p>
          </div>

          <NavLinkItem
            to="/reception/lists-of-employees"
            title={t("support")}
            icon={faPeopleArrows}
          />

          <div>
            {openInspectionServices && (
              <PopupInspectionServices
                open={openInspectionServices}
                onCancelChanges={() => setOpenInspectionServices(false)}
                successCallback={successCallbackInspectionServices}
                pathApi="reception/visits-inspection-services"
                inspectionServicesPathApi="reception/inspection-services"
                permissionZonesPathApi="reception/permission-zones?isActive=true"
                acWorkersPathApi="reception/ac-workers"
              />
            )}
            {openEntryUnauthorized && (
              <PopupEntryUnauthorized
                open={openEntryUnauthorized}
                onCancelChanges={() => setOpenEntryUnauthorized(false)}
                successCallback={successCallbackEntryUnauthorized}
                pathApi="reception/visits-unauthorized"
                ifRegistrationNumber={false}
                permissionZonesPathApi="reception/permission-zones?isActive=true"
                typesOfVisitPathApi="reception/types-of-visits-in-ports"
              />
            )}

            {openInspectionCompanies && (
              <PopupInspectionCompanies
                open={openInspectionCompanies}
                onCancelChanges={() => setOpenInspectionCompanies(false)}
                successCallback={successCallbackInspectionCompanies}
                pathApi="reception/visits-inspection-companies"
                guestsPathApi="reception/guests?addingByUser=true"
                inspectionCompaniesPathApi="reception/companies-in-ports?typeOfCompany=3"
                permissionZonesPathApi="reception/permission-zones?isActive=true"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationReception;
