import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableAndActions from "../HolidaysRequests/tableAndActions";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import { useTranslation } from "react-i18next";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import styles from "./according.module.scss";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { hoursRequestsTypes } from "../../../../Constants/hoursRequestsTypes";
import addMonthsToDate from "../../../../HelpersFunctions/dateAndTime/addMonthsToDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";

const HoursRequestsAccordion = React.memo(function HoursRequestsAccordion({
  startDateFetch,
  ifOnlyIntroduced,
  endDateFetch,
  timeWorkersIdsString,
  settingsLayoutName,
}: {
  startDateFetch: Date;
  ifOnlyIntroduced?: boolean;
  endDateFetch?: Date;
  timeWorkersIdsString?: string;
  settingsLayoutName: string;
}): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { t } = useTranslation();
  const [dataModified, setDataModified] = useState<any>(null);

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path:
      "superior-time/hour-requests?DateFrom=" +
      convertDateFetch(startDateFetch, "T00:00:00") +
      "&DateTo=" +
      (endDateFetch
        ? convertDateFetch(endDateFetch, "T23:59:59")
        : convertDateFetch(
            addMonthsToDate(getEndOfDate(new Date(), "Day"), 12)
          )) +
      (timeWorkersIdsString ? `&timeWorkersIds=${timeWorkersIdsString}` : ""),
  });

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;
    let data = fetchingState.response.resJson;
    let datamodifiedLocal: any = [];
    let lpHours = 1;

    data.forEach((element, index) => {
      if (element.status !== 1 && ifOnlyIntroduced) return;
      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].time = convertMinsToHrsMins(
        (new Date(element.dateTo).getTime() -
          new Date(element.dateFrom).getTime()) /
          (1000 * 60)
      );
      data[index].employee = element.firstName + " " + element.lastName;
      data[index].typeIdString = t(hoursRequestsTypes[data[index].typeId]);
      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].lp = lpHours;
      lpHours++;
      datamodifiedLocal.push(data[index]);
    });

    setDataModified(datamodifiedLocal);
    setIsFetching(false);
  }, [
    fetchingState.isError,
    fetchingState.response,
    ifOnlyIntroduced,
    setIsFetching,
    t,
  ]);

  const columns = [
    { name: "lp", title: "L.p." },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "dateFrom", title: t("date_from"), type: "date_hours" },
    { name: "dateTo", title: t("date_to2"), type: "date_hours" },
    { name: "time", title: t("time") },
    { name: "employee", title: t("employee") },
    { name: "typeIdString", title: t("type_of_request") },
    { name: "statusTranslated", title: t("status") },
    { name: "approvedBy", title: t("approved_by") },
    { name: "approved", title: t("approved") },
    { name: "comment", title: t("comment") },
    { name: "finallyApprovedBy", title: t("finnal_approved_by") },
  ];

  return (
    <div>
      <LoadingWrapper
        isLodadingProgress={fetchingState.isFetching}
        isError={fetchingState.isError}
        setIfFetchAgain={fetchAgain}
      >
        {!fetchingState.isFetching &&
          !fetchingState.isError &&
          dataModified !== null &&
          dataModified?.length > 0 && (
            <Accordion
              expanded={isExpanded}
              onChange={() => setIsExpanded(!isExpanded)}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={styles.titleAccordion}>
                  {t("list_of_hours")}
                  <div
                    className={
                      styles.greenBorderColor +
                      " " +
                      styles.circleAccordionTitleNumber
                    }
                  >
                    {dataModified.length}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.width100}>
                  {isExpanded && (
                    <TableAndActions
                      rows={dataModified}
                      columns={columns}
                      parallerPatchPath={"superior-time/hour-requests"}
                      ifWhite={true}
                      fetchObject={{
                        fetchAgain,
                        isError: fetchingState.isError,
                        isFetching: fetchingState.isFetching,
                        response: fetchingState.response,
                        setIsFetching,
                      }}
                      settingsLayoutName={settingsLayoutName}
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
      </LoadingWrapper>
    </div>
  );
});

export default HoursRequestsAccordion;
