import { getImageDimensionsBase64 } from "../../../../HelpersFunctions/getImageDimensionsBase64";
import L from "leaflet";

interface Parameters {
  Lmap: L.Map;
  mapBase64: string;
  currentMapOverlayRef: React.MutableRefObject<L.ImageOverlay>;
}

export default async function changeCurrentMapOverlay({
  Lmap,
  mapBase64,
  currentMapOverlayRef,
}: Parameters) {
  let dimensions = await getImageDimensionsBase64(mapBase64);
  Lmap.removeLayer(currentMapOverlayRef.current);
  const imageOverlay = L.imageOverlay(mapBase64, [
    [0, 0],
    [dimensions.h, dimensions.w],
  ]);
  currentMapOverlayRef.current = imageOverlay;
  imageOverlay.addTo(Lmap);

  Lmap.fitBounds(imageOverlay.getBounds());
}
