import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { TextFieldFormik } from "../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";

const PopupContentPfsoNewCompany = ({
  onSubmit,
  rowId,
}: {
  rowId?: number;
  onSubmit: (values) => void;
}) => {
  const { t } = useTranslation();

  const [companies, fetchingStateCompanies, fetchingAgainCompanies] =
    useFetchAndSetGET({
      path: "pfso/companies-in-ports?typeOfCompany=2",
    });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET({
      path: `pfso/companies-in-ports/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateCompanies.isFetching ||
          fetchingStateFetchedRow.isFetching
        }
        isError={
          fetchingStateCompanies.isError || fetchingStateFetchedRow.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateCompanies.isError) {
            fetchingAgainCompanies();
          }
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {companies && (fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                typeDelivery: fetchedRow
                  ? fetchedRow.type === false
                    ? true
                    : false
                  : true,
                typeGarbageDisposal: fetchedRow
                  ? fetchedRow.type === true
                    ? true
                    : false
                  : false,
                companyName: fetchedRow ? fetchedRow.name : "",
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={Yup.object({
                companyName: Yup.string()
                  .nullable()
                  .required(t("field_required"))
                  .test("isNameExists", t("company_name_exists"), (value) => {
                    return value ===
                      companies.find((c) => c.name === value && rowId !== c.id)
                        ?.name
                      ? false
                      : true;
                  }),
              })}
            >
              {(formikProps) => {
                return (
                  <Form noValidate id="submitForm">
                    <Row>
                      <RowTitle>{t("type")}:</RowTitle>
                      <RowInput>
                        <CheckboxWithLabelFormik
                          name={"typeDelivery"}
                          ifCircle={true}
                          label={<span>{t("delivery_man")}</span>}
                          onChangeExtra={(e) => {
                            if (
                              formikProps.values.typeGarbageDisposal === false
                            ) {
                              formikProps.setFieldValue(
                                "typeGarbageDisposal",
                                true
                              );
                            } else {
                              formikProps.setFieldValue(
                                "typeGarbageDisposal",
                                false
                              );
                            }
                          }}
                        />
                        <CheckboxWithLabelFormik
                          name={"typeGarbageDisposal"}
                          ifCircle={true}
                          label={<span>{t("garbage_disposal_company")}</span>}
                          onChangeExtra={(e) => {
                            if (formikProps.values.typeDelivery === false) {
                              formikProps.setFieldValue("typeDelivery", true);
                            } else {
                              formikProps.setFieldValue("typeDelivery", false);
                            }
                          }}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("name")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          ifToUpperCaseShipsAndPorts={true}
                          label={t("name")}
                          name="companyName"
                          type="text"
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentPfsoNewCompany;
