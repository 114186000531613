import { useMemo } from "react";
import { TFunction } from "react-i18next";

export default function useColumns(t: TFunction<"translation">) {
  const columns = useMemo(
    () => [
      { name: "name", title: t("employee") },
      {
        name: "day",
        title: t("day"),
        customTooltip: t("day"),
        type: "date",
      },
      {
        name: "organizationalUnits",
        title: t("organizational_units"),
        customTooltip: t("organizational_units"),
      },
      {
        name: "evidenceNumber",
        title: t("evidence_number"),
        customTooltip: t("evidence_number"),
      },
      {
        name: "entryString",
        title: t("entry"),
        // customTooltip: t("entry"),
      },
      {
        name: "beginWorkString",
        title: t("from"),
      },

      {
        name: "endWorkString",
        title: t("to"),
      },
      {
        name: "exitString",
        title: t("exit"),
        // customTooltip: t("exit"),
      },
      {
        name: "businessEntriesExitsString",
        title: "WS",
        customTooltip: t("business_entries_exits"),
      },
      {
        name: "normativeTimeString",
        title: "CN",
        customTooltip: t("normative_time"),
      },
      { name: "breaksString", title: "PR", customTooltip: t("breaks2") },

      { name: "overtime50String", title: "50", customTooltip: t("overtime50") },
      {
        name: "overtime100String",
        title: "100",
        customTooltip: t("overtime100"),
      },
      {
        name: "overtime150String",
        title: "150",
        customTooltip: t("overtime150"),
      },
      {
        name: "overtime200String",
        title: "200",
        customTooltip: t("overtime200"),
      },
      { name: "nightWorkString", title: "PN", customTooltip: t("night_work") },

      { name: "lateString", title: "SP", customTooltip: t("late") }, //
      { name: "absencesString", title: "N", customTooltip: t("absences") },
      {
        name: "absencesWithWorkedNormString",
        title: "NWN",
        customTooltip: t("absences_with_worked_norm"),
      },
      {
        name: "daysOffWithWorkedNormString",
        title: "DWN",
        customTooltip: t("days_off_wit_worked_norm"),
      },
      {
        name: "homeOfficeString",
        title: "HO",
        customTooltip: t("home_office"),
      },
      {
        name: "normBalanceString",
        title: "SN",
        customTooltip: t("norm_balance"),
      },
      {
        name: "languageLearningHoursString",
        title: "JO",
        customTooltip: t("language_learning_hours"),
      },
      {
        name: "nightWorkOvertimeString",
        title: "PNN",
        customTooltip: t("night_work_overtime"),
      },
      {
        name: "nightWorkScheduleString",
        title: "PNH",
        customTooltip: t("night_work_schedule"),
      },
      { name: "notWorkedString", title: "NDP", customTooltip: t("not_worked") },
      { name: "overtimeString", title: "NG", customTooltip: t("overtime") }, //
      ///////////////////// newwwwww
      {
        name: "remainsToWorkoutString",
        title: "POZ",
        customTooltip: t("remains_to_workout"),
      },
      {
        name: "socialBreakString",
        title: "PS",
        customTooltip: t("social_break"),
      },
      {
        name: "timeSpentOnThePremisesOfTheCompanyString",
        title: "CC",
        customTooltip: t("time_spent_on_the_premises_of_the_company"),
      },
      {
        name: "unpaidPrivatePassString",
        title: "PNPŁ",
        customTooltip: t("unpaid_private_pass"),
      },
      {
        name: "workAboveNormString",
        title: "PPN",
        customTooltip: t("work_above_norm"),
      },
      {
        name: "workDuringDutyString",
        title: "CD",
        customTooltip: t("work_during_duty"),
      },
      {
        name: "workOfAdolescentsInForbiddenWorkString",
        title: "MZ",
        customTooltip: t("work_of_adolescents_in_forbidden_work"),
      },
      {
        name: "workOnDaysOffString",
        title: "DW",
        customTooltip: t("work_on_days_off"),
      },
      {
        name: "workOnHolidayString",
        title: "CU",
        customTooltip: t("work_on_holiday"),
      },
      {
        name: "workOnSundaysAndHolidaysString",
        title: "NS",
        customTooltip: t("work_on_sundays_and_holidays"),
      },
      {
        name: "workTimeStandardString",
        title: "NR",
        customTooltip: t("work_time_standard"),
      }, //
      {
        name: "workingOffString",
        title: "ODR",
        customTooltip: t("working_off"),
      }, //
      {
        name: "workingTimeString",
        title: "CP",
        customTooltip: t("working_time"),
      },
      {
        name: "absenceShortcutString",
        title: "TN",
        customTooltip: t("absence_shortcut"),
      },
      {
        name: "do1String",
        title: "Do1",
        customTooltip: t("do1"),
      },
      {
        name: "dutyString",
        title: "DZ",
        customTooltip: t("duty"),
      },
      {
        name: "entryCompanyAreaString",
        title: "Wej",
        customTooltip: t("entry_company_area"),
      },
      {
        name: "exitCompanyAreaString",
        title: "Wyj",
        customTooltip: t("exit_company_area"),
      },
      {
        name: "firstShiftString",
        title: "I",
        customTooltip: t("first_shift"),
      },
      {
        name: "od1String",
        title: "Od1",
        customTooltip: t("od1"),
      },
      {
        name: "overtime50PercentToPayString",
        title: "W50",
        customTooltip: t("overtime_50_percent_to_pay"),
      },
      {
        name: "overtime50PercentToTakeString",
        title: "ON501",
        customTooltip: t("overtime_50_percent_to_take"),
      },
      {
        name: "overtime100PercentToPayString",
        title: "W100",
        customTooltip: t("overtime_100_percent_to_pay"),
      },
      {
        name: "overtime100PercentToTakeString",
        title: "ON1001",
        customTooltip: t("overtime_100_percent_to_take"),
      },
      {
        name: "privateLeaveString",
        title: "PNPŁ",
        customTooltip: t("private_leave"),
      },
      {
        name: "secondShfitString",
        title: "II",
        customTooltip: t("second_shfit"),
      },
      {
        name: "skTrainingString",
        title: "SK",
        customTooltip: t("sk_training"),
      },
      {
        name: "thirdShiftString",
        title: "III",
        customTooltip: t("third_shift"),
      },
      {
        name: "workAtCostCenterString",
        title: "CK",
        customTooltip: t("work_at_cost_center"),
      },
      {
        name: "workOnHolidaysString",
        title: "CU",
        customTooltip: t("work_on_holidays"),
      },
      {
        name: "workedString",
        title: "PD",
        customTooltip: t("worked2"),
      },
      {
        name: "blockToEditString",
        title: "BL",
        customTooltip: t("block_to_Edit"),
      },
      {
        name: "confirmedByString",
        title: t("approved_by"),
        customTooltip: t("approved_by"),
      },
    ],
    [t]
  );

  return columns;
}
