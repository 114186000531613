import React from "react";
import { Polygon, Polyline, useMapEvents } from "react-leaflet";
import {
  selectNewAreaPoints,
  setPushPointNewAreaPoints,
  setPushPointNewAreaPointsHistory,
} from "../../../../reducers/visualization/creatingNewArea";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import DraggableMarker from "./draggableMarker";

export function CreateNewArea() {
  const newAreaPoints = useAppSelector(selectNewAreaPoints);
  const dispatch = useAppDispatch();

  useMapEvents({
    click(e) {
      let lat = e.latlng.lat;
      let lng = e.latlng.lng;
      // if (e.latlng.lat < 0) {
      //   lat = 0;
      // }
      // if (e.latlng.lng < 0) {
      //   lng = 0;
      // }
      dispatch(
        setPushPointNewAreaPoints([
          // parseFloat(e.latlng.lat.toFixed(2)),
          // parseFloat(e.latlng.lng.toFixed(2)),
          lat,
          lng,
        ])
      );
      dispatch(
        setPushPointNewAreaPointsHistory([
          ...newAreaPoints,
          [
            // parseFloat(e.latlng.lat.toFixed(2)),
            // parseFloat(e.latlng.lng.toFixed(2)),
            lat,
            lng,
          ],
        ])
      );
    },
  });

  const limeOptions = { color: "black" };
  const temporaryPolygonStyle = { color: "lightblue", weight: 0 };

  return (
    <>
      {newAreaPoints.length > 0 &&
        newAreaPoints.map((point, pointIntex) => {
          return (
            <React.Fragment key={pointIntex}>
              <DraggableMarker
                pointIntex={pointIntex}
                key={pointIntex}
                position={point}
              />
              <Polyline pathOptions={limeOptions} positions={newAreaPoints} />
              <Polygon
                pathOptions={temporaryPolygonStyle}
                positions={newAreaPoints}
              />
            </React.Fragment>
          );
        })}
    </>
  );
}
