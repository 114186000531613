import { ProfileType } from "../../../enums/profileType";
import ServicesAndDeliveriesContent from "../../helpersComponents/ServicesAndDeliveries/ServicesAndDeliveriesContent";

const ServicesAndDeliveriesPfso = () => {
  return (
    <div>
      <ServicesAndDeliveriesContent
        pathApiChangeConfirmationStatus="pfso/delivery-service-change-status"
        pathApiServicesAndDeliveries="pfso/deliveries-services"
        layoutSettingName="layoutPfsoServicesAndDeliverie"
        profileType={ProfileType.PFSO}
      />
    </div>
  );
};

export default ServicesAndDeliveriesPfso;
