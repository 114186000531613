import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import computeDateRangeFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import FormAddNewGuest from "../../agent/FormAddNewGuest/FormAddNewGuest";
import FormAddNewCompany from "../../agent/FromAddNewCompany/FormAddNewCompany";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../helpersComponents/FormikInputs";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import { Guest } from "../../reception/InspectionCompanies/type";
import styles from "./styles.module.scss";

interface IProps {
  rowId?: number;
  onSubmit: (values: IAddDeliveryService) => void;
  pathApiCompaniesInPorts: string;
  pathApiArrivalsOfShips: string;
  pathApiTypesOfVisit: string;
  pathApiGuests: string;
  pathApiServicesAndDeliveries: string;
  repeatDeliveryId?: number | null;
}

const PopupContentServicesAndDeliveries: React.FC<IProps> = ({
  onSubmit,
  rowId,
  pathApiCompaniesInPorts,
  pathApiArrivalsOfShips,
  pathApiTypesOfVisit,
  pathApiGuests,
  pathApiServicesAndDeliveries,
  repeatDeliveryId,
}) => {
  const { t } = useTranslation();

  const startDate = getBeginningOfDate(new Date(), "Month");
  const [
    arrivalsOfShips,
    fetchingStateArrivalsOfShips,
    fetchingAgainArrivalsOfShips,
  ] = useFetchAndSetGET<ArrivalOfShip[]>({
    path: `${pathApiArrivalsOfShips}?DateFrom=${computeDateRangeFetch(
      startDate
    )}&DateTo=${computeDateRangeFetch(new Date("9999-12-31"))}`,
  });

  const [guests, fetchingStateGuests, fetchingAgainGuests] = useFetchAndSetGET<
    IGuest[]
  >({
    path: pathApiGuests,
  });

  const [companies, fetchingStateCompanies, fetchingAgainCompanies] =
    useFetchAndSetGET<ICompanyInPorts[]>({
      path: pathApiCompaniesInPorts,
    });

  const [typesOfVisit, fetchingStateTypesOfVisit, fetchingAgainTypesOfVisit] =
    useFetchAndSetGET<IDeliveryServiceType[]>({
      path: pathApiTypesOfVisit,
    });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<IDeliveryServiceGuest>({
      path: repeatDeliveryId
        ? `${pathApiServicesAndDeliveries}/${repeatDeliveryId}`
        : `${pathApiServicesAndDeliveries}/${rowId}`,
      startFetchOnInitial: repeatDeliveryId || rowId ? true : false,
    });

  const [showAddGuestForm, setShowAddGuestForm] = useState(false);
  const [addedGuestId, setAddedGuestId] = useState<false | number>(false);
  const [addedGuests, setAddedGuests] = useState<any[]>([]);

  const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
  const [addedCompanyId, setAddedCompanyId] = useState<false | number>(false);

  useEffect(() => {
    if (addedGuestId !== false) {
      fetchingAgainGuests();
    }
  }, [addedGuestId, fetchingAgainGuests]);

  useEffect(() => {
    if (addedCompanyId !== false) {
      fetchingAgainCompanies();
    }
  }, [addedCompanyId, fetchingAgainCompanies]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={
          fetchingStateFetchedRow.isFetching ||
          fetchingStateTypesOfVisit.isFetching
        }
        isError={
          fetchingStateFetchedRow.isError || fetchingStateTypesOfVisit.isError
        }
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
          if (fetchingStateTypesOfVisit.isError) {
            fetchingAgainTypesOfVisit();
          }
        }}
      >
        {arrivalsOfShips &&
          guests &&
          companies &&
          typesOfVisit &&
          (fetchedRow || rowId === undefined) && (
            <DialogContentStyled>
              <Formik
                initialValues={{
                  arrivalOfShip: fetchedRow
                    ? arrivalsOfShips.find(
                        (aos: ArrivalOfShip) =>
                          aos.id === fetchedRow.deliveryService.arrivalOfShipId
                      )
                    : null,
                  date: fetchedRow
                    ? new Date(fetchedRow.deliveryService.date)
                    : null,
                  selectedGuests:
                    fetchedRow && (repeatDeliveryId || rowId)
                      ? guests.filter((guest) =>
                          fetchedRow.guests
                            .map((item) => item.guest.id)
                            .includes(guest.id)
                        )
                        ? guests.filter((guest) =>
                            fetchedRow.guests
                              .map((item) => item.guest.id)
                              .includes(guest.id)
                          )
                        : []
                      : [],
                  company: fetchedRow
                    ? companies.find(
                        (c: ICompanyInPorts) =>
                          c.id === fetchedRow.deliveryService.companyId
                      )
                    : null,
                  registrationNumber: fetchedRow
                    ? fetchedRow.deliveryService.registrationNumber
                    : "",
                  typeOfVisit: fetchedRow
                    ? typesOfVisit.find(
                        (type) =>
                          type.id === fetchedRow.deliveryService.typeOfVisitId
                      )
                    : null,
                  comments:
                    fetchedRow && fetchedRow.deliveryService.comments
                      ? fetchedRow.deliveryService.comments
                      : "",
                }}
                onSubmit={(values) => {
                  const object = {
                    arrivalOfShipId: values.arrivalOfShip?.id,
                    date: values.date,
                    guestsIds: values.selectedGuests.map((guest) => guest?.id),
                    companyId: values.company?.id,
                    registrationNumber: values.registrationNumber
                      ? values.registrationNumber
                      : "",
                    typeOfVisitId: values.typeOfVisit?.id,
                    comments:
                      values.comments !== null && values.comments !== ""
                        ? values.comments
                        : null,
                  } as IAddDeliveryService;

                  onSubmit(object);
                }}
                validationSchema={Yup.object({
                  arrivalOfShip: Yup.object()
                    .nullable()
                    .required(t("field_required")),
                  date: Yup.date()
                    .nullable()
                    .required(t("field_required"))
                    .test(
                      "testWithArrivalOfShipDate",
                      t("data_cannot_be_earlier_then_ship_arrival_date"),
                      function (value) {
                        return !value ||
                          new Date(
                            this.parent.arrivalOfShip?.dateOfArrival
                          ).setHours(0, 0, 0, 0) >
                            new Date(value).setHours(0, 0, 0, 0)
                          ? false
                          : true;
                      }
                    ),
                  selectedGuests: Yup.array().test(
                    "isAnyGuestSelected",
                    t("field_required"),
                    (value) => {
                      return value ? value?.length > 0 : false;
                    }
                  ),
                  company: Yup.object()
                    .nullable()
                    .required(t("field_required")),
                  registrationNumber: Yup.string().nullable(),
                  typeOfVisit: Yup.object()
                    .nullable()
                    .required(t("field_required")),
                })}
              >
                {(formikProps) => (
                  <Form noValidate id="submitForm">
                    <LoadingWrapper
                      isLodadingProgress={
                        fetchingStateArrivalsOfShips.isFetching
                      }
                      isError={fetchingStateArrivalsOfShips.isError}
                      setIfFetchAgain={() => {
                        if (fetchingStateArrivalsOfShips.isError) {
                          fetchingAgainArrivalsOfShips();
                        }
                      }}
                    >
                      <Row>
                        <RowTitle>{t("arrival_of_ship")}:</RowTitle>
                        <RowInput>
                          <AutocompleteFormik
                            options={arrivalsOfShips}
                            getOptionLabel={(option: any) => {
                              return `${option.shipName} - ${new Date(
                                option.dateOfArrival
                              ).toLocaleDateString()}`;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: ArrivalOfShip,
                              value: ArrivalOfShip
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_arrival_of_ship")}
                            name="arrivalOfShip"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    </LoadingWrapper>

                    <LoadingWrapper
                      isLodadingProgress={
                        fetchingStateGuests.isFetching ||
                        fetchingStateFetchedRow.isFetching
                      }
                      isError={
                        fetchingStateGuests.isError ||
                        fetchingStateFetchedRow.isError
                      }
                      setIfFetchAgain={() => {
                        if (fetchingStateGuests.isError) {
                          fetchingAgainGuests();
                        }
                        if (fetchingStateFetchedRow.isError) {
                          fetchAgainFetchedRow();
                        }
                      }}
                    >
                      <Row>
                        <RowTitle>{t("date")}:</RowTitle>
                        <RowInput>
                          <DatePickerFormik
                            label={t("date")}
                            name="date"
                            view={["year", "month", "date"]}
                            format="dd-MM-yyyy"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                      <Row>
                        <RowTitle>{t("persons")}:</RowTitle>
                        <RowInput style={{ display: "flex" }}>
                          <AutocompleteFormik
                            options={guests}
                            getOptionLabel={(option: Guest) => {
                              return `${option.firstName} ${option.lastName}`;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: Guest,
                              value: Guest
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_persons")}
                            name="selectedGuests"
                            required={true}
                            multiple
                            disableCloseOnSelect
                            filterSelectedOptions
                            style={{ width: "300px", marginRight: "10px" }}
                            onChangeExtra={(e: any) => {
                              setAddedGuests(e);
                            }}
                          />
                          <div
                            className={styles.iconShowGuestForm}
                            onClick={() => {
                              setShowAddGuestForm(!showAddGuestForm);
                            }}
                          >
                            {showAddGuestForm ? (
                              <FontAwesomeIcon
                                className="faMinusNotifications fa-lg"
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="faPlusNotificationsAgent fa-lg"
                                icon={faPlus}
                              />
                            )}
                          </div>
                        </RowInput>
                      </Row>
                    </LoadingWrapper>

                    {showAddGuestForm && (
                      <FormAddNewGuest
                        addGuestPath={pathApiGuests}
                        setShowAddGuestFrom={setShowAddGuestForm}
                        setAddedGuestId={setAddedGuestId}
                        addedGuestId={addedGuestId}
                        guests={guests}
                        addedGuests={addedGuests}
                        setAddedGuests={setAddedGuests}
                        setFieldValue={formikProps.setFieldValue}
                        isCrewMember={false}
                      />
                    )}

                    <LoadingWrapper
                      isLodadingProgress={fetchingStateCompanies.isFetching}
                      isError={fetchingStateCompanies.isError}
                      setIfFetchAgain={() => {
                        if (fetchingStateCompanies.isError) {
                          fetchingAgainCompanies();
                        }
                      }}
                    >
                      <Row>
                        <RowTitle>{t("companies")}:</RowTitle>
                        <RowInput style={{ display: "flex" }}>
                          <AutocompleteFormik
                            options={companies}
                            getOptionLabel={(option: ICompanyInPorts) => {
                              return option.name;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: ICompanyInPorts,
                              value: ICompanyInPorts
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_company")}
                            name="company"
                            required={true}
                            style={{ width: "300px", marginRight: "10px" }}
                          />
                          <div
                            className={styles.iconShowGuestForm}
                            onClick={() => {
                              setShowAddCompanyForm(!showAddCompanyForm);
                            }}
                          >
                            {showAddCompanyForm ? (
                              <FontAwesomeIcon
                                className="faMinusNotifications fa-lg"
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="faPlusNotificationsAgent fa-lg"
                                icon={faPlus}
                              />
                            )}
                          </div>
                        </RowInput>
                      </Row>
                    </LoadingWrapper>
                    {showAddCompanyForm && (
                      <FormAddNewCompany
                        addCompanyPath={pathApiCompaniesInPorts}
                        setShowAddCompanyForm={setShowAddCompanyForm}
                        setAddedCompanyId={setAddedCompanyId}
                        addedCompanyId={addedCompanyId}
                        companies={companies}
                        setFieldValue={formikProps.setFieldValue}
                      />
                    )}

                    <Row>
                      {formikProps.values.registrationNumber.length > 0 && (
                        <div className={styles.infoContainer}>
                          {t("warning").toUpperCase()}:{" "}
                          {t("dct_agent_notification_info")}
                        </div>
                      )}
                    </Row>
                    <Row>
                      <RowTitle>{t("licence_plate")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("licence_plate")}
                          name="registrationNumber"
                          type="text"
                          ifToUpperCaseShipsAndPorts={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("type_of_visit")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={typesOfVisit}
                          getOptionLabel={(option: IDeliveryServiceType) => {
                            return option.name;
                          }}
                          width="100%"
                          getOptionSelected={(
                            option: ArrivalOfShip,
                            value: ArrivalOfShip
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("select_type_of_visit")}
                          name="typeOfVisit"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    <Row>
                      <RowTitle>{t("comments")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("comments")}
                          name="comments"
                          type="text"
                          multiline={true}
                          rows={3}
                        />
                      </RowInput>
                    </Row>
                  </Form>
                )}
              </Formik>
            </DialogContentStyled>
          )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentServicesAndDeliveries;
