import { useCallback, useEffect, useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import MoveChartButton from "./Buttons/MoveChartButton";
import ResetPositionButton from "./Buttons/ResetPositionButton";
import { colors } from "./colors";
import ChartNode from "./ItemsChart/ChartNode";
import ChartRoot from "./ItemsChart/ChartRoot";
import styles from "./OrganizationalChart.module.scss";

interface Props {
  companyStructurePathAPI: string;
  selectedCompany: Company | undefined;
}

const defaultPosition = {
  x: 50,
  y: 50,
};

const OrganizationalChart: React.FC<Props> = ({
  companyStructurePathAPI,
  selectedCompany,
}) => {
  const isACSuperior = useAppSelector(selectAuthUser).currentProfile.type === 0;

  const [mapOption, setMapOption] = useState({
    scale: 1,
    translation: { x: defaultPosition.x, y: defaultPosition.y },
  });
  const handleResetPositionChart = () => {
    setMapOption((prevOption) => {
      return {
        ...prevOption,
        translation: { x: defaultPosition.x, y: defaultPosition.y },
      };
    });
  };

  const [disablePan, setDisablePan] = useState(false);
  const [amountOfEmployees, setAmountOfEmployees] = useState(0);

  const modifyACCompanyStructureResponseCallback = useCallback(
    (responseData: CompanyStructureAC[]) => {
      let allWorkers = 0;
      const modified = responseData?.map((row: CompanyStructureAC) => {
        allWorkers += row.countACWorkers;
        const arr = row.departmentsChildren.map(
          (child) => child.countACWorkers
        );

        for (let i = 0; i < arr.length; i++) allWorkers += arr[i];

        return {
          ...row,
        };
      });
      setAmountOfEmployees(allWorkers);

      return modified;
    },
    []
  );

  const modifyTimeCompanyStructureResponseCallback = useCallback(
    (responseData: CompanyStructureTime[]) => {
      let allWorkers = 0;
      const modified = responseData?.map((row: CompanyStructureTime) => {
        allWorkers += row.countTimeWorkers;
        const arr = row.organizationalUnitsChildren.map(
          (child) => child.countTimeWorkers
        );
        for (let i = 0; i < arr.length; i++) allWorkers += arr[i];

        return {
          ...row,
        };
      });
      setAmountOfEmployees(allWorkers);

      return modified;
    },
    []
  );

  const [
    companyStructure,
    fetchingStateCompanyStructure,
    fetchAgainCompanyStructure,
  ] = useFetchAndSetGET<CompanyStructureAC[] | CompanyStructureTime[]>({
    path: `${companyStructurePathAPI}/${selectedCompany?.id}`,
    startFetchOnInitial: false,
    modifyResponseCallback: isACSuperior
      ? modifyACCompanyStructureResponseCallback
      : modifyTimeCompanyStructureResponseCallback,
  });

  useEffect(() => {
    if (selectedCompany) {
      fetchAgainCompanyStructure();
    }
  }, [selectedCompany, fetchAgainCompanyStructure]);

  return (
    <>
      {selectedCompany && (
        <LoadingWrapper
          isLodadingProgress={fetchingStateCompanyStructure.isFetching}
          isError={fetchingStateCompanyStructure.isError}
          setIfFetchAgain={() => {
            if (fetchingStateCompanyStructure.isError) {
              fetchAgainCompanyStructure();
            }
          }}
        >
          <div>
            <div className={styles.buttonsContainer}>
              <div>
                <MoveChartButton
                  disablePan={disablePan}
                  setDisablePan={setDisablePan}
                />
              </div>
              <div>
                <ResetPositionButton
                  onResetChartPosition={handleResetPositionChart}
                />
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.chart}>
                <MapInteractionCSS
                  value={mapOption}
                  onChange={(value: {
                    scale: number;
                    translation: {
                      x: number;
                      y: number;
                    };
                  }) => {
                    setMapOption(value);
                  }}
                  showControls={true}
                  minScale={0.5}
                  maxScale={2}
                  disablePan={disablePan}
                >
                  <ChartRoot
                    companyName={selectedCompany.name}
                    amountOfEmployees={amountOfEmployees}
                  >
                    {companyStructure?.map(
                      (
                        item: CompanyStructureAC | CompanyStructureTime,
                        index: number
                      ) => {
                        return (
                          <ChartNode
                            key={index}
                            data={item}
                            isACSuperior={isACSuperior}
                            color={colors[index % 10]}
                          />
                        );
                      }
                    )}
                  </ChartRoot>
                </MapInteractionCSS>
              </div>
            </div>
          </div>
        </LoadingWrapper>
      )}
    </>
  );
};

export default OrganizationalChart;
