import { faArrowDown, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { TypeOfVisit } from "../../../../Constants/typeOfVisitInPorts";
import { ProfileType } from "../../../../enums/profileType";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import { selectACSuperiorCurrentUser } from "../../../../reducers/acSuperiorCurrentUser/acSuperiorCurrentUser";
import { selectAuthUser } from "../../../../reducers/session";
import { selectSettings } from "../../../../reducers/settings";
import { useAppSelector } from "../../../../store/hooks";
import AttachmentsNewOld from "../../../helpersComponents/Attachments/attachmentsNewOld";
import DisplaySingleFileWithDeleteButton from "../../../helpersComponents/Attachments/displaySingleFileWithDeleteButton";
import SelectFileInput from "../../../helpersComponents/Attachments/selectFileInput";
import ButtonStyled from "../../../helpersComponents/Button/button";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../../helpersComponents/FormikInputs";
import CheckboxWithLabelFormik from "../../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import FeedbackDialog from "../feedbackDialog";
import GenerateGuestsForm from "../generateGuestsForm";
import styles from "../performerStyle.module.scss";
import confirmFetching from "./confrimFetching";
import getInitialState from "./initialState";
import getValidationSchema from "./validationSchema";

const postPathEnum = {
  0: "superior-ac/notification-guests-in-ports",
  8: "pfso/notification-guests-in-ports",
  9: "shift-director/notification-guests-in-ports",
  10: "security/notification-guests-in-ports",
  11: "employee/notification-guests-in-ports",
  12: "ohs/notification-guests-in-ports",
  13: "operational-support/notification-guests-in-ports",
};

const FormAddDelivery = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { confirm } = useConfirm();
  const authUserType = useAppSelector(selectAuthUser).currentProfile?.type;
  let ifRequireCaptcha = authUserType === undefined;
  const [resetForm, setResetForm] = useState<() => void>();
  const [feedbackData, setFeedbackData] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [
    generateCaptcha,
    fetchingStateGenerateCaptcha,
    fetchAgainGenerateCaptcha,
  ] = useFetchAndSetGET({
    path: "generate-captcha",
    ifBlob: true,
    startFetchOnInitial: authUserType === undefined ? true : false,
  });

  let successCallback = useCallback(
    (response: UseFetchResponse) => {
      if (resetForm === undefined) return;
      fetchAgainGenerateCaptcha();
      resetForm();
      setSelectedFile([]);
      setFeedbackData(response.resJson);
    },
    [fetchAgainGenerateCaptcha, resetForm]
  );

  const typesOfVisitsModifyResponseCallback = useCallback(
    (responseData: TypeOfVisitInterface[]) => {
      let modified = responseData;

      if (authUserType === ProfileType.OPERATIONAL_SUPPORT) {
        // Change name "Businness" for "ApplicationForTraining"
        modified = modified.filter((tov) => tov.id !== TypeOfVisit.BUSINESS);
        const applicationForTraining = {
          id: 1,
          name: t("application_for_training"),
          isActive: true,
        } as TypeOfVisitInterface;
        modified.push(applicationForTraining);

        //Remove Tour type visit
        modified = modified.filter((tov) => tov.id !== TypeOfVisit.TOUR);
      }

      if (authUserType === undefined) {
        //Remove Tour type visit
        modified = modified.filter((tov) => tov.id !== TypeOfVisit.TOUR);
      }

      return modified.sort((a, b) => a.id - b.id);
    },
    [authUserType, t]
  );

  const [typesOfVisits, fetchingStateTypesOfVisits, fetchAgainTypesOfVisits] =
    useFetchAndSetGET<TypeOfVisitInterface[]>({
      path: "employee/types-of-visits-in-ports",
      modifyResponseCallback: typesOfVisitsModifyResponseCallback,
    });

  const [lackOfGuestsError, setLackOfGuestsError] = useState(false);

  //Attachments
  const [selectedFilesAttachments, setSelectedFilesAttachments] = useState<
    File[]
  >([]);
  const [oldFilesRemoved, setOldFilesRemoved] = useState<any>([]);

  const EMPLOYEE_EMAIL_REQUIRED: boolean | undefined =
    window.globalConfig.EMPLOYEE_EMAIL_IN_NOTIFICATION;

  const validationSchemaObj = getValidationSchema({
    t,
    authUserType,
    ifRequireCaptcha,
    EMPLOYEE_EMAIL_REQUIRED,
  });

  const location = useLocation();
  const state = location.state as LocationState;
  const notificationId = state?.notificationId;
  const profileName = state?.profileName;
  const notificationForPFSOAndShiftDirector =
    authUserType === ProfileType.OPERATIONAL_SUPPORT;

  const [
    targetDepartments,
    fetchingStateTargetDepartments,
    fetchAgainTargetDepartments,
  ] = useFetchAndSetGET<TargetDepartmentInterface[]>({
    path:
      authUserType === ProfileType.EMPLOYEE
        ? "employee/departments?isNotificationAvailable=true"
        : authUserType === ProfileType.OHS
        ? "ohs/departments?isNotificationAvailable=true"
        : authUserType === ProfileType.SUPERIOR_AC
        ? "superior-ac/departments?isNotificationAvailable=true"
        : authUserType === ProfileType.PFSO
        ? "pfso/departments?isNotificationAvailable=true"
        : authUserType === ProfileType.OPERATIONAL_SUPPORT
        ? "operational-support/departments?isNotificationAvailable=true"
        : "departments?isNotificationAvailable=true",
    startFetchOnInitial: !EMPLOYEE_EMAIL_REQUIRED,
  });

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path:
      authUserType === ProfileType.EMPLOYEE
        ? "employee/permission-zones?isActive=true&forEmployee=true"
        : authUserType === ProfileType.OHS
        ? "ohs/permission-zones?isActive=true&forEmployee=true"
        : authUserType === ProfileType.SUPERIOR_AC
        ? "superior-ac/permission-zones?isActive=true&forEmployee=true"
        : authUserType === ProfileType.PFSO
        ? "pfso/permission-zones?isActive=true"
        : authUserType === ProfileType.SHIFT_DIRECTOR
        ? "shift-director/permission-zones?isActive=true"
        : "",
    startFetchOnInitial:
      authUserType !== undefined &&
      authUserType !== ProfileType.OPERATIONAL_SUPPORT,
  });

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path:
      authUserType !== undefined && authUserType !== null
        ? postPathEnum[authUserType]
        : "notification-guests-in-ports",
    method: "POST",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallback,
    errorCallback: fetchAgainGenerateCaptcha,
  });

  const [fetchingStatePutData, fetchAgainPutData] = useFetchOtherThanGET({
    path:
      authUserType !== undefined && authUserType !== null
        ? `${postPathEnum[authUserType]}/${notificationId}`
        : `notification-guests-in-ports/${notificationId}`,
    method: "PUT",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallback,
    errorCallback: fetchAgainGenerateCaptcha,
  });

  const isEditMode = useCallback(() => {
    if (history.location.state) {
      const state = history.location.state as {
        edit: boolean;
      };

      return state.edit;
    }

    return false;
  }, [history.location.state]);

  useEffect(() => {
    if (bodyRequest && resetForm !== undefined) {
      if (isEditMode()) {
        fetchAgainPutData();
      } else {
        fetchAgainPostData();
      }
    }
  }, [
    bodyRequest,
    fetchAgainPostData,
    fetchAgainPutData,
    resetForm,
    isEditMode,
  ]);

  const [informationText, setInformationText] = useState<string>(
    window.globalConfig.NOTIFICATION_FORM_INFORMATION
  );

  useEffect(() => {
    let currentLanguage: string | undefined = localStorage
      .getItem("languageAndOptionId")
      ?.toString();
    let text: string;

    if (currentLanguage?.includes("en")) {
      text = window.globalConfig.NOTIFICATION_FORM_INFORMATION_EN;
    } else if (currentLanguage?.includes("de")) {
      text = window.globalConfig.NOTIFICATION_FORM_INFORMATION_DE;
    } else if (currentLanguage?.includes("ru")) {
      text = window.globalConfig.NOTIFICATION_FORM_INFORMATION_RU;
    } else if (currentLanguage?.includes("uk")) {
      text = window.globalConfig.NOTIFICATION_FORM_INFORMATION_UK;
    } else {
      text = window.globalConfig.NOTIFICATION_FORM_INFORMATION;
    }
    setInformationText(text);
  }, [t]);

  const acUserEmail = useAppSelector(
    selectACSuperiorCurrentUser
  )?.email?.toUpperCase();
  const acUserPhone = useAppSelector(selectACSuperiorCurrentUser)?.phoneNumber;

  const [
    repeatNotification,
    fetchingStateRepeatNotification,
    fetchingAgainRepeatNotification,
  ] = useFetchAndSetGET<INotificationInPortsGuest>({
    path: `${profileName}/notification-guests-in-ports/${notificationId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (notificationId !== undefined) {
      fetchingAgainRepeatNotification();
    }
  }, [fetchingAgainRepeatNotification, notificationId]);

  const EMPLOYEE_EMAIL_KEY_NAME = window.globalConfig
    .EMPLOYEE_EMAIL_IN_NOTIFICATION_KEY_NAME
    ? window.globalConfig.EMPLOYEE_EMAIL_IN_NOTIFICATION_KEY_NAME
    : "employee_email";

  const settings = useAppSelector(selectSettings);
  const ifShowBH3 = settings["BH3_Awizacje"]?.value
    ? settings["BH3_Awizacje"].value === "1" &&
      authUserType !== ProfileType.OPERATIONAL_SUPPORT
    : false;

  const getNotificationIdIfEdited = () => {
    if (history.location.state) {
      const state = history.location.state as {
        edit: boolean;
        notificationId: number | undefined;
      };

      if (state.edit) {
        return state.notificationId;
      }
    }

    return undefined;
  };

  return (
    <>
      <div className={"flexAndCenter"}>
        <div>
          <div>
            <FeedbackDialog
              feedbackData={feedbackData}
              setFeedbackData={setFeedbackData}
            />
            <LoadingWrapper
              isLodadingProgress={
                fetchingStateTypesOfVisits.isFetching ||
                fetchingStateTargetDepartments.isFetching ||
                fetchingStateRepeatNotification.isFetching ||
                fetchingStatePermissionZones.isFetching
              }
              isError={
                fetchingStateTypesOfVisits.isError ||
                fetchingStateTargetDepartments.isError ||
                fetchingStateRepeatNotification.isError ||
                fetchingStatePermissionZones.isError
              }
              setIfFetchAgain={() => {
                if (fetchingStateTypesOfVisits.isError) {
                  fetchAgainTypesOfVisits();
                }
                if (fetchingStateTargetDepartments.isError) {
                  fetchAgainTargetDepartments();
                }
                if (fetchingStateRepeatNotification.isError) {
                  fetchingAgainRepeatNotification();
                }
                if (fetchingStatePermissionZones.isError) {
                  fetchAgainPermissionZones();
                }
              }}
            >
              {typesOfVisits !== null && (
                <Formik
                  initialValues={getInitialState({
                    authUserType,
                    repeatNotification,
                    targetDepartments,
                    typesOfVisits,
                    permissionZones,
                    EMPLOYEE_EMAIL_REQUIRED,
                    acUserEmail,
                    acUserPhone,
                  })}
                  validationSchema={validationSchemaObj}
                  onSubmit={(values, formikHelpers) => {
                    if (
                      values.guestsArray.length === 0 &&
                      selectedFile.length === 0
                    ) {
                      setLackOfGuestsError(true);
                    } else {
                      setLackOfGuestsError(false);
                      setResetForm(() => formikHelpers.resetForm);
                      confirmFetching({
                        values,
                        authUserType,
                        selectedFile,
                        selectedFilesAttachments,
                        setBodyRequest,
                        EMPLOYEE_EMAIL_REQUIRED,
                        notificationId: getNotificationIdIfEdited(),
                      });
                      formikHelpers.setFieldValue("captcha", "");
                      formikHelpers.setTouched({ captcha: false });
                    }
                  }}
                >
                  {({
                    values,
                    resetForm,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                    ...props
                  }) => (
                    <Form noValidate>
                      <div className={styles.rowContainer}>
                        <div className={styles.rowLeftRight}>
                          <div className={styles.title}>{t("date_from")}:</div>
                          <div className={styles.input}>
                            <DatePickerFormik
                              label={t("date_from")}
                              name="dateFrom"
                              view={["year", "month", "date"]}
                              format="dd-MM-yyyy"
                              required={true}
                              onChangeExtra={(event: any) => {
                                let newDate = event.target.value;
                                setFieldValue("dateFrom", newDate);
                                let dateTo = values.dateTo;
                                if (
                                  dateTo.setHours(0, 0, 0, 0) <
                                  newDate.setHours(0, 0, 0, 0)
                                ) {
                                  setFieldValue("dateTo", newDate);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.rowLeftRight}>
                          <div className={styles.title}>{t("date_to2")}:</div>
                          <div className={styles.input}>
                            <DatePickerFormik
                              label={t("date_to2")}
                              name="dateTo"
                              view={["year", "month", "date"]}
                              format="dd-MM-yyyy"
                              required={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.rowContainer}>
                        <div className={styles.rowLeftRight}>
                          <div className={styles.title}>{t("company")}:</div>
                          <div className={styles.input}>
                            <TextFieldFormik
                              label={t("company")}
                              name="company"
                              type="text"
                              required={true}
                              ifToUpperCaseShipsAndPorts={true}
                            />
                          </div>
                        </div>

                        {!notificationForPFSOAndShiftDirector ? (
                          !EMPLOYEE_EMAIL_REQUIRED ? (
                            <div className={styles.rowLeftRight}>
                              <div className={styles.title}>
                                {t("target_department")}:
                              </div>
                              <div className={styles.input}>
                                <AutocompleteFormik
                                  options={targetDepartments}
                                  getOptionLabel={(
                                    option: TargetDepartmentInterface
                                  ) => {
                                    return option.name;
                                  }}
                                  width="100%"
                                  getOptionSelected={(
                                    option: TargetDepartmentInterface,
                                    value: TargetDepartmentInterface
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  label={t("select_target_department")}
                                  name="targetDepartment"
                                  required={true}
                                />
                              </div>
                            </div>
                          ) : authUserType !== ProfileType.EMPLOYEE &&
                            authUserType !== ProfileType.OHS ? (
                            <div className={styles.rowLeftRight}>
                              <div className={styles.title}>
                                {t(EMPLOYEE_EMAIL_KEY_NAME)}:
                              </div>
                              <div className={styles.input}>
                                <TextFieldFormik
                                  label={t(EMPLOYEE_EMAIL_KEY_NAME)}
                                  name="acSuperiorEmail"
                                  type="text"
                                  required={true}
                                  ifToUpperCaseShipsAndPorts={true}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className={styles.rowLeftRight}>
                              <div className={styles.title}>
                                {t("phone_or_radio_of_employee")}:
                              </div>
                              <div className={styles.input}>
                                <TextFieldFormik
                                  label={t("phone_or_radio_of_employee")}
                                  name="acSuperiorPhoneOrRadio"
                                  type="text"
                                  required={true}
                                  ifToUpperCaseShipsAndPorts={true}
                                />
                              </div>
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className={styles.rowContainer}>
                        <div className={styles.rowLeftRight}>
                          <div className={styles.title}>
                            {t("type_of_visit")}:
                          </div>
                          <div className={styles.input}>
                            <AutocompleteFormik
                              options={typesOfVisits}
                              getOptionLabel={(option: any) => {
                                return option.name;
                              }}
                              width="100%"
                              getOptionSelected={(
                                option: TypeOfVisitInterface,
                                value: TypeOfVisitInterface
                              ) => {
                                return option.id === value.id;
                              }}
                              onChangeExtra={(value: TypeOfVisitInterface) => {
                                if (value?.id === TypeOfVisit.BUSINESS) {
                                  values.guestsArray.forEach(
                                    (guest, guestIndex) => {
                                      if (
                                        !guest.isSelectedEmail &&
                                        !guest.isSelectedDateOfBirth
                                      ) {
                                        setFieldValue(
                                          `guestsArray.${guestIndex}.isSelectedEmail`,
                                          true
                                        );
                                      }
                                    }
                                  );
                                }
                              }}
                              label={t("type_of_visit")}
                              name="typeOfVisit"
                              required={true}
                            />
                          </div>
                        </div>

                        <div className={styles.rowLeftRight}>
                          <div className={styles.title}>
                            {t("add_list_of_people")}:
                          </div>
                          <div className={styles.input}>
                            <div className={styles.selectFileContainer}>
                              <div>
                                <SelectFileInput
                                  setSelectedFiles={setSelectedFile}
                                  title={t("import")}
                                  accept={".xls, .xlsx"}
                                  multiple={false}
                                />
                              </div>
                              <div>
                                {selectedFile[0] ? (
                                  <DisplaySingleFileWithDeleteButton
                                    file={selectedFile[0]}
                                    fileIndex={0}
                                    allFiles={selectedFile}
                                    setUpadteAllFilesAfterDelete={
                                      setSelectedFile
                                    }
                                  />
                                ) : (
                                  <a
                                    href={"/Import_lista_gosci_przyklad.xlsx"}
                                    download="Import_lista_gosci_przyklad"
                                  >
                                    <ButtonStyled
                                      type="button"
                                      onClick={() => {}}
                                    >
                                      {t("example_file")}
                                      <FontAwesomeIcon icon={faArrowDown} />
                                    </ButtonStyled>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Komunikat o konieczności dodania dodatkowej awizacji, jeżeli to wycieczka */}
                      {values.typeOfVisit?.id === TypeOfVisit.TOUR && (
                        <div className={styles.tourInformation}>
                          <div>
                            {t("warning").toUpperCase()}:{" "}
                            {t("notification_guest_tour_info")}
                          </div>
                        </div>
                      )}

                      {/* Wyświetlaj numer rejestracyjny tylko gdy "Wykonanie pracy" */}
                      {values.typeOfVisit?.id &&
                        values.typeOfVisit.id !== TypeOfVisit.BUSINESS &&
                        values.typeOfVisit.id !== TypeOfVisit.TOUR && (
                          <div className={styles.rowContainer}>
                            <div className={styles.rowLeftRight}>
                              <div className={styles.title}>
                                {t("plate_number")}:
                              </div>
                              <div className={styles.input}>
                                <TextFieldFormik
                                  label={t("plate_number")}
                                  name="plateNumber"
                                  ifToUpperCaseShipsAndPorts={true}
                                  type="text"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {authUserType !== undefined &&
                        values.typeOfVisit?.id &&
                        values.typeOfVisit.id ===
                          TypeOfVisit.COMPLETION_OF_THE_WORK && (
                          <>
                            <div className={styles.rowContainer}>
                              <div className={styles.rowLeftRight}>
                                <div className={styles.title}>
                                  {t("is_work_permit_required")}:
                                </div>
                                <div className={styles.input}>
                                  <CheckboxWithLabelFormik
                                    name={"isWorkPermitRequiredTrue"}
                                    className={
                                      (touched.isWorkPermitRequiredTrue ||
                                        touched.isWorkPermitRequiredFalse) &&
                                      values.isWorkPermitRequiredTrue ===
                                        false &&
                                      values.isWorkPermitRequiredFalse === false
                                        ? styles.error
                                        : ""
                                    }
                                    onChangeExtra={(
                                      event: ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (
                                        event.target.checked &&
                                        values.isWorkPermitRequiredFalse
                                      ) {
                                        setFieldValue(
                                          "isWorkPermitRequiredFalse",
                                          false
                                        );
                                      }
                                    }}
                                    ifCircle={true}
                                    label={
                                      <span className={styles.formControlLabel}>
                                        {t("yes")}
                                      </span>
                                    }
                                  />
                                  <CheckboxWithLabelFormik
                                    name={"isWorkPermitRequiredFalse"}
                                    className={
                                      (touched.isWorkPermitRequiredTrue ||
                                        touched.isWorkPermitRequiredFalse) &&
                                      values.isWorkPermitRequiredTrue ===
                                        false &&
                                      values.isWorkPermitRequiredFalse === false
                                        ? styles.error
                                        : ""
                                    }
                                    onChangeExtra={(
                                      event: ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (
                                        event.target.checked &&
                                        values.isWorkPermitRequiredTrue
                                      ) {
                                        setFieldValue(
                                          "isWorkPermitRequiredTrue",
                                          false
                                        );
                                      }
                                    }}
                                    ifCircle={true}
                                    label={
                                      <span className={styles.formControlLabel}>
                                        {t("no")}
                                      </span>
                                    }
                                  />
                                </div>
                              </div>
                              <div className={styles.rowLeftRight}>
                                <div className={styles.rowLeftRight}>
                                  <div className={styles.title}>
                                    {t("is_work_particularly_dangerous")}:
                                  </div>
                                  <div className={styles.inputRow}>
                                    <CheckboxWithLabelFormik
                                      name={"isWorkParticularlyDangerousTrue"}
                                      className={
                                        (touched.isWorkParticularlyDangerousTrue ||
                                          touched.isWorkParticularlyDangerousFalse) &&
                                        values.isWorkParticularlyDangerousTrue ===
                                          false &&
                                        values.isWorkParticularlyDangerousFalse ===
                                          false
                                          ? styles.error
                                          : ""
                                      }
                                      onChangeExtra={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (
                                          event.target.checked &&
                                          values.isWorkParticularlyDangerousFalse
                                        ) {
                                          setFieldValue(
                                            "isWorkParticularlyDangerousFalse",
                                            false
                                          );
                                        }
                                      }}
                                      ifCircle={true}
                                      label={
                                        <span
                                          className={styles.formControlLabel}
                                        >
                                          {t("yes")}
                                        </span>
                                      }
                                    />
                                    <CheckboxWithLabelFormik
                                      name={"isWorkParticularlyDangerousFalse"}
                                      className={
                                        (touched.isWorkParticularlyDangerousTrue ||
                                          touched.isWorkParticularlyDangerousFalse) &&
                                        values.isWorkParticularlyDangerousTrue ===
                                          false &&
                                        values.isWorkParticularlyDangerousFalse ===
                                          false
                                          ? styles.error
                                          : ""
                                      }
                                      onChangeExtra={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (
                                          event.target.checked &&
                                          values.isWorkParticularlyDangerousTrue
                                        ) {
                                          setFieldValue(
                                            "isWorkParticularlyDangerousTrue",
                                            false
                                          );
                                        }
                                      }}
                                      ifCircle={true}
                                      label={
                                        <span
                                          className={styles.formControlLabel}
                                        >
                                          {t("no")}
                                        </span>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                      {values.isWorkPermitRequiredTrue && (
                        <div className={styles.rowContainer}>
                          <div className={styles.rowLeftRight}>
                            <div className={styles.title}>
                              {t("work_permit_number")}:
                            </div>
                            <div className={styles.input}>
                              <TextFieldFormik
                                label={t("work_permit_number")}
                                name="workPermitNumber"
                                type="text"
                                ifToUpperCaseShipsAndPorts={true}
                              />
                            </div>
                          </div>

                          <div className={styles.rowLeftRight}>
                            <div className={styles.title}>
                              {t("work_area")}:
                            </div>
                            <div className={styles.input}>
                              <TextFieldFormik
                                label={t("work_area")}
                                name="workArea"
                                type="text"
                                ifToUpperCaseShipsAndPorts={true}
                                required={
                                  values.isWorkParticularlyDangerousTrue
                                }
                                multiline={true}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {values.isWorkParticularlyDangerousTrue && (
                        <div className={styles.rowContainer}>
                          <div className={styles.rowLeftRight}>
                            <div className={styles.title}>
                              {t("work_particularly_dangerous_comments")}:
                            </div>
                            <div className={styles.input}>
                              <TextFieldFormik
                                label={t(
                                  "work_particularly_dangerous_comments"
                                )}
                                name="workParticularlyDangerousComments"
                                type="text"
                                ifToUpperCaseShipsAndPorts={true}
                                multiline={true}
                                rows={3}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <FieldArray
                        name="guestsArray"
                        render={(arrayHelpers) => (
                          <>
                            {values.guestsArray.map((guestData, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <GenerateGuestsForm
                                    guestIndex={index}
                                    arrayHelpers={arrayHelpers}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    typeOfVisit={values.typeOfVisit}
                                    guestsArrayValues={
                                      values.guestsArray[index]
                                    }
                                  />
                                </React.Fragment>
                              );
                            })}

                            <div className={styles.addAnotherGuest}>
                              <ButtonStyled
                                onClick={() =>
                                  arrayHelpers.push({
                                    firstName: "",
                                    lastName: "",
                                    dateOfBirth: null,
                                    email: "",
                                    isSelectedEmail:
                                      values.typeOfVisit?.id ===
                                      TypeOfVisit.BUSINESS
                                        ? true
                                        : false,
                                    isSelectedDateOfBirth: false,
                                  })
                                }
                                type="button"
                              >
                                {values.guestsArray.length === 0
                                  ? t("add_person")
                                  : t("add_another_person")}
                              </ButtonStyled>
                            </div>
                          </>
                        )}
                      />

                      <div className={styles.rowContainer}>
                        <div className={styles.rowLeftRight}>
                          <div className={styles.title}>{t("comments")}:</div>
                          <div className={styles.input}>
                            <TextFieldFormik
                              label={t("comments")}
                              name="information"
                              type="text"
                              multiline
                            />
                          </div>
                        </div>

                        {authUserType !== undefined &&
                          authUserType !== ProfileType.OPERATIONAL_SUPPORT && (
                            <div className={styles.rowLeftRight}>
                              <div className={styles.title}>
                                {t("permission_zone")}:
                              </div>
                              <div className={styles.input}>
                                <AutocompleteFormik
                                  options={permissionZones}
                                  getOptionLabel={(option: PermissionZone) => {
                                    return option.name;
                                  }}
                                  width="100%"
                                  getOptionSelected={(
                                    option: PermissionZone,
                                    value: PermissionZone
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  label={t("select_permission_zone")}
                                  name="permissionZone"
                                  required={true}
                                />
                              </div>
                            </div>
                          )}
                      </div>

                      {authUserType !== undefined && (
                        <div className={styles.rowContainer}>
                          <div className={styles.rowLeftRight}>
                            <div className={styles.input}>
                              <AttachmentsNewOld
                                selectedFiles={selectedFilesAttachments}
                                setSelectedFiles={setSelectedFilesAttachments}
                                oldFilesRemoved={oldFilesRemoved}
                                setOldFilesRemoved={setOldFilesRemoved}
                                path={"attachments-in-ports"}
                                inputProps={{ multiple: true }}
                                marginButton="80px"
                                title={t("attachments")}
                              />
                            </div>
                          </div>

                          {ifShowBH3 && (
                            <div className={styles.rowLeftRight}>
                              <div className={styles.input}>
                                <CheckboxWithLabelFormik
                                  name="bh3"
                                  ifCircle={true}
                                  label={
                                    <span
                                      className={styles.excludedFromFireReport}
                                    >
                                      {t("BH3")}
                                    </span>
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {ifRequireCaptcha && (
                        <div
                          className={
                            styles.rowContainer + " " + styles.commentsInput
                          }
                        >
                          <div className={styles.rowLeftRight}>
                            <div
                              className={styles.title}
                              onClick={() => {
                                setFieldValue(`captcha`, "");
                                props.setTouched({ captcha: false });
                                fetchAgainGenerateCaptcha();
                              }}
                            >
                              <div className={styles.captchaTitle}>
                                <div>{t("verification")}</div>
                                <div>
                                  <FontAwesomeIcon
                                    className={styles.faSyncAlt}
                                    icon={faSyncAlt}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.input}>
                              <div className={styles.captchaInput}>
                                <LoadingWrapper
                                  isLodadingProgress={
                                    fetchingStateGenerateCaptcha.isFetching
                                  }
                                  isError={fetchingStateGenerateCaptcha.isError}
                                  setIfFetchAgain={() => {
                                    fetchAgainGenerateCaptcha();
                                  }}
                                  size={20}
                                  removeStyles={true}
                                >
                                  <div>
                                    <img
                                      src={generateCaptcha}
                                      width={150}
                                      height={48}
                                      alt="Captcha"
                                    />
                                  </div>
                                  <div>
                                    <TextFieldFormik
                                      label={t("enter_the_code")}
                                      name="captcha"
                                      type="text"
                                      inputProps={{
                                        maxLength: 4,
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  </div>
                                </LoadingWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Information */}
                      <div className={styles.formInformation}>
                        <div>{t("information")}:</div>
                        <div>
                          <b
                            dangerouslySetInnerHTML={{
                              __html: informationText,
                            }}
                          ></b>
                        </div>
                      </div>
                      <div className={styles.cofnirmCelat}>
                        <div>
                          <ButtonStyled
                            onClick={async () => {
                              let selectedbuttonId = await confirm({
                                text: `${t("reset_form_information")}.`,
                                buttons: [
                                  { buttonName: t("clear"), buttonId: 0 },
                                  { buttonName: t("cancel"), buttonId: 1 },
                                ],
                              });
                              if ((await selectedbuttonId) === 0) {
                                setSelectedFile([]);
                                setSelectedFilesAttachments([]);
                                let repeatNotification = null;
                                setSelectedFile([]);
                                resetForm({
                                  values: getInitialState({
                                    authUserType,
                                    repeatNotification,
                                    targetDepartments,
                                    typesOfVisits,
                                    permissionZones,
                                    EMPLOYEE_EMAIL_REQUIRED,
                                    acUserEmail,
                                    acUserPhone,
                                  }),
                                });
                              }
                            }}
                            type="button"
                          >
                            {t("clear")}
                          </ButtonStyled>
                        </div>
                        <div>
                          {fetchingStatePostData.isFetching ||
                          fetchingStatePutData.isFetching ? (
                            <div>
                              <CircularProgress size={30} />
                            </div>
                          ) : (
                            <ButtonStyled type="submit">
                              {t("confirm")}
                            </ButtonStyled>
                          )}
                          {lackOfGuestsError && (
                            <span className={styles.lackOfGuestsError}>
                              {t("lack_of_guests_error")}
                            </span>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAddDelivery;
