import React from "react";
import VisualizationContent from "./visualizationContent";

function Visualization() {
  return (
    <div>
      <VisualizationContent />
    </div>
  );
}

export default Visualization;
