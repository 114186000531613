import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import FeedbackDialog from "./FeedbackDialog";
import PopupDialogContent from "./poupDialogContent";

interface IProps {
  onCancelChanges: () => void;
  open: boolean;
  successCallback: () => void;
  pathApi: string;
  ifRegistrationNumber: boolean;
  permissionZonesPathApi: string;
  typesOfVisitPathApi: string;
}

const PopupEntryUnauthorized: React.FC<IProps> = ({
  onCancelChanges,
  open,
  successCallback,
  pathApi,
  ifRegistrationNumber,
  permissionZonesPathApi,
  typesOfVisitPathApi,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [feedbackData, setFeedbackData] = useState<IVisitReponse[] | null>(
    null
  );

  const onSubmit = (values: PopupEntryUnauthorizedFormValues) => {
    setErrorMessage("");
    let guests = values.guestsArray.map((guest) => ({
      firstName: guest.firstName,
      lastName: guest.lastName,
      dateOfBirth: guest.dateOfBirth,
      email: guest.email,
      idNumber: guest.idNumber,
      cardNumber: guest.cardNumber,
      isTrainingNotRequired:
        values.typeOfVisit?.id !== TypeOfVisit.COMPLETION_OF_THE_WORK
          ? null
          : guest.isTrainingNotRequired,
      trainingExpiryDate:
        guest.isTrainingNotRequired === true ? null : guest.trainingExpiryDate,
      bh3: guest.bh3,
    }));

    let body = {
      guests: guests,
      comments: values.comments,
      companyName: values.companyName,
      permissionZoneId: values.permissionZone?.id,
      typeOfVisitId: values.typeOfVisit?.id,
      registrationNumber: values.registrationNumber,
    };

    setBodyRequest(JSON.stringify(body));
  };

  const successFeedbackCallback = useCallback((response: UseFetchResponse) => {
    setFeedbackData(response.resJson);
  }, []);

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: pathApi,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successFeedbackCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (open === false) {
      setErrorMessage("");
    }
  }, [open]);

  return (
    <>
      <div>
        <FeedbackDialog
          feedbackData={feedbackData}
          setFeedbackData={setFeedbackData}
          closeCallback={successCallback}
        />
      </div>

      <div>
        <DialogStyled
          open={open}
          onClose={onCancelChanges}
          disableBackdropClick={true}
        >
          <DialogTitleStyled title={t("adding_unauthorized_entry")} />
          <PopupDialogContent
            onSubmit={onSubmit}
            isPopupOpen={open}
            ifRegistrationNumber={ifRegistrationNumber}
            permissionZonesPathApi={permissionZonesPathApi}
            typesOfVisitPathApi={typesOfVisitPathApi}
          />
          <DialogActionsStyled
            typConfirm="submit"
            formConfirm="submitForm"
            onCancel={onCancelChanges}
            isLoading={fetchingStatePostData.isFetching}
            errorString={errorMessage}
          />
        </DialogStyled>
      </div>
    </>
  );
};

export default PopupEntryUnauthorized;
