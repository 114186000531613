import {
  faCreditCard,
  faIdCard,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControlLabel } from "@material-ui/core";
import { ComponentType, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { colorNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import CheckboxStyled from "../MaterialUi/Checkbox/checkbox";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupReceiveReplacementCard from "./PopupReceiveReplacementCard";
import PopupReplacementCards from "./PopupReplacementCards";
import styles from "./styles.module.scss";

interface Props {
  pathApi: string;
  pathWorkersApi: string;
  navigationComponent?: React.FC;
  layoutSettingName: string;
  SwitchStyled: ComponentType<any>;
}

const ReplacementCardsContent: React.FC<Props> = ({
  pathApi,
  pathWorkersApi,
  navigationComponent,
  layoutSettingName,
  SwitchStyled,
}) => {
  const { t } = useTranslation();

  const columns = [
    { name: "dateStart", title: t("date"), type: "date_hours" },
    { name: "acWorker", title: t("worker") },
    { name: "acSuperior", title: t("issued_by") },
    { name: "oldCardUniversalNumber", title: t("employee_card") },
    { name: "newCardUniversalNumber", title: t("replacement_card") },
    { name: "dateEnd", title: t("entry_date"), type: "date_hours" },
    { name: "isEnd", title: t("is_finished") },
    { name: "location", title: t("location") },
    { name: "cardDescription", title: t("card_description") },
    { name: "returnedCardDescription", title: t("returned_card_description") },
  ];

  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [isInactiveSwitchState, setIsInactiveSwitchState] = useState(true);

  const modifyResponseCallback = useCallback(
    (responseData) => {
      const modified: any[] = [];
      responseData.forEach((row: ReplacementCard) => {
        if (row.isEnd && isInactiveSwitchState) return;

        modified.push({
          ...row,
          acWorker: `${row.acWorkerFirstName} ${row.acWorkerLastName}`,
          acSuperior: row.acSuperiorLogin,
          isEnd: <CheckboxStyled checked={row.isEnd} disabled />,
          returnedCardDescription: row.returnedCardDescription
            ? row.returnedCardDescription
            : "-",
          location: row.location ? row.location : "-",
          cardDescription: row.cardDescription ? row.cardDescription : "-",
        });
      });
      return modified;
    },
    [isInactiveSwitchState]
  );

  const fullPathApi = `${pathApi}${startDate || endDate ? "?" : ""}${
    startDate ? `"DateFrom=${convertDateFetch(startDate)}"` : ""
  }${startDate && endDate ? "&" : ""}${
    endDate ? `DateTo=${convertDateFetch(endDate)}` : ""
  }`;

  const [enableDateRange, setEnableDateRange] = useState(false);
  const enableDateRangeHandler = () => {
    setEnableDateRange((checked) => !checked);
  };

  const [
    replacementCards,
    fetchingStateReplacementCards,
    fetchAgainReplacementCards,
  ] = useFetchAndSetGET<ReplacementCard[]>({
    path: enableDateRange ? fullPathApi : pathApi,
    modifyResponseCallback: modifyResponseCallback,
    startFetchOnInitial: true,
  });

  const NavigationComponent = navigationComponent;

  const [openReceiveCardPopup, setOpenReceiveCardPopup] = useState<{
    open: boolean;
    cardNumber: null | string;
  }>({
    open: false,
    cardNumber: null,
  });

  const authUser = useAppSelector(selectAuthUser);
  const color = colorNameDictionary[authUser.currentProfile?.type];

  const [openAddReplacementCard, setOpenAddReplacementCard] = useState(false);

  const menuCards = (
    <div className={styles.receiveCardContainer}>
      <div className={styles.switchContainer}>
        {t("all_cards")}
        <SwitchStyled
          checked={isInactiveSwitchState}
          onChange={() => setIsInactiveSwitchState(!isInactiveSwitchState)}
          name="checkedA"
        />
        {t("not_finished_replacement_card")}
      </div>

      <div
        style={{ backgroundColor: color }}
        className={styles.receiveCard}
        onClick={fetchAgainReplacementCards}
      >
        <span>
          <FontAwesomeIcon icon={faRedoAlt} />
          {" " + t("refresh")}
        </span>
      </div>

      <div
        style={{ backgroundColor: color }}
        className={styles.receiveCard}
        onClick={() => {
          setOpenAddReplacementCard(true);
        }}
      >
        <span>
          <FontAwesomeIcon icon={faIdCard} />
          {" " + t("issue_replacement_card")}
        </span>
      </div>

      <div
        style={{ backgroundColor: color }}
        className={styles.receiveCard}
        onClick={() => {
          setOpenReceiveCardPopup({
            open: true,
            cardNumber: null,
          });
        }}
      >
        <span>
          <FontAwesomeIcon icon={faCreditCard} />
          {" " + t("receive_replacement_card")}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <TopBanner pathName={t("replacement_cards")} />
      </div>
      <div className="flexAndCenter">
        {NavigationComponent !== undefined && (
          <div>
            <NavigationComponent />
          </div>
        )}
        <div>
          <DatePickerRange
            view={["year", "month", "date", "hours", "minutes"]}
            format="dd-MM-yyyy HH:mm"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
            keyboardDateTimePicker={true}
            disabled={!enableDateRange}
            componentInRow={
              <FormControlLabel
                control={
                  <CheckboxStyled
                    checked={enableDateRange}
                    name="enableDateRange"
                    onChange={enableDateRangeHandler}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<span>{t("date_range")}</span>}
              />
            }
          ></DatePickerRange>
        </div>

        {menuCards}

        <div>
          <TableComponentMemo
            columns={columns}
            rows={replacementCards}
            layoutSettingName={layoutSettingName}
            hideActionsAndVisibilityColumns={true}
            tableEditColumnWidth={150}
            fetchingState={{
              fetchAgain: fetchAgainReplacementCards,
              isError: fetchingStateReplacementCards.isError,
              isFetching: fetchingStateReplacementCards.isFetching,
            }}
            actionsColumnUpdated={{
              editButton: {
                ifShow: true,
                customComponent: (row) => {
                  return !row.isEnd.props.checked ? (
                    <Button
                      onClick={() => {
                        setOpenReceiveCardPopup({
                          open: true,
                          cardNumber: row.newCardUniversalNumber,
                        });
                      }}
                    >
                      {t("receive")}
                    </Button>
                  ) : (
                    <></>
                  );
                },
              },
            }}
          />
        </div>

        {openAddReplacementCard && (
          <PopupReplacementCards
            isOpen={openAddReplacementCard}
            closePopup={() => {
              setOpenAddReplacementCard(false);
            }}
            successCallback={fetchAgainReplacementCards}
            pathApi={pathApi}
            pathWorkersApi={pathWorkersApi}
          />
        )}

        {openReceiveCardPopup.open && (
          <PopupReceiveReplacementCard
            isOpen={openReceiveCardPopup.open}
            cardNumber={openReceiveCardPopup.cardNumber}
            closePopup={() => {
              setOpenReceiveCardPopup((prevState) => {
                return {
                  ...prevState,
                  open: false,
                };
              });
            }}
            successCallback={fetchAgainReplacementCards}
            pathApi={pathApi}
            setOpenReceiveCardPopup={setOpenReceiveCardPopup}
          />
        )}
      </div>
    </>
  );
};

export default ReplacementCardsContent;
