import React from "react";
import { Route, Redirect } from "react-router-dom";
import { profilesRoutesDictionary } from "../components/ProfilesRouting/profilesDictionaries";
import { logIn } from "../components/LoginPage/logIn";
import { useAppDispatch } from "../store/hooks";
import { setAuthUser } from "../reducers/session";

const LoginProtect: any = ({ component: LoginPageComponent, ...rest }) => {
  const dispatch = useAppDispatch();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );

  let pathRedirect = authUser?.currentProfile
    ? `/${profilesRoutesDictionary[authUser.currentProfile.type]}/home`
    : undefined;

  return (
    <Route
      {...rest}
      render={(props) => {
        const currentProfile: authUserSingleProfileData = JSON.parse(
          localStorage.getItem("authUser") || "{}"
        ).currentProfile;

        const locationState: any = props.location.state;
        if (locationState) {
          let tempPreviousPath = locationState.from.pathname;
          let profilesArray = authUser?.tokenDecoded?.profiles;

          console.log(`profilesArray: ${profilesArray}`);

          let pathParts = tempPreviousPath.split("/");
          if (pathParts && pathParts.length >= 2) {
            let profileType: number = -1;

            Object.keys(profilesRoutesDictionary).forEach((key) => {
              if (
                profilesRoutesDictionary[key].toLowerCase() ===
                pathParts[1].toLowerCase()
              ) {
                profileType = parseInt(key);
              }
            });

            if (profileType > -1 && profilesArray) {
              let foundProfiles = profilesArray.filter(
                (el) => el.type === profileType
              );

              if (foundProfiles && foundProfiles.length > 0) {
                pathRedirect = tempPreviousPath;

                logIn({
                  token: authUser.token,
                  tokenDecoded: authUser.tokenDecoded,
                  selectedProfile: foundProfiles[0],
                  isChangingProfileOldProfileType: currentProfile.type,
                  isChangingProfileOldProfileSubjectId:
                    currentProfile.subjectId,

                  setAuthUserToRedux: (authUser) => {
                    dispatch(setAuthUser(authUser));
                  },
                  resetReduxState: () => {
                    dispatch({ type: "RESET_STATE" });
                  },
                });

                pathRedirect = tempPreviousPath;
              }
            }
          }
        }

        return authUser.currentProfile === undefined ? (
          <LoginPageComponent {...props} />
        ) : (
          <Redirect
            to={{ pathname: pathRedirect, state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default LoginProtect;
