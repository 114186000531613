export const statusDictionary = {
  0: "0",
  1: "introduced",
  2: "approved",
  3: "finally_approved",
  4: "to_change",
  5: "rejected",
  6: "finally_rejected",
  7: "canceled",
};
