import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Button from "../../../helpersComponents/Button/button";
import useParallelFetchJson from "../../../../hooks/fetchHooks/parallelJSON/useParallelFetchJson";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { useSnackbar } from "notistack";

interface Props {
  rows: any[];
  fetchObject: UseFetchReturn;
  settingsLayoutName: string;
  ifWhite?: true;
  columns: ColumnTable[];
  parallerPatchPath: string;
  ifAdd?: boolean;
  ifEdit?: boolean;
  ifDelete?: boolean;
  deleteCommandName?: string;
  popup?: React.FC;
  openPopup?: boolean;
  commitChanges?: any;
  actionColumn?: React.FC;
}

export default function TableAndActions({
  rows,
  fetchObject,
  settingsLayoutName,
  ifWhite,
  columns,
  parallerPatchPath,
  ifAdd,
  ifEdit,
  ifDelete,
  deleteCommandName,
  popup,
  openPopup,
  commitChanges,
  actionColumn,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchAgain } = fetchObject;
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isPutingPosting, setisPutingPosting] = useState<boolean>(false);

  const [bodyPatch, setBodyPatch] = useState<any>(false);
  const result = useParallelFetchJson(
    "PATCH",
    parallerPatchPath,
    bodyPatch,
    selectedRows
  );
  useEffect(() => {
    if (result?.isFetching) return;
    setBodyPatch(false);

    if (result.isAllGood) {
      enqueueSnackbar(t("actions_completed_successfully"), {
        variant: "success",
      });
      fetchAgain();
      setisPutingPosting(false);
    } else if (result.isAllFail) {
      enqueueSnackbar(t("all_actions_failed"), { variant: "error" });
      fetchAgain();
      setisPutingPosting(false);
    } else {
      enqueueSnackbar(t("not_all_actions_were_successful"), {
        variant: "warning",
      });
      fetchAgain();
      setisPutingPosting(false);
    }
  }, [result, t, fetchAgain, enqueueSnackbar]);

  return (
    <>
      <div>
        <LoadingWrapper
          isLodadingProgress={fetchObject.isFetching || isPutingPosting}
          isError={fetchObject.isError}
          setIfFetchAgain={fetchAgain}
        >
          {!fetchObject.isFetching && !fetchObject.isError && (
            <TableComponentMemo
              layoutSettingName={settingsLayoutName}
              backgroundColor={ifWhite ? "White" : undefined}
              rows={rows}
              ifAdd={ifAdd}
              ifEdit={ifEdit}
              ifDelete={ifDelete}
              popup={popup}
              columns={columns}
              selectData={setSelectedRows}
              selectedData={selectedRows}
              deleteCommandName={deleteCommandName || t("cancel")}
              columnsWidth={[
                { columnName: "attachmentsList", width: "200" },
                { columnName: "lp", width: "100" },
              ]}
              commitChanges={commitChanges}
              actionColumn={actionColumn}
            />
          )}
        </LoadingWrapper>
      </div>
      <div>
        <div className="acionButtonsContainerGlobal">
          <div>
            <Button
              className="actionButtonGlobal"
              width={300}
              onClick={() => {
                setisPutingPosting(true);
                setBodyPatch([
                  {
                    op: "replace",
                    path: "/status",
                    value: 7,
                  },
                ]);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
          <div>
            <Button
              className="actionButtonGlobal"
              width={300}
              onClick={() => {
                setisPutingPosting(true);
                setBodyPatch([
                  {
                    op: "replace",
                    path: "/status",
                    value: 2,
                  },
                ]);
              }}
            >
              {t("approve")}
            </Button>
          </div>
          <div>
            <Button
              className="actionButtonGlobal"
              width={300}
              onClick={() => {
                setisPutingPosting(true);
                setBodyPatch([
                  {
                    op: "replace",
                    path: "/status",
                    value: 5,
                  },
                ]);
              }}
            >
              {t("deny")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
