import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { ProfileType } from "../../../enums/profileType";
import computeDateRangeFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import OperationsButtons from "../../pfso/OperationButtons/OperationsButons";
import ButtonStyled from "../Button/button";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import ParallelActions from "../ParallelActions/parallelActions";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupAddServicesAndDeliveries from "./PopupAddServicesAndDeliveries";
import RowDetailServicesAndDeliveries from "./RowDetailServicesAndDeliveries";
import useColumns from "./useColumns";

interface IProps {
  pathApiServicesAndDeliveries: string;
  pathApiCompaniesInPorts?: string;
  pathApiArrivalsOfShips?: string;
  pathApiGuests?: string;
  pathApiTypesOfVisit?: string;
  layoutSettingName: string;
  pathApiChangeConfirmationStatus?: string;
  profileType: ProfileType;
}

const ServicesAndDeliveriesContent: React.FC<IProps> = ({
  pathApiServicesAndDeliveries,
  pathApiCompaniesInPorts,
  pathApiArrivalsOfShips,
  pathApiGuests,
  pathApiTypesOfVisit,
  layoutSettingName,
  pathApiChangeConfirmationStatus,
  profileType,
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "CurrentWeek")
  );
  const [endDate, setEndDate] = useState(
    getEndOfDate(new Date(), "CurrentWeek")
  );

  const columns = useColumns(profileType);

  const modifyServicesAndDeliveriesResponseCallback = useCallback(
    (responseData: IDeliveryServiceGuest[]) => {
      const modified = responseData.map((row, index) => {
        return {
          ...row,
          id: ++index,
          notificationNumber: row.deliveryService.notificationNumber,
          date: row.deliveryService.date,
          companyName: row.deliveryService.companyName,
          numberOfPeople: row.guests.length,
          confirmationStatusName: row.deliveryService.confirmationStatusName,
          typeOfVisitName: row.deliveryService.typeOfVisitName,
          action: (
            <ButtonStyled
              onClick={() => {
                setRepeatDelivery({
                  isOpen: true,
                  rowId: row.deliveryService.id,
                  edit: false,
                });
              }}
            >
              <FontAwesomeIcon icon={faSyncAlt} /> {t("repeat")}
            </ButtonStyled>
          ),
          guestsFilter: row.guests
            .map((item) => ({
              firstName: item.guest.firstName,
              lastName: item.guest.lastName,
              toString: function () {
                return this.firstName + " " + this.lastName;
              },
            }))
            .join(";"),
        };
      });
      return modified;
    },
    [t]
  );

  const [
    servicesAndDeliveriesRows,
    fetchingStateServicesAndDeliveries,
    fetchAgainServicesAndDeliveries,
  ] = useFetchAndSetGET<IDeliveryServiceGuest[]>({
    path:
      `${pathApiServicesAndDeliveries}?DateFrom=` +
      computeDateRangeFetch(startDate) +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
    modifyResponseCallback: modifyServicesAndDeliveriesResponseCallback,
  });

  const [deliveriesAndServicesIdToRemove, setDeliveriesAndServicesIdToRemove] =
    useState<false | number>(false);

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `${pathApiServicesAndDeliveries}/${deliveriesAndServicesIdToRemove}`,
    method: "DELETE",
    body: deliveriesAndServicesIdToRemove,
    setBody: setDeliveriesAndServicesIdToRemove,
    successCallback: fetchAgainServicesAndDeliveries,
  });

  useEffect(() => {
    if (deliveriesAndServicesIdToRemove !== false) {
      fetchAgain();
    }
  }, [deliveriesAndServicesIdToRemove, fetchAgain]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchAgainServicesAndDeliveries();
  }, [startDate, endDate, fetchAgainServicesAndDeliveries]);

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainServicesAndDeliveries
  );

  const [repeatDelivery, setRepeatDelivery] = useState<{
    isOpen: boolean;
    rowId: number | null;
    edit: boolean;
  }>({
    isOpen: false,
    rowId: null,
    edit: false,
  });

  const closeRepeatPopup = useCallback(() => {
    setRepeatDelivery({
      isOpen: false,
      rowId: null,
      edit: false,
    });
  }, []);

  const [isOpenEditPopup, setIsOpenEditPopup] = useState<{
    isOpen: boolean;
    rowId: number | undefined;
  }>({
    isOpen: false,
    rowId: undefined,
  });
  const closeEditPopup = useCallback(() => {
    setIsOpenEditPopup({
      isOpen: false,
      rowId: undefined,
    });
  }, []);

  ////////////// parallel
  const [parallelIsFetching, setParallelIsFetching] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const subjectId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const ifHideConfirmationButton = (row: IDeliveryServiceGuest) => {
    const deliveryService = row.deliveryService;
    return (
      deliveryService.confirmationStatus === StatusInPortsType.APPROVED ||
      (deliveryService.pfsoConfirmationId != null &&
        deliveryService.pfsoConfirmationId !== subjectId) ||
      (deliveryService.shiftDirectorConfirmationId != null &&
        deliveryService.shiftDirectorConfirmationId !== subjectId) ||
      row.guests.find((guest) => guest.isLetIn) != null
    );
  };

  const ifHideRejectButton = (row: IDeliveryServiceGuest) => {
    const deliveryService = row.deliveryService;
    return (
      deliveryService.confirmationStatus === StatusInPortsType.REJECTED ||
      (deliveryService.pfsoConfirmationId != null &&
        deliveryService.pfsoConfirmationId !== subjectId) ||
      (deliveryService.shiftDirectorConfirmationId != null &&
        deliveryService.shiftDirectorConfirmationId !== subjectId) ||
      row.guests.find((guest) => guest.isLetIn) != null
    );
  };

  return (
    <div>
      <div>
        <TopBanner pathName={t("services_and_deliveries")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
          />
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={servicesAndDeliveriesRows}
            layoutSettingName={layoutSettingName}
            rowDetail={RowDetailServicesAndDeliveries}
            selectData={
              profileType !== ProfileType.AGENT &&
              profileType !== ProfileType.OPERATIONAL_SUPPORT
                ? setSelectedRows
                : undefined
            }
            hideSelectDataCondition={(row) => ifHideConfirmationButton(row)}
            actionsColumnUpdated={{
              popup: (props) => {
                return (
                  <PopupAddServicesAndDeliveries
                    pathApiCompaniesInPorts={pathApiCompaniesInPorts}
                    pathApiArrivalsOfShips={pathApiArrivalsOfShips}
                    pathApiGuests={pathApiGuests}
                    pathApiServicesAndDeliveries={pathApiServicesAndDeliveries}
                    pathApiTypesOfVisit={pathApiTypesOfVisit}
                    isOpenRepeatPopup={repeatDelivery.isOpen}
                    editRowId={
                      isOpenEditPopup.isOpen ? isOpenEditPopup.rowId : undefined
                    }
                    repeatDeliveryId={repeatDelivery.rowId}
                    closeRepeatPopup={closeRepeatPopup}
                    isOpenEditPopup={isOpenEditPopup.isOpen}
                    closeEditPopup={closeEditPopup}
                    {...props}
                  />
                );
              },
              successCallbackPopup: fetchAgainServicesAndDeliveries,
              addButton: {
                ifShow:
                  profileType !== ProfileType.AGENT &&
                  profileType !== ProfileType.OPERATIONAL_SUPPORT
                    ? false
                    : true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
                customComponent(row: IDeliveryServiceGuest) {
                  return profileType !== ProfileType.AGENT &&
                    profileType !== ProfileType.OPERATIONAL_SUPPORT ? (
                    <OperationsButtons
                      patchApi={
                        pathApiChangeConfirmationStatus +
                        "/" +
                        row.deliveryService.id
                      }
                      successCallback={fetchAgainServicesAndDeliveries}
                      newStatusIdConfirm={StatusInPortsType.APPROVED}
                      ifHideConfirm={ifHideConfirmationButton(row)}
                      ifHideReject={ifHideRejectButton(row)}
                      prefixPathName="/deliveryService"
                      isSecurity={false}
                    />
                  ) : row.deliveryService.confirmationStatus !==
                      StatusInPortsType.APPROVED &&
                    row.deliveryService.confirmationStatus !==
                      StatusInPortsType.REJECTED ? (
                    <Button
                      onClick={() => {
                        setIsOpenEditPopup({
                          isOpen: true,
                          rowId: row.deliveryService.id,
                        });
                      }}
                    >
                      {t("edit")}
                    </Button>
                  ) : (
                    <></>
                  );
                },
              },
              removeButton: {
                ifShow:
                  profileType !== ProfileType.AGENT &&
                  profileType !== ProfileType.OPERATIONAL_SUPPORT
                    ? false
                    : true,
                customComponent(row: IDeliveryServiceGuest) {
                  return row.deliveryService.confirmationStatus !==
                    StatusInPortsType.APPROVED &&
                    row.deliveryService.confirmationStatus !==
                      StatusInPortsType.REJECTED ? (
                    <Button
                      onClick={() =>
                        setDeliveriesAndServicesIdToRemove(
                          row.deliveryService.id
                        )
                      }
                    >
                      {t("remove")}
                    </Button>
                  ) : (
                    <></>
                  );
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainServicesAndDeliveries,
              isError: fetchingStateServicesAndDeliveries.isError,
              isFetching:
                fetchingStateServicesAndDeliveries.isFetching ||
                fetchingState.isFetching ||
                parallelIsFetching,
            }}
            refreshingButton={{
              ifShow:
                profileType !== ProfileType.AGENT &&
                profileType !== ProfileType.OPERATIONAL_SUPPORT
                  ? true
                  : false,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
            tableEditColumnWidth={
              profileType !== ProfileType.AGENT &&
              profileType !== ProfileType.OPERATIONAL_SUPPORT
                ? 220
                : 180
            }
            columnsWidth={[
              {
                columnName: "action",
                width: 150,
              },
            ]}
          />
        </div>

        {profileType !== ProfileType.AGENT &&
          profileType !== ProfileType.OPERATIONAL_SUPPORT && (
            <div>
              <ParallelActions
                path={pathApiChangeConfirmationStatus!}
                method="PATCH"
                selectedRows={servicesAndDeliveriesRows
                  ?.filter((row) => selectedRows.includes(row.id))
                  .map((a) => a.deliveryService.id)}
                setParallelIsFetching={setParallelIsFetching}
                allGoodOrNotAllGoodCallback={fetchAgainServicesAndDeliveries}
                buttons={[
                  {
                    name: t("approve"),
                    setBody: [
                      {
                        path: "/deliveryService/confirmationStatus",
                        op: "replace",
                        value: StatusInPortsType.APPROVED,
                      },
                    ],
                  },
                ]}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default ServicesAndDeliveriesContent;
