import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchPost from "../../../../hooks/fetchHooks/post/useFetchPost";
import "./notificationList.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  AutocompleteStyled,
  TextFieldStyled,
  DatePickerStyled,
  CheckboxStyled,
} from "../../../helpersComponents/MaterialUi/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../helpersComponents/Button/button";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import { withStyles } from "@material-ui/styles";
import AddGuestForm from "./addGuestForm";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { selectAuthUser } from "../../../../reducers/session";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

const DialogContentStyled = withStyles((theme) => ({
  root: {
    padding: "8px 0px",
  },
}))(DialogContent);

const Popup: React.FC = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
}: any) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  // const [ifFetchAnnouncementById,] = useState(false);
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  // const [putRequestBody,] = useState<any>(false);
  const settings = useAppSelector(selectSettings);

  const [dateOfArrival, setDateOfArrival] = useState([new Date()]);
  const [dateOfArrivalError, setDateOfArrivalError] = useState([false]);
  const [ifIgnoreTime, setIfIgnoreTime] = useState<boolean>(false);
  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);
  const [addedIdGuest, setAddedIdGuest] = useState<any>(null);

  const [isError, setIsError] = useState<boolean>(false);
  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);
  const company = useCachedFetch(
    "companyNotifications",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );
  const conferenceRoom = useCachedFetch(
    "conferenceRoomNotifications",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );
  const department = useCachedFetch(
    "departmentNotifications",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );
  const parking = useCachedFetch(
    "parkingNotifications",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );
  const [parkingModified, setParkingModified] = useState<any>();
  const typesOfVisits = useCachedFetch(
    "typesOfVisits",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );
  const guest = useCachedFetch(
    "guestNotifications",
    ifCheckForUpdateCache,
    3600,
    ifIgnoreTime,
    ifClearReturnedValue
  );

  const [timeWorkers, fetchingStateTimeWorkers] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/notifications/time-workers-for-target-person`,
    startFetchOnInitial: true,
  });

  const [selectedGuest, setSelectedGuest] = useState<any>(null);
  const [selectedGuestError, setSelectedGuestError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [selectedDepartmentError, setSelectedDepartmentError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [selectedParking, setSelectedParking] = useState<any>(null);
  const [selectedParkingError, setSelectedParkingError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [selectedConferenceRoomm, setSelectedConferenceRoom] =
    useState<any>(null);
  const [selectedConferenceRoommError, setSelectedConferenceRoomError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });
  const [selectedTypeOfVisit, setSelectedTypeOfVisit] = useState<any>(null);
  const [selectedTypeOfVisitError, setSelectedTypeOfVisitError] = useState<any>(
    {
      isError: false,
      errorMessage: "",
    }
  );
  const [selectedChangeTargetPerson, setSelectedChangeTargetPerson] =
    useState<any>(null);
  const [selectedChangeTargetPersonError, setSelectedChangeTargetPersonError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });
  const [description, setDescription] = useState<any>("");

  const [licencePlate, setLicencePlate] = useState<any>("");
  const [idNumber, setIdNumber] = useState<any>("");

  let postRequest = useFetchPost(
    `worker-time/${authUserId}/notifications`,
    postRequestBody,
    ifClearReturnedValue
  );

  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const [checkbox, setCheckbox] = React.useState({
    meal: false,
    changeTargetPerson: false,
    activeGuest: true,
  });

  const [showAddGuestForm, setShowAddGuestForm] = useState<boolean>(false);
  const [
    putingPostingCircularProgressGuest,
    setPutingPostingCircularProgressGuest,
  ] = useState<boolean>(false);
  const [responseErrorMessageGuest, setResponseErrorMessageGuest] =
    useState<string>();

  useEffect(() => {
    if (ifIgnoreTime) {
      setIfCheckForUpdateCache(true);
    }
  }, [ifIgnoreTime]);

  useEffect(() => {
    if (
      guest === "error" ||
      company === "error" ||
      conferenceRoom === "error" ||
      department === "error" ||
      parking === "error" ||
      typesOfVisits === "error" ||
      timeWorkers === "error"
    ) {
      setIsError(true);
      setIfCheckForUpdateCache(false);
      return;
    }

    if (guest === null) return;
    else setIfIgnoreTime(false);
    if (
      company === null ||
      conferenceRoom === null ||
      department === null ||
      parking === null ||
      typesOfVisits === null ||
      timeWorkers === null
    ) {
      return;
    } else {
      let parkingLocal = [...parking];
      // parkingLocal.unshift({ name: t("none"), id: null })
      setPutingPostingCircularProgress(false);
      setSelectedDepartment(department[0]);
      setSelectedParking(parkingLocal[0]);
      setSelectedConferenceRoom(conferenceRoom[0]);
      setSelectedTypeOfVisit(typesOfVisits[0]);
      setParkingModified(parkingLocal);
      setIsLoading(false);
    }
    if (!row.hasOwnProperty("id")) setIsLoading(false);
  }, [
    row,
    t,
    company,
    conferenceRoom,
    department,
    parking,
    typesOfVisits,
    guest,
    timeWorkers,
  ]);

  useEffect(() => {
    if (addedIdGuest !== null && guest !== null) {
      const foundGuest = guest.find((element) => {
        return element.id === addedIdGuest;
      });
      setSelectedGuest(foundGuest);
      setSelectedGuestError({
        isError: false,
        errorMessage: "",
      });
    }
  }, [addedIdGuest, guest]);

  const applyChanges = useRef(onApplyChanges);

  useEffect(() => {
    if (postRequest.data?.status === 201) {
      applyChanges.current(true);
      setPutingPostingCircularProgress(false);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setResponseErrorMessage(error);
    } else if (postRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [postRequest, t]);

  const confirm = () => {
    let ifFetch = true;

    let dateOfArrivalErrorCopy = [...dateOfArrivalError];
    dateOfArrival.forEach((date, index) => {
      if (date === null) {
        dateOfArrivalErrorCopy[index] = true;
        ifFetch = false;
      } else if (isNaN(date.getTime())) {
        dateOfArrivalErrorCopy[index] = true;
        ifFetch = false;
      } else {
        dateOfArrivalErrorCopy[index] = false;
        setDateOfArrivalError(dateOfArrivalErrorCopy);
      }
    });
    setDateOfArrivalError(dateOfArrivalErrorCopy);
    if (selectedGuest === null) {
      setSelectedGuestError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (selectedDepartment === null) {
      setSelectedDepartmentError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (selectedParking === null) {
      setSelectedParkingError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (selectedConferenceRoomm === null) {
      setSelectedConferenceRoomError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (selectedTypeOfVisit === null) {
      setSelectedTypeOfVisitError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }
    if (checkbox.changeTargetPerson === true) {
      if (selectedChangeTargetPerson === null) {
        setSelectedChangeTargetPersonError({
          isError: true,
          errorMessage: "",
        });
        ifFetch = false;
      }
    }

    if (!ifFetch) return;

    setPutingPostingCircularProgress(true);
    let dateOfArrivalString: any = [];
    dateOfArrival.forEach((date) => {
      dateOfArrivalString.push(computeDate(date));
    });

    setPostRequestBody({
      arrivalDates: dateOfArrivalString,
      guestId: selectedGuest.id,
      departmentId: selectedDepartment.id,
      parkingId: selectedParking.id,
      conferenceRoomId: selectedConferenceRoomm.id,
      typeOfVisitId: selectedTypeOfVisit.id,
      carRegistrationNumber: licencePlate,
      idNumber: idNumber,
      isProvisioning: checkbox.meal,
      announces: checkbox.changeTargetPerson
        ? selectedChangeTargetPerson.lastName +
          " " +
          selectedChangeTargetPerson.firstName
        : null,
      description: description,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setSelectedGuest(null);
          setSelectedGuestError({
            isError: false,
            errorMessage: "",
          });
          setSelectedGuest(null);
          setSelectedGuestError({
            isError: false,
            errorMessage: "",
          });
          setSelectedDepartment(null);
          setSelectedDepartmentError({
            isError: false,
            errorMessage: "",
          });
          setAddedIdGuest(null);
          // setPostRequestGuestBody(false)
          setSelectedParking(null);
          setSelectedConferenceRoom(null);
          setSelectedConferenceRoomError({
            isError: false,
            errorMessage: "",
          });
          setSelectedTypeOfVisit(null);
          setSelectedTypeOfVisitError({
            isError: false,
            errorMessage: "",
          });
          setSelectedParkingError({
            isError: false,
            errorMessage: "",
          });
          setResponseErrorMessage("");
          setSelectedChangeTargetPerson(null);
          setSelectedChangeTargetPersonError({
            isError: false,
            errorMessage: "",
          });
          setDescription("");
          setCheckbox({
            meal: false,
            changeTargetPerson: true,
            activeGuest: true,
          });
          setShowAddGuestForm(false);
          setSelectedDepartment(null);
          setSelectedDepartmentError({
            isError: false,
            errorMessage: "",
          });
          setIfClearReturnedValue(true);
          setLicencePlate("");
          setIdNumber("");
          setIsLoading(true);
          setDateOfArrival([new Date()]);
          setDateOfArrivalError([false]);
          setIfCheckForUpdateCache(false);
          setIsError(false);
        },
        onEnter: () => {
          setIfClearReturnedValue(false);
          setIfCheckForUpdateCache(true);
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("notification")}</div>
        <div className="description">{t("adding_notification")}</div>
      </DialogTitle>

      <DialogContentStyled>
        <LoadingWrapper
          isLodadingProgress={isLoading || fetchingStateTimeWorkers.isFetching}
          isError={isError || fetchingStateTimeWorkers.isError}
          setIfFetchAgain={setIfCheckForUpdateCache}
          setIsError={setIsError}
          bigSize={true}
        >
          <div className="popupContentLists">
            {dateOfArrival.map((value, index) => {
              return (
                <div key={index} className="checkboxForm">
                  <div className="titleWidthNotifLists">
                    {t("date_of_arrival")} {index + 1}
                    {index >= 1 ? (
                      <FontAwesomeIcon
                        className="faTrash"
                        icon={faTimesCircle}
                        onClick={() => {
                          let dateOfArrivalCopy = [...dateOfArrival];
                          dateOfArrivalCopy.splice(index, 1);
                          setDateOfArrival(dateOfArrivalCopy);
                          let dateOfArrivalErrorCopy = [...dateOfArrivalError];
                          dateOfArrivalErrorCopy.splice(index, 1);
                          setDateOfArrivalError(dateOfArrivalErrorCopy);
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="inputWidthNotifLists">
                    <DatePickerStyled
                      keyboardDateTimePicker={true}
                      view={["year", "month", "date", "hours", "minutes"]}
                      format="dd-MM-yyyy HH:mm"
                      date={dateOfArrival[index]}
                      isError={dateOfArrivalError[index]}
                      width="100%"
                      setDate={(date) => {
                        let dateOfArrivalCopy = [...dateOfArrival];
                        dateOfArrivalCopy[index] = date;
                        setDateOfArrival(dateOfArrivalCopy);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            <div className="checkboxForm">
              <div
                onClick={() => {
                  let dateOfArrivalCopy = [...dateOfArrival];
                  dateOfArrivalCopy.push(new Date());
                  setDateOfArrival(dateOfArrivalCopy);
                  let dateOfArrivalErrorCopy = [...dateOfArrivalError];
                  dateOfArrivalErrorCopy.push(false);
                  setDateOfArrivalError(dateOfArrivalErrorCopy);
                }}
                className="add_another_date"
              >
                <Button>{t("add_another_date")} </Button>
              </div>
            </div>

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("guest")}:</div>
              <div className="inputWidthNotifListstSmaller">
                <AutocompleteStyled
                  required={true}
                  options={guest}
                  getOptionLabel={(option: any) => {
                    return option.firstName + " " + option.lastName;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedGuest ? selectedGuest : null}
                  isError={selectedGuestError.isError}
                  onChange={(_, newValue) => {
                    setSelectedGuest(newValue);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedGuestError({
                        isError: true,
                        errorMessage: "",
                      });
                    } else {
                      setSelectedGuestError({
                        isError: false,
                        errorMessage: "",
                      });
                    }
                  }}
                  label={t("select_guest")}
                />
                {/* {selectedGuestError ? <div className="errorHolidayrequest">
      {t("select_guest")}

    </div>
      :
      null} */}
              </div>
              <div className="showAddGuestForm">
                <div
                  onClick={() => {
                    if (
                      putingPostingCircularProgressGuest &&
                      showAddGuestForm === true
                    )
                      return;
                    setResponseErrorMessageGuest("");
                    setShowAddGuestForm(!showAddGuestForm);
                  }}
                >
                  {showAddGuestForm ? (
                    <FontAwesomeIcon
                      className="faMinusNotifications"
                      icon={faMinus}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="faPlusNotifications"
                      icon={faPlus}
                    />
                  )}
                </div>
              </div>
            </div>

            {showAddGuestForm ? (
              <AddGuestForm
                setResponseErrorMessageGuest={setResponseErrorMessageGuest}
                responseErrorMessageGuest={responseErrorMessageGuest}
                setShowAddGuestForm={setShowAddGuestForm}
                setIfClearReturnedValue={setIfClearReturnedValue}
                putingPostingCircularProgressGuest={
                  putingPostingCircularProgressGuest
                }
                setPutingPostingCircularProgressGuest={
                  setPutingPostingCircularProgressGuest
                }
                setIfCheckForUpdateCache={setIfCheckForUpdateCache}
                setIfIgnoreTime={setIfIgnoreTime}
                setIsLoading={setIsLoading}
                setCheckbox={setCheckbox}
                handleChangeCheckbox={handleChangeCheckbox}
                checkbox={checkbox}
                setAddedIdGuest={setAddedIdGuest}
              />
            ) : null}

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("department")}:</div>
              <div className="inputWidthNotifLists">
                <AutocompleteStyled
                  required={true}
                  options={department}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedDepartment ? selectedDepartment : null}
                  isError={selectedDepartmentError.isError}
                  onChange={(_, newValue) => {
                    setSelectedDepartment(newValue);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedDepartmentError({
                        isError: true,
                        errorMessage: "",
                      });
                    } else {
                      setSelectedDepartmentError({
                        isError: false,
                        errorMessage: "",
                      });
                    }
                  }}
                  label={t("select_department")}
                />
                {/* {selectedGuestError ? <div className="errorHolidayrequest">
      {t("select_department")}

    </div>
      :
      null} */}
              </div>
            </div>

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("parking")}:</div>
              <div className="inputWidthNotifLists">
                <AutocompleteStyled
                  // required={true}
                  options={parkingModified}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedParking ? selectedParking : null}
                  onChange={(_, newValue) => {
                    setSelectedParking(newValue);
                  }}
                  label={t("select_parking")}
                  isError={selectedParkingError.isError}
                  required={true}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedParkingError({
                        isError: true,
                        errorMessage: "",
                      });
                    } else {
                      setSelectedParkingError({
                        isError: false,
                        errorMessage: "",
                      });
                    }
                  }}
                />
                {/* {selectedGuestError ? <div className="errorHolidayrequest">
      {t("select_parking")}

    </div>
      :
      null} */}
              </div>
            </div>

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">
                {t("conference_room")}:
              </div>
              <div className="inputWidthNotifLists">
                <AutocompleteStyled
                  options={conferenceRoom}
                  required={true}
                  width="100%"
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={
                    selectedConferenceRoomm ? selectedConferenceRoomm : null
                  }
                  isError={selectedConferenceRoommError.isError}
                  onChange={(_, newValue) => {
                    setSelectedConferenceRoom(newValue);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedConferenceRoomError({
                        isError: true,
                        errorMessage: "",
                      });
                    } else {
                      setSelectedConferenceRoomError({
                        isError: false,
                        errorMessage: "",
                      });
                    }
                  }}
                  label={t("select_conference_room")}
                />
                {/* {selectedGuestError ? <div className="errorHolidayrequest">
      {t("select_conference_room")}

    </div>
      :
      null} */}
              </div>
            </div>

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("type_of_visit")}:</div>
              <div className="inputWidthNotifLists">
                <AutocompleteStyled
                  required={true}
                  options={typesOfVisits}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  width="100%"
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedTypeOfVisit ? selectedTypeOfVisit : null}
                  isError={selectedTypeOfVisitError.isError}
                  onChange={(_, newValue) => {
                    setSelectedTypeOfVisit(newValue);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setSelectedTypeOfVisitError({
                        isError: true,
                        errorMessage: "",
                      });
                    } else {
                      setSelectedTypeOfVisitError({
                        isError: false,
                        errorMessage: "",
                      });
                    }
                  }}
                  label={t("select_type_of_visit")}
                />
                {/* {selectedGuestError ? <div className="errorHolidayrequest">
      {t("select_type_of_visit")}

    </div>
      :
      null} */}
              </div>
            </div>

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("licence_plate")}:</div>
              <div className="inputWidthNotifLists">
                <TextFieldStyled
                  // value={licencePlate}
                  defaultValue=""
                  label={t("licence_plate")}
                  // label={""}
                  width="100%"
                  onBlur={(e) => {
                    setLicencePlate(e.target.value);
                  }}
                  multiline
                />
              </div>
            </div>

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("id_number")}:</div>
              <div className="inputWidthNotifLists">
                <TextFieldStyled
                  // value={idNumber}
                  defaultValue=""
                  label={t("id_number")}
                  width="100%"
                  // label={""}
                  onBlur={(e) => {
                    setIdNumber(e.target.value);
                  }}
                  multiline
                />
              </div>
            </div>

            {!(settings.WebAnonseBezAprowizacji?.value === "1") ? (
              <div className="checkboxForm">
                <div>
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={checkbox.meal}
                        name="meal"
                        onChange={handleChangeCheckbox}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <span className="FormControlLabelStyle">{t("meal")}</span>
                    }
                  />
                </div>
              </div>
            ) : null}

            {!(settings.WebAnonseBezOsobyDocelowej?.value === "1") ? (
              <div className="checkboxForm">
                <div className="titleWidthNotifLists">
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={checkbox.changeTargetPerson}
                        name="changeTargetPerson"
                        onChange={handleChangeCheckbox}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <span className="FormControlLabelStyle">
                        {t("change_target_person")}
                      </span>
                    }
                  />
                </div>
                <div className="inputWidthNotifLists">
                  {checkbox.changeTargetPerson ? (
                    <AutocompleteStyled
                      required={true}
                      options={timeWorkers}
                      getOptionLabel={(option: any) => {
                        return option.firstName + " " + option.lastName;
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      // value={selectedChangeTargetPerson ? selectedChangeTargetPerson : setSelectedChangeTargetPerson(changeTargetPersonsArray[0])}
                      value={
                        selectedChangeTargetPerson
                          ? selectedChangeTargetPerson
                          : null
                      }
                      // closeIcon={null}
                      isError={selectedChangeTargetPersonError.isError}
                      onChange={(_, newValue) => {
                        setSelectedChangeTargetPerson(newValue);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedChangeTargetPersonError({
                            isError: true,
                            errorMessage: "",
                          });
                        } else {
                          setSelectedChangeTargetPersonError({
                            isError: false,
                            errorMessage: "",
                          });
                        }
                      }}
                      label={t("change_target_person")}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className="selectFormNotfifiLists">
              <div className="titleWidthNotifLists">{t("description")}:</div>
              <div className="inputWidthNotifLists">
                <TextFieldStyled
                  width="100%"
                  defaultValue={description}
                  label={t("description")}
                  onBlur={(e) => {
                    setDescription(e.target.value);
                  }}
                  multiline
                />
              </div>
            </div>
          </div>
        </LoadingWrapper>
      </DialogContentStyled>

      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              disabled={showAddGuestForm}
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
