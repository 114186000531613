import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import TableComponentMemo from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import PopupAddPfsoNewCompany from "./PopupAddPfsoNewCompany";

const ContentPfsoCompanies = () => {
  const { t } = useTranslation();

  const modifyCompaniesResponseCallback = useCallback(
    (responseData) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          type:
            row.type === false
              ? t("delivery_man")
              : t("garbage_disposal_company"),
        };
      });
      return modified;
    },
    [t]
  );

  const columns = [
    { name: "name", title: t("name") },
    { name: "type", title: t("type") },
    { name: "createdAt", title: t("data_created"), type: "date" },
  ];

  const [companiesRows, fetchingStateCompanies, fetchAgainCompanies] =
    useFetchAndSetGET({
      path: "pfso/companies-in-ports?typeOfCompany=2",
      modifyResponseCallback: modifyCompaniesResponseCallback,
    });

  const [companyIdToRemove, setCompanyIdToRemove] = useState<false | number>(
    false
  );

  const [fetchingState, fetchAgain] = useFetchOtherThanGET({
    path: `pfso/companies-in-ports/${companyIdToRemove}`,
    method: "DELETE",
    body: companyIdToRemove,
    setBody: setCompanyIdToRemove,
    successCallback: fetchAgainCompanies,
  });

  useEffect(() => {
    if (companyIdToRemove !== false) {
      fetchAgain();
    }
  }, [companyIdToRemove, fetchAgain]);

  return (
    <>
      <div>
        <TopBanner pathName={t("delivery_man_and_garbage_company")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <TableComponentMemo
            columns={columns}
            rows={companiesRows}
            layoutSettingName="layoutAgentComapniesRecords"
            hideActionsAndVisibilityColumns={true}
            actionsColumnUpdated={{
              popup: PopupAddPfsoNewCompany,
              successCallbackPopup: fetchAgainCompanies,
              addButton: {
                ifShow: true,
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
              },
              removeButton: {
                ifShow: true,
                onClick: (row) => {
                  setCompanyIdToRemove(row.id);
                },
              },
            }}
            fetchingState={{
              fetchAgain: fetchAgainCompanies,
              isError: fetchingStateCompanies.isError,
              isFetching:
                fetchingStateCompanies.isFetching || fetchingState.isFetching,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentPfsoCompanies;
