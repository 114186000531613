import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { ReactElement } from "react";
import { selectConfirmState } from "../../reducers/useConfirm";
import { useAppSelector } from "../../store/hooks";
import useCoffirm from "./useConfirm";
import Button from "../../components/helpersComponents/Button/button";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./confirmDialog.module.scss";

export default function ConfirmDialog(): ReactElement {
  const { show, buttons, text, typeDialog, disableBackdropClick } =
    useAppSelector(selectConfirmState);
  const { onConfirm, onCancel } = useCoffirm();
  const { t } = useTranslation();

  let icon: JSX.Element = (
    <FontAwesomeIcon icon={faQuestionCircle} size="2x" color="#1E90FF" />
  );
  switch (typeDialog) {
    case "warning":
      icon = (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="2x"
          color="#ffd500"
        />
      );
      break;
  }

  return (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={show}
        onClose={(_, reason) => {
          if (
            !disableBackdropClick ||
            (disableBackdropClick && reason !== "backdropClick")
          )
            onCancel();
        }}
      >
        <DialogTitle className="dialogTitleGlobal">
          <div className="titlePopup">
            {typeDialog ? t(typeDialog) : t("confirmation")}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogContent}>
            <div>{icon}</div>
            <div>{text}</div>
          </div>
        </DialogContent>
        <DialogActions className="dialogActionsGlobal">
          {buttons.map((button) => {
            return (
              <Button
                key={button.buttonName}
                onClick={() => onConfirm(button.buttonId)}
              >
                {button.buttonName}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
}
