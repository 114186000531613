import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../PopupHelpers";
import PopupContentNewCompany from "./PopupContentNewCompany";

const PopupNewCompany = ({
  isOpen,
  rowId,
  pathApiCompaniesInPorts,
  successCallback,
  closePopup,
}: {
  isOpen: boolean;
  rowId?: number;
  pathApiCompaniesInPorts: string;
  successCallback: () => void;
  closePopup: () => void;
}) => {
  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
  }, [successCallback, closePopup]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<false | string>(false);

  const onSubmit = (values: ICompanyInPortsAdd) => {
    setErrorMessage("");
    let body = JSON.stringify({
      name: values.name,
      shortName: "",
      description: null,
    });
    setBodyRequest(body);
  };

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathApiCompaniesInPorts}${rowId !== undefined ? `/${rowId}` : ""}`,
    method: rowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen]);

  return (
    <DialogStyled open={isOpen} onClose={closePopup}>
      <DialogTitleStyled title={t("add_company")} />
      <PopupContentNewCompany
        rowId={rowId}
        onSubmit={onSubmit}
        pathApiCompaniesInPorts={pathApiCompaniesInPorts}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={closePopup}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupNewCompany;
