import {
  faChalkboard,
  faExclamationTriangle,
  faFireAlt,
  faHardHat,
  faHome,
  faInfo,
  faMapMarkedAlt,
  faRunning,
  faSearch,
  faSitemap,
  faUserFriends,
  faUsersCog,
  faUserTie,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ACUserPermissions } from "../../../enums/acUserPermissions";
import { selectACSuperiorCurrentUser } from "../../../reducers/acSuperiorCurrentUser/acSuperiorCurrentUser";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import Comapnies from "../../superiorAc/Companies";
import CompanyStructureSuperiorAC from "../../superiorAc/CompanyStructure";
import Evacuation from "../../superiorAc/evacuation/index";
import EvacuationReportSuperiorAc from "../../superiorAc/EvacuationReport";
import FireReportSuperiorAc from "../../superiorAc/FireReport";
import Groups from "../../superiorAc/Groups";
import HomePageSuperiorAC from "../../superiorAc/HomePage";
import AlertsAC from "../../superiorAc/lists/Alerts";
import CommunicationAC from "../../superiorAc/lists/Communications";
import EventsAC from "../../superiorAc/lists/Events";
import OperationLogsAC from "../../superiorAc/lists/OperationLogs";
import NotificationInPortsACSuperior from "../../superiorAc/NotificationsInPorts";
import ReadersMonitoring from "../../superiorAc/ReadersMonitoring";
import Trainings from "../../superiorAc/Trainings";
import AddEditTraining from "../../superiorAc/Trainings/AddEdit/addEdit";
import UsersManagementSuperiorAC from "../../superiorAc/UserManagement";
import Visualization from "../../superiorAc/visualization";
import WorkersACManagement from "../../superiorAc/WorkersACManagement";
import Profile from "../../UserPanel/profile";
import { selectACSuperiorNotificationMenuItemName } from "../../../reducers/notificationsSummary";
import NotificationInPort from "../../superiorAc/NotificationInPort";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const acPermission = useAppSelector(selectACSuperiorCurrentUser)?.permission;
  const hideACWorkers =
    useAppSelector(selectSettings)["WylaczZarzadzaniePracownikamiKD"]?.value ===
    "1";

  const notificationsMenuItemName = useAppSelector(
    selectACSuperiorNotificationMenuItemName
  );

  const navLinksArrayTimeWorker: navLinksArrayTimeWorkerInterface = {
    profileId: 0,
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/superior-ac/trainings/edit/:trainingId",
        component: (props) => <AddEditTraining {...props} />,
      },
      {
        ifHidden: false,
        path: "/superior-ac/trainings/add",
        component: (props) => <AddEditTraining {...props} />,
      },
      {
        ifHidden: false,
        path: "/superior-ac/notifications/:id",
        component: (props) => <NotificationInPort {...props} />,
      },
    ],
    routesArrayWithComponents: [
      {
        icon: faHome,
        name: t("home_page"),
        ifHidden: settings.WebUkryjPrzelozonyKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/superior-ac/home",
        component: () => <HomePageSuperiorAC />,
      },
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/superior-ac/profile",
        component: () => <Profile />,
      },
      {
        icon: faSearch,
        name: t("lists"),
        ifHidden: settings.WebUkryjPrzelozonyKDEwidencja?.value === "1",
        singleLink: false,
        path: [
          {
            name: t("events"),
            path: "/superior-ac/lists/events",
            ifHidden:
              settings.WebUkryjPrzelozonyKDEwidencjaZdarzenia?.value === "1",
            component: () => <EventsAC />,
          },
          {
            name: t("alerts"),
            path: "/superior-ac/lists/alerts",
            ifHidden:
              settings.WebUkryjPrzelozonyKDEwidencjaAlerty?.value === "1" ||
              (acPermission?.id !== ACUserPermissions.ADMINISTRATOR &&
                acPermission?.id !== ACUserPermissions.SERVICE),
            component: () => <AlertsAC />,
          },
          {
            name: t("operation_logs"),
            path: "/superior-ac/lists/operation-logs",
            ifHidden:
              settings.WebUkryjPrzelozonyKDEwidencjaLogiOperacji?.value === "1",
            component: () => <OperationLogsAC />,
          },
          {
            name: t("communication"),
            path: "/superior-ac/lists/communications",
            ifHidden:
              settings.WebUkryjPrzelozonyKDEwidencjaKomunikacja?.value ===
                "1" || acPermission?.id !== ACUserPermissions.SERVICE,
            component: () => <CommunicationAC />,
          },
        ],
      },
      {
        icon: faUserFriends,
        name: notificationsMenuItemName,
        ifHidden: settings.WebUkryjPrzelozonyKDAwizacje?.value === "1",
        singleLink: true,
        path: "/superior-ac/notifications",
        component: () => <NotificationInPortsACSuperior />,
      },
      {
        icon: faSitemap,
        name: t("company_structure"),
        ifHidden: settings.WebUkryjPrzelozonyKDStrukturaFirmy?.value === "1",
        singleLink: true,
        path: "/superior-ac/company-structure",
        component: () => <CompanyStructureSuperiorAC />,
      },
      {
        icon: faWarehouse,
        name: t("companies"),
        ifHidden: settings.WebUkryjPrzelozonyKDFirmy?.value === "1",
        singleLink: true,
        path: "/superior-ac/companies",
        component: () => <Comapnies />,
      },
      {
        icon: faUsersCog,
        name: t("groups"),
        ifHidden: settings.WebUkryjPrzelozonyKDGrupy?.value === "1",
        singleLink: true,
        path: "/superior-ac/groups",
        component: () => <Groups />,
      },
      {
        icon: faUserTie,
        name: t("users"),
        ifHidden:
          settings.WebUkryjPrzelozonyKDUzytkownicy?.value === "1" ||
          (acPermission?.id !== ACUserPermissions.ADMINISTRATOR &&
            acPermission?.id !== ACUserPermissions.SERVICE),
        singleLink: true,
        path: "/superior-ac/users",
        component: () => <UsersManagementSuperiorAC />,
      },
      {
        icon: faHardHat,
        name: t("employees"),
        ifHidden:
          settings.WebUkryjPrzelozonyKDPracownicy?.value === "1" ||
          hideACWorkers,
        singleLink: true,
        path: "/superior-ac/employees",
        component: () => <WorkersACManagement />,
      },
      {
        icon: faExclamationTriangle,
        name: t("evacuation"),
        ifHidden: true,
        singleLink: true,
        path: "/superior-ac/evacuation",
        component: () => <Evacuation />,
      },

      {
        icon: faChalkboard,
        name: t("sk_training"),
        ifHidden: settings.WebUkryjPrzelozonyKDSzkolenia?.value === "1",
        singleLink: true,
        path: "/superior-ac/trainings",
        component: () => <Trainings />,
      },
      {
        icon: faRunning,
        name: t("readers_monitoring"),
        ifHidden: settings.WebUkryjPrzelozonyKDMonitorPrzejsc?.value === "1",
        singleLink: true,
        path: "/superior-ac/readers-monitoring",
        component: () => <ReadersMonitoring />,
      },
      {
        icon: faFireAlt,
        name: t("fire_report"),
        ifHidden: settings.WebUkryjPrzelozonyKDRaportPozarowy?.value === "1",
        singleLink: true,
        path: "/superior-ac/fire-report",
        component: () => <FireReportSuperiorAc />,
      },
      {
        icon: faFireAlt,
        name: t("evacuation_report"),
        ifHidden: settings.WebUkryjPrzelozonyKDRaportEwakuacji?.value === "1",
        singleLink: true,
        path: "/superior-ac/evacuation-report",
        component: () => <EvacuationReportSuperiorAc />,
      },
      {
        icon: faMapMarkedAlt,
        name: t("visualization"),
        ifHidden: settings.WebUkryjPrzelozonyKDWizualizacja?.value === "1",
        singleLink: true,
        path: "/superior-ac/visualization",
        component: () => <Visualization />,
      },
    ],
  };

  return navLinksArrayTimeWorker;
};
export default useNavLinksArray;
