import { CircularProgress } from "@material-ui/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addDaysToDate from "../../../HelpersFunctions/dateAndTime/addDaysToDate";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import Button from "../../helpersComponents/Button/button";
import CheckboxWithLabel from "../../helpersComponents/FormikInputs/CheckboxWithLabel/CheckboxWithLabel";
import {
  DatePickerStyled,
  TextFieldStyled,
} from "../../helpersComponents/MaterialUi/index";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import styles from "./formAddNewGuestStyle.module.scss";

interface Props {
  addGuestPath: string;
  setShowAddGuestFrom: Dispatch<SetStateAction<any>>;
  setAddedGuestId: Dispatch<SetStateAction<any>>;
  guests: any;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  addedGuestId: number | false;
  addedGuests: any[];
  setAddedGuests: Dispatch<SetStateAction<any[]>>;
  isCrewMember?: boolean | undefined;
  keySetFieldValue?: string;
  isSingleValue?: boolean;
  isTrainingNotRequired?: boolean;
}

const FormAddNewGuest = ({
  addGuestPath,
  setShowAddGuestFrom,
  setAddedGuestId,
  guests,
  setFieldValue,
  addedGuestId,
  addedGuests,
  setAddedGuests,
  isCrewMember,
  keySetFieldValue,
  isSingleValue,
  isTrainingNotRequired,
}: Props) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [email, setEmail] = useState<string | undefined>();
  const [emailError, setEmailError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfBirthError, setDateOfBirthError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const yesterday = addDaysToDate(new Date(), -1);

  const [isSelectedDateOfBirth, setIsSelectedDateOfBirth] = useState(true);
  const [isSelectedEmail, setIsSelectedEmail] = useState(true);

  const [errorMessage, setErrorMessage] = useState<false | any>(false);

  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: addGuestPath,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (addedGuestId && guests) {
      let foundGuest = guests.find((g) => g.id === addedGuestId);

      if (foundGuest) {
        if (!isSingleValue) {
          let array = addedGuests;
          array.push(foundGuest);
          setAddedGuests(array);
          if (setFieldValue !== undefined)
            setFieldValue(
              `${keySetFieldValue ? keySetFieldValue : "selectedGuests"}`,
              addedGuests
            );
        } else {
          if (setFieldValue !== undefined)
            setFieldValue(
              `${keySetFieldValue ? keySetFieldValue : "selectedGuests"}`,
              foundGuest
            );
        }

        setAddedGuestId(false);
        setShowAddGuestFrom(false);
      }
    }
  }, [
    addedGuestId,
    guests,
    setFieldValue,
    setAddedGuestId,
    setShowAddGuestFrom,
    addedGuests,
    setAddedGuests,
    keySetFieldValue,
    isSingleValue,
  ]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  function ValidateEmail(email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const confirm = () => {
    let ifFetch = true;

    if (firstName === "") {
      setFirstNameError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }

    if (lastName === "") {
      setLastNameError({
        isError: true,
        errorMessage: "",
      });
      ifFetch = false;
    }

    if (
      !isTrainingNotRequired &&
      !isCrewMember &&
      isSelectedEmail &&
      !ValidateEmail(email)
    ) {
      setEmailError({
        isError: true,
        errorMessage: t("invalid_email"),
      });
      ifFetch = false;
    }

    if (
      !isTrainingNotRequired &&
      !isCrewMember &&
      isSelectedDateOfBirth &&
      dateOfBirth === null
    ) {
      setDateOfBirthError({
        isError: true,
        errorMessage: t("field_required"),
      });
      ifFetch = false;
    }

    if (
      !isTrainingNotRequired &&
      !isCrewMember &&
      isSelectedDateOfBirth &&
      new Date(dateOfBirth!).setHours(0, 0, 0, 0) >=
        new Date().setHours(0, 0, 0, 0)
    ) {
      setDateOfBirthError({
        isError: true,
        errorMessage: t("date_must_be_earlier_than_today"),
      });
      ifFetch = false;
    }

    // if (!isTrainingNotRequired) {
    //   set;
    // }

    if (!ifFetch) return;

    setBodyRequest(
      JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        idNumber: "",
        companyName: "",
        carRegistrationNumber: "",
        carBrand: "",
        isHighlighted: false,
        highlightedDescription: "",
        highlightedBy: "",
        citizenship: "",
        phoneNumber: "",
        email:
          !isTrainingNotRequired && !isCrewMember && isSelectedEmail
            ? email
            : null,
        dateOfBirth:
          !isTrainingNotRequired && !isCrewMember && isSelectedDateOfBirth
            ? dateOfBirth
            : null,
        isCrewMember: isTrainingNotRequired || isCrewMember ? true : false,
      })
    );
  };

  useEffect(() => {
    if (fetchingStatePostData.response?.status === 201) {
      if (fetchingStatePostData.response.location) {
        let id = fetchingStatePostData.response.location.substring(
          fetchingStatePostData.response.location.lastIndexOf("/") + 1
        );
        setAddedGuestId(parseInt(id));
      }
    }
  }, [fetchingStatePostData.response, setAddedGuestId, setFieldValue]);

  useEffect(() => {
    if (!isTrainingNotRequired) {
      setIsSelectedDateOfBirth(true);
      setIsSelectedEmail(true);
    } else {
      setDateOfBirthError({
        isError: false,
        errorMessage: "",
      });
      setEmailError({
        isError: false,
        errorMessage: "",
      });
    }
  }, [isTrainingNotRequired]);

  return (
    <>
      <DialogContentStyled style={{ background: "#eef4f5", padding: 5 }}>
        <Row>
          <RowTitle>{t("first_name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("first_name")}
              required={true}
              isError={firstNameError.isError}
              ifToUpperCaseShipsAndPorts={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === "") {
                  setFirstNameError({
                    isError: true,
                    errorMessage: "",
                  });
                } else {
                  setFirstNameError({
                    isError: false,
                    errorMessage: "",
                  });
                }
              }}
            />
          </RowInput>
        </Row>

        <Row>
          <RowTitle>{t("last_name")}:</RowTitle>
          <RowInput>
            <TextFieldStyled
              label={t("last_name")}
              required={true}
              isError={lastNameError.isError}
              ifToUpperCaseShipsAndPorts={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === "") {
                  setLastNameError({
                    isError: true,
                    errorMessage: "",
                  });
                } else {
                  setLastNameError({
                    isError: false,
                    errorMessage: "",
                  });
                }
              }}
            />
          </RowInput>
        </Row>
        {!isCrewMember && (
          <>
            <Row>
              <RowTitle>
                <CheckboxWithLabel
                  name="isSelectedEmail"
                  checked={isSelectedEmail}
                  label={t("email")}
                  error={"true"}
                  ifCircle={true}
                  onChangeExtra={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isTrainingNotRequired) {
                      if (
                        e.target.checked === false &&
                        isSelectedDateOfBirth === false
                      ) {
                        setIsSelectedDateOfBirth(true);
                      }
                    }

                    if (e.target.checked === false) {
                      setEmailError({
                        isError: false,
                        errorMessage: "",
                      });
                    }
                    setIsSelectedEmail(e.target.checked);
                  }}
                />
              </RowTitle>
              <RowInput>
                {isSelectedEmail && (
                  <TextFieldStyled
                    label={t("email")}
                    type="text"
                    ifToUpperCaseShipsAndPorts={true}
                    required={true}
                    isError={emailError.isError}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!ValidateEmail(e.target.value)) {
                        setEmailError({
                          isError: true,
                          errorMessage: "",
                        });
                      } else {
                        setEmailError({
                          isError: false,
                          errorMessage: "",
                        });
                      }
                    }}
                  />
                )}
                {emailError.isError && (
                  <div className={styles.errorMessage}>
                    {emailError.errorMessage}
                  </div>
                )}
              </RowInput>
            </Row>

            <Row>
              <RowTitle>
                <CheckboxWithLabel
                  name="isSelectedDateOfBirth"
                  checked={isSelectedDateOfBirth}
                  label={t("date_of_birth")}
                  ifCircle={true}
                  onChangeExtra={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isTrainingNotRequired) {
                      if (
                        e.target.checked === false &&
                        isSelectedEmail === false
                      ) {
                        setIsSelectedEmail(true);
                      }
                    }

                    if (e.target.checked === false) {
                      setDateOfBirthError({
                        isError: false,
                        errorMessage: "",
                      });
                    }

                    setIsSelectedDateOfBirth(e.target.checked);
                  }}
                />
              </RowTitle>
              <RowInput>
                {isSelectedDateOfBirth && (
                  <DatePickerStyled
                    date={dateOfBirth}
                    setDate={setDateOfBirth}
                    maxDate={yesterday}
                    view={["year", "month", "date"]}
                    format="dd-MM-yyyy"
                    required={true}
                    isError={dateOfBirthError.isError}
                  />
                )}
                {dateOfBirthError.isError && (
                  <div className={styles.errorMessage}>
                    {dateOfBirthError.errorMessage}
                  </div>
                )}
              </RowInput>
            </Row>
          </>
        )}

        <Row>
          <RowTitle>
            {!fetchingStatePostData.isFetching ? (
              <>
                <div className={styles.containerAddGuestButton}>
                  <Button
                    className={styles.buttonAddGuest}
                    onClick={() => confirm()}
                    type="button"
                  >
                    {t("add")}
                  </Button>
                </div>
              </>
            ) : (
              <div className="confirmWaiting">
                <CircularProgress size={25} />
              </div>
            )}
          </RowTitle>
          {errorMessage && errorMessage !== "" && (
            <RowInput style={{ color: "red" }}>{errorMessage}</RowInput>
          )}
        </Row>
      </DialogContentStyled>
    </>
  );
};

export default FormAddNewGuest;
