import { Button, Switch, withStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import { ProfileType } from "../../../enums/profileType";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../HelpersFunctions/dateAndTime/getEndOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import { selectACSuperiorCurrentUser } from "../../../reducers/acSuperiorCurrentUser/acSuperiorCurrentUser";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import OperationsButtons from "../../pfso/OperationButtons/OperationsButons";
import ConfirmOperationDialog from "../ConfirmOperationDialog/ConfirmOperationDialog";
import DatePickerRange from "../DatePickerRange/datePickerRange";
import { CheckboxStyled } from "../MaterialUi";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import RowDetailNotificationInPorts from "./RowDetailNotificationInPorts";
import useColumns from "./useColumns";
import { faEdit, faTrash, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SwtichStyled = withStyles({
  switchBase: {
    color: "#f8a12f",
    "&$checked": {
      color: "#f8a12f",
    },
    "&$checked + $track": {
      backgroundColor: "#f8a12f",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#f8a12f",
    "$checked$checked + &": {
      backgroundColor: "#f8a12f",
    },
  },
})(Switch);

interface IProps {
  pathApiNotifications: string;
  pathApiChangeConfirmationStatus?: string;
  pathApiPermissionZones: string;
  pathApiAttachments: string;
  pathApiChangeWorkingConditions?: string;
  statusConfirmationId?: StatusInPortsType;
  ifHideConfirmCondition?: (
    row: INotificationInPortsGuest,
    currentUser: IACUser,
    subjectId: number
  ) => boolean;
  ifHideRejectCondition?: (
    row: INotificationInPortsGuest,
    currentUser: IACUser,
    subjectId: number
  ) => boolean;
  layoutSettingName: string;
  switchConfirmationStatuses?: boolean;
  profileType: ProfileType;
  noChangeStatus?: boolean;
  notificationPathName: string;
  disableAddButton?: boolean;
}

const ContentNotificationsInPorts: React.FC<IProps> = ({
  pathApiNotifications,
  pathApiChangeConfirmationStatus,
  pathApiPermissionZones,
  pathApiAttachments,
  pathApiChangeWorkingConditions,
  statusConfirmationId,
  ifHideConfirmCondition,
  ifHideRejectCondition,
  layoutSettingName,
  switchConfirmationStatuses,
  profileType,
  noChangeStatus,
  notificationPathName,
  disableAddButton,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "CurrentWeek")
  );
  const [endDate, setEndDate] = useState(
    getEndOfDate(new Date(), "CurrentWeek", { extraDays: 7 })
  );

  const [isActiveSwitchState, setIsActiveSwitchState] = useState(true);
  const IsActiveSwitch = (
    <div className="SwtichActive">
      {t("all_notification")}
      <SwtichStyled
        checked={isActiveSwitchState}
        onChange={() => setIsActiveSwitchState(!isActiveSwitchState)}
        name="checkedA"
      />
      {t("pre_approved")}
    </div>
  );

  const currentUser = useAppSelector(selectACSuperiorCurrentUser);
  const subjectId = useAppSelector(selectAuthUser)?.currentProfile.subjectId;
  const columns = useColumns(profileType);

  const modifyNotificationsResponseCallback = useCallback(
    (responseData: INotificationInPortsGuest[]) => {
      let modified = responseData.map((row, index) => {
        return {
          ...row,
          id: ++index,
          notificationNumber: row.notification.notificationNumber,
          dateFrom: row.notification.dateFrom,
          dateTo: row.notification.dateTo,
          companyName: row.notification.companyName,
          typeOfVisitName:
            row.notification.isToPfsoAndShiftDirector &&
            row.notification.typeOfVisitId === TypeOfVisit.BUSINESS
              ? t("application_for_training")
              : row.notification.typeOfVisitName,
          numberOfPeople: row.guests.length,
          confirmationStatusName:
            row.notification.confirmationStatusName ===
              "Wstępnie zatwierdzony" &&
            (profileType === ProfileType.EMPLOYEE ||
              profileType === ProfileType.SUPERIOR_AC ||
              profileType === ProfileType.OHS)
              ? "Do zatwierdzenia PFSO"
              : row.notification.confirmationStatusName,
          action: !noChangeStatus ? (
            ifHideConfirmCondition &&
            ifHideRejectCondition && (
              <OperationsButtons
                patchApi={`${pathApiChangeConfirmationStatus}/${row.notification.id}`}
                successCallback={() => setCallbackAfterChangeStatus(true)}
                newStatusIdConfirm={statusConfirmationId!}
                ifHideConfirm={ifHideConfirmCondition(
                  row,
                  currentUser,
                  subjectId
                )}
                ifHideReject={
                  ifHideRejectCondition(row, currentUser, subjectId)!
                }
                typeOfVisitId={row.notification.typeOfVisitId}
                permissionZoneId={row.notification.permissionZoneId}
                permissionZonesPatchApi={pathApiPermissionZones}
                prefixPathName="/notification"
                notificationId={row?.notification.id}
                pathApiNotifications={pathApiNotifications}
                isSecurity={false}
                isToPfsoAndShiftDirector={
                  row.notification.isToPfsoAndShiftDirector
                }
              />
            )
          ) : (
            <></>
          ),
          guestsFilter: row.guests
            .map((item) => ({
              firstName: item.guest.firstName,
              lastName: item.guest.lastName,
              toString: function () {
                return this.firstName + " " + this.lastName;
              },
            }))
            .join(";"),
          bh3Check: (
            <CheckboxStyled
              checked={row.notification.bH3 ? true : false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  profileType === ProfileType.PFSO ||
                  profileType === ProfileType.SHIFT_DIRECTOR
                ) {
                  setPatchNotificationId(row?.notification.id);
                  changeBH3(e.target.checked);
                }
              }}
            />
          ),
        };
      });

      if (switchConfirmationStatuses && isActiveSwitchState) {
        modified = modified.filter(
          (item) =>
            item.notification.confirmationStatus ===
            StatusInPortsType.PRE_APPROVED
        );
      } else if (switchConfirmationStatuses && !isActiveSwitchState) {
        modified = modified.filter(
          (item) =>
            item.notification.confirmationStatus !==
            StatusInPortsType.PRE_APPROVED
        );
      }

      return modified;
    },
    [
      currentUser,
      subjectId,
      ifHideConfirmCondition,
      ifHideRejectCondition,
      pathApiChangeConfirmationStatus,
      pathApiPermissionZones,
      statusConfirmationId,
      isActiveSwitchState,
      switchConfirmationStatuses,
      pathApiNotifications,
      profileType,
      noChangeStatus,
      t,
    ]
  );

  const [
    notificationsRows,
    fetchingStateNotifications,
    fetchAgainNotifications,
  ] = useFetchAndSetGET<INotificationInPortsGuest[]>({
    path: `${pathApiNotifications}?dateFrom=${convertDateFetch(
      startDate
    )}&dateTo=${convertDateFetch(endDate)}`,
    modifyResponseCallback: modifyNotificationsResponseCallback,
  });

  const [callbackAfterChangeStatus, setCallbackAfterChangeStatus] =
    useState(false);

  useEffect(() => {
    if (callbackAfterChangeStatus) {
      fetchAgainNotifications();
      setCallbackAfterChangeStatus(false);
    }
  }, [callbackAfterChangeStatus, fetchAgainNotifications]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchAgainNotifications();
  }, [startDate, endDate, fetchAgainNotifications]);

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainNotifications
  );

  const profileName = ProfileType[profileType]
    .toString()
    .toLowerCase()
    .replaceAll("_", "-");

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [deleteConfirmationDialogId, setDeleteConfirmationDialogId] = useState<
    number | undefined
  >(undefined);

  const [notificationIdToRemove, setNotificationIdToRemove] = useState<
    false | number
  >(false);

  const [, deleteNotificationAgain] = useFetchOtherThanGET({
    path: `${pathApiNotifications}/${notificationIdToRemove}`,
    method: "DELETE",
    body: notificationIdToRemove,
    setBody: setNotificationIdToRemove,
    successCallback: fetchAgainNotifications,
  });

  useEffect(() => {
    if (notificationIdToRemove) {
      deleteNotificationAgain();
    }
  }, [notificationIdToRemove, deleteNotificationAgain]);

  const repeatNotification = (notificationId: number) => {
    history.push({
      pathname: "/performer-add-notification",
      state: {
        successRedirectPath: `/${notificationPathName}`,
        notificationId: notificationId,
        profileName: profileName,
      },
    });
  };

  const removeNotification = (notificationId: number) => {
    setDeleteConfirmationDialogId(notificationId);
    setDeleteConfirmationDialogOpen(true);
  };

  const editNotification = (notificationId: number) => {
    history.push({
      pathname: "/performer-add-notification",
      state: {
        successRedirectPath: `/${notificationPathName}`,
        notificationId: notificationId,
        profileName: profileName,
        edit: true,
      },
    });
  };

  const confirmDelete = useCallback((idToRemove) => {
    setDeleteConfirmationDialogOpen(false);
    setNotificationIdToRemove(idToRemove);
  }, []);

  const cancelDelete = useCallback(() => {
    setDeleteConfirmationDialogOpen(false);
  }, []);

  //#region Change BH3
  const [patchNotificationId, setPatchNotificationId] = useState<
    false | number
  >(false);
  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [changeBH3FetchingState, changeBH3FetchAgain] = useFetchOtherThanGET({
    path: `${pathApiNotifications}/${patchNotificationId}`,
    method: "PATCH",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    successCallback: fetchAgainNotifications,
  });
  const changeBH3 = (checked: boolean) => {
    let body: PathProperty[] = [];

    body.push({
      path: "/notification/bh3",
      op: "replace",
      value: checked,
    });

    setBodyRequest(JSON.stringify(body));
  };

  useEffect(() => {
    if (bodyRequest) {
      changeBH3FetchAgain();
    }
  }, [bodyRequest, changeBH3FetchAgain]);
  //#endregion

  return (
    <div>
      <div>
        <TopBanner pathName={t("notifications")} />
      </div>
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            view={["year", "month", "date"]}
            darkTheme={true}
            keyboardDateTimePicker={true}
          ></DatePickerRange>
        </div>
        <div>
          <TableComponentMemo
            columns={columns}
            rows={notificationsRows}
            toolbarComponent={
              switchConfirmationStatuses ? IsActiveSwitch : <></>
            }
            layoutSettingName={layoutSettingName}
            ifAdd={!disableAddButton ? true : false}
            ifAddCustomAction={() => {
              history.push({
                pathname: "/performer-add-notification",
                state: {
                  successRedirectPath: `/${notificationPathName}`,
                },
              });
            }}
            actionsColumnUpdated={{
              redoButton: {
                ifShow: true,
                name: t("redo"),
                customComponent: (row: INotificationInPortsGuest) => {
                  return (
                    <>
                      {!row.guests[0].isLetIn && (
                        <Button>
                          <FontAwesomeIcon
                            icon={faRedoAlt}
                            size="lg"
                            title={t("edit")}
                            style={{ padding: "5px" }}
                            onClick={() =>
                              repeatNotification(row.notification.id)
                            }
                          />
                        </Button>
                      )}
                    </>
                  );
                },
              },

              removeButton: {
                ifShow: true,
                name: t("delete"),
                customComponent: (row: INotificationInPortsGuest) => {
                  return (
                    <>
                      {!row.guests[0].isLetIn && (
                        <Button>
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            title={t("delete")}
                            style={{ padding: "5px" }}
                            onClick={() =>
                              removeNotification(row.notification.id)
                            }
                          />
                        </Button>
                      )}
                    </>
                  );
                },
              },
              editButton: {
                ifShow: true,
                name: t("edit"),
                customComponent: (row: INotificationInPortsGuest) => {
                  return (
                    <>
                      {!row.guests[0].isLetIn &&
                        (profileType === ProfileType.PFSO ||
                          profileType === ProfileType.EMPLOYEE ||
                          profileType === ProfileType.OPERATIONAL_SUPPORT) && (
                          <Button>
                            <FontAwesomeIcon
                              icon={faEdit}
                              size="lg"
                              title={t("edit")}
                              style={{ padding: "5px" }}
                              onClick={() =>
                                editNotification(row.notification.id)
                              }
                            />
                          </Button>
                        )}
                    </>
                  );
                },
              },
            }}
            fetchingState={{
              isFetching:
                fetchingStateNotifications.isFetching ||
                changeBH3FetchingState.isFetching ||
                notificationsRows === null,
              isError: fetchingStateNotifications.isError,
              fetchAgain: fetchAgainNotifications,
            }}
            ifDelete={true}
            ifEdit={false}
            rowDetail={(data) => (
              <RowDetailNotificationInPorts
                row={data?.row}
                successCallback={fetchAgainNotifications}
                pathApiPermissionZones={pathApiPermissionZones}
                pathApiNotifications={pathApiNotifications}
                pathApiAttachments={pathApiAttachments}
                pathApiChangeWorkingConditions={pathApiChangeWorkingConditions}
                disableEditPermissionZone={false}
                refreshData={fetchAgainNotifications}
              />
            )}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
            tableEditColumnWidth={200}
            columnsWidth={[
              {
                columnName: "action",
                width: 200,
              },
            ]}
          />

          <ConfirmOperationDialog
            id={deleteConfirmationDialogId}
            open={deleteConfirmationDialogOpen}
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            message={t("do_you_want_to_delete_notification")}
            confirmButtonText={t("delete")}
            confirmButtonColor={"red"}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentNotificationsInPorts;
