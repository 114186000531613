import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import ButtonStyled from "../Button/button";
import { AutocompleteFormik, TextFieldFormik } from "../FormikInputs";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../PopupHelpers";
import getInitailValues from "./Formik/getInitialValues";
import getValidationSchema from "./Formik/getValidationSchema";
import GuestForm from "./guestForm";
import styles from "./styles.module.scss";

interface IProps {
  isPopupOpen: boolean;
  onSubmit: (values: PopupEntryUnauthorizedFormValues) => void;
  ifRegistrationNumber: boolean;
  permissionZonesPathApi: string;
  typesOfVisitPathApi: string;
}

const PopupDialogContent: React.FC<IProps> = ({
  isPopupOpen,
  onSubmit,
  ifRegistrationNumber,
  permissionZonesPathApi,
  typesOfVisitPathApi,
}) => {
  const { t } = useTranslation();

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path: permissionZonesPathApi,
  });

  const [typesOfVisits, fetchingStateTypesOfVisits, fetchAgainTypesOfVisits] =
    useFetchAndSetGET({
      path: typesOfVisitPathApi,
    });

  return (
    <LoadingWrapper
      isLodadingProgress={
        fetchingStatePermissionZones.isFetching ||
        fetchingStateTypesOfVisits.isFetching
      }
      isError={
        fetchingStatePermissionZones.isError ||
        fetchingStateTypesOfVisits.isError
      }
      setIfFetchAgain={() => {
        if (fetchingStatePermissionZones.isError) {
          fetchAgainPermissionZones();
        }
        if (fetchingStateTypesOfVisits.isError) {
          fetchAgainTypesOfVisits();
        }
      }}
    >
      {permissionZones && typesOfVisits && (
        <DialogContentStyled titleWidth={200}>
          <Formik
            initialValues={getInitailValues()}
            onSubmit={(values: PopupEntryUnauthorizedFormValues) => {
              onSubmit(values);
            }}
            validationSchema={getValidationSchema(t, ifRegistrationNumber)}
          >
            {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
              return (
                <Form noValidate id="submitForm">
                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("company")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("company")}
                          ifToUpperCaseShipsAndPorts={true}
                          name="companyName"
                          type="text"
                          required={true}
                        />
                      </RowInput>
                    </Row>
                    <Row>
                      <RowTitle>{t("type_of_visit")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={typesOfVisits}
                          getOptionLabel={(option: any) => {
                            return option.name;
                          }}
                          width="100%"
                          getOptionSelected={(
                            option: TypeOfVisitInterface,
                            value: TypeOfVisitInterface
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("type_of_visit")}
                          name="typeOfVisit"
                          required={true}
                        />
                      </RowInput>
                    </Row>
                  </TwoColumnsRowsContainer>

                  <TwoColumnsRowsContainer>
                    <Row>
                      <RowTitle>{t("permission_zone")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={permissionZones}
                          getOptionLabel={(option: PermissionZone) => {
                            return option.name;
                          }}
                          width="100%"
                          getOptionSelected={(
                            option: PermissionZone,
                            value: PermissionZone
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("select_permission_zone")}
                          name="permissionZone"
                          required={true}
                        />
                      </RowInput>
                    </Row>

                    {ifRegistrationNumber ? (
                      <Row>
                        <RowTitle>{t("plate_number")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            label={t("plate_number")}
                            ifToUpperCaseShipsAndPorts={true}
                            name="registrationNumber"
                            type="text"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    ) : (
                      <Row>
                        <RowTitle>{t("comments")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            label={t("comments")}
                            name="comments"
                            type="text"
                            rows={3}
                            multiline
                          />
                        </RowInput>
                      </Row>
                    )}
                  </TwoColumnsRowsContainer>

                  {ifRegistrationNumber && (
                    <Row>
                      <RowTitle>{t("comments")}:</RowTitle>
                      <RowInput>
                        <TextFieldFormik
                          label={t("comments")}
                          name="comments"
                          type="text"
                          rows={3}
                          multiline
                        />
                      </RowInput>
                    </Row>
                  )}

                  <FieldArray
                    name="guestsArray"
                    render={(arrayHelpers) => (
                      <>
                        {values.guestsArray.map((guestData, index) => {
                          return (
                            <React.Fragment key={index}>
                              <GuestForm
                                guestIndex={index}
                                arrayHelpers={arrayHelpers}
                                numberOfGuests={values.guestsArray.length}
                                guestsArray={values.guestsArray}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                setFieldTouched={setFieldTouched}
                                isPopupOpen={isPopupOpen}
                              />
                            </React.Fragment>
                          );
                        })}

                        <div className={styles.addAnotherGuest}>
                          <ButtonStyled
                            onClick={() =>
                              arrayHelpers.push({
                                firstName: "",
                                lastName: "",
                                email: "",
                                dateOfBirth: null,
                                idNumber: "",
                                cardNumber: "",
                                isTrainingNotRequired: false,
                                trainingExpiryDate: null,
                              })
                            }
                            type="button"
                          >
                            {t("add_another_person")}{" "}
                            <FontAwesomeIcon icon={faPlus} />
                          </ButtonStyled>
                        </div>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
        </DialogContentStyled>
      )}
    </LoadingWrapper>
  );
};

export default PopupDialogContent;
