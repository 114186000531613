import React from "react";
import { useTranslation } from "react-i18next";
import {
  selectAllMaps,
  selectCurrentAreaDetailsId,
} from "../../../../reducers/visualization/visualization";
import { useAppSelector } from "../../../../store/hooks";
import { TextFieldStyled } from "../../../helpersComponents/MaterialUi";
import {
  Row,
  RowInput,
  RowTitle,
} from "../../../helpersComponents/PopupHelpers";
import styles from "./areaDetails.module.scss";

export default function AreaDetails() {
  const currentAreaDetailsId = useAppSelector(selectCurrentAreaDetailsId);
  const allMaps = useAppSelector(selectAllMaps);
  const area = allMaps[currentAreaDetailsId];
  const { t } = useTranslation();

  return (
    <div className={styles.singleAreaRoot}>
      <div className={styles.title}>{t("Szczegóły obszaru")}</div>
      <div className={styles.detailsContent}>
        <Row>
          <RowTitle>{t("name")}</RowTitle>
          <RowInput>
            <TextFieldStyled value={area?.name} />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>Typ obszaru</RowTitle>
          <RowInput>
            <TextFieldStyled value={"Mapa"} />
          </RowInput>
        </Row>
        <Row>
          <RowTitle>Inna właściwość</RowTitle>
          <RowInput>
            <TextFieldStyled value={"wartość"} />
          </RowInput>
        </Row>
      </div>
    </div>
  );
}
