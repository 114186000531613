import { useTranslation } from "react-i18next";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AccordionStyled from "../MaterialUi/Accordion/AccordionStyled";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { useCallback, useEffect, useState } from "react";
import { selectAuthUser } from "./../../../reducers/session";
import { useAppSelector } from "./../../../store/hooks";
import { ProfileType } from "../../../enums/profileType";

interface IGuest {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  email: string;
  trainingExpiryDate: Date | null;
  addingPersonId: number;
  createdAt: Date;
  companyName: string;
  iDNumber: string;
}

interface IGuestInfo {
  notificationGuestId: number;
  guest: IGuest;
  letIn: boolean | null;
  securityFullName: string | null;
  securityDateConfirmation: Date | null;
  securityRejectionReason: string;
}

interface IProps {
  notificationId: number;
  guestsInfo: IGuestInfo[];
  defaultExpanded?: boolean;
  onlyNames?: boolean;
  refreshData?: () => void;
}

const GuestsList: React.FC<IProps> = ({
  notificationId,
  guestsInfo,
  defaultExpanded,
  onlyNames,
  refreshData,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const { confirm } = useConfirm();

  const [notificationGuestIdToRemove, setNotificationGuestIdToRemove] =
    useState<false | number>(false);

  const successCallback = useCallback(
    (response: UseFetchResponse) => {
      if (refreshData) {
        refreshData();
      }
    },
    [refreshData]
  );

  const [
    fetchingStateRemovePersonFromNotification,
    fetchAgainRemovePersonFromNotification,
  ] = useFetchOtherThanGET({
    path: `employee/notification-guests-in-ports/${notificationId}/guests/${notificationGuestIdToRemove}`,
    method: "DELETE",
    body: notificationGuestIdToRemove,
    setBody: setNotificationGuestIdToRemove,
    forceSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (notificationGuestIdToRemove !== false) {
      fetchAgainRemovePersonFromNotification();
    }
  }, [notificationGuestIdToRemove, fetchAgainRemovePersonFromNotification]);

  return (
    <>
      {!fetchingStateRemovePersonFromNotification.isFetching && (
        <AccordionStyled
          title={`${t("persons")} (${guestsInfo.length})`}
          defaultExpanded={defaultExpanded}
        >
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("first_name")}</th>
                  <th>{t("last_name")}</th>
                  {!onlyNames && <th>{t("email")}</th>}
                  {!onlyNames && <th>{t("date_of_birth")}</th>}
                  {!onlyNames && <th>{t("training_expiry_date")}</th>}
                  <th>{t("is_letted_in")}</th>
                  <th>{t("rejection_reason")}</th>

                  {authUser.currentProfile?.type === ProfileType.EMPLOYEE && (
                    <th>{t("operations")}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {guestsInfo.map((item) => {
                  const guest = item.guest;

                  return (
                    <tr
                      key={guest.id}
                      className={
                        item.letIn === true
                          ? styles.lettedIn
                          : item.letIn === false
                          ? styles.notLettedIn
                          : ""
                      }
                    >
                      <td>{guest.firstName}</td>
                      <td>{guest.lastName}</td>
                      {!onlyNames && (
                        <>
                          <td>{guest.email ? guest.email : "-"}</td>
                          <td>
                            {guest.dateOfBirth
                              ? toLocaleStringCustom({
                                  date: guest.dateOfBirth,
                                  t,
                                })
                              : "-"}
                          </td>
                          <td>
                            {guest.trainingExpiryDate
                              ? toLocaleStringCustom({
                                  date: guest.trainingExpiryDate,
                                  t,
                                })
                              : "-"}
                          </td>
                        </>
                      )}
                      <td>
                        {item.letIn !== null
                          ? item.letIn === true
                            ? `${t("yes").toUpperCase()} (${t("by")} ${
                                item.securityFullName
                              })`
                            : `${t("no").toUpperCase()} (${t("by")} ${
                                item.securityFullName
                              })`
                          : "-"}
                      </td>
                      <td>
                        {!item.securityRejectionReason.includes("null")
                          ? item.securityRejectionReason.replaceAll("null", "")
                          : "-"}
                      </td>

                      {authUser.currentProfile?.type ===
                        ProfileType.EMPLOYEE && (
                        <td>
                          <FontAwesomeIcon
                            className="faTrash"
                            icon={faTimesCircle}
                            onClick={async () => {
                              let selectedbuttonId = await confirm({
                                text: `${t(
                                  "are_you_sure_you_want_to_remove_person_from_notification"
                                )}`,
                                buttons: [
                                  { buttonName: t("remove"), buttonId: 0 },
                                  { buttonName: t("cancel"), buttonId: 1 },
                                ],
                              });
                              if ((await selectedbuttonId) === 0) {
                                setNotificationGuestIdToRemove(
                                  item.notificationGuestId
                                );
                              }
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </AccordionStyled>
      )}
    </>
  );
};

export default GuestsList;
