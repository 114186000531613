import React from 'react';
import HolidayContent from "./holidayContent"


function Holiday() {
    return (
        <div>
            <HolidayContent/>
        </div>
    );
}

export default Holiday;
