import { combineReducers } from "redux";
import sessionState from "./session";
import cachedFetchState from "./cachedFetch";
import settingsState from "./settings";
import advancedOptionsState from "./advancedOptions";
import workersLists from "./workersLists";
import useCoffirmState from "./useConfirm";
import checkIfNewMessages from "./checkIfNewMessages";
import profiles from "./profiles";
import visualization from "./visualization/visualization";
import creatingNewArea from "./visualization/creatingNewArea";
import homePage from "./homePage";
import homePageSuperior from "./homePageSuperior/homePageSuperior";
import isOpenLeftNavbar from "./isOpenLeftNavbar";
import cardTagReader from "./cardTagReader/cardTagReader";
import acSuperiorCurrentUser from "./acSuperiorCurrentUser/acSuperiorCurrentUser";
import notificationsSummary from "./notificationsSummary";

const appReducer = combineReducers({
  sessionState,
  cachedFetchState,
  settingsState,
  advancedOptionsState,
  workersLists,
  checkIfNewMessages,
  profiles,
  useCoffirmState,
  visualization,
  creatingNewArea,
  homePage,
  homePageSuperior,
  isOpenLeftNavbar,
  cardTagReader,
  acSuperiorCurrentUser,
  notificationsSummary,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
