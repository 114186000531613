import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useState } from "react";
import styles from "./controlPanel.module.scss";
import L from "leaflet";
import changeCurrentMapOverlay from "../helperFunctions/changeCurrentMapOverlay";
import Button from "../../../helpersComponents/Button/button";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectAllMaps,
  selectCurrentMapId,
  setCurrentAreaDetailsId,
  setCurrentMapId,
} from "../../../../reducers/visualization/visualization";
import { isObjectEmpty } from "../../../../HelpersFunctions/isObjectEmpty";

interface Props {
  Lmap: L.Map;
  currentMapOverlayRef: React.MutableRefObject<L.ImageOverlay>;
}

export default function ControlPanel({
  Lmap,
  currentMapOverlayRef,
}: Props): ReactElement {
  const [ifShowContent, setifShowContent] = useState<boolean>(false);
  const allMaps = useAppSelector(selectAllMaps);
  const currentMapId = useAppSelector(selectCurrentMapId);
  const currentMap = allMaps[currentMapId];
  const dispatch = useAppDispatch();

  const selectMapElement = (mapsIds: number[]) => {
    return (
      <>
        {mapsIds.map((mapId) => {
          let map: MapInterface = allMaps[mapId];
          if (!map) return null;
          let mapsIdsInside: number[] = [];
          map.mapsInside.forEach((mapInsideId) => {
            mapsIdsInside.push(mapInsideId.mapId);
          });
          return (
            <React.Fragment key={mapId}>
              <div className={styles.mapButtonContainer} key={mapId}>
                <div className={styles.currentMapCircle}>
                  {currentMapId === map.id && (
                    <FontAwesomeIcon icon={faCircle} />
                  )}
                </div>
                <div className={styles.selectMapButton}>
                  <Button
                    onClick={async () => {
                      if (Lmap) {
                        await changeCurrentMapOverlay({
                          Lmap,
                          mapBase64: map.base64,
                          currentMapOverlayRef,
                        });
                        dispatch(setCurrentMapId(map.id));
                        dispatch(setCurrentAreaDetailsId(map.id));
                      }
                    }}
                  >
                    {map.name}
                  </Button>
                </div>
              </div>
              <>
                {map.mapsInside.length !== 0 && (
                  <div className={styles.newRowMarginLeft}>
                    {selectMapElement(mapsIdsInside)}
                  </div>
                )}
              </>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div className={"leaflet-top leaflet-right"}>
      <div
        className={"leaflet-control leaflet-bar " + styles.contentRoot}
        onMouseEnter={() => setifShowContent(true)}
        onMouseLeave={() => setifShowContent(false)}
      >
        <div className={styles.selectMap}>
          {currentMap?.name ? currentMap.name : "Brak mapy"}
        </div>

        <div className={` ${ifShowContent ? styles.show : styles.hide}`}>
          {isObjectEmpty(allMaps) && <>Brak map</>}
          {selectMapElement([1])}
          {/* {Object.keys(allMaps).map((mapId) => {
            let map: MapInterface = allMaps[mapId];
            return selectMapElement(map, parseInt(mapId));
          })} */}
        </div>
      </div>
    </div>
  );
}
