import React, { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  selectIfOpenPopupNewArea,
  seIfOpenPopupNewArea,
} from "../../../../reducers/visualization/creatingNewArea";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../../helpersComponents/PopupHelpers";
import NewMapPopupContent from "./newMapPopupContent";

export default function NewRoomPopup(): ReactElement {
  const [
    isLoading,
    // setIsLoading
  ] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [, setIfFetch] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const closDialog = () => dispatch(seIfOpenPopupNewArea(false));
  interface RefInterface {
    confirm: () => void;
  }
  const confirmRef = useRef<RefInterface>(null);

  return (
    <div>
      <DialogStyled
        open={useAppSelector(selectIfOpenPopupNewArea) === "map"}
        onClose={closDialog}
      >
        <DialogTitleStyled title={t("adding_new_map")} />
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={setIfFetch}
          setIsError={setIsError}
          bigSize={true}
        >
          {!isLoading && !isError && (
            <NewMapPopupContent ref={confirmRef} close={closDialog} />
          )}
        </LoadingWrapper>
        <DialogActionsStyled
          onCancel={closDialog}
          onConfirm={() => {
            if (confirmRef.current !== null) {
              confirmRef.current.confirm();
            }
          }}
        />
      </DialogStyled>
    </div>
  );
}
