import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileType } from "../../../enums/profileType";
import convertDateFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useRefreshData from "../../../hooks/useRefreshData/useRefreshData";
import ImportFile from "../../agent/ImportFile";
import DatePickerComponent from "../MaterialUi/DatePicker/datePicker";
import TableComponentMemo from "../TableComponent/tableComponent";
import { TopBanner } from "../TopBanner/topBanner";
import PopupSupport from "./PopupSupport";
import RowDetailSupport from "./RowDetailSupport";
import styles from "./styles.module.scss";
import useColumns from "./useColumns";

interface IProps {
  pathApiListsOfEmployees: string;
  pathApiImportFromFile?: string;
  pathApiListOfEmployeesEmploye?: string;
  layoutSettingName: string;
  profileType: ProfileType;
}

const SupportContent: React.FC<IProps> = ({
  pathApiListsOfEmployees,
  pathApiImportFromFile,
  pathApiListOfEmployeesEmploye,
  layoutSettingName,
  profileType,
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Day")
  );

  const columns = useColumns(profileType);

  const modifyResponseCallback = useCallback(
    (responseData: IListOfEmployees[]) => {
      const modified = responseData.map((row) => {
        return {
          ...row,
          numberOfEmployees: row.employess?.length,
          timeTo: row.timeTo ? row.timeTo : "-",
          companies: [
            ...new Set(row.employess.map((employee) => employee.company)),
          ].join("; "),
          employeesFilter: row.employess
            .map((item) => ({
              employeeFirstName: item.employeeFirstName,
              employeeLastName: item.employeeLastName,
              toString: function () {
                return this.employeeFirstName + " " + this.employeeLastName;
              },
            }))
            .join(";"),
        };
      });
      return modified;
    },
    []
  );

  const [
    listsOfEmployeesRows,
    fetchingStateListsOfEmployees,
    fetchAgainListsOfEmployees,
  ] = useFetchAndSetGET<IListOfEmployees[]>({
    path: `${pathApiListsOfEmployees}?date=${convertDateFetch(startDate)}`,
    startFetchOnInitial: false,
    modifyResponseCallback: modifyResponseCallback,
  });

  useEffect(() => {
    fetchAgainListsOfEmployees();
  }, [startDate, fetchAgainListsOfEmployees]);

  const [listOfEmployeesIdToRemove, setListOfEmployeesIdToRemove] = useState<
    false | number
  >(false);

  const [removeFetchingState, removeFetchAgain] = useFetchOtherThanGET({
    path: `${pathApiListsOfEmployees}/${listOfEmployeesIdToRemove}`,
    method: "DELETE",
    body: listOfEmployeesIdToRemove,
    setBody: setListOfEmployeesIdToRemove,
    successCallback: fetchAgainListsOfEmployees,
  });

  useEffect(() => {
    if (listOfEmployeesIdToRemove !== false) {
      removeFetchAgain();
    }
  }, [listOfEmployeesIdToRemove, removeFetchAgain]);

  const exampleFile = {
    name: "wsparcie_example",
    filePath: "/Import_wsparcia_przyklad.xls",
  };

  const { confirm } = useConfirm();

  const { refreshingTime, handleChangeRefreshingTime } = useRefreshData(
    fetchAgainListsOfEmployees
  );

  return (
    <div>
      <div>
        <TopBanner pathName={t("support")} />
      </div>
      <div className="flexAndCenter">
        <div className={styles.datePickerContainer}>
          <DatePickerComponent
            format="dd-MM-yyyy"
            date={startDate}
            setDate={setStartDate}
            view={["year", "month", "date"]}
            darkTheme={true}
          />
          <div className={styles.importFileContainer}>
            {pathApiImportFromFile && (
              <ImportFile
                setFetchAgain={fetchAgainListsOfEmployees}
                exampleFile={exampleFile}
                address={pathApiImportFromFile}
              />
            )}
          </div>
        </div>

        <div>
          <TableComponentMemo
            columns={columns}
            rows={listsOfEmployeesRows}
            layoutSettingName={layoutSettingName}
            rowDetail={(props) => (
              <RowDetailSupport
                pathApiListsOfEmployees={pathApiListsOfEmployees}
                pathApiListOfEmployeesEmploye={pathApiListOfEmployeesEmploye}
                fetchAgainListsOfEmployees={fetchAgainListsOfEmployees}
                profileType={profileType}
                {...props}
              />
            )}
            fetchingState={{
              isFetching:
                fetchingStateListsOfEmployees.isFetching ||
                removeFetchingState.isFetching,
              isError: fetchingStateListsOfEmployees.isError,
              fetchAgain: fetchAgainListsOfEmployees,
            }}
            tableEditColumnWidth={
              profileType !== ProfileType.OPERATIONAL_SUPPORT ? 100 : 120
            }
            actionsColumnUpdated={{
              popup: (props) => {
                return (
                  <PopupSupport
                    pathApiListOfEmployeesEmploye={
                      pathApiListOfEmployeesEmploye
                    }
                    fetchAgainListsOfEmployees={fetchAgainListsOfEmployees}
                    {...props}
                  />
                );
              },
              successCallbackPopup: fetchAgainListsOfEmployees,
              addButton: {
                ifShow:
                  profileType === ProfileType.OPERATIONAL_SUPPORT
                    ? true
                    : false,
              },
              removeButton: {
                ifShow:
                  profileType === ProfileType.OPERATIONAL_SUPPORT
                    ? true
                    : false,
                customComponent(row: IListOfEmployees) {
                  return row.employess.find((emp) => emp.isLetIn !== null) ? (
                    <></>
                  ) : (
                    <Button
                      onClick={async () => {
                        let selectedbuttonId = await confirm({
                          text: t("confirm_delete_element"),
                          buttons: [
                            { buttonName: t("yes"), buttonId: 0 },
                            { buttonName: t("no"), buttonId: 1 },
                          ],
                          disableBackdropClick: true,
                        });

                        if (selectedbuttonId === 0) {
                          setListOfEmployeesIdToRemove(row.id);
                        }
                      }}
                    >
                      {t("remove")}
                    </Button>
                  );
                },
              },
            }}
            refreshingButton={{
              ifShow: true,
              refreshingTime: refreshingTime?.value,
              handleChangeRefreshingTime,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportContent;
