import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { ProfileType } from "../../../enums/profileType";
import ContentNotificationsInPorts from "../../helpersComponents/NotificationsInPorts/ContentNotificationsInPorts";

const NotificationInPortsShiftDirector = () => {
  return (
    <ContentNotificationsInPorts
      pathApiNotifications="shift-director/notification-guests-in-ports"
      pathApiChangeConfirmationStatus="shift-director/notification-change-status"
      pathApiPermissionZones="shift-director/permission-zones?isActive=true&forEmployee=true"
      pathApiAttachments="shift-director/attachments-in-ports"
      pathApiChangeWorkingConditions="shift-director/notification-change-working-conditions"
      layoutSettingName="layoutShiftDirectorNotificationsRecords"
      statusConfirmationId={StatusInPortsType.APPROVED}
      profileType={ProfileType.SHIFT_DIRECTOR}
      notificationPathName="shift-director/notifications"
      disableAddButton={false}
      ifHideConfirmCondition={(row, user, subjectId) => {
        return (
          row.notification.confirmationStatus === StatusInPortsType.APPROVED ||
          (row.notification.pfsoConfirmationId != null &&
            row.notification.pfsoConfirmationId !== subjectId) ||
          (row.notification.shiftDirectorConfirmationId != null &&
            row.notification.shiftDirectorConfirmationId !== subjectId) ||
          row.guests.find((guest) => guest.isLetIn) != null
        );
      }}
      ifHideRejectCondition={(row, user, subjectId) => {
        return (
          row.notification.confirmationStatus === StatusInPortsType.REJECTED ||
          (row.notification.pfsoConfirmationId != null &&
            row.notification.pfsoConfirmationId !== subjectId) ||
          (row.notification.shiftDirectorConfirmationId != null &&
            row.notification.shiftDirectorConfirmationId !== subjectId) ||
          row.guests.find((guest) => guest.isLetIn) != null
        );
      }}
    />
  );
};

export default NotificationInPortsShiftDirector;
