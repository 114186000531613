import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
// import { current } from "@reduxjs/toolkit";

interface stateInterface {
  currentMapId: number;
  allMaps: {
    [key in number]: MapInterface;
  };
  isEditMode: boolean;
  currentAreaDetailsId: number;
}

const initialState: stateInterface = {
  allMaps: JSON.parse(localStorage.getItem("allMaps") || "{}"),
  currentMapId: 1,
  isEditMode: false,
  currentAreaDetailsId: 1,
};

export const visualizationReducer = createSlice({
  name: "visualization",
  initialState,
  reducers: {
    setCurrentMapId: (state, action: PayloadAction<number>) => {
      state.currentMapId = action.payload;
    },
    setAddUpdateMapToAllMaps: (state, action: PayloadAction<MapInterface>) => {
      state.allMaps[action.payload.id] = action.payload;
      localStorage.setItem("allMaps", JSON.stringify(state.allMaps));
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    setCurrentAreaDetailsId: (state, action: PayloadAction<number>) => {
      state.currentAreaDetailsId = action.payload;
    },
    setAddNewMapInsideMap: (
      state,
      action: PayloadAction<{ mapId: number; mapPoints: L.LatLngTuple[] }>
    ) => {
      if (!state.allMaps[state.currentMapId]) return;
      state.allMaps[state.currentMapId].mapsInside.push({
        mapId: action.payload.mapId,
        points: action.payload.mapPoints,
      });
      localStorage.setItem("allMaps", JSON.stringify(state.allMaps));
    },
  },
});

export const selectCurrentMapId = (state: RootState) =>
  state.visualization.currentMapId;
export const selectIsEditMode = (state: RootState) =>
  state.visualization.isEditMode;
export const selectCurrentAreaDetailsId = (state: RootState) =>
  state.visualization.currentAreaDetailsId;
export const selectAllMaps = (state: RootState) => state.visualization.allMaps;
export const {
  setCurrentMapId,
  setAddUpdateMapToAllMaps,
  setAddNewMapInsideMap,
  setIsEditMode,
  setCurrentAreaDetailsId,
} = visualizationReducer.actions;
export default visualizationReducer.reducer;
