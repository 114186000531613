import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ActionsColumn } from "./type";
import styles from "./ConfirmRemoveActions.module.scss";

let Cell: any = TableEditColumn.Cell;

const ConfirmRemoveActions: any = ({
  row,
  actionsData,
  setPopupUpdatedState,
  rowMenu,
  paddingValue,
}: any) => {
  const { t } = useTranslation();
  let actionsDataLocal: ActionsColumn = actionsData;
  return (
    <Cell
      className={styles.tableActionsButtons}
      style={{
        padding: paddingValue !== undefined ? paddingValue : "",
      }}
    >
      <div className={styles.container}>
        <div className={styles.childContainer}>
          <div>
            {actionsDataLocal.redoButton?.customComponent &&
            actionsDataLocal.redoButton.ifShow ? (
              <>{actionsDataLocal.redoButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.redoButton?.ifShow ? "" : "none",
                }}
                onClick={() => {
                  if (actionsDataLocal.redoButton?.onClick) {
                    actionsDataLocal.redoButton.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                {actionsDataLocal.redoButton?.name
                  ? actionsDataLocal.redoButton.name
                  : t("confirm")}
              </Button>
            )}
          </div>

          <div>
            {actionsDataLocal.removeButton?.customComponent &&
            actionsDataLocal.removeButton.ifShow ? (
              <>{actionsDataLocal.removeButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.removeButton?.ifShow ? "" : "none",
                }}
                onClick={() => {
                  if (actionsDataLocal.removeButton?.onClick)
                    actionsDataLocal.removeButton.onClick(row);
                }}
              >
                {actionsDataLocal.removeButton?.name
                  ? actionsDataLocal.removeButton.name
                  : t("remove")}
              </Button>
            )}
          </div>

          <div>
            {actionsDataLocal.editButton?.customComponent &&
            actionsDataLocal.editButton.ifShow ? (
              <>{actionsDataLocal.editButton.customComponent(row)}</>
            ) : (
              <Button
                style={{
                  display: actionsDataLocal.editButton?.ifShow ? "" : "none",
                }}
                onClick={() => {
                  if (actionsDataLocal.editButton?.onClick) {
                    actionsDataLocal.editButton.onClick(row);
                  } else {
                    setPopupUpdatedState({
                      isOpen: true,
                      rowData: row,
                    });
                  }
                }}
              >
                {actionsDataLocal.editButton?.name
                  ? actionsDataLocal.editButton.name
                  : t("confirm")}
              </Button>
            )}
          </div>

          {rowMenu?.ifShow && (
            <div className={styles.menuContainer}>{rowMenu.menu(row)}</div>
          )}
        </div>
      </div>
    </Cell>
  );
};
export default ConfirmRemoveActions;
