import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getBeginningOfDate from "../../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import DatePickerRange from "../../../../helpersComponents/DatePickerRange/datePickerRange";
import TableComponent from "../../../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../../../helpersComponents/TopBanner/topBanner";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import convertDateFetch from "../../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import requestStatusName from "../../../../../HelpersFunctions/requestStatusName";
import { CircularProgress } from "@material-ui/core";
import Button from "../../../../helpersComponents/Button/button";
import styles from "./delegationsList.module.scss";
import delegationPurposeName from "../../../../../HelpersFunctions/delegationPurposeName";
import useParallelFetchJson from "../../../../../hooks/fetchHooks/parallelJSON/useParallelFetchJson";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { SnackbarStyled } from "../../../../helpersComponents/MaterialUi";

function DelegationsListContent() {
  const { t } = useTranslation();

  let currentLanguage = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  if (currentLanguage) {
    currentLanguage = currentLanguage[0];
  } else {
    currentLanguage = "pl";
  }

  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Year")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Year"));
  const [bodyPatch, setBodyPatch] = useState<any>(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [delegationsUrl, setDelegationsUrl] = useState<string | boolean>(false);
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const result = useParallelFetchJson(
    "PATCH",
    "superior-time/delegations",
    bodyPatch,
    selectedRows
  );

  const columns = [
    {
      name: "createdAt",
      title: t("data_created"),
      type: "date_hours",
    },
    {
      name: "delegationNumber",
      title: t("delagations_number"),
    },
    { name: "startDate", title: t("being_date"), type: "date" },
    { name: "endDate", title: t("ending_time"), type: "date" },
    {
      name: "person",
      title: t("employee"),
    },

    {
      name: "startCityName",
      title: t("start_city"),
    },
    {
      name: "endCityName",
      title: t("end_city"),
    },
    {
      name: "countryName",
      title: t("country"),
    },
    {
      name: "delegationPurpose",
      title: t("trip_target"),
      customFormatter: (row: TimeSuperiorDelegationRow) => (
        <p>{delegationPurposeName(row.delegationPurposeId)}</p>
      ),
    },
    {
      name: "cityName",
      title: t("ending_city"),
    },
    {
      name: "status",
      title: t("status"),
      customFormatter: (row: TimeSuperiorDelegationRow) => (
        <p>{requestStatusName(row.status)}</p>
      ),
    },
    {
      name: "approverName",
      title: t("approved_by"),
    },
    {
      name: "approvedAt",
      title: t("approved_rejected"),
    },
    {
      name: "secondApproverName",
      title: t("finnal_approved_by"),
    },
  ];

  const [rows, setRows] = useState<TimeSuperiorDelegationRow[]>([]);

  const delegationsResponseCallback = useCallback(
    (responseData: FetchedDelegation[] | null) => {
      if (!responseData) {
        setRows([]);
        return;
      }

      const modified: TimeSuperiorDelegationRow[] = responseData?.map(
        (fetchedDelegation: FetchedDelegation) => {
          let delegationRow: TimeSuperiorDelegationRow = {
            id: fetchedDelegation.id,
            createdAt: fetchedDelegation.createdAt,
            delegationNumber: fetchedDelegation.delegationNumber,
            startDate: fetchedDelegation.startDate,
            endDate: fetchedDelegation.endDate,
            person: `${fetchedDelegation.firstName} ${fetchedDelegation.lastName}`,
            startCityName: fetchedDelegation.startCityName ?? "",
            endCityName: fetchedDelegation.endCityName ?? "",
            countryName: fetchedDelegation.countryName ?? "",
            delegationPurposeId: fetchedDelegation.delegationPurposeId,
            cityName: fetchedDelegation.cityName ?? "",
            status: fetchedDelegation.status,
            approverName: fetchedDelegation.approverName ?? "",
            approvedAt: fetchedDelegation.approvedAt,
            secondApproverName: fetchedDelegation.secondApproverName ?? "",
          };

          return delegationRow;
        }
      );

      setRows(modified);
      return modified;
    },
    []
  );

  const [, delegationsFetchingState, delegationsFetchAgain] =
    useFetchAndSetGET<FetchedDelegation>({
      path: delegationsUrl ? delegationsUrl.toString() : "",
      startFetchOnInitial: false,
      modifyResponseCallback: delegationsResponseCallback,
    });

  useEffect(() => {
    if (result?.isFetching) return;
    setBodyPatch(false);

    if (result.isAllGood) {
      setSnackbarState([true, "success", t("actions_completed_successfully")]);
      delegationsFetchAgain();
    } else if (result.isAllFail) {
      setSnackbarState([true, "error", t("all_actions_failed")]);
    } else {
      setSnackbarState([true, "warning", t("not_all_actions_were_successful")]);
    }
  }, [result, delegationsFetchAgain, t]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {delegationsFetchingState.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <>
            <Button
              className={styles.filterButton}
              onClick={() => {
                filterRange();
              }}
            >
              {t("filter")}
            </Button>

            <Button
              className={styles.filterButton}
              onClick={() => {
                filterIntroduced();
              }}
            >
              {t("filter_introduced")}
            </Button>
          </>
        )}
      </div>
    </>
  );

  const filterRange = () => {
    setDelegationsUrl(
      `superior-time/delegations?dateFrom=` +
        convertDateFetch(startDate) +
        "&dateTo=" +
        convertDateFetch(endDate)
    );
  };

  const filterIntroduced = () => {
    setDelegationsUrl(`superior-time/delegations?allIntroduced=true`);
  };

  useEffect(() => {
    if (typeof delegationsUrl === "string") {
      delegationsFetchAgain();
    }
  }, [delegationsUrl, delegationsFetchAgain]);

  return (
    <>
      <TopBanner pathName={t("list_of_delegations")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>
        <div>
          <div>
            <LoadingWrapper
              isLodadingProgress={delegationsFetchingState.isFetching}
              isError={delegationsFetchingState.isError}
              setIfFetchAgain={delegationsFetchAgain}
            >
              {!delegationsFetchingState.isFetching && (
                <TableComponent
                  layoutSettingName="layoutSuperiorTimeDelegationsList"
                  rows={rows}
                  columns={columns}
                  selectData={setSelectedRows}
                  selectedData={selectedRows}
                />
              )}
            </LoadingWrapper>
          </div>
        </div>

        <div>
          <div className="acionButtonsContainerGlobal">
            <div>
              <Button
                className="actionButtonGlobal"
                width={250}
                onClick={() => {
                  setBodyPatch([
                    {
                      op: "replace",
                      path: "/status",
                      value: 7,
                    },
                  ]);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
            <div>
              <Button
                width={250}
                onClick={() => {
                  setBodyPatch([
                    {
                      op: "replace",
                      path: "/status",
                      value: 2,
                    },
                  ]);
                }}
                className="actionButtonGlobal"
              >
                {t("approve")}
              </Button>
            </div>
            <div>
              <Button
                width={250}
                onClick={() => {
                  setBodyPatch([
                    {
                      op: "replace",
                      path: "/status",
                      value: 5,
                    },
                  ]);
                }}
                className="actionButtonGlobal"
              >
                {t("deny")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default DelegationsListContent;
