import { useEffect, useState } from "react";
import useFetchGet from "../fetchHooks/get/useFetchGet";
import {
  setAbsenceTypes,
  setTimeWorkersWorkerTime,
  setAcWorkersSuperiorAc,
  setCompanyNotifications,
  setConferenceRoomNotifications,
  setDepartmentNotifications,
  setParkingNotifications,
  setTypesOfVisits,
  setGuestNotifications,
  setRoomsSuperiorTime,
  setRoomsSuperiorAc,
  setEventTypesSuperiorTime,
  setEventTypesSuperiorAc,
} from "../../reducers/cachedFetch";
import {
  selectTimeWorkersWorkerTime,
  selectTimeWorkersSuperiorTime,
  selectAcWorkersSuperiorAc,
  selectAbsenceTypes,
  selectCompanyNotifications,
  selectConferenceRoomNotifications,
  selectDepartmentNotifications,
  selectParkingNotifications,
  selectTypesOfVisits,
  selectGuestNotifications,
  selectEventTypesSuperiorTime,
  selectEventTypesSuperiorAc,
  selectRoomsSuperiorTime,
  selectRoomsSuperiorAc,
} from "../../reducers/cachedFetch";
import { useAppDispatch } from "../../store/hooks";
import { useAppSelector } from "../../store/hooks";
import { selectAuthUser } from "../../reducers/session";
import { ProfileType } from "../../enums/profileType";

const useCachedFetch = (
  nameOfTheList:
    | "absenceTypes"
    | "typesOfVisits"
    | "companyNotifications"
    | "conferenceRoomNotifications"
    | "departmentNotifications"
    | "parkingNotifications"
    | "guestNotifications"
    | "roomsSuperiorTime"
    | "eventTypesSuperiorTime"
    | "timeWorkersWorkerTime"
    // | "timeWorkersSuperiorTime"
    | "roomsSuperiorAc"
    | "eventTypesSuperiorAc"
    | "acWorkersSuperiorAc",
  ifCheckForUpdate: boolean = true,
  ifIsOldConditionSeconds: number = 300,
  ifIgnoreTime: boolean = false,
  ifReturnNull: boolean = false
) => {
  const [fetchedList, setFetchedList] = useState<any>(null);
  const [ifFetch, setIfFetch] = useState<boolean>(false);
  const [ifCheckForUpdateLocal, setIfCheckForUpdateLocal] =
    useState<boolean>(ifCheckForUpdate);
  const [urlFetch, setUrlFetch] = useState<string>("");
  const dispatch = useAppDispatch();
  const absenceTypes = useAppSelector(selectAbsenceTypes);
  const timeWorkersWorkerTime = useAppSelector(selectTimeWorkersWorkerTime);
  const timeWorkersSuperiorTime = useAppSelector(selectTimeWorkersSuperiorTime);
  const acWorkersSuperiorAc = useAppSelector(selectAcWorkersSuperiorAc);
  const typesOfVisits = useAppSelector(selectTypesOfVisits);
  const companyNotifications = useAppSelector(selectCompanyNotifications);
  const conferenceRoomNotifications = useAppSelector(
    selectConferenceRoomNotifications
  );
  const departmentNotifications = useAppSelector(selectDepartmentNotifications);
  const parkingNotifications = useAppSelector(selectParkingNotifications);
  const guestNotifications = useAppSelector(selectGuestNotifications);
  const roomsSuperiorTime = useAppSelector(selectRoomsSuperiorTime);
  const roomsSuperiorAc = useAppSelector(selectRoomsSuperiorAc);
  const eventTypesSuperiorTime = useAppSelector(selectEventTypesSuperiorTime);
  const eventTypesSuperiorAc = useAppSelector(selectEventTypesSuperiorAc);

  const fetchedListResponse = useFetchGet(urlFetch, ifFetch);
  let authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  let authUserType = useAppSelector(selectAuthUser).currentProfile.type;

  if (authUserType === ProfileType.SUPERIOR_TIME) {
    authUserId = -1;
  }

  useEffect(() => {
    if (ifReturnNull === true) setFetchedList(null);
  }, [ifReturnNull]);

  useEffect(() => {
    setIfCheckForUpdateLocal(ifCheckForUpdate);
  }, [ifCheckForUpdate]);

  useEffect(() => {
    if (!ifCheckForUpdateLocal) return;
    if (ifReturnNull === true) return;

    let ifIsOldConditionMilliseconds = ifIsOldConditionSeconds * 1000;

    if (nameOfTheList === "absenceTypes") {
      if (
        Date.now() - absenceTypes[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/absence-types`);
      } else setFetchedList(absenceTypes[1]);
    } else if (nameOfTheList === "timeWorkersWorkerTime") {
      if (
        Date.now() - timeWorkersWorkerTime[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/time-workers`);
      } else setFetchedList(timeWorkersWorkerTime[1]);
    }
    // else if (nameOfTheList === "timeWorkersSuperiorTime") {
    //   if (
    //     Date.now() - timeWorkersSuperiorTime[0] >
    //       ifIsOldConditionMilliseconds ||
    //     ifIgnoreTime === true
    //   ) {
    //     setFetchedList(null);
    //     setIfFetch(true);
    //     setUrlFetch("superior-time/time-workers");
    //   } else setFetchedList(timeWorkersSuperiorTime[1]);
    // }
    else if (nameOfTheList === "acWorkersSuperiorAc") {
      if (
        Date.now() - acWorkersSuperiorAc[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch("superior-ac/ac-workers");
      } else setFetchedList(acWorkersSuperiorAc[1]);
    } else if (nameOfTheList === "typesOfVisits") {
      if (
        Date.now() - typesOfVisits[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/types-of-visits`);
      } else setFetchedList(typesOfVisits[1]);
    } else if (nameOfTheList === "companyNotifications") {
      if (
        Date.now() - companyNotifications[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/company-notifications`);
      } else setFetchedList(companyNotifications[1]);
    } else if (nameOfTheList === "conferenceRoomNotifications") {
      if (
        Date.now() - conferenceRoomNotifications[0] >
          ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/conference-room-notifications`);
      } else setFetchedList(conferenceRoomNotifications[1]);
    } else if (nameOfTheList === "departmentNotifications") {
      if (
        Date.now() - departmentNotifications[0] >
          ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/department-notifications`);
      } else setFetchedList(departmentNotifications[1]);
    } else if (nameOfTheList === "parkingNotifications") {
      if (
        Date.now() - parkingNotifications[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/parking-notifications`);
      } else setFetchedList(parkingNotifications[1]);
    } else if (nameOfTheList === "guestNotifications") {
      if (
        Date.now() - guestNotifications[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch(`worker-time/${authUserId}/guest-notifications`);
      } else setFetchedList(guestNotifications[1]);
    } else if (nameOfTheList === "eventTypesSuperiorTime") {
      if (
        Date.now() - eventTypesSuperiorTime[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch("superior-time/event-types");
      } else setFetchedList(eventTypesSuperiorTime[1]);
    } else if (nameOfTheList === "eventTypesSuperiorAc") {
      if (
        Date.now() - eventTypesSuperiorAc[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch("superior-ac/event-types");
      } else setFetchedList(eventTypesSuperiorAc[1]);
    } else if (nameOfTheList === "roomsSuperiorTime") {
      if (
        Date.now() - roomsSuperiorTime[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch("superior-time/rooms");
      } else setFetchedList(roomsSuperiorTime[1]);
    } else if (nameOfTheList === "roomsSuperiorAc") {
      if (
        Date.now() - roomsSuperiorAc[0] > ifIsOldConditionMilliseconds ||
        ifIgnoreTime === true
      ) {
        setFetchedList(null);
        setIfFetch(true);
        setUrlFetch("superior-ac/rooms");
      } else setFetchedList(roomsSuperiorAc[1]);
    }
  }, [
    urlFetch,
    ifIsOldConditionSeconds,
    ifIgnoreTime,
    ifReturnNull,
    nameOfTheList,
    ifCheckForUpdate,
    ifCheckForUpdateLocal,
    absenceTypes,
    typesOfVisits,
    companyNotifications,
    conferenceRoomNotifications,
    departmentNotifications,
    parkingNotifications,
    guestNotifications,
    timeWorkersWorkerTime,
    roomsSuperiorTime,
    eventTypesSuperiorTime,
    timeWorkersSuperiorTime,
    acWorkersSuperiorAc,
    roomsSuperiorAc,
    eventTypesSuperiorAc,
    authUserId,
  ]);

  useEffect(() => {
    if (fetchedListResponse.error !== null) {
      setIfFetch(false);
      setIfCheckForUpdateLocal(false);
      setFetchedList("error");
      return;
    }
    if (fetchedListResponse.data === null) return;
    if (nameOfTheList === "absenceTypes")
      dispatch(setAbsenceTypes([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "timeWorkersWorkerTime")
      dispatch(
        setTimeWorkersWorkerTime([Date.now(), fetchedListResponse.data])
      );
    // else if (nameOfTheList === "timeWorkersSuperiorTime")
    //   dispatch(
    //     setTimeWorkersSuperiorTime([Date.now(), fetchedListResponse.data])
    //   );
    else if (nameOfTheList === "acWorkersSuperiorAc")
      dispatch(setAcWorkersSuperiorAc([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "typesOfVisits")
      dispatch(setTypesOfVisits([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "companyNotifications")
      dispatch(setCompanyNotifications([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "conferenceRoomNotifications")
      dispatch(
        setConferenceRoomNotifications([Date.now(), fetchedListResponse.data])
      );
    else if (nameOfTheList === "departmentNotifications")
      dispatch(
        setDepartmentNotifications([Date.now(), fetchedListResponse.data])
      );
    else if (nameOfTheList === "parkingNotifications")
      dispatch(setParkingNotifications([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "guestNotifications")
      dispatch(setGuestNotifications([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "roomsSuperiorTime")
      dispatch(setRoomsSuperiorTime([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "roomsSuperiorAc")
      dispatch(setRoomsSuperiorAc([Date.now(), fetchedListResponse.data]));
    else if (nameOfTheList === "eventTypesSuperiorTime")
      dispatch(
        setEventTypesSuperiorTime([Date.now(), fetchedListResponse.data])
      );
    else if (nameOfTheList === "eventTypesSuperiorAc")
      dispatch(setEventTypesSuperiorAc([Date.now(), fetchedListResponse.data]));
    setIfCheckForUpdateLocal(false);
    setIfFetch(false);
    setFetchedList(fetchedListResponse.data);
  }, [fetchedListResponse, dispatch, nameOfTheList]);

  return fetchedList;
};

export default useCachedFetch;
