import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useFetchGet from "../../../hooks/fetchHooks/get/useFetchGet";
import { selectTimeWorkerProfile } from "../../../reducers/profiles";
import { selectAuthUser } from "../../../reducers/session";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectSettings } from "../../../reducers/settings";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import TimeWithDescription from "../NumberWithDescription/numberWithDescription";
import "./holidayLimitBrief.scss";
import { useAppSelector } from "../../../store/hooks";
import { bankersRound } from "../../../HelpersFunctions/math/rounding/bankersRound";
import { getLocalizedHoursString } from "../../../HelpersFunctions/dateAndTime/getLocalizedHoursString";
import { getLocalizedDaysString } from "../../../HelpersFunctions/dateAndTime/getLocalizedDaysString";
import { ProfileType } from "../../../enums/profileType";

interface HolidayLimitBriefInterface {
  size?: "small";
  hideTop?: boolean;
  addVacationButton?: boolean;
  goToRemainedHolidaysLimitsButton?: boolean;
  year?: number;
  timeWorkerId?: number;
}

const HolidayLimitBrief = ({
  size,
  hideTop,
  addVacationButton,
  goToRemainedHolidaysLimitsButton,
  year,
  timeWorkerId,
}: HolidayLimitBriefInterface) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const profiles = useAppSelector(selectTimeWorkerProfile);
  const [dataModified, setDataModified] = useState<{ [key: string]: any }>({});
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [isFetching, setIsFetching] = useState(true);
  const [fetchAgain, setFetchAgain] = useState(year ? false : true);
  const [isError, setIsError] = useState<boolean>(false);

  const holidayLimitsResponse = useFetchGet(
    profileType === ProfileType.SUPERIOR_TIME
      ? `superior-time/holiday-limits-for-time-worker?timeWorkerId=${timeWorkerId}${
          year ? `&year=${year}` : ""
        }`
      : `worker-time/${authUserId}/holiday-limits?timeWorkerId=${authUserId}${
          year ? `&year=${year}` : ""
        }`,
    fetchAgain
  );

  const history = useHistory();

  useEffect(() => {
    setIsError(false);
    setIsFetching(true);
    setFetchAgain(true);
  }, [timeWorkerId, year]);

  useEffect(() => {
    if (holidayLimitsResponse.error !== null) {
      setIsError(true);
      setFetchAgain(false);
      return;
    }
    if (holidayLimitsResponse.data === null) return;
    setDataModified(holidayLimitsResponse.data);
    setIsFetching(false);
    setFetchAgain(false);
  }, [holidayLimitsResponse, t]);

  const limitOfHolidays: {
    holiday: number;
    onDemand: number;
    training: number;
    childCare: number;
  } = {
    holiday: 1,
    onDemand: 2,
    training: 4,
    childCare: 8,
  };

  type elementsNamesType =
    | "overdueLeaveOverdueLeave"
    | "leaveDuration"
    | "overdueLeave"
    | "leaveOnDemandDuration"
    | "educationalLeaveDuration"
    | "careA188Duration"
    | "remainedLeave"
    | "leaveFromRequests"
    | "remainedLeaveOnDemand"
    | "remainedEducationalLeave"
    | "remainedCareA188";

  let currentLanguage = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  if (currentLanguage) {
    currentLanguage = currentLanguage[0];
  } else {
    currentLanguage = "pl";
  }

  const convertUnitAndTime = (
    unitNumberString: "1" | "2" | "3" | "4",
    time: number
  ) => {
    if (unitNumberString === "1") {
      return {
        unit: getLocalizedHoursString(time, currentLanguage),
        time: time.toString(),
      };
    } else if (unitNumberString === "2") {
      return {
        unit: getLocalizedDaysString(
          bankersRound(time / 8, 2),
          currentLanguage
        ),
        time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
      };
    } else if (unitNumberString === "3") {
      let days = parseInt(bankersRound(time / 8, 2).toString());
      let hours = parseInt(bankersRound(time % 8, 2).toString());
      let unit = "";

      if (hours > 0) {
        unit = getLocalizedHoursString(hours, currentLanguage);
      }

      return {
        unit: unit,
        time: `${days} ${getLocalizedDaysString(days, currentLanguage)} ${
          hours > 0 ? hours : ""
        }`,
      };
    } else if (unitNumberString === "4") {
      return {
        unit: getLocalizedDaysString(
          bankersRound(time / 8, 2),
          currentLanguage
        ),
        time: bankersRound(time / 8, 2).toString(),
      };
    }

    return {
      unit: getLocalizedHoursString(time, currentLanguage),
      time: time.toString(),
    };
  };

  const unitTimeSettings = (time: number): { time: string; unit: string } => {
    const unitNumberString = settings.WebWymusTypWymiaruUrlopu.value;
    const converted = convertUnitAndTime(unitNumberString, time);
    return {
      time: converted.time,
      unit: converted.unit,
    };
  };

  const formElementUnitAndTime = (
    time: number,
    elementName: elementsNamesType
  ): { time: string; unit: string } => {
    if (
      settings?.WebWymusTypWymiaruUrlopu &&
      (settings.WebWymusTypWymiaruUrlopu.value === "1" ||
        settings.WebWymusTypWymiaruUrlopu.value === "2" ||
        settings.WebWymusTypWymiaruUrlopu.value === "3" ||
        settings.WebWymusTypWymiaruUrlopu.value === "4")
    ) {
      return unitTimeSettings(time);
    }

    if (
      elementName === "overdueLeaveOverdueLeave" ||
      elementName === "leaveDuration" ||
      elementName === "overdueLeave" ||
      elementName === "remainedLeave" ||
      elementName === "leaveFromRequests"
    ) {
      if (dataModified.oryginalLeaveUnit === 0) {
        return {
          time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
          unit: getLocalizedDaysString(
            bankersRound(time / 8, 2),
            currentLanguage
          ),
        };
      }
    } else if (
      elementName === "leaveOnDemandDuration" ||
      elementName === "remainedLeaveOnDemand"
    ) {
      if (dataModified.oryginalLeaveOnDemandUnit === 0) {
        return {
          time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
          unit: getLocalizedDaysString(
            bankersRound(time / 8, 2),
            currentLanguage
          ),
        };
      }
    } else if (
      elementName === "educationalLeaveDuration" ||
      elementName === "remainedEducationalLeave"
    ) {
      if (dataModified.oryginalEducationalLeaveUnit === 0) {
        return {
          time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
          unit: getLocalizedDaysString(
            bankersRound(time / 8, 2),
            currentLanguage
          ),
        };
      }
    } else if (
      elementName === "careA188Duration" ||
      elementName === "remainedCareA188"
    ) {
      if (dataModified.oryginalCareA188Unit === 0) {
        return {
          time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
          unit: getLocalizedDaysString(
            bankersRound(time / 8, 2),
            currentLanguage
          ),
        };
      }
    }

    return {
      time: time.toString(),
      unit: getLocalizedHoursString(time, currentLanguage),
    };
  };

  interface ObjectInterface {
    name?: string;
    description?: string;
    time?: string;
    unit?: string;
  }

  const holidayLimitElement = (
    elementName: elementsNamesType
  ): ObjectInterface => {
    if (elementName === "overdueLeaveOverdueLeave") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.leaveDuration + dataModified.overdueLeave,
        "overdueLeaveOverdueLeave"
      );
      let description = t("holiday_string");
      if (settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "leaveDuration") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.leaveDuration,
        "leaveDuration"
      );
      let description = t("holidays_limit_2");
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "overdueLeave") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.overdueLeave,
        "overdueLeave"
      );
      let description = t("outstanding_holidays");
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "leaveOnDemandDuration") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.leaveOnDemandDuration,
        "leaveOnDemandDuration"
      );
      let description = t("on_demand");
      if (settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "educationalLeaveDuration") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.educationalLeaveDuration,
        "educationalLeaveDuration"
      );
      let description = t("training");
      if (settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "careA188Duration") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.careA188Duration,
        "careA188Duration"
      );
      let description = t("child_care");
      if (settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "remainedLeave") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.remainedLeave,
        "remainedLeave"
      );
      let description = t("holiday_string");
      if (settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "remainedLeaveOnDemand") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.remainedLeaveOnDemand,
        "remainedLeaveOnDemand"
      );
      let description = t("on_demand");
      if (settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "leaveFromRequests") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.leaveFromRequests,
        "leaveFromRequests"
      );
      let description = t("holiday_with_request");
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "remainedEducationalLeave") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.remainedEducationalLeave,
        "remainedEducationalLeave"
      );
      let description = t("training");
      if (settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    } else if (elementName === "remainedCareA188") {
      const timeAndUnit = formElementUnitAndTime(
        dataModified.remainedCareA188,
        "remainedCareA188"
      );
      let description = t("child_care");
      if (settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName]) {
        description =
          settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName].value;
      }
      return {
        time: timeAndUnit.time,
        description: description,
        unit: timeAndUnit.unit,
      };
    }

    return {
      time: "timeAndUnit.time",
      description: "description",
      unit: " timeAndUnit.unit",
    };
  };
  let WebWyswietlaneTypyUrlopuGlownego =
    settings?.WebWyswietlaneTypyUrlopuGlownego?.value;
  if (!WebWyswietlaneTypyUrlopuGlownego) WebWyswietlaneTypyUrlopuGlownego = 1;

  let WebWyswietlaneTypyUrlopuPozostalo =
    settings?.WebWyswietlaneTypyUrlopuPozostalo?.value;
  if (!WebWyswietlaneTypyUrlopuPozostalo) WebWyswietlaneTypyUrlopuPozostalo = 3;

  let ifSmall = "";
  if (size === "small") ifSmall = "Small";

  const GoToRemainedHolidaysLimitsButton = (
    <div className="addVacationButtonContainer">
      {t("limit_of_holidays")}
      <div
        className="addVacationButton"
        onClick={() => {
          history.push({
            pathname: "/worker-time/profile",
            state: { startDate: new Date(), endDate: new Date() },
          });
        }}
      >
        <div>
          <FontAwesomeIcon
            className="addVacationButtonIcon"
            icon={faPlusCircle}
            size="2x"
          />
        </div>
        <div className="addVacationButtonText">
          {t("go_to_remained_holidays_limits")}
        </div>
      </div>
    </div>
  );

  const ApplicationForHolidayButton = (
    <div className="addVacationButtonContainer">
      {t("holiday_left")}{" "}
      <div
        className="addVacationButton"
        onClick={() => {
          history.push({
            pathname: "/worker-time/holiday",
            state: { startDate: new Date(), endDate: new Date() },
          });
        }}
      >
        <div>
          <FontAwesomeIcon
            className="addVacationButtonIcon"
            icon={faPlusCircle}
            size="2x"
          />
        </div>
        <div className="addVacationButtonText">
          {t("application_for_holiday")}
        </div>
      </div>
    </div>
  );

  return (
    <div className={"holidayLimitBrief" + ifSmall}>
      {settings?.WebUkryjWymiarUrlopu?.value === "1" ? null : (
        <LoadingWrapper
          isLodadingProgress={isFetching}
          isError={isError}
          setIfFetchAgain={setFetchAgain}
          setIsError={setIsError}
        >
          {!isFetching && !isError && (
            <>
              {hideTop !== true && (
                <div>
                  <TimeWithDescription
                    size={size}
                    parametrs={[
                      {
                        name: goToRemainedHolidaysLimitsButton
                          ? GoToRemainedHolidaysLimitsButton
                          : t("limit_of_holidays"),
                      },
                      ...((WebWyswietlaneTypyUrlopuGlownego &
                        limitOfHolidays.holiday) ===
                      limitOfHolidays.holiday
                        ? [holidayLimitElement("overdueLeaveOverdueLeave")]
                        : []),

                      ...((WebWyswietlaneTypyUrlopuGlownego &
                        limitOfHolidays.holiday) ===
                      limitOfHolidays.holiday
                        ? [holidayLimitElement("leaveDuration")]
                        : []),

                      ...((WebWyswietlaneTypyUrlopuGlownego &
                        limitOfHolidays.holiday) ===
                      limitOfHolidays.holiday
                        ? [holidayLimitElement("overdueLeave")]
                        : []),

                      ...((WebWyswietlaneTypyUrlopuGlownego &
                        limitOfHolidays.onDemand) ===
                      limitOfHolidays.onDemand
                        ? [holidayLimitElement("leaveOnDemandDuration")]
                        : []),

                      ...((WebWyswietlaneTypyUrlopuGlownego &
                        limitOfHolidays.training) ===
                      limitOfHolidays.training
                        ? [holidayLimitElement("educationalLeaveDuration")]
                        : []),

                      ...((WebWyswietlaneTypyUrlopuGlownego &
                        limitOfHolidays.childCare) ===
                      limitOfHolidays.childCare
                        ? [holidayLimitElement("careA188Duration")]
                        : []),
                    ]}
                  />
                </div>
              )}
              <div>
                <TimeWithDescription
                  size={size}
                  parametrs={[
                    {
                      name: addVacationButton
                        ? ApplicationForHolidayButton
                        : t("holiday_left"),
                    },

                    ...((WebWyswietlaneTypyUrlopuPozostalo &
                      limitOfHolidays.holiday) ===
                    limitOfHolidays.holiday
                      ? [holidayLimitElement("remainedLeave")]
                      : []),

                    holidayLimitElement("leaveFromRequests"),

                    ...((WebWyswietlaneTypyUrlopuPozostalo &
                      limitOfHolidays.onDemand) ===
                    limitOfHolidays.onDemand
                      ? [holidayLimitElement("remainedLeaveOnDemand")]
                      : []),

                    ...((WebWyswietlaneTypyUrlopuPozostalo &
                      limitOfHolidays.training) ===
                    limitOfHolidays.training
                      ? [holidayLimitElement("remainedEducationalLeave")]
                      : []),

                    ...((WebWyswietlaneTypyUrlopuPozostalo &
                      limitOfHolidays.childCare) ===
                    limitOfHolidays.childCare
                      ? [holidayLimitElement("remainedCareA188")]
                      : []),
                  ]}
                />
              </div>
            </>
          )}
        </LoadingWrapper>
      )}
    </div>
  );
};

export default HolidayLimitBrief;
