import { TFunction } from "react-i18next";
import erro400getTranslatedErrorString from "./erro400getTranslatedErrorString";

interface Props {
  t: TFunction<"translation">;
  enqueueSnackbar: any;
  fetchingStateResponse: UseFetchResponse;
  disableErrorSnackbar?: boolean;
}

export default function handleFetchErrors({
  fetchingStateResponse,
  enqueueSnackbar,
  t,
  disableErrorSnackbar,
}: Props): string {
  console.error(JSON.stringify(fetchingStateResponse));
  if (fetchingStateResponse?.status === 400) {
    let errorMessage = erro400getTranslatedErrorString(
      fetchingStateResponse?.resJson?.errors,
      t
    );
    if (!disableErrorSnackbar)
      enqueueSnackbar(errorMessage, { variant: "error" });
    return errorMessage;
  } else {
    if (!disableErrorSnackbar)
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    return t("something_went_wrong");
  }
}
