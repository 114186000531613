import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  DialogActionsStyled,
  DialogStyled,
  DialogTitleStyled,
} from "../../PopupHelpers";
import PopupContentWhiteListGuest from "./PopupContentWhiteListGuest";

const PopupWhiteListGuest = ({
  isOpen,
  isOpenPopup,
  closeIsOpenPopup,
  rowId,
  closePopup,
  pathWhiteListGuests,
  pathCompaniesInPorts,
  pathGuests,
  pathPermissionZones,
  successCallback,
}: {
  isOpen: boolean;
  isOpenPopup: { isOpen: boolean; rowId: number | undefined };
  closeIsOpenPopup: () => void;
  rowId: number | undefined;
  closePopup: () => void;
  pathWhiteListGuests: string;
  pathCompaniesInPorts: string;
  pathGuests: string;
  pathPermissionZones: string;
  successCallback: () => void;
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<string | false>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (values: IAddWhiteListGuest | IUpdateWhiteListGuest) => {
    setErrorMessage("");
    setBodyRequest(JSON.stringify(values));
  };

  useEffect(() => {
    if (isOpen === false || isOpenPopup.isOpen === false) {
      setErrorMessage("");
    }
  }, [isOpen, isOpenPopup]);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
    closePopup();
    closeIsOpenPopup();
  }, [successCallback, closePopup, closeIsOpenPopup]);

  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `${pathWhiteListGuests}${
      rowId !== undefined
        ? `/${rowId}`
        : isOpenPopup.rowId
        ? `/${isOpenPopup.rowId}`
        : ""
    }`,
    method:
      rowId !== undefined || isOpenPopup.rowId !== undefined ? "PUT" : "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    getErrorMessage: setErrorMessage,
    successCallback: successCallbackAndClose,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  return (
    <DialogStyled
      open={isOpen || isOpenPopup.isOpen}
      onClose={() => {
        closePopup();
        closeIsOpenPopup();
      }}
    >
      <DialogTitleStyled title={t("add_person")} />
      <PopupContentWhiteListGuest
        rowId={rowId ? rowId : isOpenPopup.rowId}
        onSubmit={onSubmit}
        isOpen={isOpen}
        pathWhiteListGuests={pathWhiteListGuests}
        pathCompaniesInPorts={pathCompaniesInPorts}
        pathGuests={pathGuests}
        pathPermissionZones={pathPermissionZones}
      />
      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onCancel={() => {
          closePopup();
          closeIsOpenPopup();
        }}
        isLoading={fetchingStatePostData.isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default PopupWhiteListGuest;
