import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfVisit } from "../../../Constants/typeOfVisitInPorts";
import toLocaleStringCustom from "../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import AttachmentsListInRowDetail from "../Attachments/attachmentsListInRowDetail";
import ButtonStyled from "../Button/button";
import FormatRegistrationNumber from "../FormattersComponent/FormatRegistrationNumber";
import GuestsList from "../GuestsList";
import AccordionStyled from "../MaterialUi/Accordion/AccordionStyled";
import PopupPermissionZone from "../PermissionZoneChange/PopupPermissionZone";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../RowDetailsHelpers";
import PopupWorkingConditions from "../WorkingConditions/PopupWorkingConditions";
import styles from "./styles.module.scss";

interface IProps {
  row: INotificationInPortsGuest;
  successCallback: () => void;
  pathApiPermissionZones: string;
  pathApiNotifications: string;
  pathApiAttachments: string;
  pathApiChangeWorkingConditions?: string;
  disableEditPermissionZone?: boolean;
  refreshData?: () => void;
}

const RowDetailNotificationInPorts: React.FC<IProps> = ({
  row,
  successCallback,
  pathApiPermissionZones,
  pathApiNotifications,
  pathApiAttachments,
  pathApiChangeWorkingConditions,
  disableEditPermissionZone,
  refreshData,
}) => {
  const { t } = useTranslation();
  const guestsInfo = row?.guests.map((item) => ({
    notificationGuestId: item.notificationGuestId,
    guest: item.guest,
    letIn: item.isLetIn,
    securityFullName: item.securityFullName,
    securityDateConfirmation: item.securityDateConfirmation,
    securityRejectionReason: `${item.securityRejectionReason} ${
      item.securityRejectionDescription
        ? ` - ${item.securityRejectionDescription}`
        : ""
    }`,
  }));

  const isEditPossible =
    guestsInfo?.filter((item) => item.letIn === null).length ===
    guestsInfo.length;

  const EMPLOYEE_EMAIL_REQUIRED =
    window.globalConfig.EMPLOYEE_EMAIL_IN_NOTIFICATION;

  // STREFA UPRAWNIEŃ
  const [openPermissionZone, setOpenPermissionZone] = useState(false);
  const openPermissionZonePopup = useCallback(() => {
    setOpenPermissionZone(true);
  }, []);
  const closePermissionZonePopup = useCallback(() => {
    setOpenPermissionZone(false);
  }, []);

  const successCallbackPermissionZone = useCallback(() => {
    setOpenPermissionZone(false);
    if (successCallback !== undefined) {
      successCallback();
    }
  }, [successCallback]);

  // WARUNKI PRACY
  const [openWorkingConditions, setOpenWorkingConditions] = useState(false);
  const openWorkingConditionsPopup = useCallback(() => {
    setOpenWorkingConditions(true);
  }, []);
  const closeWorkingConditionsPopup = useCallback(() => {
    setOpenWorkingConditions(false);
  }, []);

  const successCallbackWorkingConditions = useCallback(() => {
    setOpenWorkingConditions(false);
    if (successCallback !== undefined) {
      successCallback();
    }
  }, [successCallback]);

  return (
    <div>
      <RowDetailsContainer>
        {/* INFORMACJE */}
        <AccordionStyled title={t("information")} defaultExpanded={true}>
          <>
            <RowDetailsRow>
              {EMPLOYEE_EMAIL_REQUIRED && (
                <RowDetailsTitleValue>
                  <RowDetailsTitle>
                    {row?.notification.acSuperiorEmail
                      ? t("employee_email")
                      : t("phone_employee")}
                    :
                  </RowDetailsTitle>
                  <RowDetailsValue>
                    {row?.notification.acSuperiorEmail
                      ? row.notification.acSuperiorEmail
                      : row.notification.acSuperiorPhoneOrRadio}
                  </RowDetailsValue>
                </RowDetailsTitleValue>
              )}

              {!EMPLOYEE_EMAIL_REQUIRED && (
                <RowDetailsTitleValue>
                  <RowDetailsTitle>{t("target_department")}:</RowDetailsTitle>
                  <RowDetailsValue>
                    {row?.notification.targetDepartmentName
                      ? row?.notification.targetDepartmentName
                      : "-"}
                  </RowDetailsValue>
                </RowDetailsTitleValue>
              )}

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("plate_number")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.registrationNumber ? (
                    <FormatRegistrationNumber
                      registrationNumber={row.notification.registrationNumber}
                    />
                  ) : (
                    "-"
                  )}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("type_of_visit")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.typeOfVisitName
                    ? row.typeOfVisitName
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("BH3")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.bH3
                    ? row.notification.bH3
                      ? t("yes")
                      : t("no")
                    : t("no")}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("permission_zone")}:</RowDetailsTitle>
                <RowDetailsValue>
                  <div className={styles.permissionZone}>
                    {row?.notification.permissionZoneName
                      ? row?.notification.permissionZoneName
                      : "-"}

                    {!disableEditPermissionZone && isEditPossible ? (
                      <ButtonStyled onClick={openPermissionZonePopup}>
                        {t("edit")} <FontAwesomeIcon icon={faCogs} />
                      </ButtonStyled>
                    ) : (
                      <></>
                    )}
                  </div>
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("comments")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.comments ? row.notification.comments : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("created")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.addingPersonFullName
                    ? row.notification.addingPersonFullName + ", "
                    : ""}
                  {row?.notification.createdAt
                    ? toLocaleStringCustom({
                        date: row.notification.createdAt,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              {!row.notification.isToPfsoAndShiftDirector ? (
                <RowDetailsTitleValue>
                  <RowDetailsTitle>
                    {t("confirmation_employee")}:
                  </RowDetailsTitle>
                  <RowDetailsValue>
                    {row?.notification.employeeFullName
                      ? row.notification.employeeFullName + ", "
                      : ""}
                    {row?.notification.employeeDateConfirmation
                      ? toLocaleStringCustom({
                          date: row.notification.employeeDateConfirmation,
                          t,
                          ifShowHours: true,
                        })
                      : "-"}
                  </RowDetailsValue>
                </RowDetailsTitleValue>
              ) : (
                <RowDetailsTitleValue>
                  <RowDetailsTitle>
                    {t("confirmation_shift_director")}:
                  </RowDetailsTitle>
                  <RowDetailsValue>
                    {row?.notification.shiftDirectorFullName
                      ? row.notification.shiftDirectorFullName + ", "
                      : ""}
                    {row?.notification.shiftDirectorDateConfirmation
                      ? toLocaleStringCustom({
                          date: row.notification.shiftDirectorDateConfirmation,
                          t,
                          ifShowHours: true,
                        })
                      : "-"}
                  </RowDetailsValue>
                </RowDetailsTitleValue>
              )}
            </RowDetailsRow>

            {/*  */}
            <RowDetailsRow>
              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("confirmation_pfso")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.pfsoFullName
                    ? row.notification.pfsoFullName + ", "
                    : ""}
                  {row?.notification.pfsoDateConfirmation
                    ? toLocaleStringCustom({
                        date: row.notification.pfsoDateConfirmation,
                        t,
                        ifShowHours: true,
                      })
                    : "-"}
                </RowDetailsValue>
              </RowDetailsTitleValue>

              <RowDetailsTitleValue>
                <RowDetailsTitle>{t("rejection_reason")}:</RowDetailsTitle>
                <RowDetailsValue>
                  {row?.notification.rejectionReason
                    ? row.notification.rejectionReason
                    : "-"}
                  {row?.notification.rejectionDescription &&
                    ` (${row.notification.rejectionDescription})`}
                </RowDetailsValue>
              </RowDetailsTitleValue>
            </RowDetailsRow>

            {/* ZAŁĄCZNIKI */}
            <AttachmentsListInRowDetail
              title={t("attachments")}
              files={row?.notification.attachments}
              path={pathApiAttachments}
            />
          </>
        </AccordionStyled>

        {/* WARUNKI PRACY */}
        {row?.notification.typeOfVisitId ===
          TypeOfVisit.COMPLETION_OF_THE_WORK && (
          <div>
            <AccordionStyled
              title={t("working_conditions")}
              defaultExpanded={true}
            >
              <>
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("is_work_permit_required")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.notification.isWorkPermitRequired === null
                        ? "-"
                        : row?.notification.isWorkPermitRequired
                        ? t("yes")
                        : t("no")}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>

                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("is_work_particularly_dangerous")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.notification.isWorkParticularlyDangerous === null
                        ? "-"
                        : row?.notification.isWorkParticularlyDangerous
                        ? t("yes")
                        : t("no")}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>
                </RowDetailsRow>

                {/*  */}
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("work_permit_number")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.notification.workPermitNumber
                        ? row?.notification.workPermitNumber
                        : "-"}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>

                  <RowDetailsTitleValue>
                    <RowDetailsTitle>{t("work_area")}:</RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.notification.workArea
                        ? row?.notification.workArea
                        : "-"}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>
                </RowDetailsRow>

                {/*  */}
                <RowDetailsRow>
                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {t("work_particularly_dangerous_comments")}:
                    </RowDetailsTitle>
                    <RowDetailsValue>
                      {row?.notification.workParticularlyDangerousComments
                        ? row?.notification.workParticularlyDangerousComments
                        : "-"}
                    </RowDetailsValue>
                  </RowDetailsTitleValue>

                  <RowDetailsTitleValue>
                    <RowDetailsTitle>
                      {pathApiChangeWorkingConditions && isEditPossible ? (
                        <ButtonStyled onClick={openWorkingConditionsPopup}>
                          {t("edit")} <FontAwesomeIcon icon={faCogs} />
                        </ButtonStyled>
                      ) : (
                        <></>
                      )}
                    </RowDetailsTitle>
                  </RowDetailsTitleValue>
                </RowDetailsRow>
              </>
            </AccordionStyled>
          </div>
        )}

        {/* OSOBY  */}
        <GuestsList
          notificationId={row?.notification.id}
          guestsInfo={guestsInfo}
          defaultExpanded={true}
          refreshData={refreshData}
        />
      </RowDetailsContainer>

      {openPermissionZone && (
        <PopupPermissionZone
          open={openPermissionZone}
          onCancelChanges={closePermissionZonePopup}
          successCallback={successCallbackPermissionZone}
          pathApi={`${pathApiNotifications}/${row.notification.id}`}
          permissionZonesPathApi={pathApiPermissionZones}
          selectedPermissionZoneId={row.notification.permissionZoneId}
          pathNameToVariable="/notification/permissionZoneId"
        />
      )}

      {openWorkingConditions && (
        <PopupWorkingConditions
          open={openWorkingConditions}
          onCancelChanges={closeWorkingConditionsPopup}
          successCallback={successCallbackWorkingConditions}
          typeOfVisitId={row?.notification.typeOfVisitId}
          pathApi={`${pathApiChangeWorkingConditions}/${row.notification.id}`}
          rowId={row?.notification.id}
          prefixPathName={"/notification"}
        />
      )}
    </div>
  );
};

export default RowDetailNotificationInPorts;
