import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import styles from "./visualization.module.scss";
import { MapContainer } from "react-leaflet";
import map1 from "./testMaps/map1.png";
import { CRS, LatLngBoundsExpression } from "leaflet";
import L from "leaflet";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectAllMaps,
  selectCurrentAreaDetailsId,
  selectCurrentMapId,
  selectIsEditMode,
  setAddUpdateMapToAllMaps,
  setIsEditMode,
} from "../../../reducers/visualization/visualization";
import CreateNewAreaActions from "./CreateNewArea/createNewAreaActions";
import {
  selectIsCreatingAreaMode,
  selectNewAreaPoints,
  setToggleIsCreatingAreaMode,
} from "../../../reducers/visualization/creatingNewArea";
import { CreateNewArea } from "./CreateNewArea/createNewArea";
import NewMapPopup from "./AddNewMapPopup/newMapPopup";
import NewRoomPopup from "./AddNewRoomPopup/newRoomPopup";
import SingleAreaPolygon from "./SingleArea/singleAreaPolygon";
import ControlPanel from "./ControlPanel/controlPanel";
import Button from "../../helpersComponents/Button/button";
import SelectFileInput from "../../helpersComponents/Attachments/selectFileInput";
import DisplaySingleFileWithDeleteButton from "../../helpersComponents/Attachments/displaySingleFileWithDeleteButton";
import { toDataURL } from "../../../HelpersFunctions/toDataURL";
import changeCurrentMapOverlay from "./helperFunctions/changeCurrentMapOverlay";
import useCofirm from "../../../hooks/useConfirm/useConfirm";
import AreaDetails from "./areaDetails/areaDetails";

let bounds: LatLngBoundsExpression = [
  [0, 0],
  [1000, 1000],
];

const position: L.LatLngTuple = [500, 500];

export default function VisualizationContent(): ReactElement {
  const { t } = useTranslation();
  const { confirm } = useCofirm();
  const [map, setMap] = useState<L.Map>();
  const newAreaPoints = useAppSelector(selectNewAreaPoints);
  const isEditMode = useAppSelector(selectIsEditMode);
  const dispatch = useAppDispatch();
  const [selectMainMap, setSelectMainMap] = useState<File[]>([]);
  const allMaps = useAppSelector(selectAllMaps);
  const currentMapId = useAppSelector(selectCurrentMapId);
  const isCreatingAreaMode = useAppSelector(selectIsCreatingAreaMode);
  const currentAreaDetailsId = useAppSelector(selectCurrentAreaDetailsId);
  const currentMapOverlayRef: React.MutableRefObject<L.ImageOverlay> = useRef(
    L.imageOverlay(map1, bounds)
  );
  const [isInitMapSet, setIsInitMapSet] = useState<boolean>(false);
  useEffect(() => {
    if (isInitMapSet) return;
    if (map) {
      if (allMaps[1]) {
        const setInitialMap = async () => {
          await changeCurrentMapOverlay({
            Lmap: map,
            mapBase64: allMaps[1].base64,
            currentMapOverlayRef,
          });
        };
        setInitialMap();
        setIsInitMapSet(true);
      }
    }
  }, [map, allMaps, isInitMapSet]);

  useEffect(() => {
    return () => {
      dispatch(setToggleIsCreatingAreaMode(false));
    };
  }, [dispatch]);

  const polygonStyle = { color: "lightblue" };

  useEffect(() => {
    if (selectMainMap.length === 0) return;
    const setMainMap = async () => {
      if (map) {
        let selectedFileBase64: any = await toDataURL(selectMainMap[0]);
        await changeCurrentMapOverlay({
          Lmap: map,
          mapBase64: selectedFileBase64,
          currentMapOverlayRef,
        });
        dispatch(
          setAddUpdateMapToAllMaps({
            id: 1,
            isMainMap: true,
            name: "Main map",
            base64: selectedFileBase64,
            rooms: [],
            mapsInside: [],
          })
        );
      }
      setSelectMainMap([]);
    };
    setMainMap();
  }, [selectMainMap, dispatch, map]);

  return (
    <div>
      <TopBanner pathName={t("visualization")} />
      <div className={"flexAndCenter"}>
        <div>
          <div className={styles.visualizationRootConatiner}>
            <NewMapPopup />
            <NewRoomPopup />
            <div className={styles.editModeButton}>
              <Button
                onClick={async () => {
                  if (newAreaPoints.length > 0) {
                    let selectedbuttonId = await confirm({
                      text: t(
                        "Czy na pewno chcesz zakończyć tworzenie obszaru? Stracisz cały aktualny postep."
                      ),
                      buttons: [
                        { buttonName: t("zakończ"), buttonId: 0 },
                        { buttonName: t("cancel"), buttonId: 1 },
                      ],
                    });
                    if (selectedbuttonId === 0) {
                      dispatch(setIsEditMode(!isEditMode));
                      dispatch(setToggleIsCreatingAreaMode(false));
                    }
                  } else {
                    dispatch(setIsEditMode(!isEditMode));
                    dispatch(setToggleIsCreatingAreaMode(false));
                  }
                }}
              >
                {isEditMode ? (
                  <>Wyjdź z trybu edycji</>
                ) : (
                  <>Wejdź w tryb edycji</>
                )}
              </Button>
            </div>
            {isEditMode && (
              <div>
                <div className={styles.setMainMap}>
                  <div className={styles.editModeTitle}>
                    {t("Ustawianie głównej mapy")}
                  </div>
                  <div className={styles.setMainMapButtons}>
                    <div>
                      <SelectFileInput
                        setSelectedFiles={setSelectMainMap}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </div>
                    <div>
                      {selectMainMap.length > 0 && (
                        <DisplaySingleFileWithDeleteButton
                          file={selectMainMap[0]}
                          fileIndex={0}
                          allFiles={selectMainMap}
                          setUpadteAllFilesAfterDelete={setSelectMainMap}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <CreateNewAreaActions />
                </div>
              </div>
            )}
            <div
              className={
                isEditMode && currentAreaDetailsId !== 0
                  ? styles.mapAndAreaDetails
                  : ""
              }
            >
              <div>
                {isEditMode && currentAreaDetailsId !== 0 && <AreaDetails />}
              </div>
              <div>
                <MapContainer
                  className={styles.mapContainer}
                  center={position}
                  zoom={1}
                  minZoom={-5}
                  scrollWheelZoom={false}
                  crs={CRS.Simple}
                  whenCreated={setMap}
                >
                  {map && (
                    <ControlPanel
                      currentMapOverlayRef={currentMapOverlayRef}
                      Lmap={map}
                    />
                  )}
                  {isCreatingAreaMode && <CreateNewArea />}
                  {allMaps[currentMapId] &&
                    allMaps[currentMapId].mapsInside.map((map) => {
                      return (
                        <SingleAreaPolygon
                          areaId={map.mapId}
                          key={map.mapId}
                          positions={map.points}
                          pathOptions={polygonStyle}
                        />
                      );
                    })}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
