import { DatePickerStyled, TextFieldStyled } from "../../MaterialUi";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionStyled from "../../MaterialUi/Accordion/AccordionStyled";

interface IProps {
  isLoadingTopRows: boolean;
  date?: HourRequestContentDate;
  fromHour: HourRequestContentTime;
  tillHour: HourRequestContentTime;
  title?: string;
  expand?: boolean;
}

const HoursRequestContent = ({
  isLoadingTopRows,
  date,
  fromHour,
  tillHour,
  title,
  expand,
}: IProps) => {
  const { t } = useTranslation();
  let content = (
    <>
      {date && (
        <div className="selectDateHoursPopup">
          <div className="titleWidthForHours">{t("day")}:</div>
          <div className="inputWidthForHours">
            <DatePickerStyled
              view={["year", "month", "date"]}
              format="dd-MM-yyyy"
              date={date.value}
              minDate={date.min}
              maxDate={date.max}
              width={"100%"}
              isError={date.error.isError}
              setDate={date.setValue}
            />
            {date.error.isError ? (
              <div className="errorMinDateForHours">
                {date.error.errorMessage}
              </div>
            ) : null}
          </div>
        </div>
      )}

      <div className="selectTimeHours">
        <div>
          <div className="titleWidthForHours">{t("hours")}:</div>
        </div>

        <div className="selectTimeHoursRight">
          {isLoadingTopRows ? (
            <div className={"loadingWrapper"}>
              <CircularProgress />
            </div>
          ) : (
            <div className="selectTimeHoursRightFirst">
              <div>
                <div className="fromHoursMargin">{t("from")}:</div>
                <TextFieldStyled
                  value={fromHour.value}
                  hiddenLabel
                  width={100}
                  isError={fromHour.error.isError}
                  onChange={(e) => {
                    fromHour.setValue(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      fromHour.setError({
                        isError: true,
                        errorMessage: "",
                      });
                    }
                  }}
                  type="time"
                />
              </div>
              <div>
                <div className="toHoursMargin">{t("to")}:</div>
                <TextFieldStyled
                  value={tillHour.value}
                  isError={tillHour.error.isError}
                  onChange={(e) => {
                    tillHour.setValue(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      tillHour.setValue({
                        isError: true,
                        errorMessage: "",
                      });
                    }
                  }}
                  type="time"
                  width={100}
                  hiddenLabel
                />
              </div>
            </div>
          )}

          <div className="selectTimeHoursRightExamples">
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("08:00");
                  tillHour.setValue("09:00");
                }}
              >
                8-9
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("09:00");
                  tillHour.setValue("10:00");
                }}
              >
                9-10
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("10:00");
                  tillHour.setValue("11:00");
                }}
              >
                10-11
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("11:00");
                  tillHour.setValue("12:00");
                }}
              >
                11-12
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("12:00");
                  tillHour.setValue("13:00");
                }}
              >
                12-13
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("14:00");
                  tillHour.setValue("15:00");
                }}
              >
                14-15
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("15:00");
                  tillHour.setValue("16:00");
                }}
              >
                15-16
              </div>
              <span className="selectTimeHoursMargin"></span>
            </div>
          </div>

          <div className="selectTimeHoursRightExamples">
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("08:00");
                  tillHour.setValue("10:00");
                }}
              >
                8-10
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("09:00");
                  tillHour.setValue("11:00");
                }}
              >
                9-11
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("10:00");
                  tillHour.setValue("12:00");
                }}
              >
                10-12
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("11:00");
                  tillHour.setValue("13:00");
                }}
              >
                11-13
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("12:00");
                  tillHour.setValue("14:00");
                }}
              >
                12-14
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("14:00");
                  tillHour.setValue("16:00");
                }}
              >
                14-16
              </div>
              <span className="selectTimeHoursMargin">|</span>
            </div>
            <div>
              <div
                onClick={() => {
                  fromHour.setValue("15:00");
                  tillHour.setValue("17:00");
                }}
              >
                15-17
              </div>
              <span className="selectTimeHoursMargin"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return title ? (
    <AccordionStyled title={title} defaultExpanded={expand ? expand : false}>
      <div style={{ padding: "5px" }}>{content}</div>
    </AccordionStyled>
  ) : (
    <>{content}</>
  );
};

export default HoursRequestContent;
