import {
  faArrowAltCircleRight,
  faPlusCircle,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import getCurrentPosition from "../../../HelpersFunctions/getCurrentPosition";
import useFetchPost from "../../../hooks/fetchHooks/post/useFetchPost";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import HolidayLimitBrief from "../../helpersComponents/HolidayLimitBrief/holidayLimitBrief";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import Chart from "./chartWorkingTime";
import styles from "./homePage.module.scss";
import QuickTask from "./quickTaskContainer";
import { useHistory } from "react-router";
import { selectAuthUser } from "../../../reducers/session";
import {
  selectLastEvent,
  selectEightHoursLastEvent,
  selectRemoteDutyLastEvent,
  selectHomeOfficeLastEvent,
} from "../../../reducers/homePage";
import ValueUnitDescription from "../../helpersComponents/NumberWithDescription/valueUnitDescription";
import { AdditionalEventType } from "../../../enums/additionalEventType";
import { HolidayRequestTypeFunction } from "../../../enums/holidayRequestTypeFunction";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { getLocalizedDaysString } from "../../../HelpersFunctions/dateAndTime/getLocalizedDaysString";

const HomePageContent = () => {
  const { t } = useTranslation();
  const tRef = useRef(t);
  const settings = useAppSelector(selectSettings);
  const { enqueueSnackbar } = useSnackbar();
  const [fetchAgainChartTasks, setFetchAgainChartTasks] = useState(true);
  const history = useHistory();
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;

  const lastEvent = useAppSelector(selectLastEvent);
  const eightHoursLastEvent = useAppSelector(selectEightHoursLastEvent);
  const remoteDutyLastEvent = useAppSelector(selectRemoteDutyLastEvent);
  const homeOfficeLastEvent = useAppSelector(selectHomeOfficeLastEvent);

  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  let postRequest = useFetchPost(
    `worker-time/${authUserId}/events`,
    postRequestBody
  );

  let currentLanguage = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  if (currentLanguage) {
    currentLanguage = currentLanguage[0];
  } else {
    currentLanguage = "pl";
  }

  const returnColorLastEvent = (typeId: number) => {
    switch (typeId) {
      // case 1:
      // case 11:
      //   return "#43a047";
      // case 8:
      // case 18:
      //   return "#ed5565";
      default:
        return "#676a6c";
    }
  };

  useEffect(() => {
    if (postRequest.data?.status === 201) {
      enqueueSnackbar(t("success_action"), { variant: "success" });
      setPostRequestBody(false);
      setTimeout(() => setFetchAgainChartTasks(true), 1000);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += tRef.current(errorString) + " ";
        });
      });
      enqueueSnackbar(error, { variant: "error" });

      setPostRequestBody(false);
    } else if (postRequest.error !== null) {
      setPostRequestBody(false);
      enqueueSnackbar(t("something_went_wrong"), { variant: "error" });
    }
  }, [postRequest, enqueueSnackbar, t]);

  const createEvent = async (
    eventType: number,
    additionalEventType: null | number = null
  ) => {
    const position: any = await getCurrentPosition();
    let latitude = null;
    let longitude = null;
    if (position) {
      latitude = position.latitude;
      longitude = position.longitude;
    }
    let authUser: authUserInfo = JSON.parse(
      localStorage.getItem("authUser") || "{}"
    );
    let timeWorkerId = authUser.currentProfile.subjectId;
    setPostRequestBody({
      timeWorkerId: timeWorkerId,
      time: null,
      typeId: eventType,
      additionalEventType: additionalEventType,
      latitude: latitude,
      longitude: longitude,
      photo: "",
    });
  };

  const [remainedOccasionalHomeOffice] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/holiday-limits/remained-occasional-home-office?timeWorkerId=${authUserId}&year=${new Date().getFullYear()}`,
    startFetchOnInitial: true,
  });

  return (
    <>
      <TopBanner pathName={t("home_page")} />
      <div className={"flexAndCenter"}>
        <div>
          <Chart
            fetchAgain={fetchAgainChartTasks}
            setFetchAgain={setFetchAgainChartTasks}
          />
        </div>
        <div>
          <HolidayLimitBrief
            addVacationButton={true}
            goToRemainedHolidaysLimitsButton={true}
          />
        </div>
        {settings.ZablokujWeWy?.value !== "1" && (
          <>
            {settings.WebWejsciaWyjscia?.value === "1" ? (
              <div>
                <div className={styles.workTimeManagment}>
                  <div className={styles.title}>
                    <div>{t("time_managment")}</div>
                    <div>
                      <div
                        className={styles.buttonGoTo}
                        onClick={() => {
                          history.push({
                            pathname: "/worker-time/events",
                          });
                        }}
                      >
                        <div className={styles.buttonGoToIcon}>
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            size="2x"
                          />
                        </div>
                        <div className={styles.buttonGoToText}>
                          {t("go_to_events")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.elementsManagement}>
                    <div
                      className={styles.greenButton}
                      onClick={() => {
                        createEvent(1);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />
                      </div>
                      <div>{t("entry")}</div>
                    </div>
                    <div
                      className={styles.redButton}
                      onClick={() => {
                        createEvent(8);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          className="fa-2x"
                          icon={faSignOutAlt}
                        />
                      </div>
                      <div>{t("exit")}</div>
                    </div>
                    {!(
                      settings.WebWejsciaWyjsciaUkryjPrzyciskSluzbowy?.value ===
                      "1"
                    ) && (
                      <>
                        <div
                          className={styles.orangeButton}
                          onClick={() => {
                            createEvent(2, AdditionalEventType.BUSINESS_ENTRY);
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              className="fa-2x"
                              icon={faSignInAlt}
                            />
                          </div>
                          <div>{t("business_entry")}</div>
                        </div>

                        <div
                          className={styles.orangeButton}
                          onClick={() => {
                            createEvent(2, AdditionalEventType.BUSINESS_EXIT);
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              className="fa-2x"
                              icon={faSignOutAlt}
                            />
                          </div>
                          <div>{t("business_exit")}</div>
                        </div>
                      </>
                    )}
                    <div>
                      <ValueUnitDescription
                        value={
                          lastEvent !== false && lastEvent !== undefined ? (
                            <span
                              style={{
                                color: `${returnColorLastEvent(
                                  lastEvent?.typeId
                                )}`,
                              }}
                            >
                              {`${lastEvent.typeName}  ${new Date(
                                lastEvent.time
                              ).toLocaleString(t("scheduler_language"), {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}`}
                            </span>
                          ) : (
                            <> {t("none")}</>
                          )
                        }
                        description={t("last_event")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {settings.WebWejscieWyjscieZdalnePrzycisk8Godzin?.value === "1" ? (
              <div>
                <div className={styles.workTimeManagment}>
                  <div className={styles.title}>
                    <div>{t("time_managment")}</div>
                  </div>
                  <div className={styles.elementsManagement}>
                    <div
                      className={styles.greenButton}
                      onClick={() => {
                        createEvent(1, AdditionalEventType.ENTRY_EXIT_8_HOURS);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />
                      </div>
                      <div>8 {t("hours_2")}</div>
                    </div>

                    <div>
                      <ValueUnitDescription
                        value={
                          eightHoursLastEvent !== false &&
                          eightHoursLastEvent !== undefined ? (
                            <span
                              style={{
                                color: `${returnColorLastEvent(
                                  eightHoursLastEvent?.typeId
                                )}`,
                              }}
                            >
                              {`${eightHoursLastEvent.typeName}  ${new Date(
                                eightHoursLastEvent.time
                              ).toLocaleString(t("scheduler_language"), {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}`}
                            </span>
                          ) : (
                            <> {t("none")}</>
                          )
                        }
                        description={t("last_event")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {settings.WebWejsciaWyjsciaZdalne?.value === "1" ? (
              <div>
                <div className={styles.workTimeManagment}>
                  <div className={styles.title}>
                    <div>{t("remote_shifts")}</div>
                  </div>
                  <div className={styles.elementsManagement}>
                    <div
                      className={styles.orangeButton}
                      onClick={() => {
                        createEvent(11);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />
                      </div>
                      <div>{t("begin")}</div>
                    </div>
                    <div
                      className={styles.blueButton}
                      onClick={() => {
                        createEvent(18);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          className="fa-2x"
                          icon={faSignOutAlt}
                        />
                      </div>
                      <div>{t("end")}</div>
                    </div>

                    <div>
                      <ValueUnitDescription
                        value={
                          remoteDutyLastEvent !== false &&
                          remoteDutyLastEvent !== undefined ? (
                            <span
                              style={{
                                color: `${returnColorLastEvent(
                                  remoteDutyLastEvent?.typeId
                                )}`,
                              }}
                            >
                              {`${remoteDutyLastEvent.typeName}  ${new Date(
                                remoteDutyLastEvent.time
                              ).toLocaleString(t("scheduler_language"), {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}`}
                            </span>
                          ) : (
                            <> {t("none")}</>
                          )
                        }
                        description={t("last_event")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {settings.HomeOffice?.value === "1" &&
            settings.BrakHO?.value !== "1" ? (
              <div>
                <div className={styles.workTimeManagment}>
                  <div className={styles.title}>
                    <div>{t("home_office")}</div>

                    <div
                      className="addVacationButton"
                      onClick={() => {
                        history.push({
                          pathname: "/worker-time/holiday",
                          state: {
                            startDate: new Date(),
                            endDate: new Date(),
                            requestTypeFunction:
                              HolidayRequestTypeFunction.HOME_OFFICE,
                          },
                        });
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          className="addVacationButtonIcon"
                          icon={faPlusCircle}
                          size="2x"
                        />
                      </div>
                      <div className="addVacationButtonText">
                        {t("application_for_homeoffice")}
                      </div>
                    </div>
                  </div>

                  <div className={styles.elementsManagement}>
                    <div
                      className={styles.orangeButton}
                      onClick={() => {
                        createEvent(
                          1,
                          AdditionalEventType.HOME_OFFICE_ENTRANCE
                        );
                      }}
                    >
                      <div>
                        <FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />
                      </div>
                      <div>{t("begin")}</div>
                    </div>
                    <div
                      className={styles.blueButton}
                      onClick={() => {
                        createEvent(8, AdditionalEventType.HOME_OFFICE_EXIT);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          className="fa-2x"
                          icon={faSignOutAlt}
                        />
                      </div>
                      <div>{t("end")}</div>
                    </div>

                    {!(
                      settings.WebWejsciaWyjsciaHomeOfficeUkryjPrzyciskSluzbowy
                        ?.value === "1"
                    ) && (
                      <>
                        <div
                          className={styles.orangeButton}
                          onClick={() => {
                            createEvent(
                              2,
                              AdditionalEventType.HOME_OFFICE_BUSINESS_ENTRY
                            );
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              className="fa-2x"
                              icon={faSignInAlt}
                            />
                          </div>
                          <div>{t("business_entry")}</div>
                        </div>

                        <div
                          className={styles.orangeButton}
                          onClick={() => {
                            createEvent(
                              2,
                              AdditionalEventType.HOME_OFFICE_BUSINESS_EXIT
                            );
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              className="fa-2x"
                              icon={faSignOutAlt}
                            />
                          </div>
                          <div>{t("business_exit")}</div>
                        </div>
                      </>
                    )}

                    <div style={{ color: "black", fontSize: "20px" }}>
                      <ValueUnitDescription
                        value={
                          <span
                            style={{ fontWeight: "normal" }}
                          >{`${remainedOccasionalHomeOffice} ${getLocalizedDaysString(
                            remainedOccasionalHomeOffice,
                            currentLanguage
                          )}`}</span>
                        }
                        description={t(
                          "remained_days_for_occasional_home_office"
                        )}
                      />
                    </div>

                    <div>
                      <ValueUnitDescription
                        value={
                          homeOfficeLastEvent !== false &&
                          homeOfficeLastEvent !== undefined ? (
                            <span
                              style={{
                                color: `${returnColorLastEvent(
                                  homeOfficeLastEvent?.typeId
                                )}`,
                              }}
                            >
                              {`${homeOfficeLastEvent.typeName}  ${new Date(
                                homeOfficeLastEvent.time
                              ).toLocaleString(t("scheduler_language"), {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}`}
                            </span>
                          ) : (
                            <> {t("none")}</>
                          )
                        }
                        description={t("last_event")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
        {settings.WebStronaGlownaUkryjSzybkieZadania?.value !== "1" && (
          <div>
            <QuickTask />
          </div>
        )}
      </div>
    </>
  );
};

export default HomePageContent;
