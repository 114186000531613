import useNavLinksArrayAgent from "./Agent/navLinksArray";
import useNavLinksArraysEmployee from "./Employee/navLinksArray";
import useNavLinksArrayOhs from "./OHS/navLinksArray";
import useNavLinksArraysPfso from "./PFSO/navLinksArray";
import useNavLinksArraysReception from "./Reception/navLinksArray";
import useNavLinksArraysSecurity from "./Security/navLinksArray";
import useNavLinksArraysShiftDirector from "./ShiftDirector/navLinksArray";
import useNavLinksArraySuperriorAc from "./SuperiorAc/navLinksArray";
import useNavLinksArraySuperriorTime from "./SuperiorTime/navLinksArray";
import useNavLinksArrayWorkerAc from "./WorkerAc/navLinksArray";
import useNavLinksArrayWorkerTime from "./WorkerTime/navLinksArray";
import useNavLinksArrayOperationalSupport from "./OperationalSupport/navLinksArray";
import useNavLinksArrayEventsPreview from "./EventsPreview/navLinksArray";
import useNavLinksArrayGuard from "./Guard/navLinksArray";

export default function useNavbarsArray() {
  const navLinksArraySuperriorTime = useNavLinksArraySuperriorTime();
  const navLinksArraySuperriorAc = useNavLinksArraySuperriorAc();
  const navLinksArrayWorkerTime = useNavLinksArrayWorkerTime();
  const navLinksArrayWorkerAc = useNavLinksArrayWorkerAc();
  const navLinksArrayAgent = useNavLinksArrayAgent();
  const navLinksArraysSecurity = useNavLinksArraysSecurity();
  const navLinksArraysPfso = useNavLinksArraysPfso();
  const navLinksArraysShiftDirector = useNavLinksArraysShiftDirector();
  const navLinksArraysEmployee = useNavLinksArraysEmployee();
  const navLinksArraysReception = useNavLinksArraysReception();
  const navLinksArraysOhs = useNavLinksArrayOhs();
  const navLinksArraysOperationalSupport = useNavLinksArrayOperationalSupport();
  const navLinksArraysEventsPreview = useNavLinksArrayEventsPreview();
  const navLinksArrayGuard = useNavLinksArrayGuard();

  return [
    navLinksArraySuperriorTime,
    navLinksArrayWorkerTime,
    navLinksArraySuperriorAc,
    navLinksArrayWorkerAc,
    navLinksArrayAgent,
    navLinksArraysSecurity,
    navLinksArraysPfso,
    navLinksArraysShiftDirector,
    navLinksArraysEmployee,
    navLinksArraysReception,
    navLinksArraysOhs,
    navLinksArraysOperationalSupport,
    navLinksArraysEventsPreview,
    navLinksArrayGuard,
  ];
}
