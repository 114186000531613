import React, { useCallback, useEffect, useRef, useState } from "react";
import "./loginPage.scss";
import LogoSkalmex from "./skalmex-logo-white.png";
import SelectModule from "./selectModule";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { setAuthUser } from "../../reducers/session";
import { useAppDispatch } from "../../store/hooks";
import ChangePasswordPopup from "./changePasswordPopup";
import { logIn } from "./logIn";
import erro400getTranslatedErrorString from "../../HelpersFunctions/erro400getTranslatedErrorString";
import { Link, useHistory } from "react-router-dom";
import { profilesRoutesDictionary } from "../ProfilesRouting/profilesDictionaries";

function LoginPageContent(props) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const [openSelectProfile, setOpenSelectProfile] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [verificationCodeVisible, setVerificationCodeVisible] = useState(false);

  const [profiles, setProfiles] = useState<any[]>([]);
  const [token, setToken] = useState("");
  const [tokenDecoded, setTokenDecoded] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [fullName, setFullName] = useState("");
  const [isErrorMessage, setIsErrorMessage] = useState<false | string>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [previousPath, setPreviousPath] = useState<string | undefined>();

  let isMounted = useRef(true);

  const afterReceivingToken = useCallback(
    (data) => {
      let tokenDecodedLocal: any = jwt_decode(data);
      let profilesArray = JSON.parse(tokenDecodedLocal.profiles);

      tokenDecodedLocal.profiles = profilesArray;
      setTokenDecoded(tokenDecodedLocal);
      setToken(data);

      if (tokenDecodedLocal.isChangePasswordRequired === "True") {
        setOpenChangePassword(true);
        return;
      }

      if (history.location.state) {
        let tempPreviousPath: string = (
          history.location.state as any
        ).from.pathname.toLowerCase();
        let pathParts = tempPreviousPath.split("/");
        if (pathParts && pathParts.length >= 2) {
          let profileType: number = -1;

          Object.keys(profilesRoutesDictionary).forEach((key) => {
            if (
              profilesRoutesDictionary[key].toLowerCase() ===
              pathParts[1].toLowerCase()
            ) {
              profileType = parseInt(key);
            }
          });

          if (profileType > -1) {
            let foundProfiles = profilesArray.filter(
              (el) => el.type === profileType
            );

            if (foundProfiles && foundProfiles.length === 1) {
              logIn({
                token: data,
                tokenDecoded: tokenDecodedLocal,
                selectedProfile: foundProfiles[0],
                isChangingProfileOldProfileType: false,
                isChangingProfileOldProfileSubjectId: false,
                setAuthUserToRedux: (authUser) =>
                  dispatch(setAuthUser(authUser)),
                resetReduxState: false,
              });

              history.push(tempPreviousPath);
              return;
            } else {
              setPreviousPath(tempPreviousPath);
              profilesArray = profilesArray.filter(
                (el) => el.type === profileType
              );
            }
          }
        }
      }

      if (profilesArray.length === 1) {
        logIn({
          token: data,
          tokenDecoded: tokenDecodedLocal,
          selectedProfile: profilesArray[0],
          isChangingProfileOldProfileType: false,
          isChangingProfileOldProfileSubjectId: false,
          setAuthUserToRedux: (authUser) => dispatch(setAuthUser(authUser)),
          resetReduxState: false,
        });
        return;
      }
      if (isMounted.current === false) return;

      setProfiles(profilesArray);

      setToken(data);
      setFullName(
        tokenDecodedLocal.firstName + " " + tokenDecodedLocal.lastName
      );
      setOpenSelectProfile(true);
    },
    [dispatch, history]
  );

  const logByWindowsDomain = useCallback(async () => {
    setIsFetching(true);
    setIsErrorMessage(false);

    await fetch(
      window.globalConfig.API_URL + "/api/account/login-by-windows-domain",
      {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200) return response.json();
        else if (response.status === 400) {
          response.json().then((responseErrors) => {
            setIsErrorMessage(
              erro400getTranslatedErrorString(responseErrors.errors, t)
            );
          });
        } else {
          let error: string = t("something_went_wrong");
          setIsErrorMessage(error);
        }
        throw response.status;
      })
      .then((data) => {
        afterReceivingToken(data);
      })
      .catch(() => {});
    if (isMounted.current === false) return;
    setIsFetching(false);
  }, [afterReceivingToken, t]);

  useEffect(() => {
    if (window.globalConfig?.LOG_BY_WINDOWS_DOMAIN) {
      logByWindowsDomain();
    }
  }, [logByWindowsDomain]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (login === "" || password === "") return;
    setIsFetching(true);
    setIsErrorMessage(false);

    await fetch(window.globalConfig.API_URL + "/api/account/login", {
      method: "POST",
      credentials: window.globalConfig?.LOG_BY_WINDOWS_DOMAIN
        ? "include"
        : "omit",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login: login,
        password: password,
        verificationCode: verificationCode,
      }),
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        else if (response.status === 400) {
          response.json().then((responseErrors) => {
            let validationErrors = erro400getTranslatedErrorString(
              responseErrors.errors,
              t
            );

            Object.keys(responseErrors.errors).forEach((errorObject) => {
              responseErrors.errors[errorObject].forEach((errorString) => {
                if (errorString === "INVALID_SECURITY_CODE") {
                  if (!verificationCodeVisible) {
                    validationErrors = "";
                    setVerificationCodeVisible(true);
                  }
                }
              });
            });

            setIsErrorMessage(validationErrors);
          });
        } else {
          let error: string = t("something_went_wrong");
          setIsErrorMessage(error);
        }
        throw response.status;
      })
      .then((data) => {
        afterReceivingToken(data);
      })
      .catch(() => {});
    if (isMounted.current === false) return;
    setIsFetching(false);
  };

  const handleClose = () => {
    setOpenSelectProfile(false);
    setOpenChangePassword(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const LOGIN_SCREEN_TITLE_CHANGED: string | undefined =
    window.globalConfig?.LOGIN_SCREEN_TITLE;

  return (
    <div>
      <div className="loginPage">
        <div>
          <SelectModule
            open={openSelectProfile}
            profiles={profiles}
            tokenDecoded={tokenDecoded}
            token={token}
            fullName={fullName}
            handleClose={handleClose}
            previousPath={previousPath}
          />
          <ChangePasswordPopup
            handleClose={handleClose}
            open={openChangePassword}
            token={token}
          />
          <div>
            <div className="loginForm">
              <div className="loginFormHeader">
                <div>
                  {LOGIN_SCREEN_TITLE_CHANGED
                    ? t(LOGIN_SCREEN_TITLE_CHANGED)
                    : t("login_to_webRCP")}
                </div>
                <div>{t("log_in_to_use_skalfi_net")}</div>
              </div>
              <div>
                <form className="loginInputs" onSubmit={onSubmit}>
                  <div>
                    <input
                      disabled={verificationCodeVisible}
                      className="outlined-basic inputStyle"
                      name="login"
                      value={login}
                      onChange={(e) => setLogin(e.target.value)}
                      type="text"
                      placeholder="Nazwa użytkownika"
                    />
                  </div>
                  <div>
                    <input
                      disabled={verificationCodeVisible}
                      className="outlined-basic inputStyle"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Hasło"
                      autoComplete="off"
                    />
                  </div>

                  {verificationCodeVisible && (
                    <div>
                      <input
                        className="outlined-basic inputStyle"
                        name="verificationCode"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        type="number"
                        placeholder="Kod weryfikacji"
                        autoComplete="off"
                      />
                    </div>
                  )}

                  <div className="confirmLogin">
                    <button disabled={isFetching} onClick={() => onSubmit}>
                      {isFetching ? t("loading") : t("sign_in")}
                    </button>
                  </div>
                  {isErrorMessage !== false ? (
                    <div className="errorLoginRegister">{isErrorMessage}</div>
                  ) : null}
                  {/* {error === 400 ? (
                    <div className="errorLoginRegister">
                      {t("bad_login_or_password")}
                    </div>
                  ) : null}
                  {error && error !== 400 ? (
                    <div className="errorLoginRegister">
                      {t("something_went_wrong")}
                    </div>
                  ) : null} */}
                </form>
              </div>
            </div>
            <div className="loginFooter">
              <div>
                <img src={LogoSkalmex} alt="Skalmex" />
              </div>
              <div>
                <div>
                  <a className="helpLink" href="https://pomoc.skalmex.pl">
                    {t("help")}
                  </a>
                </div>
                <div>
                  <p className="versionLabel">
                    {t("version").toLowerCase()} {process.env.REACT_APP_VERSION}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {window.globalConfig?.LOGIN_PAGE_GO_TO_NOTIFICATION_FORM && (
          <div>
            <div className="bottomLinkLoginPage">
              <div>
                <div>
                  <Link to={"/training"}>
                    <div>{t("training_2")}</div>
                  </Link>
                </div>
                <div>
                  <Link to={"/performer-add-notification"}>
                    <div>{t("form_notification")}</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPageContent;
