import { StatusInPortsType } from "../../../Constants/statusInPortsType";
import { ProfileType } from "../../../enums/profileType";
import ContentNotificationsInPorts from "../../helpersComponents/NotificationsInPorts/ContentNotificationsInPorts";

const NotificationInPortsEmployee = () => {
  const EMPLOYEE_EMAIL_REQUIRED: boolean | undefined =
    window.globalConfig.EMPLOYEE_EMAIL_IN_NOTIFICATION;

  return (
    <ContentNotificationsInPorts
      pathApiNotifications="employee/notification-guests-in-ports"
      pathApiChangeConfirmationStatus="employee/notification-change-status"
      pathApiPermissionZones="employee/permission-zones?isActive=true&forEmployee=true"
      pathApiAttachments="employee/attachments-in-ports"
      statusConfirmationId={StatusInPortsType.PRE_APPROVED}
      ifHideConfirmCondition={(row, currentUser) => {
        return (
          row.notification.pfsoConfirmationId !== null ||
          row.notification.confirmationStatus ===
            StatusInPortsType.PRE_APPROVED ||
          row.notification.confirmationStatus === StatusInPortsType.APPROVED ||
          (row.notification.acSuperiorEmail?.toLowerCase() !==
            currentUser?.email?.toLowerCase() &&
            row.notification.acSuperiorPhoneOrRadio !==
              currentUser?.phoneNumber) ||
          (!EMPLOYEE_EMAIL_REQUIRED &&
            !currentUser.departments
              ?.map((d) => d.id)
              .includes(row.notification.targetDepartmentId!))
        );
      }}
      ifHideRejectCondition={(row, currentUser) => {
        return (
          row.notification.pfsoConfirmationId !== null ||
          row.notification.confirmationStatus === StatusInPortsType.REJECTED ||
          row.notification.confirmationStatus === StatusInPortsType.APPROVED ||
          (row.notification.acSuperiorEmail?.toLowerCase() !==
            currentUser?.email?.toLowerCase() &&
            row.notification.acSuperiorPhoneOrRadio !==
              currentUser?.phoneNumber) ||
          (!EMPLOYEE_EMAIL_REQUIRED &&
            !currentUser.departments
              ?.map((d) => d.id)
              ?.includes(row.notification.targetDepartmentId!))
        );
      }}
      layoutSettingName="layoutEmployeeNotificationsRecords"
      profileType={ProfileType.EMPLOYEE}
      notificationPathName="employee/home"
    />
  );
};

export default NotificationInPortsEmployee;
