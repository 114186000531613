import { ProfileType } from "../../../enums/profileType";
import ServicesAndDeliveriesContent from "../../helpersComponents/ServicesAndDeliveries/ServicesAndDeliveriesContent";

const ServicesAndDeliveriesAgent = () => {
  return (
    <ServicesAndDeliveriesContent
      pathApiServicesAndDeliveries="agent/deliveries-services"
      pathApiCompaniesInPorts="agent/companies-in-ports"
      pathApiArrivalsOfShips="agent/arrivals-of-ships"
      pathApiGuests="agent/guests"
      pathApiTypesOfVisit="agent/delivery-service-types"
      layoutSettingName="layoutAgentDeliveriesAndServicesRecords"
      profileType={ProfileType.AGENT}
    />
  );
};

export default ServicesAndDeliveriesAgent;
