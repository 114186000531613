import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import Button from "../../../helpersComponents/Button/button";
import SelectMenu from "../../../helpersComponents/SelectMenu/selectMenu";
import { useHistory } from "react-router-dom";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import RowDetails from "./rowDetails";
import FiltersSelectMenu from "./filtersSelectMenu";
import { getMinutesFromDatesHoursAndMinutes } from "../../../../HelpersFunctions/dateAndTime/getMinutesFromDatesHoursAndMinutes";
import styles from "./workersRecords.module.scss";
import useColumns from "./useColumns";
import useSummaryRowProps from "./useSummaryRowProps";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import WorkersSelectMenu from "./SelectMenus/WorkersSelectMenu";
import AddEditHolideyRequestPopup from "./../../../helpersComponents/Requests/Holiday/AddEditHolidayRequestPopup";
import AddEditHoursRequestPopup from "./../../../helpersComponents/Requests/Hours/AddEditHoursRequestPopup";
import CompleteAccordingToTheStandardPopup from "./Popups/CompleteAccordingToTheStandard/CompleteAccordingToTheStandardPopup";
import AddCommentPopup from "./Popups/AddCommentPopup/AddCommentPopup";
import { EvidenceOperationType } from "../../../../enums/EvidenceOperationType";
import CheckboxStyled from "../../../helpersComponents/MaterialUi/Checkbox/checkbox";

const CompleteAccordingToTheStandardPopupComponent = ({
  isOpen,
  summariesIds,
  setPopupCompleteAccordingToTheNormRequestParams,
  refreshData,
}) => {
  return (
    <CompleteAccordingToTheStandardPopup
      open={isOpen}
      summariesIds={summariesIds}
      onApplyChanges={() => {
        setPopupCompleteAccordingToTheNormRequestParams({
          isOpen: false,
          summariesIds: [],
        });
        refreshData();
      }}
      onCancelChanges={() => {
        setPopupCompleteAccordingToTheNormRequestParams({
          isOpen: false,
          summariesIds: [],
        });
      }}
    />
  );
};

const AddCommentPopupComponent = ({
  isOpen,
  summariesIds,
  setPopupAddCommentRequestParams,
  refreshData,
}) => {
  return (
    <AddCommentPopup
      open={isOpen}
      summariesIds={summariesIds}
      onApplyChanges={() => {
        setPopupAddCommentRequestParams({
          isOpen: false,
          summariesIds: [],
        });

        refreshData();
      }}
      onCancelChanges={() => {
        setPopupAddCommentRequestParams({
          isOpen: false,
          summariesIds: [],
        });
      }}
    />
  );
};

const WorkersRecordsContent = () => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const [ifWasFirstTableRender, setIfWasFirstTableRender] =
    useState<boolean>(false);
  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [allWorkers, setAllWorkers] = useState<any[]>([]);
  const [selectedWorkersString, setSelectedWorkersString] = useState<
    string | undefined
  >();
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);

  const [
    summaryRowProps,
    // setTotalItems
  ] = useSummaryRowProps();

  const [startDate, setStartDate] = useState(
    history.location.state?.dateFrom
      ? history.location.state.dateFrom
      : getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(
    history.location.state?.dateTo
      ? history.location.state.dateTo
      : getEndOfDate(new Date(), "Month")
  );
  const [rows, setRows] = useState<{ [key in string]: any }[] | null>(null);
  const [columnsWidth, setColumnsWidth] = useState<
    { columnName: string; width: string }[]
  >([]);

  const columns = useColumns(t);
  const [summariesBody, setSummariesBody] = useState<false | string>(false);

  const [fetchingSummariesState, fetchSummariesAgain] = useFetchOtherThanGET({
    path: "superior-time/daily-summaries",
    method: "POST",
    contentType: "application/json",
    body: summariesBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  const [popupAddHolidayRequestParams, setPopupAddHolidayRequestParams] =
    useState<IAddHolidayRequestPopupParams>({
      isOpen: false,
      timeWorkerId: 0,
      defaultDay: new Date(),
    });

  const [popupAddHourRequestParams, setPopupAddHourRequestParams] =
    useState<IAddHourRequestPopupParams>({
      isOpen: false,
      timeWorkerId: 0,
      defaultDay: new Date(),
    });

  const [
    popupCompleteAccordingToTheStandardRequestParams,
    setPopupCompleteAccordingToTheStandardRequestParams,
  ] = useState<ICompleteAccordingToTheNormRequestPopupParams>({
    isOpen: false,
    summariesIds: [],
  });

  const [popupAddCommentRequestParams, setPopupAddCommentRequestParams] =
    useState<IAddCommentRequestPopupParams>({
      isOpen: false,
      summariesIds: [],
    });

  useEffect(() => {
    if (startDate && endDate) {
      let requestBodyObj = {
        dateFrom: convertDateFetch(startDate),
        dateTo: convertDateFetch(endDate),
        timeWorkersIds: allWorkersSelected
          ? []
          : selectedWorkersString
          ? selectedWorkersString.split(",").map((e) => parseInt(e))
          : [],
      };

      setSummariesBody(JSON.stringify(requestBodyObj));
    }
  }, [startDate, endDate, allWorkersSelected, selectedWorkersString]);

  const getSelectedTimeWorkerId = () => {
    if (selectedRows && selectedRows.length > 0) {
      return Number(selectedRows[0].split("_")[1]);
    }

    return 0;
  };

  useEffect(() => {
    if (fetchingSummariesState.isError) {
      setSelectedWorkersString(undefined);
      return;
    }
    if (fetchingSummariesState.response === undefined) return;
    if (!Array.isArray(fetchingSummariesState.response.resJson)) return;
    let data = fetchingSummariesState.response.resJson;

    data.forEach((worker, index) => {
      data[index].id = `${index}_${worker.timeWorkerId}`;
      data[index]["name"] = worker.firstName + " " + worker.lastName;
      data[index]["organizationalUnits"] = worker.organizationalUnits;
      data[index]["evidenceNumber"] = worker.evidenceNumber;
      data[index]["absencesString"] =
        worker.absences > 0 ? convertMinsToHrsMins(worker.absences) : "";

      data[index]["absencesWithWorkedNormString"] =
        worker.absencesWithWorkedNorm > 0
          ? convertMinsToHrsMins(worker.absencesWithWorkedNorm)
          : "";
      data[index]["breaksString"] =
        worker.breaks > 0 ? convertMinsToHrsMins(worker.breaks) : "";
      data[index]["businessEntriesExitsString"] =
        worker.businessEntriesExits > 0
          ? convertMinsToHrsMins(worker.businessEntriesExits)
          : "";
      data[index]["daysOffWithWorkedNormString"] =
        worker.daysOffWithWorkedNorm > 0
          ? convertMinsToHrsMins(worker.daysOffWithWorkedNorm)
          : "";
      data[index]["homeOfficeString"] =
        worker.homeOffice > 0 ? convertMinsToHrsMins(worker.homeOffice) : "";
      data[index]["languageLearningHoursString"] =
        worker.languageLearningHours > 0
          ? convertMinsToHrsMins(worker.languageLearningHours)
          : "";
      data[index]["lateString"] =
        worker.late > 0 ? <b>{convertMinsToHrsMins(worker.late)}</b> : "";
      data[index]["nightWorkString"] =
        worker.nightWork > 0 ? (
          <span className={styles.nightWorkCell}>
            {convertMinsToHrsMins(worker.nightWork)}
          </span>
        ) : (
          ""
        );
      data[index]["nightWorkOvertimeString"] =
        worker.nightWorkOvertime > 0
          ? convertMinsToHrsMins(worker.nightWorkOvertime)
          : "";
      data[index]["nightWorkScheduleString"] =
        worker.nightWorkSchedule > 0
          ? convertMinsToHrsMins(worker.nightWorkSchedule)
          : "";
      data[index]["normBalanceString"] = (
        <b>{convertMinsToHrsMins(worker.normBalance)}</b>
      );

      data[index]["notWorkedString"] =
        worker.notWorked > 0 ? convertMinsToHrsMins(worker.notWorked) : "";
      data[index]["overtimeString"] =
        worker.overtime > 0 ? (
          <b>{convertMinsToHrsMins(worker.overtime)}</b>
        ) : (
          ""
        );
      data[index]["overtime50String"] =
        worker.overtime50 > 0 ? (
          <b>{convertMinsToHrsMins(worker.overtime50)}</b>
        ) : (
          ""
        );
      data[index]["overtime100String"] =
        worker.overtime100 > 0 ? (
          <b>{convertMinsToHrsMins(worker.overtime100)}</b>
        ) : (
          ""
        );
      data[index]["overtime150String"] =
        worker.overtime150 > 0 ? (
          <b>{convertMinsToHrsMins(worker.overtime150)}</b>
        ) : (
          ""
        );
      data[index]["overtime200String"] =
        worker.overtime200 > 0 ? (
          <b>{convertMinsToHrsMins(worker.overtime200)}</b>
        ) : (
          ""
        );

      let beginWorkMinutes = getMinutesFromDatesHoursAndMinutes(
        new Date(worker.beginWork)
      );
      let endWorkMinutes = getMinutesFromDatesHoursAndMinutes(
        new Date(worker.endWork)
      );

      if (worker.entry > 0 || worker.exit > 0) {
        data[index]["entryString"] = convertMinsToHrsMins(worker.entry);
        data[index]["exitString"] = convertMinsToHrsMins(worker.exit);
      } else {
        data[index]["entryString"] = "";
        data[index]["exitString"] = "";
      }
      if (beginWorkMinutes > 0 || endWorkMinutes > 0) {
        data[index]["beginWorkString"] = convertMinsToHrsMins(beginWorkMinutes);
        data[index]["endWorkString"] = convertMinsToHrsMins(endWorkMinutes);
      } else {
        data[index]["beginWorkString"] = "";
        data[index]["endWorkString"] = "";
      }

      ////// new columns
      data[index]["remainsToWorkoutString"] = convertMinsToHrsMins(
        worker.remainsToWorkout,
        true
      );
      data[index]["socialBreakString"] = convertMinsToHrsMins(
        worker.socialBreak,
        true
      );
      data[index]["timeSpentOnThePremisesOfTheCompanyString"] =
        convertMinsToHrsMins(worker.timeSpentOnThePremisesOfTheCompany, true);
      data[index]["unpaidPrivatePassString"] = convertMinsToHrsMins(
        worker.unpaidPrivatePass,
        true
      );
      data[index]["workAboveNormString"] = convertMinsToHrsMins(
        worker.workAboveNorm,
        true
      );
      data[index]["workDuringDutyString"] = convertMinsToHrsMins(
        worker.workDuringDuty,
        true
      );
      data[index]["workOfAdolescentsInForbiddenWorkString"] =
        convertMinsToHrsMins(worker.workOfAdolescentsInForbiddenWork, true);
      data[index]["workOnDaysOffString"] = convertMinsToHrsMins(
        worker.workOnDaysOff,
        true
      );
      data[index]["workOnHolidayString"] = convertMinsToHrsMins(
        worker.workOnHoliday,
        true
      );
      data[index]["workOnSundaysAndHolidaysString"] = convertMinsToHrsMins(
        worker.workOnSundaysAndHolidays,
        true
      );

      data[index]["workingOffString"] = convertMinsToHrsMins(
        worker.workingOff,
        true
      );

      data[index]["absenceShortcutString"] = worker.absenceShortcut;
      data[index]["blockToEditString"] = (
        <CheckboxStyled
          inputProps={{ "aria-label": "primary checkbox" }}
          checked={worker.blockToEdit === 1}
        />
      );
      data[index]["do1String"] = convertMinsToHrsMins(worker.do1, true);
      data[index]["dutyString"] = convertMinsToHrsMins(worker.duty, true);
      data[index]["entryCompanyAreaString"] = convertMinsToHrsMins(
        worker.entryCompanyArea,
        true
      );
      data[index]["exitCompanyAreaString"] = convertMinsToHrsMins(
        worker.exitCompanyArea,
        true
      );
      data[index]["firstShiftString"] = convertMinsToHrsMins(
        worker.firstShift,
        true
      );
      data[index]["od1String"] = convertMinsToHrsMins(worker.od1, true);
      data[index]["overtime50PercentToPayString"] = convertMinsToHrsMins(
        worker.overtime50PercentToPay,
        true
      );
      data[index]["overtime50PercentToTakeString"] = convertMinsToHrsMins(
        worker.overtime50PercentToTake,
        true
      );
      data[index]["overtime100PercentToPayString"] = convertMinsToHrsMins(
        worker.overtime100PercentToPay,
        true
      );
      data[index]["overtime100PercentToTakeString"] = convertMinsToHrsMins(
        worker.overtime100PercentToTake,
        true
      );
      data[index]["privateLeaveString"] = convertMinsToHrsMins(
        worker.privateLeave,
        true
      );
      data[index]["secondShfitString"] = convertMinsToHrsMins(
        worker.secondShfit,
        true
      );
      data[index]["skTrainingString"] = convertMinsToHrsMins(
        worker.skTraining,
        true
      );
      data[index]["thirdShiftString"] = convertMinsToHrsMins(
        worker.thirdShift,
        true
      );
      data[index]["workAtCostCenterString"] = convertMinsToHrsMins(
        worker.workAtCostCenter,
        true
      );
      data[index]["workOnHolidaysString"] = convertMinsToHrsMins(
        worker.workOnHolidays,
        true
      );
      data[index]["workedString"] = convertMinsToHrsMins(worker.worked, true);

      ///////////////////////////////// coloring cells

      if (worker.normativeTime < worker.workTimeStandard) {
        data[index]["normativeTimeString"] = (
          <span className={styles.redColor}>
            {convertMinsToHrsMins(worker.normativeTime, true)}
          </span>
        );
      } else {
        data[index]["normativeTimeString"] = convertMinsToHrsMins(
          worker.normativeTime,
          true
        );
      }

      if (worker.workingTime > worker.workTimeStandard) {
        data[index]["workingTimeString"] = (
          <span className={styles.greenColor}>
            {convertMinsToHrsMins(worker.workingTime, true)}
          </span>
        );
      } else {
        data[index]["workingTimeString"] = convertMinsToHrsMins(
          worker.workingTime,
          true
        );
      }

      data[index]["workTimeStandardString"] = convertMinsToHrsMins(
        worker.workTimeStandard,
        true
      );

      data[index]["confirmedByString"] = worker.timeSuperiorName;
    });

    /// NR - worker.workTimeStandard
    /// CN - worker.normativeTime
    /// CP - worker.workingTime

    let columnsWidthLocal: { columnName: string; width: string }[] = [];
    columns.forEach((column) => {
      if (column.name === "day") {
        columnsWidthLocal.push({ columnName: column.name, width: "100" });
        return;
      }

      if (column.name === "organizationalUnits") {
        columnsWidthLocal.push({ columnName: column.name, width: "110" });
        return;
      }

      if (column.name === "name") {
        columnsWidthLocal.push({ columnName: column.name, width: "200" });
        return;
      }

      if (column.name === "confirmedByString") {
        columnsWidthLocal.push({ columnName: column.name, width: "200" });
        return;
      }

      if (column.name === "evidenceNumber") return;
      columnsWidthLocal.push({ columnName: column.name, width: "50" });
    });
    setSelectedWorkersString(undefined);
    setColumnsWidth(columnsWidthLocal);
    setRows(data);
  }, [
    fetchingSummariesState.isError,
    fetchingSummariesState.response,
    columns,
    fetchingSummariesState.isFetching,
    t,
  ]);

  useEffect(() => {
    if (selectedWorkersString) {
      fetchSummariesAgain();
    }
  }, [selectedWorkersString, fetchSummariesAgain]);

  const confirmFilters = useCallback(() => {
    let selectedWorkersLocal = [...selectedWorkers];
    if (selectedWorkers.length === 0) {
      allWorkers.forEach((worker) => {
        selectedWorkersLocal.push(worker.id);
      });
    }

    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
  }, [allWorkers, selectedWorkers]);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [rowsFiltered, setRowsFiltered] = useState<{}[] | null>(null);
  const [currentFilter, setcurrentFilter] = useState<Filters>("none");

  useEffect(() => {
    if (rows === null) return;
    let filteredRows: {}[] = [];
    if (currentFilter === "none") {
      setRowsFiltered(rows);
      return;
    } else if (currentFilter === "show_gaps") {
      rows.forEach((row) => {
        if (row?.workingTime < row?.workTimeStandard && row?.workingTime > 0) {
          filteredRows.push(row);
        }
      });
    } else if (currentFilter === "show_lateness") {
      rows.forEach((row) => {
        if (row?.late > 0) {
          filteredRows.push(row);
        }
      });
    } else if (currentFilter === "show_business_i_o") {
      rows.forEach((row) => {
        if (row?.businessEntriesExits > 0) {
          filteredRows.push(row);
        }
      });
    } else if (currentFilter === "show_private_outputs") {
      rows.forEach((row) => {
        if (row?.unpaidPrivatePass > 0) {
          filteredRows.push(row);
        }
      });
    } else if (currentFilter === "show_potential_overtime") {
      rows.forEach((row) => {
        if (
          row?.timeSpentOnThePremisesOfTheCompany - row?.normativeTime >=
          30
        ) {
          filteredRows.push(row);
        }
      });
    } else if (currentFilter === "show_overtime") {
      rows.forEach((row) => {
        if (row?.overtime > 0) {
          filteredRows.push(row);
        }
      });
    } else if (currentFilter === "unexcused_absence") {
      rows.forEach((row) => {
        if (
          typeof row?.absenceShortcut === "string" ||
          row?.absenceShortcut instanceof String
        ) {
          let spreadedShorcut = [...row.absenceShortcut];
          if (spreadedShorcut?.[0] === "N" && spreadedShorcut?.[1] === "N") {
            filteredRows.push(row);
          }
        }
      });
    }
    setRowsFiltered(filteredRows);
  }, [currentFilter, rows]);

  const ConfirmFilters = (
    <div className={styles.filters}>
      <SelectMenu
        name={t("select_period")}
        items={[
          {
            optionName: t("yesterday"),
            onClick: () => {
              let actualDate = new Date();
              actualDate.setDate(actualDate.getDate() - 1);
              setStartDate(getBeginningOfDate(actualDate, "Day"));
              setEndDate(getEndOfDate(actualDate, "Day"));
            },
          },
          {
            optionName: t("this_week"),
            onClick: () => {
              let actualDate = new Date();
              setStartDate(getBeginningOfDate(actualDate, "CurrentWeek"));
              setEndDate(getEndOfDate(actualDate, "CurrentWeek"));
            },
          },
          {
            optionName: t("current_month"),
            onClick: () => {
              let actualDate = new Date();
              actualDate.setMonth(actualDate.getMonth());
              setStartDate(getBeginningOfDate(actualDate, "Month"));
              setEndDate(getEndOfDate(actualDate, "Month"));
            },
          },
          {
            optionName: t("previous_month"),
            onClick: () => {
              let actualDate = new Date();
              actualDate.setMonth(actualDate.getMonth() - 1);
              setStartDate(getBeginningOfDate(actualDate, "Month"));
              setEndDate(getEndOfDate(actualDate, "Month"));
            },
          },
          {
            optionName: t("current_quarter"),
            onClick: () => {
              let actualDate = new Date();
              setStartDate(getBeginningOfDate(actualDate, "CurrentQuarter"));
              setEndDate(getEndOfDate(actualDate, "CurrentQuarter"));
            },
          },
        ]}
      />
      <div>
        <Button
          onClick={() => {
            setIfWasFirstTableRender(true);
            confirmFilters();
          }}
        >
          {t("refresh")}
        </Button>
      </div>
    </div>
  );

  useEffect(() => {
    if (history.location.state?.workerId !== undefined) {
      setSelectedWorkers([history.location.state.workerId]);
      setSelectedWorkersString(history.location.state.workerId.toString());
    }
  }, [history]);

  const refreshData = useCallback(() => {
    setIfWasFirstTableRender(true);
    confirmFilters();
  }, [confirmFilters]);

  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: "superior-time/summaries-operations",
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: false,
    disableSuccessSnackbar: false,
    forceSnackbar: true,
    successCallback: refreshData,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const btnLockSelectedDaysClick = useCallback((summariesIds) => {
    let obj = {
      summariesIds: summariesIds,
      operationType: EvidenceOperationType.LOCK_SELECTED_DAYS,
    };

    setBodyRequest(JSON.stringify(obj));
  }, []);

  const btnUnlockSelectedDaysClick = useCallback((summariesIds) => {
    let obj = {
      summariesIds: summariesIds,
      operationType: EvidenceOperationType.UNLOCK_SELECTED_DAYS,
    };

    setBodyRequest(JSON.stringify(obj));
  }, []);

  const btnLockDayAndAllPreviousDaysClick = useCallback((summariesIds) => {
    let obj = {
      summariesIds: summariesIds,
      operationType: EvidenceOperationType.LOCK_DAY_AND_ALL_PREVIOUS,
    };

    setBodyRequest(JSON.stringify(obj));
  }, []);

  const btnUnlockDayAndAllNextDaysClick = useCallback((summariesIds) => {
    let obj = {
      summariesIds: summariesIds,
      operationType: EvidenceOperationType.UNLOCK_DAY_AND_ALL_NEXT,
    };

    setBodyRequest(JSON.stringify(obj));
  }, []);

  const btnAcceptClick = useCallback((summariesIds) => {
    let obj = {
      summariesIds: summariesIds,
      operationType: EvidenceOperationType.ACCEPT,
    };

    setBodyRequest(JSON.stringify(obj));
  }, []);

  const btnAcceptAndLockClick = useCallback((summariesIds) => {
    let obj = {
      summariesIds: summariesIds,
      operationType: EvidenceOperationType.ACCEPT_AND_LOCK,
    };

    setBodyRequest(JSON.stringify(obj));
  }, []);

  return (
    <>
      <TopBanner pathName={t("workers_records")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName="layoutSuperiorTimeListsSelectWorkers"
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={ConfirmFilters}
          />
        </div>
        <div>
          {ifWasFirstTableRender && (
            <TableComponentMemo
              layoutSettingName="layoutSuperiorTimeWorkersrecords"
              rows={rowsFiltered ? rowsFiltered : []}
              columns={columns}
              selectData={(ids) => {
                setSelectedRows(ids as string[]);
              }}
              selectedData={selectedRows}
              toolbarComponent={
                <div>
                  <FiltersSelectMenu
                    setcurrentFilter={setcurrentFilter}
                    currentFilter={currentFilter}
                  />
                </div>
              }
              summaryRowProps={summaryRowProps}
              leftColumns={["dayString", "name"]}
              leftFixedSelectionColumn={true}
              columnsWidth={columnsWidth}
              rowDetail={RowDetails}
              pageSize={31}
              filteringRowDefaultHidden={true}
              actionsColumnUpdated={{
                leftToolbarComponent: {
                  ifShow: true,
                  customComponent: () => {
                    return (
                      <WorkersSelectMenu
                        rows={rows}
                        selectedRows={selectedRows}
                        setPopupAddHolidayRequestParams={
                          setPopupAddHolidayRequestParams
                        }
                        setPopupAddHourRequestParams={
                          setPopupAddHourRequestParams
                        }
                        setPopupCompleteAccordingToTheNormRequestParams={
                          setPopupCompleteAccordingToTheStandardRequestParams
                        }
                        setPopupAddCommentRequestParams={
                          setPopupAddCommentRequestParams
                        }
                        btnLockSelectedDaysClick={btnLockSelectedDaysClick}
                        btnUnlockSelectedDaysClick={btnUnlockSelectedDaysClick}
                        btnLockDayAndAllPreviousDaysClick={
                          btnLockDayAndAllPreviousDaysClick
                        }
                        btnUnlockDayAndAllNextDaysClick={
                          btnUnlockDayAndAllNextDaysClick
                        }
                        btnAcceptClick={btnAcceptClick}
                        btnAcceptAndLockClick={btnAcceptAndLockClick}
                      />
                    );
                  },
                },
              }}
              fetchingState={{
                isFetching: fetchingSummariesState.isFetching,
                isError: fetchingSummariesState.isError,
                fetchAgain: fetchSummariesAgain,
              }}
              cellPadding={"1px;"}
            />
          )}
        </div>

        <div>
          <AddEditHolideyRequestPopup
            open={popupAddHolidayRequestParams.isOpen}
            timeWorkerId={getSelectedTimeWorkerId()}
            onApplyChanges={() => {
              setPopupAddHolidayRequestParams({
                isOpen: false,
                timeWorkerId: 0,
                defaultDay: new Date(),
              });
            }}
            onCancelChanges={() => {
              setPopupAddHolidayRequestParams({
                isOpen: false,
                timeWorkerId: 0,
                defaultDay: new Date(),
              });
            }}
            row={{}}
            defaultDay={popupAddHolidayRequestParams.defaultDay}
          />
        </div>

        <div>
          <AddEditHoursRequestPopup
            open={popupAddHourRequestParams.isOpen}
            timeWorkerId={getSelectedTimeWorkerId()}
            onApplyChanges={() => {
              setPopupAddHourRequestParams({
                isOpen: false,
                timeWorkerId: 0,
                defaultDay: new Date(),
              });
            }}
            onCancelChanges={() => {
              setPopupAddHourRequestParams({
                isOpen: false,
                timeWorkerId: 0,
                defaultDay: new Date(),
              });
            }}
            row={{}}
            defaultDay={popupAddHourRequestParams.defaultDay}
          />
        </div>

        <div>
          {popupCompleteAccordingToTheStandardRequestParams.isOpen && (
            <CompleteAccordingToTheStandardPopupComponent
              isOpen={popupCompleteAccordingToTheStandardRequestParams.isOpen}
              summariesIds={
                popupCompleteAccordingToTheStandardRequestParams.summariesIds
              }
              setPopupCompleteAccordingToTheNormRequestParams={
                setPopupCompleteAccordingToTheStandardRequestParams
              }
              refreshData={refreshData}
            />
          )}
        </div>

        <div>
          {popupAddCommentRequestParams.isOpen && (
            <AddCommentPopupComponent
              isOpen={popupAddCommentRequestParams.isOpen}
              summariesIds={popupAddCommentRequestParams.summariesIds}
              setPopupAddCommentRequestParams={setPopupAddCommentRequestParams}
              refreshData={refreshData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WorkersRecordsContent;
